import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SubMenuItem, mySecurePracticeSubMenuItems, pbhsWebisteAdminSubMenuItems } from './sidebar-sub-menu-items';

import { ReactComponent as MySecurePracticeButtonSvg } from '../../../assets/menu/icon-my-secure-practice.svg';
import { ReactComponent as PBHSWebsiteAdminButtonSvg } from '../../../assets/menu/icon-pbhs-website-admin-portal.svg';

const useStyles = makeStyles(() => ({
    menuPaper: {
        backgroundColor: 'white',
        padding: '10px',
        width: 'max-content'
    }
}));

export const enum SubMenuType {
    MY_SECURE_PRACTICE,
    PBHS_WEBSITE_ADMIN
}

interface SubMenuProps {
    menuType: SubMenuType;
    locationId?: number;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

const subMenuItemData = (menuType: SubMenuType, locationId?: number): SubMenuItem[] => {
    switch (menuType) {
        case SubMenuType.MY_SECURE_PRACTICE: {
            return mySecurePracticeSubMenuItems(locationId!);
        }
        case SubMenuType.PBHS_WEBSITE_ADMIN: {
            return pbhsWebisteAdminSubMenuItems(locationId!);
        }
    }
};

export function getSidebarItemImage(menuType: SubMenuType) {
    switch (menuType) {
        case SubMenuType.MY_SECURE_PRACTICE: {
            return <MySecurePracticeButtonSvg />;
        }
        case SubMenuType.PBHS_WEBSITE_ADMIN: {
            return <PBHSWebsiteAdminButtonSvg />;
        }
    }
}

export default function SubMenu({ menuType, locationId, anchorEl, onClose }: SubMenuProps) {
    const classes = useStyles();

    return (
        <Menu
            classes={{ paper: classes.menuPaper }}
            id='sidebar-item-submenu'
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
            }}
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            {subMenuItemData(menuType, locationId).map(subMenuItem => {
                return <MenuItem key={subMenuItem.label} onClick={() => window.open(subMenuItem.url)}>{subMenuItem.label}</MenuItem>;
            })}
        </Menu>
    );
}
