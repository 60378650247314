import { Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { NotificationsActiveOutlined as NotificationIcon } from '@mui/icons-material';

const Title = styled(Typography)({
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#000'
});

const Notification = styled(NotificationIcon)({
    borderRadius: '50%',
    backgroundColor: '#EBF3FA',
    color: '#0259AB',
    fontSize: 26,
    padding: 27,
    display: 'block',
    margin: '32px auto 16px'
});

interface AlertsEmptyProps {
    type: 'active' | 'dismissed';
}

export default function AlertsEmpty({ type }: AlertsEmptyProps) {
    return (
        <Box>
            <Title variant='body1'>
                <Notification />
                You have no {type} alerts
            </Title>
        </Box>
    );
}
