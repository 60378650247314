import OutboundCallIcon from '@mui/icons-material/CallMade';
import MissedCallIcon from '@mui/icons-material/CallMissed';
import InboundCallIcon from '@mui/icons-material/CallReceived';
import FaxIcon from '@mui/icons-material/FeedOutlined';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { styled } from '@mui/material';
import { CallDirection, CallType } from '../../../../services/inbox-service/types';
import {
    InboxPreviewAvatar,
    InboxPreviewCallContentGrid,
    InboxPreviewContent
} from './styles/inbox-preview-card-styles';

const InboxCallPreviewIcon = styled(InboxPreviewAvatar)({
    height: 20
});

enum CallTypeDirection {
    InboundCall = 'Inbound Call',
    OutboundCall = 'Outbound Call',
    MissedCall = 'Missed Call',
    Voicemail = 'Voicemail',
    InboundFax = 'Inbound Fax',
    OutboundFax = 'Outbound Fax'
}

export const callTypeDirectionMap: { [key in CallType]: { [key in CallDirection]: CallTypeDirection } } = {
    [CallType.CallBridged]: {
        [CallDirection.Inbound]: CallTypeDirection.InboundCall,
        [CallDirection.Outbound]: CallTypeDirection.OutboundCall
    },
    [CallType.MissedCall]: {
        [CallDirection.Inbound]: CallTypeDirection.MissedCall,
        [CallDirection.Outbound]: CallTypeDirection.OutboundCall
    },
    [CallType.Voicemail]: {
        [CallDirection.Inbound]: CallTypeDirection.Voicemail,
        [CallDirection.Outbound]: CallTypeDirection.OutboundCall
    },
    [CallType.Fax]: {
        [CallDirection.Inbound]: CallTypeDirection.InboundFax,
        [CallDirection.Outbound]: CallTypeDirection.OutboundFax
    }
};

const getCallInfo = ({ direction, callType }: InboxCallPreviewContentProps) => {
    const callTypeDirection = callTypeDirectionMap[callType]?.[direction];

    const info = {
        colorClass: null as { background: string; color: string } | null,
        icon: null as JSX.Element | null,
        callText: callTypeDirection
    };

    switch (callTypeDirection) {
        case CallTypeDirection.InboundCall:
            info.colorClass = { background: '#D8F8D9', color: '#005E05' };
            info.icon = <InboundCallIcon style={{ fontSize: '16px' }} />;
            break;
        case CallTypeDirection.OutboundCall:
            info.colorClass = { background: '#D8F8D9', color: '#005E05' };
            info.icon = <OutboundCallIcon style={{ fontSize: '16px' }} />;
            break;
        case CallTypeDirection.MissedCall:
            info.colorClass = { background: '#F7CECD', color: '#621B16' };
            info.icon = <MissedCallIcon style={{ fontSize: '16px' }} />;
            break;
        case CallTypeDirection.Voicemail:
            info.colorClass = { background: '#CEE8FF', color: '0259AB' };
            info.icon = <VoicemailIcon style={{ fontSize: '16px' }} />;
            break;
        case CallTypeDirection.InboundFax:
            info.colorClass = { background: '#B9EBEF', color: '#005E05' };
            info.icon = <FaxIcon style={{ fontSize: '18px' }} />;
            break;
        case CallTypeDirection.OutboundFax:
            info.colorClass = { background: '#B9EBEF', color: '#005E05' };
            info.icon = <FaxIcon style={{ fontSize: '18px' }} />;
            break;
    }
    return info;
};

export interface InboxCallPreviewContentProps {
    direction: CallDirection;
    callType: CallType;
}

const InboxCallPreviewContent = ({ direction, callType }: InboxCallPreviewContentProps) => {
    const callInfo = getCallInfo({ direction, callType });

    return (
        <InboxPreviewCallContentGrid>
            <InboxCallPreviewIcon style={{ ...callInfo.colorClass }}> {callInfo.icon} </InboxCallPreviewIcon>
            <InboxPreviewContent>{callInfo.callText}</InboxPreviewContent>
        </InboxPreviewCallContentGrid>
    );
};

export default InboxCallPreviewContent;
