import { Address } from "../../../../../../../services/api-client/contact-api";

export const isAddressEmpty = (address?: Address) => {
  return (
    !address?.address1 ||
    address.address1.trim().length === 0 ||
    !address?.zip ||
    address.zip.trim().length === 0 ||
    !address?.city ||
    address.city.trim().length === 0 ||
    !address?.state ||
    address.state.trim().length === 0
  );
};
