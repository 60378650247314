import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { OidcService } from '@revenuewell/logic-oidc';

export interface CallerIdInfo {
	locationId: number;
	currentCallerId: string;
	mainPhoneNumber: string;
	isMainPhoneNumberVerified: boolean;
}

export default class CallerIdService extends AuthApiService {
    private static instance: CallerIdService;
    private config: any;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!CallerIdService.instance) CallerIdService.instance = new CallerIdService(config, oidcService);

        return CallerIdService.instance;
    }

    getCallerIdInfo = async (locationId: number): Promise<CallerIdInfo> => {
        const url = `${this.config.config.apiUrl}/location-callerids/${locationId}`;
        const response = await this.authFetch(url);
        const res: CallerIdInfo = await response.json();
        return res;
    };
}
