// create a context with useSlideOutCard hook and SlideOutCardProvider
// with a portal to render the slide out card

import { Box } from '@mui/system';
import React, { useContext, useRef, ReactElement } from 'react';

export interface SlideOutCardContext {
    portal: React.RefObject<HTMLDivElement>;
}

const SlideOutCardContext = React.createContext<SlideOutCardContext | null>(null);

export function useSlideOutCard() {
    const context = useContext(SlideOutCardContext);
    if (!context) {
        throw new Error('useSlideOutCard must be used within a SlideOutCardProvider');
    }
    return context;
}

export function SlideOutCardProvider({ children }: { children: ReactElement }) {
    const portal = useRef(null);

    return (
        <SlideOutCardContext.Provider value={{ portal }}>
            {children}
            <Box ref={portal} id='slide-out-card-portal' />
        </SlideOutCardContext.Provider>
    );
}
