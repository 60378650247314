import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  profilesSelector: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '48px 24px 24px 24px',
    backgroundColor: '#FFFFFF',
    height: '100%'
  },
  description: {
    color: '#5E6C84',
    fontSize: '14px',
    textAlign: 'center'
  },
  header: {
    textAlign: 'center',
    color: '#000000'
  },
  profile: {
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F0F1F4',
    marginTop: '5px',
    padding: '16px 16px 16px 14px',
    cursor: 'pointer'
  },
  profiles: {
    width: '100%',
    padding: '24px 0 40px 0'
  },
  profileName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& p': {
      marginLeft: '12px',
      color: '#5E6C84',
      flexShrink: 1
    },
    '& button': {
      backgroundColor: '#EFF7EE',
      width: '32px',
      height: '32px',
      '& svg': {
        width: '20px',
        height: '20px'
      }
    }
  }
});