import React from 'react'
import { styled, Box, Typography } from '@mui/material'

export const SlideoutContent = styled(Box)(() => ({
    height: '100%',
    backgroundColor: 'white',
    fontFamily: 'Roboto, sans-serif',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column'
}))

export const SlideoutHeader = styled(Box)(() => ({
    color: 'black',
    margin: '16px',
    fontWeight: '500',
    fontFamily: 'Roboto, sans-serif',
    display: 'flex',
    padding: '8px 16px',
    border: '1px solid rgb(213, 217, 221)',
    borderRadius: '8px'
}))

export const SlideoutMessages = styled(Box)(() => ({
    borderTop: '1px solid #E1E4E7',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}))

export const NoMfaMessages = styled(Typography)(() => ({
  margin: 'auto',
  width: '60%',
  textAlign: 'center'
}))

export const MfaMessage = styled(Box)(() => ({
    display: 'flex',
    margin: '20px 16px',
    flexDirection: 'column',
}))

export const MfaMessageText = styled(Box)(() => ({
    overflow: 'hidden',
    backgroundColor: 'rgb(223, 240, 252)',
    border: '1px solid rgb(206, 229, 245)',
    borderRadius: '8px',
    padding: '8px 16px',
    letterSpacing: '0.15px',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'rgb(44, 53, 66)',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: '15px'
}))

export const MfaMessageSignature = styled(Box)(() => ({
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    fontWeight: 500,
    fontSize: '12px',
    marginLeft: '6px'
}))