import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AlertsService, { Alert } from '../../services/alerts-service/alerts-service';
import { usePubnub } from '../use-pubnub/use-pubnub';
import uniqBy from 'lodash/uniqBy';
import { NotificationEvent, useNotifications } from '@revenuewell/uc-notifications-client';
import { LegacyAlertEvent } from '@revenuewell/uc-notifications-client/dist/types/notifications';

export interface SearchFilter {
    isDismissed?: boolean;
    startTime?: Date;
}

interface IAltertsContext {
    isOpen: boolean;
    alertsActive: Alert[];
    alertsDismissed: Alert[];
    toggle: () => void;
    close: () => void;
    dismiss: (alertId: string) => Promise<void>;
    search: (search: SearchFilter) => Promise<Alert[]>;
}

const AlertsContext = createContext<IAltertsContext | null>(null);

export const useAlerts = (): IAltertsContext => {
    const context = useContext(AlertsContext);
    if (!context) {
        throw new Error('useAlerts must be used within an AlertsContext');
    }

    return context;
};

export function AlertsProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const [isOpen, setIsOpen] = useState(false);
    const [alertsActive, setAlertsActive] = useState<Alert[]>([]);
    const [alertsDismissed, setAlertsDismissed] = useState<Alert[]>([]);

    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { mailboxes } = usePubnub();
    const { listen } = useNotifications();

    useEffect(() => {
        return listen('legacy-alert', (event: NotificationEvent) => {
            const { data, id } = event as LegacyAlertEvent;

            const alert: Alert = {
                id,
                ...data,
                idLocation: Number(data.idLocation),
                idCategory: Number(data.idCategory),
                isPostponed: data.isPostponed === 'True',
                isDismissed: data.isDismissed === 'True'
            };

            if (alert.isDismissed) {
                setAlertsDismissed(alerts => uniqBy([alert, ...alerts], 'id'));
            } else {
                setAlertsActive(alerts => uniqBy([alert, ...alerts], 'id'));
            }
        });
    }, [listen]);

    const alertsService = useMemo(() => AlertsService.getInstance(config, oidcService), [config, oidcService]);

    const search = useCallback(
        async ({ startTime, isDismissed }: SearchFilter = {}): Promise<Alert[]> => {
            const set = isDismissed ? setAlertsDismissed : setAlertsActive;
            const alerts = await alertsService.search({
                locationIds: mailboxes.map(mb => mb.locationId),
                startTime: startTime ? new Date(startTime) : new Date(2000, 0, 1),
                endTime: new Date(),
                count: 30,
                excludeTypes: [0],
                isDismissed: !!isDismissed
            });

            if (startTime) {
                set(oldAlerts => uniqBy([...oldAlerts, ...alerts], 'id'));
            } else {
                set(alerts);
            }

            return alerts;
        },
        [alertsService, mailboxes]
    );

    useEffect(() => {
        if (mailboxes.length > 0) {
            search();
            search({ isDismissed: true });
        }
    }, [search, mailboxes]);

    const dismiss = useCallback(
        async (alertId: string) => {
            setAlertsActive(alertsActive => alertsActive.filter(alert => alert.id !== alertId));
            await alertsService.dismiss(alertId);
        },
        [alertsService]
    );

    const toggle = useCallback(() => {
        setIsOpen(isOpen => !isOpen);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <AlertsContext.Provider value={{ alertsActive, alertsDismissed, isOpen, toggle, search, close, dismiss }}>
            {children}
        </AlertsContext.Provider>
    );
}
