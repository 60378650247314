import { useContext } from 'react'
import { WrapperNavigationCtx } from './provider'

export default function useWrapperNavigation() {
    const ctx = useContext(WrapperNavigationCtx)

    if (!ctx) throw new Error('useWrapperNavigation must be used within WrapperNavigationProvider')

    return ctx
}
