import { Button, ListItem, styled } from "@mui/material";

const LoadMoreButton = styled(Button)({
    fontSize: 15,
    padding: 16,
    height: 58,
    width: '100%',
    color: '#0675DD',
    textTransform: 'none',
})

const LoadMoreListItem = styled(ListItem)({
    pointerEvents: 'all',
    margin: '0 0 -8px 0',
    padding: 0,
})

export default function SearchLoadMore ({ onClick }: { onClick: () => void }) {
    return (
        <LoadMoreListItem>
            <LoadMoreButton onClick={onClick}>Load More</LoadMoreButton>
        </LoadMoreListItem>
    )
}