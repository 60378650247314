import * as React from 'react';
import { Avatar } from '@revenuewell/ui-icons';
import { Link } from '@mui/material';
import moment from 'moment';
import { useEventBus } from '../../../../../../../hooks/use-event-bus';
import { Contact } from '../../../../../../../services/api-client/contact-api';
import { EventPatientProfile } from '../../../../../../../types/event-bus';
import { useStyles } from './family-members.styles';

export type IProps = {
  familyMembers: Contact[];
};

export const FamilyMembers: React.FC<IProps> = props => {
  const classes = useStyles();
  const { publish } = useEventBus();

  const renderAge = (contact: Contact) => {
    return (
      <span className={classes.age}>
        {contact.dateOfBirth ? `, ${moment().diff(contact.dateOfBirth, 'years', false)}` : ''}
      </span>
    );
  };

  return (
    <>
      {props.familyMembers.length === 0 && <span>-</span>}
      {props.familyMembers.map((familyMember: Contact, i: number) => {
        return (
          <div
            data-testid={`patient-widget-family-member-${familyMember.firstName}-${familyMember.lastName}`}
            className={classes.familyMember}
            key={i}
          >
            <Avatar size='m' />
            <Link
              className={classes.name}
              onClick={() => {
                publish({
                  messageType: 'patientProfile',
                  data: {
                    patientId: familyMember.id
                  } as EventPatientProfile['data']
                });
              }}
            >
              {`${familyMember.firstName} ${familyMember.lastName}`}
              {familyMember.isResponsibleParty && <span className={classes.rp}>(RP)</span>}
              {renderAge(familyMember)}
            </Link>
          </div>
        );
      })}
    </>
  );
};
