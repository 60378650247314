import { FC, useMemo, useState } from "react";
import { PatientProfileHeader } from "../../../../header/header";
import { NavigationBreadcrumbs } from "../../../../navigation/breadcrumbs/breadcrumbs";
import { usePatientProfileWidget } from "../../../../hooks/use-patient-profile-widget";
import { ViewContentWrapper } from "../../../shared/view-content/view-content.styles";
import { useConfig, useNotification, useOidcWithAdmin } from "@revenuewell/front-end-bundle";
import { ContactApi, PatientPreferencesRequest, PatientPreferencesResponse } from "../../../../../../../../services/api-client/contact-api";
import AllCommunicationSection from "../../shared/sub-section/all-communication-section";
import CommunicationSubSectionHeader from "../../shared/sub-section/communication-sub-section-header";
import CommunicationSubSection from "../../shared/sub-section/communication-sub-section";
import { PreferencesViewWrapper } from "../../shared/preferences-view-wrapper/preferences-view-wrapper";
import AllPromotionalSubSection from "../../shared/all-promotional-sub-section/all-promotional-sub-section";

export const EmailPreferencesView: FC = () => {
  const { config } = useConfig();
  const { oidcService } = useOidcWithAdmin();
  const { showSuccess } = useNotification();
  const { patientPreferences, refreshPatientPreferences, goBack } = usePatientProfileWidget();
  const [localPreferences, setLocalPreferences] = useState<PatientPreferencesResponse>(patientPreferences);
  const contactApi = useMemo(() => new ContactApi(config, oidcService), [config, oidcService]);

  const updateLocalPreferences = (state: Partial<PatientPreferencesResponse>) => {
    const newState = { ...localPreferences, ...state };
    setLocalPreferences(newState);
  }

  const updateEmailPreferences = async () => {
    await contactApi.patchPatientPreferences(
      localPreferences.patientId,
      localPreferences as PatientPreferencesRequest
    );
    await refreshPatientPreferences();
    showSuccess("Email preferences successfully saved");
    goBack();
  };

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContentWrapper>
      <PreferencesViewWrapper onSave={updateEmailPreferences}>
        <AllCommunicationSection
          text='All Email'
          isChecked={localPreferences.emailAppointmentReminders
            && localPreferences.emailBirthdayAndHolidayCards
            && localPreferences.emailExpiringInsuranceReminder
            && localPreferences.emailPatientReactivation
            && localPreferences.emailPostOpInstructions
            && localPreferences.emailRecalls
            && localPreferences.emailReviewsAndSurveys
            && localPreferences.emailSpecialsAndNewsletters
            && localPreferences.emailTreatmentPlanFollowUps}
          onChange={value => updateLocalPreferences({
            emailAppointmentReminders: value,
            emailBirthdayAndHolidayCards: value,
            emailExpiringInsuranceReminder: value,
            emailPatientReactivation: value,
            emailPostOpInstructions: value,
            emailRecalls: value,
            emailReviewsAndSurveys: value,
            emailSpecialsAndNewsletters: value,
            emailTreatmentPlanFollowUps: value
          })}
        />
        <CommunicationSubSectionHeader displayText='ESSENTIAL COMMUNICATION' />
        <CommunicationSubSection
          text='Appointment Reminders'
          isChecked={localPreferences.emailAppointmentReminders}
          onChange={value => updateLocalPreferences({ emailAppointmentReminders: value })}
        />
        <CommunicationSubSection
          text='Post-Op Instructions'
          isChecked={localPreferences.emailPostOpInstructions}
          onChange={value => updateLocalPreferences({ emailPostOpInstructions: value })}
        />
        <CommunicationSubSection
          text='Recalls'
          isChecked={localPreferences.emailRecalls}
          onChange={value => updateLocalPreferences({ emailRecalls: value })}
        />
        <CommunicationSubSection
          text='Patient Reactivation'
          isChecked={localPreferences.emailPatientReactivation}
          onChange={value => updateLocalPreferences({ emailPatientReactivation: value })}
        />
        <CommunicationSubSectionHeader displayText='MARKETING AND PROMOTIONS' />
        <AllPromotionalSubSection
          text='All Promotional Email'
          isChecked={localPreferences.emailTreatmentPlanFollowUps
            && localPreferences.emailReviewsAndSurveys
            && localPreferences.emailBirthdayAndHolidayCards
            && localPreferences.emailSpecialsAndNewsletters
            && localPreferences.emailExpiringInsuranceReminder}
          onChange={value => updateLocalPreferences({
            emailTreatmentPlanFollowUps: value,
            emailReviewsAndSurveys: value,
            emailBirthdayAndHolidayCards: value,
            emailSpecialsAndNewsletters: value,
            emailExpiringInsuranceReminder: value
          })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Treatment Plan Follow-ups'
          isChecked={localPreferences.emailTreatmentPlanFollowUps}
          onChange={value => updateLocalPreferences({ emailTreatmentPlanFollowUps: value })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Reviews & Surveys'
          isChecked={localPreferences.emailReviewsAndSurveys}
          onChange={value => updateLocalPreferences({ emailReviewsAndSurveys: value })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Birthday/Holiday Cards'
          isChecked={localPreferences.emailBirthdayAndHolidayCards}
          onChange={value => updateLocalPreferences({ emailBirthdayAndHolidayCards: value })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Email Specials & Newsletters'
          isChecked={localPreferences.emailSpecialsAndNewsletters}
          onChange={value => updateLocalPreferences({ emailSpecialsAndNewsletters: value })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Expiring Insurance Reminder'
          isChecked={localPreferences.emailExpiringInsuranceReminder}
          onChange={value => updateLocalPreferences({ emailExpiringInsuranceReminder: value })}
        />
      </PreferencesViewWrapper>
    </ViewContentWrapper>
  </>
}