import SidebarItem from './sidebar-item';
import useMenu from '../../../hooks/use-menu';
import SubMenu, { SubMenuType, getSidebarItemImage } from './sidebar-sub-menu';

export type SidebarItemWithSubMenuProps = {
    menuType: SubMenuType;
    activeFrame: string;
    locationId?: number;
    title?: string;
};

export default function SidebarItemWithSubMenu({
    title,
    menuType,
    activeFrame,
    locationId
}: SidebarItemWithSubMenuProps) {
    const { anchorEl: anchorEl, openMenu: openSubMenu, closeMenu: closeSubMenu } = useMenu();

    return (
        <>
            <SidebarItem title={title} to={'/' + activeFrame} replace={true} onClick={openSubMenu}>
                {getSidebarItemImage(menuType)}
            </SidebarItem>
            <SubMenu menuType={menuType} locationId={locationId} anchorEl={anchorEl} onClose={closeSubMenu} />
        </>
    );
}
