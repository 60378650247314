import InboxFilteringPanel from './comms-filter-panel';
import { useCommsFilter } from '../../../../hooks/use-communications';
import { ICommsFilter } from '../../../../hooks/use-communications/use-communications-filter';
import { useEffect, useState } from 'react';
import {
    InboxContainer,
    InboxHeader,
    InboxTitle,
    InboxPreviewFooter,
    InboxFooterButton,
    InboxFooterButtonLight
} from '../inbox-widget/styles/inbox-styles';

interface CommsFilterWidgetProps {
    onFilterApplied: () => void;
}

export default function CommsFilterWidget({ onFilterApplied }: CommsFilterWidgetProps) {
    const { commsFilter, updateCommsFilter } = useCommsFilter();
    const [filterPre, setFilterPre] = useState(commsFilter);

    useEffect(() => {
        setFilterPre(commsFilter);
    }, [commsFilter]);

    const onFilterPreselected = (newFilterPre: Partial<ICommsFilter>) => {
        setFilterPre((prevFilterPre) => ({
            ...prevFilterPre,
            ...newFilterPre
        }));
    };

    const onClickResetFilter = () => {
        setFilterPre({
            commType: undefined,
            mailboxes: undefined
        })
    };

    const onClickApplyFilter = () => {
        updateCommsFilter(filterPre);
        onFilterApplied();
    };

    const isFilterOptionsEmpty = (filterPre: ICommsFilter) => {
        return (
            !filterPre.mailboxes?.length &&
            !filterPre.commType
        );
    };

    return (
        <InboxContainer>
            <InboxHeader>
                <InboxTitle variant='h3'> Filtering - Communications </InboxTitle>
            </InboxHeader>
            <InboxFilteringPanel
                filterPre={filterPre}
                onFilterPreselected={onFilterPreselected}
            />
            <InboxPreviewFooter>
                <InboxFooterButtonLight
                    disabled={isFilterOptionsEmpty(filterPre)}
                    variant='contained'
                    size='medium'
                    onClick={onClickResetFilter}
                    disableElevation
                >
                    Clear All
                </InboxFooterButtonLight>
                <InboxFooterButton
                    variant='contained'
                    size='medium'
                    onClick={onClickApplyFilter}
                    disableElevation
                >
                    Apply
                </InboxFooterButton>
            </InboxPreviewFooter>
        </InboxContainer>
    );
}
