import { useTheme } from '@mui/material';
import { theme } from '../../shared/material-theme';

export const faxTableTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    secondary: {
      ...theme.palette.secondary,
      light: 'rgba(77, 206, 160, 0.16)',
      dark: 'rgba(226, 86, 80, 0.16)',
    },
    background: {
      ...theme.palette.background,
      default: '#fff',
      paper: '#fff',
    },
    info: {
      ...theme.palette.info,
      light: '#2EAF81',
      dark: '#C33731',
      contrastText: '#FFB14A',
    },
    action: {
      ...theme.palette.action,
      hover: 'rgba(255, 177, 74, 0.16)',
    },
  },
};

export const fromNumberDropDownTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
    secondary: {
      ...theme.palette.secondary,
      light: '#64B6F7',
      dark: 'rgba(33, 150, 243, 0.08)',
    },
  },
};

export const sendFaxTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    secondary: {
      ...theme.palette.secondary,
      light: 'rgba(112, 203, 248, 0.16)',
      dark: '#4CAF50',
      main: '#F44336',
      contrastText: '#EFF7EE',
    },
    action: {
      ...theme.palette.action,
      focus: '#FDECEA',
      hover: 'rgba(0, 0, 0, 0.23)',
    },
    background: {
      ...theme.palette.background,
      default: '#fff',
      paper: '#fff',
    },
    info: {
      ...theme.palette.info,
      light: 'rgba(8, 154, 247, 0.04)',
    },
  },
};

export const noFaxTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    secondary: {
      ...theme.palette.secondary,
      light: 'rgba(112, 203, 248, 0.16)',
    },
  },
};
