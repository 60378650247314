import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useUrlBuilder } from '../../../../hooks/use-history-intercept';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%!important',
        position: 'relative !important' as any,
        width: '100%!important',
        border: 'none'
    }
}));

interface PatientWidgetProps {
    url: string;
    iframeRef: React.MutableRefObject<HTMLIFrameElement>;
}

export default function MessengerWidget({ url, iframeRef }: PatientWidgetProps) {
    const classes = useStyles();
    const { buildUrl } = useUrlBuilder()

    const handleOnLoad = () => {
      // this activates the widget frame if not it is blank
      // we delay 500ms to make sure the app can handle the event
      window.setTimeout(() => {
        iframeRef.current.contentWindow?.postMessage({
          messageType: 'frame-activate',
          data: {
                frameName: "messenger"
          }
        }, "*");
      }, 500);
      
    }
    return <iframe id="messenger-widget" src={buildUrl(url)} className={classes.root} ref={iframeRef} name='messenger-slideout' onLoad={handleOnLoad}/>;
}
