import { useMemo, useState } from 'react';
import { MenuItem, Menu, Typography } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { QuickLinks } from '../communications-chat-box';
import { SubmenuSearch } from './submenu-search';
import Box from '@mui/material/Box';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    menuHighlighted: {
        background: 'rgba(0, 0, 0, 0.08)'
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        height: 48
    },
    formsMenu: {
        height: 300,
        display: 'grid',
        gridTemplateRows: '1fr min-content',
        gridTemplateAreas: `'formsItems' 'formsSearch'`
    },
    formsItems: {
        gridArea: 'formsItems',
        overflowY: 'scroll'
    },
    formsSearch: {
        gridArea: 'formsSearch',
        borderTop: '1px solid #E9EAEE',
        paddingTop: 11
    },
    noScroll: {
        overflow: 'hidden'
    }
}));

interface FormsSubmenuProps {
    quickLinks: QuickLinks;
    onSelect: (val: string) => void;
}

export const FormsSubmenu = ({ quickLinks, onSelect }: FormsSubmenuProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);
    const [searchText, setSearchText] = useState('');

    const handleOpen = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSearchText('');
        setAnchorEl(null);
    };

    const filteredForms = useMemo(() => {
        return quickLinks.formLinks.filter(link => link.name?.toLowerCase().includes(searchText.toLowerCase()));
    }, [quickLinks.formLinks, searchText]);

    const handleSelect = (link: string) => {
        onSelect(link);
        handleClose();
    };

    if (!quickLinks.formLinks.length) return null;

    return (
        <>
            <MenuItem
                data-testid='unified-inbox-forms-quick-action'
                className={`${classes.menuItem} ${Boolean(anchorEl) && classes.menuHighlighted}`}
                onClick={handleOpen}
            >
                <Typography variant='body1'>&#8288;Insert Forms Link</Typography>
                <ChevronRightIcon />
            </MenuItem>
            <Menu
                id='unified-inbox-forms-quick-action-menu'
                data-testid='unified-inbox-forms-quick-action-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                PaperProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                className={classes.noScroll}
            >
                <Box className={classes.formsMenu}>
                    <Box className={classes.formsItems}>
                        {filteredForms.map(form => (
                            <MenuItem
                                data-testid='unified-inbox-forms-quick-action-menu-item'
                                className={classes.menuItem}
                                key={form.id}
                                onClick={() => handleSelect(form.link || '')}
                            >
                                <Typography variant='body1'>&#8288;{form.name}</Typography>
                            </MenuItem>
                        ))}
                    </Box>
                    {quickLinks.formLinks.length > 0 && (
                        <SubmenuSearch searchText={searchText} onChange={e => setSearchText(e.target.value)} />
                    )}
                </Box>
            </Menu>
        </>
    );
};
