import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { formatUSPhoneNumber } from '../../../utils/formatter';

export interface IProps extends React.HTMLAttributes<HTMLElement> {
    children: string;
}

const useStyles = makeStyles(theme => ({
    phoneNumber: {
        color: theme.typography.caption.color
    }
}));

const PhoneNumber: React.FC<IProps> = props => {
    const phone = props.children;
    const formatted = phone ? formatUSPhoneNumber(phone) : '-';
    const classes = useStyles();
    return <span className={classes.phoneNumber}>{formatted}</span>;
};

export default PhoneNumber;
