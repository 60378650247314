import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  widgetContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #E1E4E7',
    padding: '0px 24px 12px'
  },
  headerText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#000000'
  },
  headerSubText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#000000',
    paddingTop: '12px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    borderTop: '1px solid #E1E4E7',
    padding: '16px 0px',
    marginTop: 'auto'
  },
  button: {
    width: '132px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px'
  },
  cancel: {
    backgroundColor: '#FBF0F0',
    color: '#621B16',
    '&:hover': {
      backgroundColor: '#FBF0F0',
      color: '#621B16'
    }
  }
});
