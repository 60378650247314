import {IPNKeys} from './team-chat-pubnub-service'
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { OidcService } from '@revenuewell/logic-oidc';

export interface IAuthResp{
  authenticationToken: string
  channelPrefix: string
  publishKey: string
  subscribeKey: string
  userChannelGroup: string
  uuid: string
}
export default class TeamChatAuthService extends AuthApiService {
  private static instance: TeamChatAuthService
  private serviceUrl: string

  private constructor(oidcService: OidcService, serviceUrl: any) {
    super(oidcService, new NavigationService());
    this.serviceUrl = serviceUrl
    return this
  }

  public static getInstance(oidcService: OidcService, serviceUrl: string) {
    if (!TeamChatAuthService.instance)
    TeamChatAuthService.instance = new TeamChatAuthService(oidcService, serviceUrl)

    return TeamChatAuthService.instance
  }

  public async getAuthCredentials(): Promise<IPNKeys> {
    console.log("BLUE DOT TEAM CHAT AUTH:")
    const response = await this.authFetch(this.serviceUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const data: IAuthResp = await response.json()
    const keys: IPNKeys = {
      authKey: data.authenticationToken,
      publishKey: data.publishKey,
      subscribeKey: data.subscribeKey,
      prefix: data.channelPrefix,
      userChannelGroup: data.userChannelGroup,
      uuid: data.uuid
    }
    return keys
  }

}
