import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { ReactComponent as DownloadIcon } from '../../../../assets/download.svg';
import { useUrl } from '@revenuewell/front-end-bundle';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HideImageIcon from '@mui/icons-material/HideImage';
import ImageIcon from '@mui/icons-material/Image';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useMessageAction } from '../../../../hooks/use-message-action/use-message-action';
import { IMessageReaction } from '../../../../services/pubnub-service';

interface IChatImageProps {
    src: string;
    phoneNumber: string;
    reaction?: IMessageReaction;
    timetoken?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '53.52%',
        width: '340px',
        margin: '-10px',
        [theme.breakpoints.up('sm')]: {
            width: '290px',
        },
    },
    img: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'all .3s'
    },
    blurred: {
        filter: 'blur(20px)',
        transition: 'all .3s'
    },
    blurTitle: {
        cursor: 'default',
        position: 'absolute',
        top: '50%',
        left: '40%',
        transform: 'translate(-50%, -50%)',
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontSize: '16px',
        width: '100%',
        textAlign: 'center',
        textShadow: '0px 0px 4px white'
    },
    menuButton: {
        width: 40,
        height: 40,
        position: 'absolute',
        top: 0,
        right: "20%",
        margin: '8px',
        backgroundColor: '#EDF5FE',
        transition: 'all .3s',
        '&:hover': {
            transition: 'all .3s',
            backgroundColor: 'white'
        }
    }
}));

const ChatImage = ({ src, phoneNumber, reaction, timetoken }: IChatImageProps) => {
    const classes = useStyles();
    const { open: downloadImage } = useUrl({ url: src, web: 'NewTab', desktop: 'DefaultBrowser' });
    const [blurred, setBlurred] = useState(Boolean(reaction?.hidden));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuIconVisible, setMenuIconVisible] = useState(false);
    const open = Boolean(anchorEl);
    const { addMessageHiddenReaction, removeMessageHiddenReaction } = useMessageAction();

    const handleBlur = async () => {
        if (blurred && reaction && reaction.hidden && reaction.hidden.length > 0) {
            const actionTimetoken = reaction.hidden[0].actionTimetoken;
            removeMessageHiddenReaction(phoneNumber, timetoken!, actionTimetoken);
        } else if (!blurred) {
            addMessageHiddenReaction(phoneNumber, timetoken!);
        }
        setBlurred(!blurred);
    };

    const handleMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            className={classes.root}
            onMouseEnter={() => setMenuIconVisible(true)}
            onMouseLeave={() => setMenuIconVisible(false)}
            data-testid='chat-image'
        >
            <img src={src} className={`${classes.img} ${blurred ? classes.blurred : ''}`} />
            {blurred && <span className={classes.blurTitle}>Content is hidden</span>}
            <IconButton
                className={classes.menuButton}
                size='small'
                color='primary'
                onClick={handleMenuButtonClick}
                sx={{ display: menuIconVisible ? 'block' : 'none' }}
            >
                <MenuIcon sx={{ width: 30, height: 30 }} />
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorEl={anchorEl}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleBlur}>
                    {blurred ? (
                        <>
                            <ListItemIcon>
                                <ImageIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Unhide</ListItemText>
                        </>
                    ) : (
                        <>
                            <ListItemIcon>
                                <HideImageIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Hide</ListItemText>
                        </>
                    )}
                </MenuItem>
                <MenuItem aria-label='download' onClick={downloadImage}>
                    <ListItemIcon>
                        <DownloadIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Download</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ChatImage;
