import React from 'react'
import { styled } from '@mui/material'

export const PatientInfo = styled('div')({
  borderRadius: '5px',
  border: '1px solid rgba(225, 228, 234, 1)',
  margin: '0px 20px 0px 20px',
  padding: '15px'
})

export const PatientName = styled('div')({
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '8px',
  cursor: 'default'
})

export const VerticalSeparator = styled(() => (
  <span style={{ padding: '0 8px' }}>|</span>
))();