import { CallDirectionTypes, CallHistory } from '../../../services/call-history/types';

export const CallTypes = {
  INBOUND_CALL: 'Inbound Call',
  OUTBOUND_CALL: 'Outbound Call',
  MISSED_CALL: 'Missed Call',
  VOICEMAIL: 'Voicemail',
  INBOUND_FAX: 'Inbound Fax',
  OUTBOUND_FAX: 'Outbound Fax'
} as const;

export type CallType = typeof CallTypes[keyof typeof CallTypes];

export type CallHistoryItem = {
  id: string | number;
  locationId: number;
  masterAccountId: number;
  callType: CallType;
  duration: string;
  callEnded?: string;
  callStarted: string;
  callDirection: string;
  calleeNumber: string;
  calleeUser: string;
  callerDestinationNumber: string;
  callerName: string;
  callerNumber: string;
  callerUser: string;
  timetoken: number;
  callIds?: string[];
  rootCallId?: string;
  faxTransferredPages?: number;
};

export function getCallType(callHistory: CallHistory): CallType {
  const { callDirection, hasFax, hasVoicemail, isMissedCall } = callHistory;

  if (callDirection.toLowerCase() === CallDirectionTypes.INBOUND.toLowerCase()) {
    if (hasFax)
      return CallTypes.INBOUND_FAX;
    else if (hasVoicemail)
      return CallTypes.VOICEMAIL;
    else if (isMissedCall)
      return CallTypes.MISSED_CALL;
    else
      return CallTypes.INBOUND_CALL;
  } else if (callDirection.toLowerCase() === CallDirectionTypes.OUTBOUND.toLowerCase()) {
    if (hasFax)
      return CallTypes.OUTBOUND_FAX;
    else
      return CallTypes.OUTBOUND_CALL;
  }
  return CallTypes.OUTBOUND_CALL;
}

export function getCallDuration(callHistory: CallHistory): string {
  const startTime = new Date(callHistory.callStarted);
  const endTime = new Date(callHistory.callEnded || new Date());
 return getDiffBetweenDates(startTime, endTime);
}

export const getDiffBetweenDates = (startDate: Date, endDate: Date): string => {
  const deltaInSeconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);

  const minutes = Math.floor(deltaInSeconds / 60);
  const seconds = deltaInSeconds % 60;

  const minStr = minutes.toString().padStart(2, '0');
  const secStr = seconds.toString().padStart(2, '0');

  return `${minStr}:${secStr}`;
}

export const transformPayloadToCallHistoryItem = (callHistory: CallHistory): CallHistoryItem => {
  const callType = getCallType(callHistory);
  const duration = getCallDuration(callHistory);

  return {
    ...callHistory,
    callType,
    duration,
    timetoken: new Date(callHistory.callStarted).getTime()
  };
};
