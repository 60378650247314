import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';

export interface IPhoneNumber {
    phoneSid: string;
    phoneNumber: string;
    isPrimaryOutbound: boolean;
    isProvisionedToKazoo: boolean;
    isActive: boolean;
}

export default class PhoneNumberService extends AuthApiService {
    private static instance: PhoneNumberService;
    private config: any;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!PhoneNumberService.instance) PhoneNumberService.instance = new PhoneNumberService(config, oidcService);

        return PhoneNumberService.instance;
    }

    getByLocation = async (locationId: number): Promise<IPhoneNumber[]> => {
        const url = `${this.config.config.apiUrl}/locations/${locationId}/phone-numbers`;
        const response = await this.authFetch(url);

        const res: IPhoneNumber[] = await response.json();

        return res;
    };
}
