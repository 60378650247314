import { AuthApiService, NavigationService, OidcService } from "@revenuewell/front-end-bundle";
import { KazooUserApi } from "../api-client/kazoo-user-api";

export interface Response<T> {
  data: T;
  revision: string;
  timestamp: string;
  version: string;
  node: string;
  request_id: string;
  status: string;
  auth_token: string;
}

export interface VoicemailBox {
  id: string;
  name: string;
  mailbox: string;
  owner_id?: string;
  messages: number;
  flags: any[];
  folders: Folders;
}

export interface Message {
  timestamp: number;
  from: string;
  to: string;
  caller_id_number: string;
  caller_id_name: string;
  call_id: string;
  folder: string;
  length: number;
  media_id: string;
}

export type VoicemailBoxes = Response<VoicemailBox[]>;
export type VoicemailMessages = Response<Message[]>;

export interface Folders {
  saved: number;
  new: number;
}

export class VoicemailApi extends AuthApiService  {
  private config: any;

  constructor(config: any, oidcService: OidcService) {
    super(oidcService, new NavigationService());
    this.config = config;
  }

  public async getTokenForWebSocket() {
    return '';
  }

  public async fetchBoxes(kazooApiUrl: string, kazooAccountId: string, kazooAuthToken: string) {
    const config = {
      headers: {
        'X-Auth-Token': kazooAuthToken,
      },
    };

    
    const kazooUserApi = new KazooUserApi
    const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/vmboxes?paginate=false`;
    const resp = await kazooUserApi.fetchKazooApi(url, config);

    return resp as VoicemailBoxes;
  }

  public async fetchMessages(kazooApiUrl: string, kazooAccountId: string, kazooAuthToken: string, vmboxId: string) {
    const config = {
      headers: {
        'X-Auth-Token': kazooAuthToken,
      },
    };
    const kazooUserApi = new KazooUserApi
    const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/vmboxes/${vmboxId}/messages?paginate=false`;
    const resp = await kazooUserApi.fetchKazooApi(url, config);

    return resp as VoicemailMessages;
  }

  public async fetchMessage(kazooApiUrl: string, kazooAccountId: string, kazooAuthToken: string, vmboxId: string, vmMsgId: string) {
    const config = {
      headers: {
        'X-Auth-Token': kazooAuthToken,
      },
    };
    const kazooUserApi = new KazooUserApi
    const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/vmboxes/${vmboxId}/messages/${vmMsgId}`;
    const resp = await kazooUserApi.fetchKazooApi(url, config);

    if (resp) {
      return resp.data as Message;
    } else {
      // if entering this block, more likely than not the message has been deleted
      return
    }    
  }
  
  public async generateRawAudioUrl(
    kazooApiUrl: string,
    kazooAccountId: string,
    kazooAuthToken: string,
    vmboxId: string,
    mediaId: string
  ) {
    return `${kazooApiUrl}/v2/accounts/${kazooAccountId}/vmboxes/${vmboxId}/messages/${mediaId}/raw?auth_token=${kazooAuthToken}`;
  }

  public async deleteVoicemail(
    kazooApiUrl: string,
    kazooAccountId: string,
    kazooAuthToken: string,
    vmboxId: string,
    mediaId: string
  ) {
    const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/vmboxes/${vmboxId}/messages/${mediaId}`;

    const config = {
      method: 'DELETE',
      body: {
        data: {
          messages: [mediaId],
          ui_metadata: { version: '5.0-61', ui: 'monster-ui', origin: 'voicemails' },
        },
      },
      headers: {
        'X-Auth-Token': kazooAuthToken,
      },
    };
    const kazooUserApi = new KazooUserApi

    await kazooUserApi.fetchKazooApi(url, config);
  }
}
