import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },
  locations: {
    width: '80%',
    marginBottom: '16px'
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    marginBottom: '16px'
  },
  headerIconButton: {
    backgroundColor: 'rgba(0, 125, 255, 0.08)',
    marginLeft: '6px',
    marginRight: '6px',
    '&:hover': {
      backgroundColor: 'rgba(0, 125, 255, 0.16)',
      transition: 'backgroundColor .3s'
    },
    '& i': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: '20px',
      backgroundPosition: 'center',
      width: '24px',
      height: '24px'
    }
  },
  call: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.6842 12.5263H17.7894C17.7894 9.04314 14.9568 6.21051 11.4736 6.21051V8.31577C13.7957 8.31577 15.6842 10.2042 15.6842 12.5263Z' fill='%23089AF7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4736 2V4.10526C16.1168 4.10526 19.8947 7.88316 19.8947 12.5263H21.9999C21.9999 6.72211 17.2778 2 11.4736 2Z' fill='%23089AF7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4736 10.421V12.5263H13.5789C13.5789 11.3642 12.6357 10.421 11.4736 10.421Z' fill='%23089AF7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.8421 15.6842H14.6316C14.0495 15.6842 13.5789 16.1547 13.5789 16.7368V17.7895C9.36842 17.7895 6.27579 14.5663 6.21053 10.421H7.26316C7.84526 10.421 8.31579 9.95052 8.31579 9.36841V5.15789C8.31579 4.57578 7.84526 4.10526 7.26316 4.10526H3.05263C2.47053 4.10526 2 4.57578 2 5.15789V10.421C2 16.8158 7.18421 22 13.5789 22H18.8421C19.4242 22 19.8947 21.5295 19.8947 20.9474V16.7368C19.8947 16.1547 19.4242 15.6842 18.8421 15.6842Z' fill='%23089AF7'/%3E%3C/svg%3E%0A")`
  },
  message: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 4H17V12C17 12.55 16.55 13 16 13H4V14C4 15.1 4.9 16 6 16H16L20 20V6C20 4.9 19.1 4 18 4ZM15 9V2C15 0.9 14.1 0 13 0H2C0.9 0 0 0.9 0 2V15L4 11H13C14.1 11 15 10.1 15 9Z' fill='%23089AF7'/%3E%3C/svg%3E")`
  },
  video: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM14.38 10.7L12 8.8V11C12 11.55 11.55 12 11 12H5C4.45 12 4 11.55 4 11V5C4 4.45 4.45 4 5 4H11C11.55 4 12 4.45 12 5V7.2L14.38 5.3C15.03 4.78 16 5.24 16 6.08V9.92C16 10.76 15.03 11.22 14.38 10.7Z' fill='%23089AF7'/%3E%3C/svg%3E ")`
  },
  core: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1667 0.5H2.83333C1.53833 0.5 0.5 1.56875 0.5 2.875V17.125C0.5 18.4312 1.53833 19.5 2.83333 19.5H19.1667C20.45 19.5 21.5 18.4312 21.5 17.125V2.875C21.5 1.56875 20.4617 0.5 19.1667 0.5ZM16.9188 7.53694H15.4146C15.294 7.53694 15.1902 7.62036 15.165 7.73714L14.4318 11.2019L13.7172 7.73833C13.692 7.62155 13.5876 7.53694 13.467 7.53694H11.976C11.8542 7.53694 11.7492 7.62274 11.7252 7.74072L11.0544 11.1643L10.3266 7.73714C10.3014 7.62036 10.1976 7.53694 10.0776 7.53694L7.9686 7.5C7.455 7.5 7.0278 7.63764 6.7014 7.90934C6.6024 7.98977 6.4974 8.1 6.3858 8.243V7.83664C6.3858 7.7288 6.2982 7.64181 6.1896 7.64181H4.6962C4.5882 7.64181 4.5 7.7288 4.5 7.83664V13.724C4.5 13.8676 4.6176 13.9844 4.7628 13.9844H6.2058C6.351 13.9844 6.4686 13.8676 6.4686 13.724V10.9445C6.4686 10.5012 6.522 10.1741 6.6276 9.97448C6.8094 9.63188 7.1688 9.46504 7.7268 9.46504C7.7724 9.46504 7.8318 9.46683 7.905 9.47041C7.9734 9.47339 8.553 9.47994 8.7078 9.48888L9.9252 13.7943C9.957 13.907 10.0602 13.9844 10.1784 13.9844H11.7006C11.8236 13.9844 11.931 13.8992 11.9568 13.7794L12.7062 10.3647L13.4466 13.7788C13.473 13.8992 13.5798 13.9844 13.7034 13.9844H15.2094C15.3264 13.9844 15.4296 13.9075 15.462 13.7967L17.1642 7.86167C17.2116 7.69901 17.0892 7.53694 16.9188 7.53694ZM19.1667 17.125H2.83333V5.25H19.1667V17.125Z' fill='%23089AF7'/%3E%3C/svg%3E ")`
  },
  patient: {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z' fill='%23089AF7'/%3E%3C/svg%3E%0A")`
  }
});