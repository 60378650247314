import { useConfig } from '@revenuewell/front-end-bundle';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory,  useUrlBuilder } from '../use-history-intercept';
import { WrapperNavigation } from './types';


export const WrapperNavigationCtx = createContext<WrapperNavigation>(null!);

const getProxyPathFromPath = (path: string) => {
  const pathSegments = path.split('/');
  pathSegments[1] = `${pathSegments[1]}-frame`;
  const newPath = pathSegments.join('/')
  return newPath;
};

export default function WrapperNavigationProvider({ children }: PropsWithChildren<{}>) {
    const { frameName: frame } = useParams<{ frameName: string }>()
    const history = useHistory()
    const { buildUrl } = useUrlBuilder()
    const { config } = useConfig()
    const [activeFrame, setActiveFrame] = useState('')
    const legacyFrameRouteMap = {
        phone1: '/phone1-frame/#/voip/startcall',
        contacts1: '/contacts1-frame/#/patients',
        virtualvisits: '/virtualvisits-frame/app'
    } as Record<string, string>


    useEffect(() => {
        if (!frame) return

        if (activeFrame === frame) return

        // Handle switching to another app preserving its state. Triggered by menu buttons
        if (history.location.pathname.split('/').length === 2) {
            setActiveFrame(frame)
            return
        }

        // Handle deep linking to another app
        if (history.location.pathname.split('/').length > 2) {
            const proxyPath = getProxyPathFromPath(history.location.pathname);
            const urlOverride = buildUrl({
              pathname: proxyPath, 
              search: history.location.search, 
              hash: history.location.hash,
              state: {}
            })
            const url = getFrameUrl(frame, urlOverride)
            setFrameUrls((f: Record<string, string>) => ({ ...f, [frame]: url }))
            setActiveFrame(frame)
            return
        }
    }, [activeFrame, frame, history.location.pathname, 
        history.location.search, history.location.hash])

    const getFrameName = (pathname: string) => {
        return pathname.split('/')[1]?.replace('-frame', '')
    }

    const getFrameUrl = useCallback((frame: string, urlOverride?: string) => {
        if (!urlOverride && legacyFrameRouteMap[frame] && !history.location.hash)
            return legacyFrameRouteMap[frame]

        if (urlOverride == null && history.location.pathname.startsWith(`/${frame}`))
            urlOverride = history.location.pathname + history.location.search + history.location.hash

        urlOverride = urlOverride || ''

        if (frame === 'core')
            return config.practice.url + urlOverride.replace('/core', '')

        const segments = urlOverride.split('/')
        const urlOverrideFrameName = getFrameName(urlOverride)

        if (urlOverrideFrameName === frame) {
            const framePath = segments.slice(2).join('/')
            return `/${frame}-frame/${framePath}`  
        }

        return `/${frame}-frame/`
    }, [config.practice.url])

    const [frameUrls, setFrameUrls] = useState(
        config.frames.reduce((f: Record<string, string>, key: string) =>
            ({ ...f, [key]: buildUrl(getFrameUrl(key)) }), {})
    )
    const [windowUrls] = useState(
        config.frames.reduce((f: Record<string, string>, key: string) =>
            ({ ...f, [key]: `/${key}` }), {})
    )

    const navigate = useCallback((frame: string, url: string) => {
        const urlFrameSegment = url.split('/')[1]

        if (!urlFrameSegment?.includes(frame)) return

        if (urlFrameSegment !== `${frame}-frame`)
            url = `/${frame}-frame/${url.split('/').slice(2).join('/')}`

        setFrameUrls((f: Record<string, string>) => ({ ...f, [frame]: url }))
        history.push(url.replace('-frame', ''))
        setActiveFrame(frame)
    }, [history])

    return (
        <WrapperNavigationCtx.Provider value={{ frameUrls, windowUrls, activeFrame, navigate, getFrameUrl, getFrameName }}>
            {children}
        </WrapperNavigationCtx.Provider>
    )
}
