import { ListenerParameters } from 'pubnub';
import { useEffect } from 'react';
import { IMailboxWithLocation } from '../../services/mailbox-service/mailbox-service';
import { Message } from '../../services/pubnub-service';
import { EventJumpToMessage } from '../../types/event-bus';
import { formatUSPhoneNumber, getFullNameForGroup } from '../../utils/formatter';
import { makeProvider } from '../provider';
import { useContactState } from '../use-contacts';
import { useEventBus } from '../use-event-bus';
import { useNotification } from '../use-notification/use-notification';
import { usePubnub } from '../use-pubnub/use-pubnub';

export interface INativeNotification {
  title: string;
  body: string;
}

export const { MessengerNotificationProvider, useMessengerNotification } = makeProvider('MessengerNotification', () => {
    const { isPermissionDefault, requestPermission, showNotification } = useNotification();
    const { client, mailboxes } = usePubnub();
    const { searchByNumber, contactCache } = useContactState();
    const { publish } = useEventBus();

    useEffect(() => {
        if (isPermissionDefault()) {
            requestPermission();
        }
    }, [isPermissionDefault, requestPermission]);

    useEffect(() => {
        if (!client) return;

        const listener: ListenerParameters = {
            message: async (data: any) => {
                const message = data.message as Message;
                if (
                    message.messageType === 'incoming' &&
                    data.channel?.includes(message.from) &&
                    message.body !== 'C'
                ) {
                    await searchByNumber(message.from);
                    const contacts = contactCache[message.from];
                    const fromDisplay = contacts?.data?.length
                        ? getFullNameForGroup(contacts.data)
                        : formatUSPhoneNumber(message.from);
                    const mailbox = (mailboxes as IMailboxWithLocation[])
                        .filter(mailbox => data.channel.startsWith(mailbox.pubnubPrefix))
                        .pop();
                    let onclick = undefined;
                    if (mailbox) {
                        onclick = () => {
                            publish({
                                messageType: 'jumpToMessage',
                                data: {
                                    phoneNumber: message.from,
                                    locationId: mailbox.locationId
                                } as EventJumpToMessage['data']
                            });
                        };
                    }

                    showNotification(`New message from ${fromDisplay}`, { body: message.body }, onclick);
                }
            }
        };

        client.client.addListener(listener);

        return () => client.client.removeListener(listener);
    }, [client, mailboxes, publish, showNotification, searchByNumber, contactCache]);

    return {};
});
