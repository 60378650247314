import styled from 'styled-components';
import { Box } from '@mui/material';

export const StatusList = styled((props: any) => (
  <ul {...props} />
))((props) => ({
  cursor: 'default',
  flexDirection: 'column',
  display: 'flex',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  flexWrap: 'wrap'
}))

export const StatusListItem = styled((props: any) => (
  <li {...props}>
    {props.icon && <props.icon sx={{ fontSize: '18px', color: props.iconcolor ? props.iconcolor : '#8D97A4', marginRight: '6px' }} />}
    <Box sx={{ cursor: props.link ? 'pointer' : 'default', "&:hover": { textDecoration: props.link ? 'underline' : 'none' } }}>
      {props.text}
    </Box>
    <Box sx={{ width: '100%' }}>{props.children}</Box>
  </li>
))((props) => ({
  alignItems: 'center',
  display: 'flex',
  color: '#2C3542',
  fontSize: '14px',
  padding: '16px 0px',
  borderBottom: '1px solid #E9EAEE',
  margin: '0 20px',
  flexWrap: 'wrap',
  '&:last-of-type': {
    borderBottom: 'none'
  }
}))