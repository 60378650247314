import { ReactElement, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { IMailboxWithLocation } from '../../services/mailbox-service/mailbox-service';
import { usePubnub } from '../use-pubnub/use-pubnub';
import { CommunicationType } from '../../services/inbox-service/types';
import { IInboxQuery } from './types/inbox-preview-types';
import { useInbox } from './use-inbox-previews';
import useCommsFilter from '../use-communications/use-communications-filter';
import { useEventBus } from '../use-event-bus';
import UniversalPatientSearchService, {
    UniversalSearch
} from '../../services/universal-patient-search-service/universal-patient-search-service';

export const commTypes = ['Phone', 'SMS'] as const;
export const commStatuses = ['Unread', 'Starred', 'Archived'] as const;

export type CommType = typeof commTypes[number];
export type CommStatus = typeof commStatuses[number];

export interface IInboxFilter {
    searchText?: string;
    commType?: CommType;
    commStatuses?: CommStatus[];
    mailboxes?: IMailboxWithLocation[];
}

interface IInboxFilterContext {
    previewFilter: IInboxFilter;
    updatePreviewFilter: (newFilter: Partial<IInboxFilter>) => Promise<void>;
}

const InboxFilterContext = createContext<IInboxFilterContext | null>(null);

export const useInboxFilter = (): IInboxFilterContext => {
    const context = useContext(InboxFilterContext);

    if (!context)
        throw new Error('useInboxFilter must be used within an InboxFilterContext');

    return context;
};

export function InboxFilterProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const [previewFilter, setPreviewFilter] = useState<IInboxFilter>({});
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { setSearch } = useInbox();
    const { updateCommsFilter } = useCommsFilter();
    const { mailboxes } = usePubnub();
    const { listen } = useEventBus();

    const universalSearchService = useMemo(() => {
        if (!config || !oidcService) return null;
        return UniversalPatientSearchService.getInstance(config, oidcService);
    }, [config, oidcService]);

    // update inbox preview filter
    const updatePreviewFilter = async (newFilter: Partial<IInboxFilter>) => {
        setPreviewFilter((prevFilter) => ({
            ...prevFilter,
            ...newFilter
        }));
    };

    // get patients phone numbers by text search
    const getPhonesByText = useCallback(
        async (searchText?: string): Promise<string[] | undefined> => {
            if (!universalSearchService || !searchText?.length)
                return undefined;

            const searchRequest: UniversalSearch = {
                searchTerm: searchText,
                page: 1,
                networks: mailboxes.map(m => m.location.idNetwork),
                preferredLocationIds: mailboxes.map(m => m.location.id),
                sort: 'LastActivity',
                pageSize: 100,
                status: 'Active'
            };

            const searchContacts = await universalSearchService.search(searchRequest);

            const phones = searchContacts
                .flatMap((contact) => [contact.cellPhone, contact.homePhone, contact.workPhone])
                .filter(item => item) as string[];

            const onlyNumber = searchText.replace(/[^0-9]/gi, '');
            if (onlyNumber.length > 3 && !phones.includes(onlyNumber))
                phones.push(onlyNumber);

            return phones;
        },
        [universalSearchService, mailboxes]
    );

    // transform filter to query search object
    const transformFilterToInboxQuery = async (previewFilter: IInboxFilter): Promise<IInboxQuery> => {
        const { mailboxes: filterMailboxes, commStatuses, commType, searchText } = previewFilter;
        const phones = await getPhonesByText(searchText);

        return {
            phones: phones,
            mailboxes: filterMailboxes,
            isUnread: commStatuses?.includes('Unread') ? true : null,
            isSaved: commStatuses?.includes('Starred') ? true : null,
            isArchived: commStatuses?.includes('Archived') ? true : null,
            commType: commType === 'SMS' ? CommunicationType.Sms
                : commType === 'Phone' ? CommunicationType.Phone : null
        };
    };

    // set search params and update locations in communications filter
    useEffect(() => {
        transformFilterToInboxQuery(previewFilter)
            .then((query: IInboxQuery) => setSearch(query));

        updateCommsFilter({
            mailboxes: previewFilter.mailboxes
        });
    }, [previewFilter]);

    // we need to listen if slideout closed from other page
    useEffect(() => {
        const listener = listen('closeWidget', event => {
            if (event.messageType === 'closeWidget') {
                updatePreviewFilter({
                    searchText: undefined,
                    commType: undefined,
                    commStatuses: undefined,
                    mailboxes: undefined
                });
            }
        });

        return () => listener();
    }, [listen]);

    return (
        <InboxFilterContext.Provider value={{ previewFilter, updatePreviewFilter }}>
            {children}
        </InboxFilterContext.Provider>
    );
}

export default useInboxFilter;
