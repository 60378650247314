import { Box, styled } from '@mui/material';

export const AlertContainer = styled(Box)({
    fontFamily: 'Roboto',
    cursor: 'pointer',
    position: 'relative',
    padding: 16,
    borderTop: '1px solid #E1E4E7',
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#EDEFF2',
    },
    '&:hover .alert-date': {
        display: 'none'
    },
    '&:hover .alert-close': {
        display: 'block'
    }
})

export const AlertIcon = styled(Box)({
    width: 32,
    height: 32,
    backgroundColor: '#C6E3CB',
    borderRadius: '50%'
})

export const AlertContent = styled(Box)({
    marginLeft: 40,
    marginTop: -8,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#2C3542',
})

export const AlertMessage = styled(Box)({
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#2C3542',
    marginLeft: 40,
    marginTop: 8,
    padding: 8,
    backgroundColor: '#F9FAFB',
    border: '1px solid #D5D9DD',
    borderRadius: 4
})

export const IconBox = styled(Box)({
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#C6E3CB'
});
