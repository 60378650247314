import * as React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface ColorClass {
  primary: string;
  secondary: string;
}

type IProps = {
    color: ColorClass;
    icon: React.ReactElement;
};

export const Chip: React.FC<IProps> = (props: IProps) => {
    const colorClass = props.color;

    const useStyles = makeStyles({
        chip: {
            backgroundColor: colorClass.secondary,
            '& span': {
                display: 'none'
            },
            '& svg': {
                padding: '8px'
            },
            '& .MuiChip-icon': {
                margin: '0px',
                color: colorClass.primary
            }
        }
    });

    const classes = useStyles();

    return <MuiChip size='small' icon={props.icon} className={classes.chip} />;
};
