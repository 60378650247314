import { FC, ReactNode } from "react"
import { Scrollbars } from "react-custom-scrollbars-2";
import { ViewContentWrapper } from "./view-content.styles";

export interface ViewContentProps {
  children?: ReactNode
  sx?: any
}

export const ViewContent: FC<ViewContentProps> = (props) => {
  return <ViewContentWrapper sx={props.sx}>
    <Scrollbars autoHide style={{ flexGrow: 1 }}>
      {props.children}
    </Scrollbars>
  </ViewContentWrapper>
}