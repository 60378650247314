import { useMemo, useState } from 'react';
import { MenuItem, Menu, Typography, IconButton, Box } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useTextTemplates } from '../../../../../hooks/use-text-templates/use-text-templates';
import { SubmenuSearch } from './submenu-search';
import { TemplateForMultipleLocations } from '../../../../../services/text-templates-service/text-templates-service';
import makeStyles from '@mui/styles/makeStyles';
import { usePubnub } from '../../../../../hooks/use-pubnub/use-pubnub';
import { useEventBus } from '../../../../../hooks/use-event-bus/use-event-bus';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles(() => {
    return {
        menuHighlighted: {
            background: 'rgba(0, 0, 0, 0.08)'
        },
        menuItem: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 16px',
            height: '48px'
        },
        menuItemEdit: {
            visibility: 'hidden'
        },
        newTemplateMenuItem: {
            marginTop: 'auto'
        },
        newTemplateMenuItemContent: {
            display: 'flex',
            width: '90%'
        },
        textTemplatesMenu: {
            height: 300,
            display: 'grid',
            gridTemplateRows: '1fr min-content',
            gridTemplateAreas: `'formsItems' 'formsSearch'`
        },
        textTemplatesItems: {
            gridArea: 'formsItems',
            overflowY: 'scroll'
        },
        noScroll: {
            overflow: 'hidden'
        }
    };
});

interface TextTemplateSubmenuProps {
    locationId?: number;
    onSelect: (val: string) => void;
}

export const TextTemplateSubmenu = ({ locationId, onSelect }: TextTemplateSubmenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);
    const [searchText, setSearchText] = useState('');
    const { getTemplatesFromLocation } = useTextTemplates();
    const textTemplatesData = getTemplatesFromLocation(locationId);
    const [hovered, setHovered] = useState<{ [key: number]: boolean }>({});
    const { currentMailboxes } = usePubnub();
    const { publish } = useEventBus();

    const handleOpen = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSearchText('');
        setAnchorEl(null);
    };

    const settingsUrl = useMemo(() => {
        const url = '/settings';

        if (currentMailboxes.length > 1) {
            return `${url}/messenger-settings/enterprise-settings`;
        }
        return `${url}/messenger-settings/practice-settings`;
    }, [currentMailboxes]);

    const filteredTextTemplates = useMemo(() => {
        return textTemplatesData.filter(link => link.name?.toLowerCase().includes(searchText.toLowerCase()));
    }, [textTemplatesData, searchText]);

    const classes = useStyles({ filteredTextTemplates });

    const handleSelect = (template: string) => {
        onSelect(template);
        handleClose();
    };

    function handleOverMenuItem(key: number, status: boolean) {
        setHovered((prevState: any) => ({ ...prevState, [key]: status }));
    }

    function handleTemplateEditClick(e: any) {
        e.stopPropagation();
        handleSelect('');
        publish({
            messageType: 'navigate',
            data: {
                pathname: settingsUrl,
                crossFrame: true,
                replaceState: false
            }
        });
    }

    const NewTemplate = (
        <Box>
            <MenuItem
                data-testid='unified-inbox-quick-actions-menu'
                onClick={e => {
                    handleTemplateEditClick(e);
                }}
                className={classes.menuItem}
            >
                <div className={classes.newTemplateMenuItemContent}>
                    <AddCircleOutlineIcon color='primary' style={{ marginRight: '10px' }} fontSize={'small'} />
                    <Typography variant='body2' color='primary'>
                        CREATE A NEW TEMPLATE
                    </Typography>
                </div>
            </MenuItem>
        </Box>
    );

    return (
        <>
            <MenuItem
                id='unified-inbox-text-template-quick-action'
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                className={`${classes.menuItem} ${Boolean(anchorEl) && classes.menuHighlighted}`}
                onClick={e => {
                    handleOpen(e);
                }}
            >
                <Typography variant='body1'>&#8288;Insert Text Message Template</Typography>
                <ChevronRightIcon />
            </MenuItem>
            <Menu
                id='unified-inbox-text-template-quick-action-menu'
                data-testid='unified-inbox-text-template-quick-action-menu'
                anchorEl={anchorEl}
                keepMounted
                PaperProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                onClose={handleClose}
                className={classes.noScroll}
            >
                <Box className={classes.textTemplatesMenu}>
                    <Box className={classes.textTemplatesItems}>
                        {filteredTextTemplates.map((template: TemplateForMultipleLocations) => (
                            <div
                                onMouseEnter={() => {
                                    handleOverMenuItem(template.id, true);
                                }}
                                key={template.id}
                                onMouseLeave={() => handleOverMenuItem(template.id, false)}
                            >
                                <MenuItem
                                    data-testid='unified-inbox-text-template-quick-action-menu-item'
                                    className={classes.menuItem}
                                    key={template.id}
                                    onClick={() => handleSelect(template.text)}
                                >
                                    <Typography variant='body1'>&#8288;{template.name}</Typography>

                                    <IconButton
                                        onClick={e => {
                                            handleTemplateEditClick(e);
                                        }}
                                        color='secondary'
                                        aria-label='edit template'
                                        className={`${!hovered[template.id] && classes.menuItemEdit}`}
                                    >
                                        <EditIcon color='primary' fontSize='small' />
                                    </IconButton>
                                </MenuItem>
                            </div>
                        ))}
                        {NewTemplate}
                    </Box>
                </Box>
                {textTemplatesData.length > 0 && (
                    <SubmenuSearch searchText={searchText} onChange={e => setSearchText(e.target.value)} />
                )}
            </Menu>
        </>
    );
};
