import { FC, useMemo, useState } from "react";
import { NavigationBreadcrumbs } from "../../../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../../../header/header";
import { ViewContentWrapper } from "../../../shared/view-content/view-content.styles";
import { PreferencesViewWrapper } from "../../shared/preferences-view-wrapper/preferences-view-wrapper";
import { useConfig, useNotification, useOidcWithAdmin } from "@revenuewell/front-end-bundle";
import { usePatientProfileWidget } from "../../../../hooks/use-patient-profile-widget";
import { ContactApi, PatientPreferencesRequest, PatientPreferencesResponse } from "../../../../../../../../services/api-client/contact-api";
import AllCommunicationSection from "../../shared/sub-section/all-communication-section";
import CommunicationSubSection from "../../shared/sub-section/communication-sub-section";
import CommunicationSubSectionHeader from "../../shared/sub-section/communication-sub-section-header";
import AllPromotionalSubSection from "../../shared/all-promotional-sub-section/all-promotional-sub-section";

export const DirectMailPreferencesView: FC = () => {
  const { config } = useConfig();
  const { oidcService } = useOidcWithAdmin();
  const { showSuccess } = useNotification();
  const { patientPreferences, refreshPatientPreferences, goBack } = usePatientProfileWidget();
  const [localPreferences, setLocalPreferences] = useState<PatientPreferencesResponse>(patientPreferences);
  const contactApi = useMemo(() => new ContactApi(config, oidcService), [config, oidcService]);

  const updateLocalPreferences = (state: Partial<PatientPreferencesResponse>) => {
    const newState = { ...localPreferences, ...state };
    setLocalPreferences(newState);
  }

  const updateDmPreferences = async () => {
    await contactApi.patchPatientPreferences(
      localPreferences.patientId,
      localPreferences as PatientPreferencesRequest
    );
    await refreshPatientPreferences();
    showSuccess("Direct Mail preferences successfully saved");
    goBack();
  };

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContentWrapper>
      <PreferencesViewWrapper onSave={updateDmPreferences}>
        <AllCommunicationSection
          text='All Direct Mail'
          isChecked={localPreferences.dmAppointmentReminders
            && localPreferences.dmBirthdayAndHolidayCards
            && localPreferences.dmExpiringInsuranceReminder
            && localPreferences.dmPatientReactivation
            && localPreferences.dmRecalls
            && localPreferences.dmSpecialsAndNewsletters
            && localPreferences.dmTreatmentPlanFollowUps}
          onChange={value => updateLocalPreferences({
            dmAppointmentReminders: value,
            dmBirthdayAndHolidayCards: value,
            dmExpiringInsuranceReminder: value,
            dmPatientReactivation: value,
            dmRecalls: value,
            dmSpecialsAndNewsletters: value,
            dmTreatmentPlanFollowUps: value
          })}
        />
        <CommunicationSubSectionHeader displayText='ESSENTIAL COMMUNICATION' />
        <CommunicationSubSection
          text='Appointment Reminders'
          isChecked={localPreferences.dmAppointmentReminders}
          onChange={value => updateLocalPreferences({ dmAppointmentReminders: value })}
        />
        <CommunicationSubSection
          text='Patient Reactivation'
          isChecked={localPreferences.dmPatientReactivation}
          onChange={value => updateLocalPreferences({ dmPatientReactivation: value })}
        />
        <CommunicationSubSection
          text='Recalls'
          isChecked={localPreferences.dmRecalls}
          onChange={value => updateLocalPreferences({ dmRecalls: value })}
        />
        <CommunicationSubSectionHeader displayText='MARKETING AND PROMOTIONS' />
        <AllPromotionalSubSection
          text='All Promotional Direct Mail'
          isChecked={localPreferences.dmTreatmentPlanFollowUps
            && localPreferences.dmBirthdayAndHolidayCards
            && localPreferences.dmSpecialsAndNewsletters
            && localPreferences.dmExpiringInsuranceReminder
          }
          onChange={value => updateLocalPreferences({
            dmTreatmentPlanFollowUps: value,
            dmBirthdayAndHolidayCards: value,
            dmSpecialsAndNewsletters: value,
            dmExpiringInsuranceReminder: value
          })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Treatment Plan Follow-ups'
          isChecked={localPreferences.dmTreatmentPlanFollowUps}
          onChange={value => updateLocalPreferences({ dmTreatmentPlanFollowUps: value })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Birthday/Holiday Cards'
          isChecked={localPreferences.dmBirthdayAndHolidayCards}
          onChange={value => updateLocalPreferences({ dmBirthdayAndHolidayCards: value })}
        />

        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Specials & Newsletters'
          isChecked={localPreferences.dmSpecialsAndNewsletters}
          onChange={value => updateLocalPreferences({ dmSpecialsAndNewsletters: value })}
        />
        <CommunicationSubSection
          isPromotionalComSubSection={true}
          text='Expiring Insurance Reminder'
          isChecked={localPreferences.dmExpiringInsuranceReminder}
          onChange={value => updateLocalPreferences({ dmExpiringInsuranceReminder: value })}
        />
      </PreferencesViewWrapper>
    </ViewContentWrapper>
  </>
}