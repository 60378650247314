import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { OidcService } from '@revenuewell/logic-oidc';

export class SmsRegistrationService extends AuthApiService {
  private config;

  constructor(config: any, oidcService: OidcService) {
    super(oidcService, new NavigationService());
    this.config = config;
    return this;
  }

  public fetchBrandInfo = async () => {
    const claims = await this.oidcService.getClaims();
    const locationBrandUrl = `${this.config.account.apiUrl}/locations/${claims.locationId}/a2p-brand`;

    try {
      const response = await this.authFetch(locationBrandUrl);
      return await Promise.resolve({
        status: response.status,
        brand: await response.json()
      });
    } catch (ex: any) {
      return await Promise.resolve({
        status: ex?.status ? ex.status : 500,
        brand: null
      });
    }
  };
};

