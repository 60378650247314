import { useEffect, useState, useCallback } from 'react';
import PNService from '../../services/pubnub-service';
import MailboxService from '../../services/mailbox-service/mailbox-service';
import { useConfig } from '@revenuewell/front-end-bundle';
import { IMailboxWithLocation } from '../../services/mailbox-service/mailbox-service';
import PhoneNumberService, { IPhoneNumber } from '../../services/phone-number-service/phone-number-service';
import { makeProvider } from '../provider';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';

export const { PubnubProvider, PubnubContext, usePubnub } = makeProvider('Pubnub', () => {
    const [client, setClient] = useState<PNService>(null!);
    const [mailboxes, setMailboxes] = useState<IMailboxWithLocation[]>([]);
    const [selectedMailboxes, setSelectedMailboxes] = useState<IMailboxWithLocation[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState<{ [key: string]: IPhoneNumber[] }>({});
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    const setSelectedMailboxesInteral = useCallback(
        (selectedMailboxes: IMailboxWithLocation[]) => {
            console.debug('UTILS ___________________________ selectedMailBoxes:', selectedMailboxes);
            if (selectedMailboxes?.length) client.setWildcard(getWildcard(selectedMailboxes));
            else client.setWildcard(getWildcard(mailboxes));
            setSelectedMailboxes(selectedMailboxes);
        },
        [client, mailboxes]
    );

    function getWildcard(mailboxes: IMailboxWithLocation[]) {
        return mailboxes.map(c => `${c.pubnubPrefix}.*`);
    }

    function handleSetupSubscriptions(client: PNService, mailboxes: IMailboxWithLocation[]) {
        console.debug('EVENTS - HANDLE SUBCRIPTIONS:', mailboxes);
        client.setupSubscriptions();
    }

    useEffect(() => {
        if (client || !oidcService) return;
        const setupClients = async () => {
            const mailboxService = MailboxService.getInstance(config, oidcService);
            const tmpMailboxes = await mailboxService.getMailboxes();
            const credentials = await mailboxService.getPubnubCredentials(tmpMailboxes.map(box => box.id));
            const { masterAccountId } = await oidcService.getClaims();
            const client = new PNService(config, masterAccountId ? masterAccountId.toString() : 'RW', credentials);
            console.debug('WILD SETTING CLIENT:  ', mailboxes);
            console.debug('PHONE TEST: ');
            client.setWildcard(getWildcard(tmpMailboxes));
            setClient(client);
            setMailboxes(tmpMailboxes);
        };

        setupClients();
    }, [config, client, mailboxes, oidcService]);

    useEffect(() => {
        if (!client || !mailboxes?.length) return;
        const currentMailboxes = selectedMailboxes?.length ? selectedMailboxes : mailboxes;
        handleSetupSubscriptions(client, currentMailboxes);
    }, [client, mailboxes, selectedMailboxes]);

    useEffect(() => {
        return () => {
            client?.dispose();
        };
    }, [client]);

    useEffect(() => {
        if (!mailboxes.length || !oidcService) return;
        const getPhones = async () => {
            console.debug('PHONES ALL:', mailboxes);
            const phoneNumberService = PhoneNumberService.getInstance(config, oidcService);
            //we are only supporting the default phone
            const phones: { [key: string]: IPhoneNumber[] } = {};
            for (const mailbox of mailboxes) {
                const phoneNumbers = await phoneNumberService.getByLocation(mailbox.locationId);
                phones[mailbox.locationId] = phoneNumbers;
            }
            setPhoneNumbers(phones);
        };
        getPhones();
    }, [config, mailboxes, oidcService]);

    const getMfaCodes = useCallback(async (mailboxId: number) => {
        if(!client) return [];

        const mailbox = mailboxes.find(m => m.id === mailboxId);

        if(!mailbox) return [];

        return await client.fetchMessageHistory(`${mailbox.pubnubPrefix}.insurance-mfa`);
    }, [client, mailboxes]);

    return {
        client: client,
        mailboxes: mailboxes,
        phoneNumbers: phoneNumbers,
        selectedMailboxes: selectedMailboxes,
        currentMailboxes: selectedMailboxes?.length ? selectedMailboxes : mailboxes,
        setSelectedMailboxes: setSelectedMailboxesInteral,
        getMfaCodes
    };
});
