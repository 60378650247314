import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useEventBus } from '../../../hooks/use-event-bus';
import { useUrlBuilder } from '../../../hooks/use-history-intercept';
import useWrapperFrames from '../../../hooks/use-wrapper-frames/hook';
import { WrapperFrame } from '../../../hooks/use-wrapper-frames/types';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';
import { useStyles } from './frame-styles';


interface FrameProps {
  frameName: string
  render: boolean
  autoActivate?: boolean
}

const mergeRefs = (...refs: any[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
}

export const FrameComponent = React.forwardRef<HTMLIFrameElement, FrameProps>(({ frameName, render, autoActivate}, ref) => {
    const classes = useStyles();
    const { activeFrame, getFrameName, getFrameUrl } = useWrapperNavigation();
    const { updateFrame, getFrame, sendActivationEvent } = useWrapperFrames();
    const { listen } = useEventBus();
    const [frame, setFrame] = useState<WrapperFrame>({ ...getFrame(frameName), render: render });
    const { buildUrl } = useUrlBuilder();

    const frameRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        return listen('navigate', event => {
            if (event.messageType !== 'navigate' || !activeFrame) return;
            const eventData = event.data;
            const frame = getFrameName(eventData.pathname);

            if (frame === frameName && eventData.crossFrame && frameRef.current) {
                const url = buildUrl({
                    pathname: getFrameUrl(frame, eventData.pathname),
                    search: eventData.search || '',
                    hash: eventData.hash || '',
                    state: null
                });
                frameRef.current.src = url!;
            }
        });
    }, [listen, frameName, activeFrame]);

    useEffect(() => {
        updateFrame(frame);
    }, [frame]);

    useEffect(() => {
        if (frame.isLoaded && render && (frameName === activeFrame || autoActivate)) {
            setTimeout(() => {
                sendActivationEvent(frameName);
            }, 1000);
        }
    }, [frame.isLoaded, render, activeFrame, frameName]);

    const getClassName = () => {
        return activeFrame === frameName ? classes.iframe : classes.hiddenFrame;
    };

    const handleLoad = () => {
        setFrame({ ...frame, isLoaded: true });
    };
    const handleIsLoading = () => {
        setFrame({ ...frame, isLoaded: false });
    };

    return (
        <>
            {frame && frame.render && (
                <iframe
                    id={frameName + '-frame'}
                    src={frame.defaultSrc}
                    className={getClassName()}
                    onLoad={handleLoad}
                    onLoadStart={handleIsLoading}
                    allowFullScreen={true}
                    ref={mergeRefs(frameRef, ref)}
                    allow="clipboard-read; clipboard-write"
                />
            )}
        </>
    );
})

