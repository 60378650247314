/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-types */
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { NotificationApi } from '../../services/notification-service/notification-service';

export interface ISettingsContext {
    snooze: (expirationDate?: number) => Promise<void>;
    snoozeExpirationDate?: number;
}

export const SettingsContext = createContext<ISettingsContext>(null!);

export function SettingsProvider({ children }: PropsWithChildren<{}>) {
    const [snoozeExpirationDate, setSnoozeExpirationDate] = useState<number | undefined>();

    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    const notificationService = useMemo(() => {
        if (!(config && oidcService)) return;
        return new NotificationApi(config, oidcService);
    }, [config, oidcService]);

    useEffect(() => {
        if (notificationService) {
            notificationService.getNotificationStatus().then(status => {
                if (status?.snoozeExpirationDate) {
                    setSnoozeExpirationDate(+new Date(status.snoozeExpirationDate));
                }
            });
        }
    }, [notificationService]);

    const snooze = useCallback(
        async (expirationDate?: number): Promise<void> => {
            if (notificationService) {
                notificationService.setSnooze(expirationDate ? new Date(expirationDate) : new Date());
                setSnoozeExpirationDate(expirationDate);
            }
        },
        [notificationService, setSnoozeExpirationDate]
    );

    return (
        <SettingsContext.Provider
            value={{
                snoozeExpirationDate: (snoozeExpirationDate || 0) > +new Date() ? snoozeExpirationDate : undefined,
                snooze
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}
