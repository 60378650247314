import { Box, Card, CardContent, Typography, styled } from '@mui/material';
import CommCardLabel from './communications-card-label';
import ChatImage from './communications-chat-image';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HideImageIcon from '@mui/icons-material/HideImage';
import ImageIcon from '@mui/icons-material/Image';
import { useMessageAction } from '../../../../hooks/use-message-action/use-message-action';
import { IMessageReaction } from '../../../../services/pubnub-service';
import { useState } from 'react';

const Row = styled('div', {
    shouldForwardProp: prop => prop !== 'lowerCase' && prop !== 'sender'
})<{ sender: Sender; showreduced?: string }>(({ sender, showreduced }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: showreduced === 'true' ? '85%' : '100%',
    minWidth: 0,
    alignItems: showreduced === 'true' ? 'flex-start' : ['me', 'system'].includes(sender) ? 'flex-end' : 'flex-start',
    paddingLeft: showreduced === 'true' ? '24px' : ['me', 'system'].includes(sender) ? '20%' : '0',
    paddingRight: showreduced === 'true' ? '' : ['me', 'system'].includes(sender) ? '0' : '20%'
}));

const MessageCard = styled(Card, {
    shouldForwardProp: prop => prop !== 'sender'
})<{ sender: Sender }>(({ sender }) => ({
    backgroundColor: cardBgMap[sender],
    border: `1px solid ${borderColorMap[sender]}`,
    borderRadius: '8px',
    '& .MuiCardContent-root': {
        padding: 10,
        '&:last-child': {
            paddingBottom: 10
        }
    }
}));

const CardText = styled(Typography)<{ blur?: string }>(({ blur }) => ({
    color: '#2C3542',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: 15,
    fontStyle: blur == 'true' ? 'italic' : 'none'
}));

export type Sender = 'me' | 'system' | 'patient';

const cardBgMap = {
    me: '#EDEFF2',
    system: '#F9FAFB',
    patient: '#DFF0FC'
};

const borderColorMap = {
    me: '#EAEAEA',
    system: '#D5D9DD',
    patient: '#CEE5F5'
};

interface CommunicationsMessageCardProps {
    from: string;
    to: string;
    text?: string;
    timeStamp: string;
    sender: Sender;
    senderName: string;
    locationName: string;
    timestamp: string;
    status?: string;
    showReduced?: string;
    images?: string[];
    isPatientProfile?: boolean;
    phoneNumber: string;
    reaction?: IMessageReaction;
    timetokenString?: string;
    onClickResend?: () => void;
}

const CommunicationsMessageCard = ({
    sender,
    text,
    locationName,
    timestamp,
    status,
    showReduced,
    images,
    isPatientProfile,
    phoneNumber,
    reaction,
    timetokenString,
    onClickResend
}: CommunicationsMessageCardProps) => {
    const [blurred, setBlurred] = useState(Boolean(reaction?.hidden));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { addMessageHiddenReaction, removeMessageHiddenReaction } = useMessageAction();

    const handleBlur = async () => {
        if (blurred && reaction && reaction.hidden && reaction.hidden.length > 0) {
            const actionTimetoken = reaction.hidden[0].actionTimetoken;
            removeMessageHiddenReaction(phoneNumber, timetokenString!, actionTimetoken);
        } else if (!blurred) {
            addMessageHiddenReaction(phoneNumber, timetokenString!);
        }
        setBlurred(!blurred);
    };

    const handleMessageClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.ctrlKey && !showReduced) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (event.ctrlKey) {
            event.preventDefault();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const content = (
        <Row sender={sender} showreduced={showReduced}>
            <Box width='100%' display='flex' justifyContent={sender == 'patient' ? 'flex-start' : 'flex-end'}>
                {images && images.length !== 0 && (
                    <MessageCard variant='outlined' sender={sender}>
                        <CardContent>
                            {images.map(image => (
                                <ChatImage
                                    key={image}
                                    src={image}
                                    phoneNumber={phoneNumber}
                                    reaction={reaction}
                                    timetoken={timetokenString}
                                />
                            ))}
                        </CardContent>
                    </MessageCard>
                )}
                {text && (
                    <MessageCard
                        variant='outlined'
                        sender={sender}
                        onMouseDown={handleMessageClick}
                        onContextMenu={handleContextMenu}
                        sx={{
                            width: 'fit-content',
                            mt: images?.length !== 0 ? '8px' : 0
                        }}
                    >
                        <CardContent>
                            <CardText blur={blurred?.toString()}>{!blurred ? text : 'Content is hidden'}</CardText>
                        </CardContent>

                        <Menu
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            anchorEl={anchorEl}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleBlur}>
                                {blurred ? (
                                    <>
                                        <ListItemIcon>
                                            <ImageIcon fontSize='small' />
                                        </ListItemIcon>
                                        <ListItemText>Unhide</ListItemText>
                                    </>
                                ) : (
                                    <>
                                        <ListItemIcon>
                                            <HideImageIcon fontSize='small' />
                                        </ListItemIcon>
                                        <ListItemText>Hide</ListItemText>
                                    </>
                                )}
                            </MenuItem>
                        </Menu>
                    </MessageCard>
                )}
            </Box>
            <CommCardLabel
                direction={sender}
                locationName={locationName}
                timestamp={timestamp}
                status={status}
                isPatientProfile={isPatientProfile}
                onClickResend={onClickResend}
            />
        </Row>
    );
    return content;
};

export default CommunicationsMessageCard;
