import { useOidcWithAdmin } from '@revenuewell/front-end-bundle'
import { createContext, PropsWithChildren, useEffect } from 'react'
import { matchPath } from 'react-router'
import { useHistory } from '../use-history-intercept'
import { useEventBus } from '../use-event-bus'
import { useWrapperNavigation } from '../use-wrapper-navigation'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WrapperBusContextProps {}

const blacklistUrls = ['/locations/:locationId/phone/:phoneNumber']

export const WrapperBusContext = createContext<WrapperBusContextProps>(null!);

export default function WrapperBusProvider({ children }: PropsWithChildren<{}>) {
    const history = useHistory()
    const { listen } = useEventBus()
    const { signinRedirect, getClaims } = useOidcWithAdmin()
    const { activeFrame, getFrameName } = useWrapperNavigation()
    
    useEffect(() => {
        return listen('unauthorized', () => {
            getClaims().then((res) => {
                if(res.userClass != 'GlobalUser')
                    signinRedirect()
            })
        })
    }, [listen, signinRedirect, getClaims])

    useEffect(() => {
        return listen('navigate', event => {
            if (event.messageType !== 'navigate') return

            const eventData = event.data
            const frame = getFrameName(eventData.pathname)

            // prevent non-active pages from interferring with history
            if(!eventData.crossFrame && frame !== activeFrame) return

            if (blacklistUrls.find(url => !!matchPath(eventData.pathname, url))) return

            const historyEntry = {
                pathname: eventData.pathname.replace('-frame', ''),
                search: eventData.search,
                hash: eventData.hash
            };

            eventData.replaceState ? history.replace(historyEntry) : history.push(historyEntry)
        });
    }, [listen, activeFrame, history])

    useEffect(() => {
        return listen('absolute-navigate', event => {
            if (event.messageType !== 'absolute-navigate') return
            window.location.href = event.data.url
        })
      }, [listen])

    return <WrapperBusContext.Provider value={{}}>{children}</WrapperBusContext.Provider>
}
