import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';

export interface ILocation {
    id: number;
    name: string;
    crmAccountId: string;
    idMasterAccount: number;
    rwAccountId: string;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressPostalCode: string;
    addressState: string;
    phone: string;
    accountStatus: number;
    timezone: string;
    idNetwork: number;
}

export default class LocationService extends AuthApiService {
    private static instance: LocationService;

    private config: any;
    private locationsUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.locationsUrl = `${config.account.apiUrl}/locations`;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!LocationService.instance) LocationService.instance = new LocationService(config, oidcService);

        return LocationService.instance;
    }

    public async getLocation(locationId: number): Promise<ILocation> {
        const url = `${this.locationsUrl}/${locationId}`;

        const response = await this.authFetch(url, { method: 'GET' });
        const data: ILocation = await response.json();

        return data;
    }
}
