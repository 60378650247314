import { Box, Select, MenuItem, ListSubheader, TextField, InputAdornment, Typography, styled, FormControl, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { Search as SearchIcon, Business as LocationIcon } from '@mui/icons-material';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { useState, useMemo } from 'react';

const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

interface CommLocationSelectProps {
    pubnubPrefix: string;
    updatePubnubPrefix: (pubnubPrefix: string) => void;
}

const CommLocationSelect = ({ pubnubPrefix, updatePubnubPrefix }: CommLocationSelectProps) => {
    const { mailboxes } = usePubnub();
    const [searchText, setSearchText] = useState('');

    const LocationName = styled(Typography)({
        color: '#0259AB',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    });

    const LocationTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    const style = {
        '.MuiOutlinedInput-input': {
            paddingTop: '8px',
            paddingBottom: '8px'
        },
        '.MuiOutlinedInput-notchedOutline ': {
            border: '1px solid rgba(25, 118, 210, 0.5)'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(25, 118, 210, 0.5)'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(25, 118, 210, 0.5)'
        }
    };

    const displayedOptions = useMemo(
        () => mailboxes.filter((mailbox) => containsText(mailbox.location.name, searchText)),
        [searchText, mailboxes]
    );

    return (
        <FormControl fullWidth variant='outlined'>
            <Select
                id='search-select'
                labelId='search-select-label'
                sx={{ ...style, color: '#0259AB' }}
                MenuProps={{
                    autoFocus: false,
                    MenuListProps: { sx: { backgroundColor: '#FFFFFF', width: 290 } },
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    transformOrigin: { vertical: 'bottom', horizontal: 'left' }
                }}
                value={pubnubPrefix}
                onChange={(e) => updatePubnubPrefix(e.target.value)}
                onClose={() => setSearchText('')}
                renderValue={() => {
                    const locationName = mailboxes.find(mailbox => mailbox.pubnubPrefix === pubnubPrefix)?.location.name;
                    return (
                        <LocationTooltip title={locationName}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <LocationIcon sx={{ color: '#0675DD' }} />
                                <LocationName> {locationName} </LocationName>
                            </Box>
                        </LocationTooltip>
                    )
                }}
            >
                {displayedOptions.map((option, i) => (
                    <MenuItem key={i} value={option.pubnubPrefix}>
                        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                            {option.location.name}
                        </Typography>
                    </MenuItem>
                ))}
                <ListSubheader
                    sx={{ backgroundColor: '#FFFFFF' }}
                >
                    <TextField
                        size='small'
                        sx={style}
                        placeholder=''
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape')
                                e.stopPropagation();
                        }}
                    />
                </ListSubheader>
            </Select>
        </FormControl>
    );
}

export default CommLocationSelect;
