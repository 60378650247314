import Pubnub from 'pubnub'

export interface IPNKeys {
  publishKey: string
  subscribeKey: string
  authKey: string
  prefix: string
  userChannelGroup: string
  uuid: string
}

export default class PNService {
  public client: Pubnub
  private keys: IPNKeys
  private subscriptionWildcard: string[] = []

  constructor(keys: IPNKeys) {
    if (!keys.prefix?.length || !keys.userChannelGroup?.length)
      throw new Error("must have prefix and channel user group to create team chat client")
    // setup Listeners
    this.client = new Pubnub({
      ...keys
    })
    this.keys = keys

  }

  public setupSubscription(): void {
    console.log('EVENTS - SETTING UP SUBSCRIPTION:', this.keys.prefix)
    
    if (this.keys.userChannelGroup) {
      this.client.subscribe({
        channelGroups: [this.keys.userChannelGroup]
      })
    }
  }


  public async fetchMemberships(): Promise<{string: string}> {
    const memberships = await this.client.objects.getMemberships({
      include: {
        customFields: true,
      },
      sort: {
        'channel.updated': 'desc',
      }
    })

    const membershipsTimetoken = memberships.data
        .filter((d: any) => d.custom?.lastReadTimetoken && isNaN(Number(d.custom?.lastReadTimetoken)) === false)
        .map((d: any) => ({
          [d.channel.id]: String(d.custom?.lastReadTimetoken),
        }))
        .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});

    return membershipsTimetoken;
  }

  public async fetchUnreadCount(memberships: {string: string}): Promise<number> {
    const query = {
      channels: Object.keys(memberships),
      channelTimetokens: Object.values(memberships),
    };
    
    if (query.channelTimetokens?.length) {
      const unreads = await this.client.messageCounts(query).then((mc) => mc.channels);
      const count =  Object.values(unreads).reduce((acc, cur) => acc + cur,0);
      return count
    };


    return 0;
  }

  public dispose(): void {
    this.client.unsubscribeAll()
  }
}
