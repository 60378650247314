import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { CallHistory, CallHistorySearch } from './types';
import { VoiceApi } from '../../services/api-client/voice-api';

export default class CallHistoryService extends AuthApiService {
  private static instance: CallHistoryService;
  private config: any;
  private apiUrl: string;
  private voiceApi: VoiceApi;

  private constructor(config: any, oidcService: OidcService) {
    super(oidcService, new NavigationService());
    this.config = config;
    this.apiUrl = this.config.voice.apiUrl;
    this.voiceApi = VoiceApi.initialize(config, oidcService);
    return this;
  }

  public static getInstance(config: any, oidcService: OidcService) {
    if (!CallHistoryService.instance)
      CallHistoryService.instance = new CallHistoryService(config, oidcService);

    return CallHistoryService.instance;
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  toDateString(time: number) {
    return new Date(time).toISOString().slice(0, 19) + '+00:00';
  }

  public async search(search: CallHistorySearch): Promise<CallHistory[]> {
    const params = new URLSearchParams();
    
    const locationIds = search.locationIds
      ? search.locationIds.join(',')
      : (await this.voiceApi.getLocations()).map(item => item.id).join(',');
    
    const { masterAccountId } = await this.oidcService.getClaims();
    params.append("masterAccountId", String(masterAccountId));
    params.append('locationIds', locationIds);
    if (search.searchText) params.append('searchText', search.searchText);
    if (search.callDirection) params.append('direction', this.capitalizeFirstLetter(search.callDirection));
    if (search.isMissedCall) params.append('successful', String(false));
    if (search.timeStart && search.timeStart != 0) params.append('timeStartFrom', this.toDateString(search.timeStart));
    if (search.timeEnd && search.timeEnd != 0) params.append('timeStartTo', this.toDateString(search.timeEnd));
    if (search.hasVoicemail !== undefined) params.append('hasVoicemail', String(search.hasVoicemail));
    if (search.pageSize) params.append('pageSize', String(search.pageSize));
    if (search.isFax !== undefined) params.append('isFax', String(search.isFax));
    if (search.direction) params.append('direction', String(search.direction));
    if (search.timeStartTo) params.append('timeStartTo', search.timeStartTo);

    if (search.mailbox?.value === 'user_mailbox') {
      params.append('isMyCall', String(true));
    }

    if (!search.pageSize) params.append('pageSize', String(60));

    const query = params.toString() ? `?${params.toString()}` : '';
    const url = `${this.apiUrl}/call-history/search${query}`;
    const resp = await this.authFetch(url);
    const jsonResponse = await resp.json();

    return jsonResponse.calls.filter((d: CallHistory) => {
      const result = d.callDirection && d.callStarted;

      return result;
    }) as CallHistory[];
  }

  public async get(id: string): Promise<CallHistory> {
    const url = `${this.apiUrl}/call/${id}`;
    const resp = await this.authFetch(url);
    const jsonResponse = await resp.json();

    return jsonResponse as CallHistory;
  }

  public async getFax(id: string): Promise<Blob> {
    const url = `${this.apiUrl}/call/${id}/fax?download=true`;
    const resp = await this.authFetch(url);

    return resp.blob();
  }
}