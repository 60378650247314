import { format } from 'date-fns';
import { AlertContainer, AlertContent, IconBox } from './alert';
import AlertHeader from './alert-header';
import AlertLocation from './alert-location';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { styled } from '@mui/material';
import { useHistory } from '../../../hooks/use-history-intercept';

const AlertIconBox = styled(IconBox)({
    backgroundColor: '#C6E3CB'
});

const AlertIcon = styled(DescriptionOutlinedIcon)({
    color: '#4E7054',
    width: 16
});

interface FormAlertProps {
    date: Date;
    count: number;
    location?: string;
    onRemove?: () => void;
}

export default function FormAlert({ date, count, location, onRemove }: FormAlertProps) {
    const history = useHistory();

    return (
        <AlertContainer onClick={() => history.push('/core/Forms')}>
            <AlertHeader
                icon={
                    <AlertIconBox>
                        <AlertIcon />
                    </AlertIconBox>
                }
                title='Forms'
                date={format(date, 'hh:mm a')}
                onClose={onRemove}
            />
            <AlertContent>You have {count} new patient form(s).</AlertContent>
            {!!location && <AlertLocation>{location}</AlertLocation>}
        </AlertContainer>
    );
}
