import { AppBar, Box, IconButton, Slide, Toolbar } from '@mui/material';
import { Search as SearchIcon, Menu as MenuIcon } from '@mui/icons-material';
import { ReactComponent as RwLogo } from '../../../../assets/logo-small.svg';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import TopNavMenuMobile from './top-nav-menu-mobile';
import SearchTopNav from './search';

export const useStyles = makeStyles({
    logo: {
        marginLeft: 8
    },
    actionMenu: {
        width: 48,
        height: 48,
        margin: '11px 0 11px 18px',
        borderRadius: 24,
        color: '#fff',
        '& svg': {
            fontSize: '26px !important'
        },
        '&:hover': {
            backgroundColor: '#265D7C'
        },
        '&:focus-visible': {
            border: '2px solid #1EB9FB'
        }
    },
    actionMenuOpen: {
        backgroundColor: '#032F40',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#032F40'
        }
    }
});

export default function TopNavMobile() {
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    return (
        <>
            <AppBar position='relative' sx={{ backgroundColor: 'nav.main', height: 56 }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box className={classes.logo}>
                        <RwLogo />
                    </Box>

                    <Box display='flex' alignItems='center'>
                        <IconButton onClick={() => setIsSearchOpen(true)} className={classes.actionMenu}>
                            <SearchIcon />
                        </IconButton>
                        <IconButton
                            className={classes.actionMenu}
                            onClick={() => {
                                setIsVisible(d => !d);
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
                <SearchTopNav visible={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
            </AppBar>
            <Slide direction='down' in={isVisible} mountOnEnter exit={true} onExited={() => setIsVisible(false)}>
                <TopNavMenuMobile onClose={() => setIsVisible(false)} />
            </Slide>
        </>
    );
}
