import React, { useState, useEffect } from 'react';
import { AppTemplateRoot, useOidc } from '@revenuewell/front-end-bundle';
import { makeStyles } from '@mui/styles';
import { useChildrenListener } from '../../../hooks/use-children-listener';
import Main from '../main/main';
import { useEventBus } from '../../../hooks/use-event-bus';

const useStyles = makeStyles(() => ({
    app: {
        display: 'flex',
        width: '100%',
        height: '100%'
    }
}))

export default function App() {
    const classes = useStyles()
    const { onLogout } = useChildrenListener()
    const { listen, unlisten } = useEventBus()
    const { logoutRedirect } = useOidc()
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => setReady(true), 500);
    }, [])

    useEffect(() => {
        const listener = listen('logout', () => logoutRedirect());
        return () => unlisten('logout', listener);
      },[]);

    useEffect(() => {
        if (onLogout) onLogout(logoutRedirect)
    }, [onLogout, logoutRedirect])

    return (
        <AppTemplateRoot className={classes.app} ready={ready} productMenu={{ render: false }}>
            <Main />
        </AppTemplateRoot>
    )
}
