import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Help as HelpIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { ReactComponent as RwLogo } from '../../../../assets/logo-small.svg';
import { useState } from 'react';
import { useWrapperNavigation } from '../../../../hooks/use-wrapper-navigation';
import AlertButton from './alert-button';
import InboxButton from './inbox-button';
import UserButton from './user-button';
import { useFeatureFlag } from '@revenuewell/front-end-bundle';
import config from '../../../../config/config.json';
import { useStyles } from './top-nav';
import { Search as SearchIcon } from '@mui/icons-material';
import SearchTopNav from './search';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';
import SettingsButton from './settings-button';

export default function TopNavTablet() {
    const classes = useStyles();
    const { windowUrls, activeFrame } = useWrapperNavigation();
    const { publish } = useEventBus();
    const { hasFlag } = useFeatureFlag();
    const hasUniversalSearch = hasFlag('UniversalSearch');
    const hasAlerts = hasFlag('Alerts2');
    const hasUnifiedInbox = hasFlag('UnifiedInbox');
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    return (
        <AppBar position='relative' sx={{ backgroundColor: 'nav.main' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box>
                    <RwLogo />
                </Box>
                <Box display='flex' alignItems='center'>
                    {hasUniversalSearch && (
                        <IconButton onClick={() => setIsSearchOpen(true)} className={classes.actionMenu}>
                            <SearchIcon />
                        </IconButton>
                    )}
                    {hasUnifiedInbox && <InboxButton />}
                    {hasAlerts && (
                        <>
                            <AlertButton />
                            <Box sx={{ width: '2px', height: '48px', backgroundColor: 'nav.dark' }} />
                        </>
                    )}
                    <SettingsButton/>
                    <IconButton
                        className={`${classes.actionMenu} ${classes.actionMenuGrey}`}
                        onClick={() => {
                            publish({
                                messageType: 'navigate',
                                data: {
                                    pathname: config.support.url,
                                    crossFrame: true,
                                    replaceState: false
                                }
                            });
                        }}
                    >
                        <HelpIcon />
                    </IconButton>
                    <UserButton />
                </Box>
            </Toolbar>
            <SearchTopNav visible={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
        </AppBar>
    );
}
