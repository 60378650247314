import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavigationService, useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { AccountService } from '../../services/account/account-service';
import FeatureFlagsService from '../../services/feature-flag-service/feature-flag-service';
import { ILocation } from '../../services/location-service/location-service';

export interface FeatureFlagContextProps {
    contextReady: boolean
    features(locationId: number): string[];
    hasFeature(locationId: number, feature: string): boolean;
    locationsWithFeature(feature: string): ILocation[];
}

export const FeatureFlagContext = createContext<FeatureFlagContextProps>(null!);

export function FeatureFlagProvider(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();
    const {  getClaims, oidcService } = useOidcWithAdmin();
    const [locationsFeaturesMap, setLocationsFeaturesMap] = useState<Map<number,string[]>>(new Map());
    const [featuresLocationsMap, setFeaturesLocationsMap]  = useState<Map<string, ILocation[]>>(new Map());
    const [contextReady, setContextReady] = useState<boolean>(false);
    const navigationService = useMemo(() => new NavigationService(), [])
    const accountService = useMemo(() => new AccountService(config, oidcService,), [config, oidcService])
    const featureFlagsService = new FeatureFlagsService(config, oidcService);

    useEffect(() => {
        (async () => {
            const featureMap: Map<string,ILocation[]> = new Map();
            const locationMap: Map<number, string[]> = new Map();
            const claims = await getClaims();
            const customerIdParameter = navigationService.getCustomerId();
            const masterAccountId = (customerIdParameter || claims.masterAccountId);
            const locs = await accountService.getLocationsByMasterId(masterAccountId!);
            const fflist = await Promise.all(locs.map(async (loc) => {
                const ff = await featureFlagsService.getFeatureFlags(loc.id);
                return {location: loc, features: ff};
            }))

            fflist.forEach(item => {
                locationMap.set(item.location.id, item.features);
                item.features.forEach(ffTmp => {
                    const ff = ffTmp.toLowerCase();
                    if(!featureMap.get(ff))
                        featureMap.set(ff, [item.location]);
                    else
                        featureMap.get(ff)?.push(item.location);
                })
            })
            setFeaturesLocationsMap(featureMap);
            setLocationsFeaturesMap(locationMap);
            setContextReady(true);

        })()
    }, []);

    const hasFeature = useCallback((locationId: number, feature: string) : boolean => {
        const featureLower = feature.toLowerCase();
        return featuresLocationsMap.get(featureLower)?.find(loc => loc.id == locationId) != undefined;
    },[featuresLocationsMap]);

    const features = useCallback((locationId: number) => {
        return locationsFeaturesMap.get(locationId);
    },[locationsFeaturesMap]);

    const locationsWithFeature = useCallback((feature: string) => {
        const featureLower = feature.toLowerCase();
        return featuresLocationsMap.get(featureLower);
    },[featuresLocationsMap])

   

    const context = {
        contextReady: contextReady,
        hasFeature: hasFeature,
        features: features,
        locationsWithFeature: locationsWithFeature
    } as FeatureFlagContextProps;

    return <FeatureFlagContext.Provider value={{ ...context }}>
            {props.children}
        </FeatureFlagContext.Provider>;
}

export function useFeatureFlags() {
    const context = useContext(FeatureFlagContext);

    if (!context) {
        throw new Error('useFeatureFlags must be used within the FeatureFlagsProvider');
    }

    return context;
}