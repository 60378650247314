import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { dedupeContacts } from '../../utils/contact';
import { IContactProfile } from '../api-client/interface';
import { ContactSearchByNumber } from '../api-client/contact-api';

export interface UniversalSearch {
    searchTerm: string;
    networks: number[];
    preferredLocationIds: number[];
    sort: 'LastActivity' | 'PreferredLocation' | 'LastName';
    pageSize: number;
    page: number;
    status?: 'Active' | 'Inactive';
}

export interface UniversalSearchResponse {
    patientId: number;
    idResponsibleParty: number;
    preferredLocationId: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: null;
    primaryPhone: null;
    secondaryPhone: null;
    cellPhone: null;
    birthDate: string | null;
    idNetwork: number;
    status: string;
    isCommEmail: boolean;
    isCommDirectMail: boolean;
    isCommSms: boolean;
    isCommPhone: boolean;
    language: number;
    systemLastModified: Date;
    lastActivity: Date;
}

export default class UniversalPatientSearchService extends AuthApiService {
    private static instance: UniversalPatientSearchService;
    private config: any;
    private configUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.configUrl = this.config.universalPatientSearch.apiUrl;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!UniversalPatientSearchService.instance)
            UniversalPatientSearchService.instance = new UniversalPatientSearchService(config, oidcService);

        return UniversalPatientSearchService.instance;
    }

    public async search(search: UniversalSearch): Promise<IContactProfile[]> {
        const url = `${this.configUrl}/patients/search`;

        const response = await this.authFetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(search)
        });

        const data = (await response.json()) as UniversalSearchResponse[];

        const dataMapped = data.map(
            item =>
                ({
                    cellPhone: item.cellPhone || undefined,
                    dateOfBirth: item.birthDate || undefined,
                    email: item.email || undefined,
                    firstName: item.firstName || undefined,
                    lastName: item.lastName || undefined,
                    homePhone: item.primaryPhone || undefined,
                    id: item.patientId,
                    isResponsibleParty: item.patientId === item.idResponsibleParty,
                    location: {
                        id: item.preferredLocationId
                    },
                    networkId: item.idNetwork,
                    phoneNo: item.cellPhone || item.primaryPhone || item.secondaryPhone || undefined
                } as IContactProfile)
        );

        return dedupeContacts(dataMapped);
    }


    public async searchByPhoneNumbers(phoneNumbers: string[], networkIds?: number[]): Promise<Record<string, ContactSearchByNumber[]>> {
        const url = `${this.configUrl}/patients/searchByPhoneNumbers`;

        const response = await this.authFetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({PhoneNumbers: phoneNumbers, Networks: networkIds ? networkIds : []})
        });

        const data = (await response.json()) as UniversalSearchResponse[];

        const dataMapped = data.map(
            (item: UniversalSearchResponse): ContactSearchByNumber => {
                return {
                    cellPhone: item.cellPhone || undefined,
                    homePhone: item.primaryPhone || undefined,
                    workPhone: item.secondaryPhone || undefined,
                    dateOfBirth: item.birthDate || undefined, 
                    firstName: item.firstName,
                    lastName: item.lastName,
                    id: item.patientId,
                    isResponsibleParty: item.patientId === item.idResponsibleParty,
                    responsiblePartyId: item.idResponsibleParty,
                    locationId: item.preferredLocationId || undefined,
                    networkId: item.idNetwork
                };
            }
        );

        const records: Record<string, ContactSearchByNumber[]> = {};
        for(const phoneNumber of phoneNumbers){
            for(const data of dataMapped){
                if(data.cellPhone == phoneNumber || data.homePhone == phoneNumber || data.homePhone == phoneNumber){
                    const cur = records[phoneNumber];
                    if(cur){
                        records[phoneNumber].push(data);
                    }else{
                        records[phoneNumber] = [data];
                    }
                }
            }
        }

        return records;
    }
}
