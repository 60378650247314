import ReactDOM from 'react-dom';
import App from './components/layout/app/app';
import { AppRoutes } from './routing/app-routes';
import AppProviders from './components/layout/app/app-provider-list';
import { AppTemplate } from '@revenuewell/front-end-bundle';
import CONFIG from './config/config.json';
import '@revenuewell/logic-notification/logic-notification.esm.css';
import '@revenuewell/logic-product-menu-loader/logic-product-menu-loader.esm.css';
import '@revenuewell/widget-virtual-visit-starter/widgets-virtual-visit-starter.esm.css';
import '@revenuewell/ui-dialogs/ui-dialogs.esm.css';
import '@revenuewell/ui-notifications/ui-notifications.esm.css';
import '@revenuewell/ui-progress/ui-progress.esm.css';
import '@revenuewell/ui-drawers/ui-drawers.esm.css';
import '@revenuewell/ui-icons/ui-icons.esm.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * { font-family: Roboto, sans-serif; }
`;

function Index() {
    return <>
        <GlobalStyle />
        <AppTemplate
            isProduction
            devConfig={CONFIG}
            routes={AppRoutes}
            preload={{ products: true }}
        >
            <AppProviders>
                <App />
            </AppProviders>
        </AppTemplate>
    </>
}

ReactDOM.render(<Index />, document.getElementById('root'));
