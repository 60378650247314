import { useDesktop, DesktopMessage, DesktopMessageWrapperPatientProfile } from '@revenuewell/front-end-bundle';
import { useEffect } from 'react';
import { useEventBus } from '../../../hooks/use-event-bus';
import { EventJumpToMessageNativeNotification, EventSetUnreadBadge } from '../../../types/event-bus';
import { useNotification } from '../../../hooks/use-notification/use-notification';

export const NotificationEventListener: React.FC = ({ children }) => {
    const { listen, publish } = useEventBus();
    const { isDesktopApplication, listenMessage, setUnreadBadge } = useDesktop();
    const { showNotification } = useNotification();

    useEffect(() => {
        if (isDesktopApplication()) {
            // uc-notification project -> phone call -> patient profile click handler
            return listenMessage('desktop-wrapper-patient-profile', (e: DesktopMessage) =>
                publish({
                    messageType: 'patientProfile',
                    data: (e as DesktopMessageWrapperPatientProfile).payload
                })
            );
        }
    }, []);

    useEffect(() => {
        if (isDesktopApplication()) {
            // messenger 1 project -> notification click handler
            const createJumpToMessageNativeNotification = (e: EventJumpToMessageNativeNotification) => {
                const onClick = () => {
                    publish({
                        messageType: 'jumpToMessage',
                        data: {
                            conversationId: e.data.conversationId
                        }
                    });
                };
                showNotification(e.data.title, { body: e.data.body }, onClick);
            };
            return listen('jump-to-message-native-notification', e =>
                createJumpToMessageNativeNotification(e as EventJumpToMessageNativeNotification)
            );
        }
    }, []);

    useEffect(() => {
        if (isDesktopApplication()) {
            // messenger 1 project -> unread badge handler
            return listen('set-unread-badge', e => setUnreadBadge((e as EventSetUnreadBadge).data.unread));
        }
    }, []);

    return <>{children}</>;
};
