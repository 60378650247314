import { useMemo } from 'react';
import { Menu, MenuItem, Box, Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InfoOutlined } from '@mui/icons-material';
import { snoozeDateFormatter } from '../../../../utils/formatter';
import { useViewMode } from '../../../../hooks/use-view-mode';
import { useSettings } from '../../../../hooks/use-settings';

const useStyle = () => {
    const { viewMode } = useViewMode();

    const useStyles = useMemo(
        () =>
            makeStyles({
                root: {
                    zIndex: 10000,
                    ...(viewMode !== 'Phone' && {
                        marginLeft: -28,
                        marginTop: -16
                    })
                },
                paper: {
                    backgroundColor: 'white',
                    width: viewMode === 'Phone' ? 'calc(100vw - 32px)' : 320,
                    '& [role="menuitem"]': {
                        minHeight: 48
                    }
                },
                box: {
                    fontFamily: 'Roboto',
                    fontSize: 16,
                    fontWeight: 400,
                    margin: '8px 32px',
                    lineHeight: '40px',
                    color: '#2C3542',
                    '& hr': {
                        border: 'none',
                        backgroundColor: '#E1E4E7',
                        height: 2,
                        margin: '16px 0'
                    }
                },
                button: {
                    marginLeft: '-8px',
                    textTransform: 'initial',
                    color: '#0675DD',
                    fontWeight: 400,
                    fontSize: 16
                },
                title: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'initial',
                    '&:hover': {
                        background: 'transparent'
                    }
                },
                tooltip: {
                    maxWidth: '210px',
                    fontWeight: 500,
                    textAlign: 'center'
                }
            }),
        [viewMode]
    );

    return useStyles();
};

interface SnoozeMenuProps {
    anchor: null | HTMLElement;
    onClose?: () => void;
}

export default function SnoozeMenu({ anchor, onClose }: SnoozeMenuProps) {
    const style = useStyle();
    const { snooze, snoozeExpirationDate } = useSettings();

    const { viewMode } = useViewMode();

    const handleSnooze = (expirationDate?: number) => {
        return () => {
            onClose?.();
            setTimeout(() => {
                snooze(expirationDate);
            }, 500);
        };
    };

    return (
        <Menu
            classes={{
                root: style.root,
                paper: style.paper
            }}
            id='notifications-menu'
            anchorEl={anchor}
            keepMounted={false}
            open={Boolean(anchor)}
            autoFocus={false}
            onClose={onClose}
            variant='selectedMenu'
            anchorOrigin={{
                vertical: 'top',
                horizontal: viewMode !== 'Phone' ? 'left' : 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: viewMode !== 'Phone' ? 'right' : 'center'
            }}
            anchorReference='anchorEl'
        >
            {snoozeExpirationDate ? (
                <Box className={style.box}>
                    Do Not Disturb is active...
                    <br />
                    until {snoozeDateFormatter(snoozeExpirationDate)}
                    <hr />
                    <Button className={style.button} color='primary' onClick={handleSnooze()}>
                        Resume Notifications
                    </Button>
                </Box>
            ) : (
                <Box>
                    <MenuItem className={style.title}>
                        Do Not Disturb for...
                        <Tooltip
                            arrow
                            classes={{ tooltip: style.tooltip }}
                            title='Do Not Disturb will pause all notifications from the RevenueWell application'
                            placement='top'
                        >
                            <InfoOutlined />
                        </Tooltip>
                    </MenuItem>
                    <MenuItem onClick={handleSnooze(+new Date() + 1800000)}>30 minutes</MenuItem>
                    <MenuItem onClick={handleSnooze(+new Date() + 3600000)}>1 hour</MenuItem>
                    <MenuItem onClick={handleSnooze(+new Date() + 7200000)}>2 hours</MenuItem>
                    <MenuItem onClick={handleSnooze(+new Date() + 86400000)}>until tomorrow</MenuItem>
                </Box>
            )}
        </Menu>
    );
}
