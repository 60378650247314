import { makeStyles } from '@mui/styles';
import React, { useCallback, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { formatUSPhoneNumber } from '../../../../utils/formatter';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { useInbox } from '../../../../hooks/use-inbox';
import { useContactState } from '../../../../hooks/use-contacts';
import { InboxPreview } from '../../../../hooks/use-inbox/types/inbox-preview-types';
import InboxPreviewCardLoading from './inbox-preview-card-loading';
import InboxPreviewCard from './inbox-preview-card';
import InboxSearchEmpty from './inbox-search-empty'
import { InboxSectionSeparator, OverlineText } from './styles/inbox-styles';
import { formatFromNow } from '../../../../utils/date-time';
import { importantContacts } from '../../../../utils/contact';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { useIntercomApi } from '../../../../hooks/use-intercom';

export const CallDirectionTypes = {
    Inbound: 'Inbound',
    Outbound: 'Outbound',
    Missed: 'Missed',
    Voicemail: 'Voicemail',
    Fax: 'Fax'
} as const;

export type CallDirectionType = typeof CallDirectionTypes[keyof typeof CallDirectionTypes];

const useClasses = makeStyles({
    scroll: { borderTop: '1px solid #E1E4E7' }
});

export default function InboxPreviewPanel() {
    const { data, isLoading, hasMore, loadMore } = useInbox();
    const { contactCache } = useContactState();
    const { mailboxes } = usePubnub();
    const { publish } = useEventBus();
    const { submitEvent } = useIntercomApi();

    const scrollbarRef: any = useRef<Scrollbars>(null);

    const classes = useClasses();
    const dates = new Set<string>();

    const dedupeInboxPreviews = useCallback((inboxPreviews: InboxPreview[]) => {
        const dedupedChannels = inboxPreviews.reduce<InboxPreview[]>((acc, current) => {
            const existingPreview = acc.find((c) => c.patientPhoneNumber === current.patientPhoneNumber);

            if (existingPreview) {
                existingPreview.isUnread = existingPreview.isUnread || current.isUnread;
                existingPreview.isSaved = existingPreview.isSaved || current.isSaved;
                existingPreview.isArchived = existingPreview.isArchived || current.isArchived;
            } else {
                acc.push({ ...current });
            }

            return acc;
        }, []);

        return dedupedChannels;
    }, []);

    const loadNextPage = useCallback(() => {
        if (!isLoading && hasMore) {
            loadMore();
        }
    }, [loadMore, isLoading, hasMore]);

    const onScrollStop = () => {
        const scroll = scrollbarRef.current;
        const { scrollHeight, scrollTop, clientHeight } = scroll.getValues();
        if (scrollHeight < scrollTop + clientHeight + 5 && data.length > 0) {
            loadNextPage();
        }
    };

    const handlePreviewClick = (patientPhoneNumber: string, locationId: number | undefined) => {
        publish({
            messageType: 'inboxCommunications',
            data: {
                phoneNumber: patientPhoneNumber,
                locationId: locationId
            }
        });

        submitEvent('unified-inbox-conversation-opened')
    };

    const renderInbox = useCallback((item: InboxPreview) => {
        const mailbox = mailboxes.find(mb => mb.id === item.mailboxId);
        const contacts = contactCache[item.patientPhoneNumber];
        const finalContacts = (mailbox && contacts.data.length)
            ? importantContacts(contacts.data,undefined, mailbox.location.idNetwork) 
            : [];

        const contactInfo = finalContacts.length ? finalContacts[0] : null;

        const contact = contactInfo
            ? `${contactInfo.firstName} ${contactInfo.lastName}${finalContacts.length > 1 ? ' + Others' : ''}`
            : formatUSPhoneNumber(item.patientPhoneNumber);

        if (item.lastMessage) {
            return (
                <InboxPreviewCard
                    contact={contact}
                    message={item.lastMessage?.body}
                    timestamp={new Date(item.lastMessage?.date).toLocaleString()}
                    isNewPatient={item.isNewPatient}
                    isUnread={item.isUnread}
                    isSaved={item.isSaved}
                    isArchived={item.isArchived}
                    communicationId={item.lastMessage?.communicationId}
                    onPreviewClick={() => handlePreviewClick(item.patientPhoneNumber, mailbox?.locationId)}
                />
            );
        }

        if (item.lastCall) {
            return (
                <InboxPreviewCard
                    contact={contact}
                    direction={item.lastCall?.direction}
                    callType={item.lastCall?.callType}
                    isNewPatient={item.isNewPatient}
                    isUnread={item.isUnread}
                    isSaved={item.isSaved}
                    isArchived={item.isArchived}
                    timestamp={new Date(item.lastCall?.date).toLocaleString()}
                    onPreviewClick={() => handlePreviewClick(item.patientPhoneNumber, mailbox?.locationId)}
                />
            );
        }
        return null;
    }, [mailboxes, contactCache]);

    const renderDateDevider = useCallback((inboxTimetoken: string | null) => {
        if (inboxTimetoken && !dates.has(inboxTimetoken)) {
            dates.add(inboxTimetoken);
            return (
                <InboxSectionSeparator>
                    <OverlineText variant='overline'>{formatFromNow(inboxTimetoken)}</OverlineText>
                </InboxSectionSeparator>
            )
        }
        return null;
    }, [dates]);

    return (
        <Scrollbars
            autoHide ref={scrollbarRef}
            onScroll={onScrollStop}
            className={classes.scroll}
        >
            {dedupeInboxPreviews(data).map((item, index) => {
                const inboxTimetoken = item.lastMessage
                    ? new Date(item.lastMessage.date).toLocaleDateString()
                    : item.lastCall
                        ? new Date(item.lastCall.date).toLocaleDateString()
                        : null;
                return (
                    <React.Fragment key={index}>
                        {renderDateDevider(inboxTimetoken)}
                        {renderInbox(item)}
                    </React.Fragment>
                );
            })}
            {isLoading && <InboxPreviewCardLoading />}
            {!isLoading && !data.length && <InboxSearchEmpty />}
        </Scrollbars>
    );
}