import SlideOutCard from '../../../shared/slide-out-card';
import CommsFilterWidget from './comms-filter-widget';
import { useSlideout } from '../../../../hooks/use-slideout';
import { useCommsFilter } from '../../../../hooks/use-communications';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { useEffect } from 'react';

export default function CommunicationsFilterSlideout() {
    const { isVisibleCommunicationsFilter, back } = useSlideout();
    const { updateCommsFilter } = useCommsFilter();
    const { publish, listen } = useEventBus();

    // we need to listen if slideout closed from other page
    useEffect(() => {
        const listener = listen('closeWidget', event => {
            if (event.messageType === 'closeWidget') {
                updateCommsFilter({
                    commType: undefined,
                    mailboxes: undefined
                });
            }
        });

        return () => listener();
    }, [listen]);

    const handleClose = () => {
        publish({
            messageType: 'closeWidget'
        });
    };

    const handleBackClick = () => {
        back();
    };

    return (
        <SlideOutCard isVisible={isVisibleCommunicationsFilter} onClose={handleClose} onBackClick={handleBackClick}>
            <CommsFilterWidget onFilterApplied={handleBackClick} />
        </SlideOutCard>
    );
}

