import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { useWrapperNavigation } from '../../../../hooks/use-wrapper-navigation';
import { ContactApi } from '../../../../services/api-client/contact-api';
import { EventJumpToMessage, EventJumpToMessageDataPhoneNumber } from '../../../../types/event-bus';
import SlideOutCard from '../../../shared/slide-out-card';
import MessengerWidget from './messenger-widget';
import { useSlideout } from '../../../../hooks/use-slideout';

export default function MessengerSlideout() {
    const { publish, publishToIframes } = useEventBus();
    const { isVisibleMessenger, hasHistory, back, currentEvent } = useSlideout();
    const [patient, setPatient] = useState<EventJumpToMessageDataPhoneNumber>(null!);
    const { mailboxes } = usePubnub();
    const { windowUrls } = useWrapperNavigation();
    const ref = useRef<HTMLIFrameElement>(null!);
    const { currentMailboxes } = usePubnub();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const contactApi = useMemo(() => new ContactApi(config, oidcService), [config, oidcService]);
    const [isMultiLocation, setIsMultiLocation] = useState<boolean>(null!);
    const [navFromPatient, setNavFromPatient] = useState<boolean>(false!);

    useEffect(() => {
        setIsMultiLocation(currentMailboxes.length > 1);
    });

    useEffect(() => {
        const handleContactEvent = async (data: any) => {
            console.debug('MESSAGE EVENT:', data.patientId);

            if (data.patientId) {
                const contactDetails = await contactApi.getContactDetailsById(data.patientId);
                const phoneNumber = contactDetails?.cellPhone || contactDetails?.homePhone;

                if (phoneNumber) {
                    setNavFromPatient(true);
                    setPatient({
                        patientId: data.patientId,
                        locationId: data.locationId || contactDetails?.location?.id,
                        timestamp: data.timestamp,
                        phoneNumber
                    });
                }
            } else {
                setNavFromPatient(false);
                setPatient(data as EventJumpToMessageDataPhoneNumber);
            }
        };

        if (currentEvent && isVisibleMessenger) handleContactEvent((currentEvent as EventJumpToMessage).data);
    }, [currentEvent, isVisibleMessenger]);

    const handleClose = () => {
        publish({
            messageType: 'closeWidget'
        });
    };

    const url = useMemo(() => {
        if (isMultiLocation && !navFromPatient) {
            return `/messenger-frame/phone/${patient?.phoneNumber}`;
        } else {
            return `/messenger-frame/locations/${patient?.locationId}/phone/${patient?.phoneNumber}${
                patient?.timestamp ? `?date=${patient?.timestamp}` : ''
            }`;
        }
    }, [patient]);

    const handleBackClick = () => {
        back();
    };

    const handleFullscreen = () => {
        const pubnubPrefix = mailboxes.find(mb => String(mb.locationId) === String(patient.locationId))?.pubnubPrefix;
        if (pubnubPrefix) {
            const channel = `${pubnubPrefix}.${patient.phoneNumber}`;
            const url = `${windowUrls.messenger}/inbox/${channel}${
                patient.timestamp ? `?date=${patient.timestamp}` : ''
            }`;
            publish({
                messageType: 'navigate',
                data: {
                    pathname: url,
                    crossFrame: true,
                    replaceState: false
                }
            });
            window.setTimeout(() => {
                publishToIframes({
                    messageType: 'frame-activate',
                    data: {
                        frameName: 'messenger'
                    }
                });
            }, 500);
        }
        handleClose();
    };

    return (
        <SlideOutCard
            isVisible={isVisibleMessenger}
            onBackClick={hasHistory ? handleBackClick : undefined}
            onFullscreen={handleFullscreen}
            onClose={handleClose}
        >
            {!!patient && <MessengerWidget url={url} iframeRef={ref} />}
        </SlideOutCard>
    );
}
