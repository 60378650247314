import React from 'react';
import { Box, Typography, styled, Button } from '@mui/material';
import { theme } from '../material-theme';

export const DialogContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  color: 'black',
  fontSize: '16px',
  lineHeight: '1.3rem'
}));

export const DialogToolbar = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '40px',
  justifyContent: 'end',
  "& > button": {
    display: 'flex',
    marginLeft: '20px'
  }
});

export const TextFieldBox = styled(Box)({  
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});