import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Claims,
    useConfig,
    useOidc,
    useOidcWithAdmin,
    useProduct,
    useProgress,
    useDesktop,
    useUrl,
} from '@revenuewell/front-end-bundle';
import { PhoneInUseBanner, PhoneWidget, usePhoneWidget } from '@revenuewell/widget-phone';
import { useEffect } from 'react';
import { Route } from 'react-router';
import { useEventBus } from '../../../hooks/use-event-bus';
import { useHistory } from '../../../hooks/use-history-intercept';
import { EventFileDownloadOrPreview, EventStartCall } from '../../../types/event-bus';
import { VoiceApi } from '../../../services/api-client/voice-api';
import { ContactApi } from '../../../services/api-client/contact-api';
import { SlideoutProvider } from '../../../hooks/use-slideout';
import { useViewMode } from '../../../hooks/use-view-mode';
import WrapperBusProvider from '../../../hooks/use-wrapper-bus/provider';
import { WrapperFramesProvider, useFrames } from '../../../hooks/use-wrapper-frames';
import { WrapperNavigationProvider } from '../../../hooks/use-wrapper-navigation';
import Menu from '../../pages/menu/menu';
import { VirtualVisitInvite } from '../../shared/virtual-visit-invite/virtual-visit-invite';
import SlideOut from '../slideout/slideout';
import Frames from './frames';
import { NotificationEventListener } from './notification-event-listenter';
import { TopNav } from './top-nav';
import { SmsRegistartion } from '../../shared/sms-registration/sms-registration';
import { LwpPhoneWidget, useLwpActions } from '@revenuewell/widget-legacy-phone';
import { useNotifications } from '@revenuewell/uc-notifications-client';
import { MfaDialog } from '../../shared/mfa-dialog/mfa-dialog';

const useStyles = () => {
    const { heightMode } = useViewMode();
    const isShort = heightMode === 'Short';
    const isMedium = heightMode === 'Medium';

    const useStyles = makeStyles(() => ({
        container: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            flex: 1,
            justifyContent: 'space-around',
            alignItems: 'stretch'
        },

        columnLeft: {
            flexBasis: '80px',
            flexGrow: 0,
            flexShrink: 0,
            backgroundColor2: '#004763',
            ...(isShort && {
                flexBasis: '50px'
            }),
            ...(isMedium && {
                flexBasis: '60px'
            })
        },
        columnCenter: {
            flexBasis: 'auto',
            width: '100%',
            position: 'relative'
        }
    }));

    return useStyles();
};

function Main() {
    const classes = useStyles();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { phoneActions } = usePhoneWidget();
    const { call: lwpCall } = useLwpActions();
    const { listen, publish } = useEventBus();
    const { listen: listenNotification } = useNotifications();
    const { showAppSpinner, hideAppSpinner } = useProgress();
    const { allFramesLoaded } = useFrames();
    const { hasProduct } = useProduct();
    const { isDesktopApplication, downloadFile } = useDesktop();
    const { openUrl } = useUrl({ web: 'NewTab', desktop: 'DefaultBrowser' });

    useEffect(() => {
        showAppSpinner();
    }, []);

    if (config && oidcService) {
        VoiceApi.initialize(config, oidcService);
        ContactApi.initialize(config, oidcService);
    }

    useEffect(() => {
        if (allFramesLoaded) hideAppSpinner();
    }, [allFramesLoaded]);

    useEffect(() => {
        return listen('startCall', event => {
            //console.log('LWP START CALL:', event);
            const { data } = event as EventStartCall;
            if (hasProduct('phone2')) {
                phoneActions.call(data.phoneNumber);
            } else if (hasProduct('phone') || hasProduct('phone1')) {
                lwpCall(data.phoneNumber);
            } else {
                console.error('MUST HAVE PHONE1 or PHONE2 to call');
            }
        });
    }, [listen, phoneActions.call, hasProduct, lwpCall]);

    useEffect(() => {
        return listen('fileDownloadOrPreview', event => {
            const { data } = event as EventFileDownloadOrPreview;

            if (isDesktopApplication()) {
                downloadFile(data.url);
            } else {
                openUrl(data.url)
            }
        });
    }, []);

    useEffect(() => {
        return listenNotification('m2-message', (event: any) => {
            if(!event.locationid && !event.locationId) return;
      
            const data = event.data as MessageEvent['data'];
      
            if (data?.channel?.includes('insurance-mfa')) {
              publish({
                messageType: 'mfaCodesWidget',
                data: {
                  locationId: event.locationid || event.locationId
                }
              });
            }
          })
    }, [listenNotification]);

    useEffect(() => {
        return listenNotification('insurance-verification-update', (event: any) => {
            if(!event.locationid && !event.locationId) return;
            console.log("**** - INSURANCE VERIFICATION UPDATE EVENT:", event);
            const data = event.data as MessageEvent['data'];
      
            if (data?.eventType == 'datasource_waiting_2fa') {
              publish({
                messageType: 'mfaCodeDialog',
                data: {
                  locationId: event.locationid || event.locationId,
                  entityId: data?.entityId 
                }
              });
            }
          })
    }, [listenNotification]);

    const { viewMode } = useViewMode();
    const isSmall = viewMode === 'Phone';

    const handlePatientProfileClick = (phoneNumber: string, locationId: number) => {
        publish({
            messageType: 'patientProfile',
            data: { phoneNumber, locationId }
        });
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <TopNav />
            <PhoneInUseBanner />
            <div className={classes.container}>
                {!isSmall && (
                    <div className={classes.columnLeft}>
                        <Menu />
                    </div>
                )}
                <div className={classes.columnCenter}>
                    {hasProduct('phone') || hasProduct('phone1') ? (
                        <LwpPhoneWidget
                            events={{
                                onPatientProfileClick: handlePatientProfileClick
                            }} />
                    ) : (
                        <PhoneWidget
                            events={{
                                onPatientProfileClick: handlePatientProfileClick
                            }}
                        />
                    )}
                    <Frames />
                </div>
                <SlideOut />
            </div>
            <SmsRegistartion />
            <MfaDialog/>
        </Box>
    );
}

export default () => {
    const history = useHistory();
    const { hasProduct } = useProduct();
    const isRootPath = history.location.pathname === '/';

    const { getClaims } = useOidcWithAdmin();

    useEffect(() => {
        getClaims().then((res: Claims) => {
            if (history.location.pathname === '/') {
                const corePath = res.userClass === 'GlobalUser' ? '/core/Auth/SignInForOthers' : '/core';
                console.log(corePath);
                if (hasProduct('core') || hasProduct('reminders')) history.push(corePath);
                else if (hasProduct('/messenger2')) history.push('/messenger');
                else history.push('/messenger1');
            }
        });
    }, [history, hasProduct]);

    return (
        <>
            {!isRootPath && (
                <>
                    <Route path='/:frameName'>
                        <WrapperNavigationProvider>
                            <SlideoutProvider>
                                <WrapperBusProvider>
                                    <WrapperFramesProvider>
                                        <NotificationEventListener>
                                            <Main />
                                        </NotificationEventListener>
                                    </WrapperFramesProvider>
                                </WrapperBusProvider>
                            </SlideoutProvider>
                        </WrapperNavigationProvider>
                    </Route>
                    <VirtualVisitInvite />
                </>
            )}
        </>
    );
};
