import { Box, Button, Typography, styled } from '@mui/material';

/* ------------------ top of the page ----------------*/
export const PatientHeader = styled(Box)({
    top: 56,
    padding: '0px 16px 8px 16px',
    gap: 8
});

export const CommsHeader = styled(Box)({
    display: 'flex',
    padding: '8px 0',
    alignItems: 'center',
    marginLeft: 'auto'
});

export const CommsTitle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: 4
});

/* ------------------ bottom of the page ----------------*/
export const CommsPreviewFooter = styled(Box)({
    padding: '8px 16px',
    borderTop: '1px solid #E1E4E7',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 'auto'
});

export const CommsFooterButton = styled(Button)({
    width: 132,
    height: 36,
    fontSize: 14,
    lineHeight: 24,
    letterSpacing: 0.4,
    textTransform: 'none',
    color: '#0675DD'
});

/* ------------------ section separator ----------------*/
export const CommsSectionSeparator = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0'
})

export const CommsOverlineText = styled(Typography)({
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '15px',
    color: '#2C3542',
    letterSpacing: 1
})

