import { Close as CloseIcon, Phone as PhoneIcon, Forum as SmsIcon } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FaxIcon from '@mui/icons-material/PrintRounded';
import { StyledEngineProvider } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useMemo, useState } from 'react';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { useFax } from "../../../../hooks/use-fax/use-fax";
import { useInboxFilter } from '../../../../hooks/use-inbox';
import { useKazoo } from "../../../../hooks/use-kazoo/use-kazoo";
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import InboxService from '../../../../services/inbox-service/inbox-service';
import { IMailboxWithLocation } from '../../../../services/mailbox-service/mailbox-service';
import { sendFaxTheme } from "../../../pages/fax/fax-theme";
import { SendFaxDialog } from "../../../pages/fax/send-fax-dialog";
import { FilterChip, FilterChipsContainer, FilterTooltip } from '../filter-widget/styles/inbox-filter-styles';
import InboxPreviewPanel from './inbox-preview-panel';
import InboxSearchBar from './inbox-search-bar';
import {
    InboxContainer,
    InboxFooterButton,
    InboxHeader,
    InboxIconButton,
    InboxPreviewFooter,
    InboxTitle
} from './styles/inbox-styles';
export const ColumnContainer = styled(Box)({
    height: '100vh',
    overflowY: 'hidden',
});
export default function InboxWidget() {
    const { oidcService } = useOidcWithAdmin();
    const { config } = useConfig();
    const { previewFilter, updatePreviewFilter } = useInboxFilter();
    const { publish } = useEventBus();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchBarExpanded, setSearchBarExpanded] = useState(!!previewFilter.searchText?.length);
    const inboxService = useMemo(() => InboxService.getInstance(config, oidcService), [config, oidcService]);
    const { mailboxes } = usePubnub();
    
    const { userCredentials } = useKazoo();
    const [openSendFaxDialog, setOpenSendFaxDialog] = useState(false);
    const { faxStatus, getFaxboxes } = useFax();

    useEffect(() => {
        if (userCredentials) {
            getFaxboxes();
        }
    }, [userCredentials]);

    const onClickSearch = () => {
        const isExpanded = !searchBarExpanded;
        setSearchBarExpanded(isExpanded);
        handleClose();

        if (!isExpanded) updatePreviewFilter({ searchText: undefined });
    };

    const onClickFilter = () => {
        handleClose();
        publish({ messageType: 'inboxPreviewFilter' });
    };

    const onClickMarkAllRead = async () => {
        handleClose();

        if (previewFilter.mailboxes && previewFilter.mailboxes.length > 0) {
            await Promise.all(
                previewFilter.mailboxes.map(mailbox => {
                    inboxService.markAllChannelAsRead(mailbox.id);
                })
            );
        } else {
            await Promise.all(
                mailboxes.map(mailbox => {
                    inboxService.markAllChannelAsRead(mailbox.id);
                })
            );
        }       
    };

    const handleDeleteCommType = () => {
        updatePreviewFilter({ commType: undefined });
    };

    const handleDeleteStatus = (itemToDelete: string) => () => {
        const newStatuses = previewFilter.commStatuses?.filter(status => status !== itemToDelete);
        updatePreviewFilter({ commStatuses: newStatuses });
    };

    const handleDeleteLocation = (itemToDelete: IMailboxWithLocation) => () => {
        const newMailboxes = previewFilter.mailboxes?.filter(mailbox => mailbox !== itemToDelete);
        updatePreviewFilter({ mailboxes: newMailboxes });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickFaxDialogClose = () => {
        setOpenSendFaxDialog(false);
    };

    const handleClickFaxDialogOpen = () => {
        setOpenSendFaxDialog(true);
    };
    
    const faxConfigured = faxStatus === 'configured';

    return (
        <InboxContainer>
            <InboxHeader>
                <InboxTitle variant='h3'> Inbox </InboxTitle>
                <InboxIconButton
                    aria-controls='inbox-options-menu'
                    aria-haspopup='true'
                    data-testid='inbox-options-menu-button'
                    style={{ background: 'transparent' }}
                    onClick={handleClick}
                >
                    <MoreVertIcon style={{ color: '#8993A4' }} />
                </InboxIconButton>
                <Menu
                    id='inbox-options-menu'
                    data-testid='inbox-options-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                >
                    <MenuItem onClick={onClickFilter}>Filter</MenuItem>
                    <MenuItem onClick={onClickSearch}>Search</MenuItem>
                    <MenuItem onClick={onClickMarkAllRead}>Mark All Read</MenuItem>
                </Menu>
            </InboxHeader>
            {searchBarExpanded && <InboxSearchBar />}
            {previewFilter.commStatuses?.length || previewFilter.commType || previewFilter.mailboxes?.length ? (
                <FilterChipsContainer>
                    {previewFilter.commType && (
                        <FilterChip
                            label={previewFilter.commType}
                            variant='outlined'
                            deleteIcon={<CloseIcon />}
                            onDelete={handleDeleteCommType}
                        />
                    )}
                    {previewFilter.commStatuses?.map((item, index) => {
                        return (
                            <FilterChip
                                key={index}
                                label={item}
                                variant='outlined'
                                deleteIcon={<CloseIcon />}
                                onDelete={handleDeleteStatus(item)}
                            />
                        );
                    })}
                    {previewFilter.mailboxes?.map((item, index) => {
                        return (
                            <FilterTooltip title={item.location.name} placement='top'>
                                <FilterChip
                                    key={index}
                                    label={item.location.name}
                                    variant='outlined'
                                    deleteIcon={<CloseIcon />}
                                    onDelete={handleDeleteLocation(item)}
                                />
                            </FilterTooltip>
                        );
                    })}
                </FilterChipsContainer>
            ) : null}
            <InboxPreviewPanel />
            <InboxPreviewFooter>
                <InboxFooterButton
                    variant='contained'
                    color='primary'
                    size='medium'
                    startIcon={<PhoneIcon />}
                    withFaxButton={faxConfigured}
                    onClick={() => {
                        publish({
                            messageType: 'dialpadWidget'
                        });
                    }}
                >
                    Start Call
                </InboxFooterButton>
                <InboxFooterButton
                    variant='contained'
                    color='primary'
                    size='medium'
                    startIcon={<SmsIcon />}
                    withFaxButton={faxConfigured}
                    onClick={() => {
                        publish({
                            messageType: 'startSmsWidget'
                        });
                    }}
                >
                    Start SMS
                </InboxFooterButton>
                { faxConfigured && (
                    <>
                        <InboxFooterButton
                            data-testid={`send-fax-button`}
                            variant='contained'
                            color='primary'
                            size='medium'
                            startIcon={<FaxIcon/>}
                            withFaxButton={faxConfigured}
                            onClick={handleClickFaxDialogOpen}>
                            Fax
                        </InboxFooterButton>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={sendFaxTheme}>
                                <SendFaxDialog open={openSendFaxDialog} onClose={handleClickFaxDialogClose}/>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </>
                )}
            </InboxPreviewFooter>
        </InboxContainer>
    );
}
