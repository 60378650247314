import { useEffect, useRef } from 'react';
import { useHistory, useUrlBuilder } from '../../../hooks/use-history-intercept';
import { useProduct } from '@revenuewell/front-end-bundle';
import { FrameComponent } from './frame';
import { useEventBus } from '../../../hooks/use-event-bus';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';

enum MessageForwardType {
    JUMP_TO_MESSAGE = 'jump_to_messages'
}

type MessageForward = {
    data: {
        message_type: MessageForwardType;
        data: any;
    };
};

export default function Frames() {
    const refMessenger = useRef<HTMLIFrameElement>(null);
    const refPhone = useRef<HTMLIFrameElement>(null);
    const refCore = useRef<HTMLIFrameElement>(null);

    const history = useHistory();
    const { hasProduct } = useProduct();
    const { listen } = useEventBus();
    const { getFrameUrl, getFrameName } = useWrapperNavigation();
    const { buildUrl } = useUrlBuilder()


    useEffect(() => {
        const handleLocationChange = (event: MessageForward) => {
            if (Object.values(MessageForwardType).includes(event.data.message_type)) {
                refMessenger.current?.contentWindow?.postMessage(event.data, '*')
                refPhone.current?.contentWindow?.postMessage(event.data, '*')
            }
        }

        window.addEventListener('message', handleLocationChange)

        return () => window.removeEventListener('message', handleLocationChange);
    }, [history])

    useEffect(() => {
        return listen('navigate', event => {
            if (event.messageType !== 'navigate') return
            const eventData = event.data
            const frame = getFrameName(eventData.pathname)

            if (frame === 'core' && eventData.crossFrame && refCore.current) {
                const url = buildUrl(getFrameUrl(frame, eventData.pathname))
                refCore.current.src = url!
            }
        })
    }, [listen])

    return (
        <>
            <FrameComponent frameName='phone' ref={refPhone} render={hasProduct('phone2')} />
            <FrameComponent frameName='messenger' ref={refMessenger} render={hasProduct('messenger2')} />
            <FrameComponent frameName='core' ref={refCore} render={hasProduct('core') || hasProduct('reminders') || hasProduct('forms') || hasProduct('campaign') || hasProduct('forms2')} />
            <FrameComponent frameName='contacts' render={hasProduct('patients2')} />
            <FrameComponent frameName='contacts1' render={hasProduct('patients1')} />
            <FrameComponent frameName='scheduler' render={hasProduct('scheduler')} autoActivate={true} />
            <FrameComponent frameName='messenger1' render={ hasProduct('messenger')} />
            <FrameComponent frameName='phone1' render={hasProduct('phone') || hasProduct('phone1')} autoActivate={true}/>
            <FrameComponent frameName='insights' render={true} />
            <FrameComponent frameName='settings' render={true} />
            <FrameComponent frameName='reputation' render={hasProduct('reputationmanagement')} />
            <FrameComponent frameName='campaigns' render={hasProduct('campaign')} />
            <FrameComponent frameName='forms' render={hasProduct('forms2')} />
            <FrameComponent frameName='virtualvisits' render={hasProduct('virtualvisits')} />
            <FrameComponent frameName='evaluatemypractice' render={hasProduct('evaluatemypractice')} />
            <FrameComponent frameName='teamchat' render={hasProduct('teamchat')} />
        </>
    );
}
