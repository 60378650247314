import React, { createContext, useContext, useEffect, useState } from 'react';
import { w3cwebsocket } from 'websocket';
import { UserInfoStatus } from './types';
import { useConfig, useOidc } from '@revenuewell/front-end-bundle';
import { KazooUser, KazooUserCredentials, VoiceApi } from '../../services/api-client/voice-api';
import { KazooUserApi, KazooUserRaw } from '../../services/api-client/kazoo-user-api';

export interface IKazooContext {
    userCredentials: KazooUserCredentials | null;
    userInfo: KazooUser | null;
    userInfoStatus: UserInfoStatus;
    kazooUsers: KazooUserRaw[];
    kazooClient: w3cwebsocket | null;

    kazooInit: () => Promise<void>;
    isStarted: () => boolean;
    connect: () => Promise<void>;
    deinitialize: () => Promise<void>;
    refreshKazooUserCredentials: () => Promise<void>;
    kazooFetchUsers: () => Promise<void>;
}

export const KazooContext = createContext<IKazooContext>(null!);

export function KazooProvider(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();
    const { oidcService } = useOidc();
    const [userCredentials, setUserCredentials] = useState<KazooUserCredentials | null>(null);
    const [userInfo, setUserInfo] = useState<KazooUser | null>(null);
    const [userInfoStatus, setUserInfoSatus] = useState<UserInfoStatus>('default');
    const [kazooUsers, setKazooUsers] = useState<KazooUserRaw[]>([]);
    const [kazooClient, setKazooClient] = useState<w3cwebsocket | null>(null);
    const voiceApi = new VoiceApi(config, oidcService);
    const kazooUserApi = new KazooUserApi();
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            await kazooInit();
            setReady(true);
        })();
    }, []);

    const kazooInit = async () => {
        try {
            setUserInfoSatus('pending');

            const [kazooUser, credentials] = await Promise.all([
                voiceApi.getUserInfo(),
                voiceApi.getKazooUserCredentials()
            ]);

            setUserInfo(kazooUser);
            setUserCredentials(credentials);
            setUserInfoSatus('success');
        } catch (error) {
            setUserInfoSatus('error');
        }
    };

    const deinitialize = async () => {
        if (kazooClient) {
            try {
                const client = kazooClient;
                client.close();
                setKazooClient(null);
            } catch (ex) {
                console.log('ex : ', ex);
            }
        }
    };

    const refreshKazooUserCredentials = async () => {
        const credentials = await voiceApi.getKazooUserCredentials();
        setUserCredentials(credentials);
    };

    const kazooFetchUsers = async () => {
        const credentials = await voiceApi.getKazooUserCredentials();
        const users = await kazooUserApi.fetchUsers(
            credentials.kazooApiUrl,
            credentials.kazooAccountId,
            credentials.kazooUserToken
        );

        setKazooUsers(users);
    };

    const connect = async () => {
        const kazooUserCredentials = userCredentials;

        if (kazooUserCredentials) {
            const client = new w3cwebsocket(`${kazooUserCredentials.kazooApiWssUrl}/v2/websockets`);

            setKazooClient(client);

            const reconnect = async () => {
                if (kazooClient) {
                    deinitialize();
                    connect();
                }
            };

            client.onclose = (e: any) => {
                if (e.code === 1006) reconnect();
            };
        }
    };

    return (
        <KazooContext.Provider
            value={{
                userCredentials,
                userInfo,
                userInfoStatus,
                kazooUsers,
                kazooClient,

                kazooInit,
                isStarted: () => !!kazooClient,
                connect,
                deinitialize,
                refreshKazooUserCredentials,
                kazooFetchUsers
            }}
        >
            {ready && props.children}
        </KazooContext.Provider>
    );
}

export function useKazoo() {
    const context = useContext(KazooContext);

    if (!context) throw new Error('useKazoo must be used within the KazooProvider');

    return context;
}
