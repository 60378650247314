import React, { FC } from 'react';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { HeaderWrapper } from './header.styles';

export const PatientProfileHeaderSkeleton: FC = () => {
  return <HeaderWrapper>
    <Skeleton variant="rounded" width={280} height={74} sx={{ margin: 'auto' }} />
    <Box sx={{ margin: '16px 0', display: 'flex', justifyContent: 'center' }}>
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton key={index} variant="circular" width={40} height={40} sx={{ margin: '0 6px' }} />
      ))}
    </Box>
  </HeaderWrapper>
}