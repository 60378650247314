import EventEmitter from 'eventemitter3';
import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { EventBus } from '../../types/event-bus';

type removeEvent = () => void;

interface IEventBusContext {
    publish: (type: EventBus) => void;
    publishToIframes: (type: EventBus) => void;
    listen: (type: EventBus['messageType'], callBack: (cb: EventBus) => void) => removeEvent;
    unlisten: (type: EventBus['messageType'], callBack: (cb: EventBus) => void) => void;
}

const EventBusContext = createContext<IEventBusContext | null>(null);

export const useEventBus = (): IEventBusContext => {
    const context = useContext(EventBusContext);
    if (!context) {
        throw new Error('useEventBus must be used within an EventBusContext');
    }

    return context;
};

export function EventBusProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const eventEmitter = useMemo(() => new EventEmitter(), []);

    const listen = useCallback(
        (type: EventBus['messageType'], callBack: (cb: EventBus) => void) => {
            eventEmitter.on(type, callBack);

            return () => eventEmitter.off(type, callBack);
        },
        [eventEmitter]
    );

    const unlisten = useCallback(
        (type: EventBus['messageType'], callBack: (cb: EventBus) => void) => {
            eventEmitter.off(type, callBack);
        },
        [eventEmitter]
    );

    const publish = useCallback(
        (data: EventBus) => {
            eventEmitter.emit(data.messageType, data);
        },
        [eventEmitter]
    );

    const publishToIframes = useCallback((event: EventBus) => {
        const iframes = document.querySelectorAll('iframe');

        iframes.forEach(frame => {
            frame.contentWindow?.postMessage(event, '*');
        });
    }, []);

    useEffect(() => {
        const handlePostMessage = (event: MessageEvent<EventBus>) => {
            eventEmitter.emit(event.data.messageType, event.data);
        };

        window.addEventListener('message', handlePostMessage);

        return () => window.removeEventListener('message', handlePostMessage);
    }, [eventEmitter]);

    return (
        <EventBusContext.Provider value={{ listen, unlisten, publish, publishToIframes }}>
            {children}
        </EventBusContext.Provider>
    );
}
