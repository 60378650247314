import SlideOutCard from '../../../shared/slide-out-card';
import { useSlideout } from '../../../../hooks/use-slideout';
import InboxWidget from './inbox-widget';
import { useEventBus } from '../../../../hooks/use-event-bus';

export default function InboxPreviewSlideout() {
    const { isVisibleInboxPreview, hasHistory, back } = useSlideout();
    const { publish } = useEventBus();

    const handleClose = () => {
        publish({
            messageType: 'closeWidget'
        });
    };

    return (
        <SlideOutCard isVisible={isVisibleInboxPreview} onClose={handleClose} onBackClick={hasHistory ? back : undefined}>
            <InboxWidget />
        </SlideOutCard>
    );
}

