import React, { createContext, PropsWithChildren, useContext } from 'react';

type ProviderField<Name extends string, P extends {} = {}> = {
    [U in string as `${Capitalize<Name>}Provider`]: (props: PropsWithChildren<P>) => JSX.Element;
};

type HookField<Name extends string, ContextType> = {
    [U in string as `use${Capitalize<Name>}`]: () => ContextType;
};

type ContextField<Name extends string, ContextType> = {
    [U in string as `${Capitalize<Name>}Context`]: React.Context<ContextType>;
};

type Provider<Name extends string, ContextType, P extends {} = {}> = ProviderField<Name, P> &
    HookField<Name, ContextType> &
    ContextField<Name, ContextType>;

export function makeProvider<P extends {}, ContextType, Name extends string>(
    name: Name,
    fn: (props: P) => ContextType
): Provider<Name, ContextType, P> {
    if (!name) throw Error('Name should be specified.');
    const capName = name[0].toUpperCase() + name.slice(1);
    const hookName = `use${capName}`;
    const providerName = `${capName}Provider`;

    const Context = createContext<ContextType>(null!);
    const result = {
        [providerName]: function provider(props: PropsWithChildren<P>) {
            const contextValue = fn(props);
            return <Context.Provider value={{ ...contextValue }}>{props.children}</Context.Provider>;
        },
        [hookName]: () => {
            const context = useContext(Context);
            if (!context) {
                throw new Error(`${hookName} must be used within the ${providerName}`);
            }
            return context;
        },
        [`${capName}Context`]: Context
    };

    return result;
}
