import _ from 'lodash';
import { Contact, ContactSearchByNumber } from '../services/api-client/contact-api';
import { IContactProfile } from '../services/api-client/interface';

export function getUniqueNumbers(contact?: Contact) {
    const phoneNumbers = [contact?.cellPhone, contact?.homePhone, contact?.workPhone];
    const uniqNumbers = [...new Set(phoneNumbers.filter(n => !!n))];
    return uniqNumbers as string[];
}

export function getSingleNumber(contact?: Contact): string | null {
    const uniqNumbers = getUniqueNumbers(contact);
    return uniqNumbers.length === 1 ? uniqNumbers[0] : null;
}

export function getMainContact(contacts: ContactSearchByNumber[]) {
    const sorted = _(contacts).sortBy(
        x => (x.isResponsibleParty ? 0 : 1),
        x => x.lastName,
        x => x.firstName
    );
    return sorted.first();
}

export function getFullContactNamesList(contacts?: ContactSearchByNumber[]) {
    return contacts?.map(c => c.firstName + ' ' + c.lastName).join(', ') || '';
}

export function dedupeContacts(contacts: IContactProfile[]) {
    const removeDuplicate = (contactArr: IContactProfile[], uniqueContacts: IContactProfile[]): IContactProfile[] => {
        if (!contactArr.length) return uniqueContacts;

        const duplicateIndices: number[] = [];
        const contact = contactArr[0];
        const uniqueContact = { ...contact };
        contactArr.forEach((comparator, i) => {
            const hasSameBio =
                comparator.firstName === contact.firstName &&
                comparator.lastName === contact.lastName &&
                comparator.dateOfBirth === contact.dateOfBirth;

            if (!hasSameBio) return;

            if (contact.cellPhone && comparator.cellPhone && contact.cellPhone !== comparator.cellPhone) return;

            if (!contact.cellPhone && !comparator.cellPhone && contact.homePhone !== comparator.homePhone) return;

            // Duplicate contact found:
            duplicateIndices.push(i);
            if (!uniqueContact.cellPhone && comparator.cellPhone) {
                uniqueContact.cellPhone = comparator.cellPhone;
            }

            if (!uniqueContact.homePhone && comparator.homePhone) {
                uniqueContact.homePhone = comparator.homePhone;
            }
        });
        uniqueContacts.push(uniqueContact);
        duplicateIndices.reverse().forEach(i => contactArr.splice(i, 1));
        return removeDuplicate(contactArr, uniqueContacts);
    };

    return removeDuplicate([...contacts], []);
}

export function dedupeContactsByPhone(contacts: ContactSearchByNumber[], networkId?: number) {
    const finalContacts: ContactSearchByNumber[] = [];
    contacts.forEach(c => {
        const lc = finalContacts.length ? finalContacts[finalContacts.length - 1] : undefined;
        if (!lc || lc.lastName != c.lastName || lc.firstName != c.firstName || lc.dateOfBirth != c.dateOfBirth || ((!networkId || c.networkId == networkId) && lc.networkId != c.networkId))
            finalContacts.push(c);
    });

    return finalContacts;
};

export function sortContactsByPhone(contacts: ContactSearchByNumber[], patientId?: number, networkId?: number) {
    return contacts.sort((c1, c2) => {
        if(networkId && c1.networkId == networkId && c2.networkId != networkId){
            return 1;
        } if(networkId && c2.networkId == networkId && c1.networkId != networkId){
            return -1;
        } else if (c1.isResponsibleParty && !c2.isResponsibleParty) {
            return 1;
        } else if (c2.isResponsibleParty && !c1.isResponsibleParty) {
            return -1;
        } else if (patientId && c1.id == patientId && c2.id != patientId) {
            return 1;
        } else if (patientId && c2.id == patientId && c1.id != patientId) {
            return -1;
        } else if (c1.dateOfBirth && c2.dateOfBirth && new Date(c1.dateOfBirth) < new Date(c2.dateOfBirth)) {
            return 1;
        } else if (c1.dateOfBirth && c2.dateOfBirth && new Date(c1.dateOfBirth) > new Date(c2.dateOfBirth)) {
            return -1;
        } else if (c1.firstName > c2.firstName) {
            return 1;
        } else if (c1.firstName < c2.firstName) {
            return -1;
        } else if (c1.lastName > c2.lastName) {
            return 1;
        } else if (c1.lastName < c2.lastName) {
            return -1;
        }
        return 0;
    }).reverse();
};

//This function is used by inbox and patient profile inbox to determine what contacts should be shown and used for phoneNumbers
export function importantContacts(contacts: ContactSearchByNumber[], patientId?: number, networkId?: number) {
    let sortedContacts = dedupeContactsByPhone(sortContactsByPhone(contacts, patientId, networkId), networkId);  

    if(!sortedContacts.length)
        sortedContacts = dedupeContactsByPhone(sortContactsByPhone(contacts, patientId));

    let finalContacts = sortedContacts.filter(contact => (contact.isResponsibleParty && contact.networkId == networkId) || contact.id == patientId) 
    
    const patient = finalContacts.filter(contact => contact.id == patientId).pop();

    if(patient?.responsiblePartyId) { //limit to the patients family
        finalContacts = finalContacts.filter(contact => contact.id == patient.responsiblePartyId || contact.id == patientId);
    }

    finalContacts = finalContacts?.length ? finalContacts : sortedContacts.filter(contact => contact.isResponsibleParty || contact.id == patientId) 
    finalContacts = finalContacts?.length ? finalContacts : sortedContacts;

    return finalContacts;
}