import { useCallback, useEffect, useState, FormEvent } from 'react'
import { Box, TextField, InputAdornment } from '@mui/material'
import { Search as SearchIcon, CloseSharp } from '@mui/icons-material'
import { useInboxFilter } from '../../../../hooks/use-inbox';
import debounce from 'lodash.debounce';

const InboxSearchBar = () => {
    const { previewFilter, updatePreviewFilter } = useInboxFilter();
    const [searchText, setSearchText] = useState<string>(previewFilter.searchText ? previewFilter.searchText : '');

    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        updatePreviewFilter({ searchText });
    }, [searchText, updatePreviewFilter]);

    const handleSearchText = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    useEffect(() => {
        const debouncedSearch = debounce(() => {
            updatePreviewFilter({ searchText });
        }, 500);

        debouncedSearch();

        return () => {
            debouncedSearch.cancel();
        };
    }, [searchText]);

    return (
        <Box sx={{ padding: '12px 16px', paddingTop: 0 }}>
            <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    size='small'
                    label='Search'
                    variant='outlined'
                    value={searchText}
                    onChange={handleSearchText}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon sx={{ color: '#616C79' }} />
                            </InputAdornment>
                        ),
                        endAdornment: searchText && (
                            <InputAdornment position='end'>
                                <CloseSharp onClick={() => setSearchText('')} />
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
        </Box>
    );
}

export default InboxSearchBar;
