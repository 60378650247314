import { Box, Fade } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import SearchBar from '../../../shared/search-bar/search-bar';
import SearchResults from '../../../shared/search-results/search-results';

import { makeStyles } from '@mui/styles';
import { useViewMode } from '../../../../hooks/use-view-mode';

export const useStyles = () => {
    const { viewMode } = useViewMode();
    const isPhone = viewMode === 'Phone';

    const useStyles = useMemo(() => makeStyles({
        container: {
            position: 'absolute',
            top: 0,
            left: 78,
            right: 0,
            height: 70,
            padding: '16px 24px 16px 5px',
            backgroundColor: '#05374A',
            zIndex: 1000,
            boxSizing: 'border-box',
            ...(isPhone && {
                left: 0,
                height: 56,
                padding: '8px 24px'
            })
        }
    }), [isPhone]);

    return useStyles();
};

interface SearchTopNavProps {
    visible: boolean;
    onClose: () => void;
}

export default function SearchTopNav({ visible, onClose }: SearchTopNavProps) {
    const [query, setQuery] = useState('');
    const searchRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const showResults = useMemo(() => !!query.length, [query]);

    return (
        <Fade in={visible}>
            <Box className={classes.container}>
                <Box display='flex' style={{ height: 40 }}>
                    <SearchBar onBack={onClose} onChange={setQuery} value={query} ref={searchRef} />
                    <SearchResults
                        query={query}
                        open={showResults}
                        anchorEl={searchRef.current}
                        onClose={() => {
                            setQuery('');
                        }}
                    />
                </Box>
            </Box>
        </Fade>
    );
}
