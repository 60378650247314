import { Box, IconButton, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import searchAvatar from '../../../assets/searchavatar.svg';
import PhoneNumber from '../phone-number/phone-number';
import { useMemo, useState } from 'react';
import { formatToLocale } from '../../../utils/date-time';
import ForumIcon from '@mui/icons-material/Forum';
import CallIcon from '@mui/icons-material/Call';

const useStyles = makeStyles({
    card: {
        borderBottom: '1px solid #BDC3CA',
        padding: '24px',
        pointerEvents: 'all',
        cursor: 'pointer',
        '&:hover': {
            background: '#EDEFF2'
        },
        '&:last-of-type': {
            border: 'none'
        }
    },
    primaryText: {
        width: '74%',
        fontSize: '16px',
        fontWeight: 500
    },
    invalidPhone: {
        color: '#E25650'
    },
    secondaryText: {
        fontSize: '14px',
        color: '#2C3542',
        fontWeight: 400
    },
    actionButton: {
        width: 35,
        height: 35,
        backgroundColor: '#EBF3FA',
        color: '#0259AB',
        '&:hover': {
            backgroundColor: '#d6e7f5',
            transition: '0.2s'
        }
    },
    actionIcons: {
        fontSize: 20
    }
});

interface Props {
    patient: {
        id: number;
        name: string;
        phone?: string | null;
        birthDate?: string | null;
        locationId: number;
    };
    focused?: boolean;
    onPatientClick?: (patientId: number, locationId: number) => void;
    onPhoneClick?: (phone: string) => void;
    onMessageClick?: (patientId: number, phone: string) => void;
    disabledMessenger?: boolean;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
    tabIndex?: number;
}

export function SearchCardLoading() {
    const classes = useStyles();

    return (
        <ListItem
            component='div'
            className={classes.card}
            secondaryAction={
                <Box display='flex' flexDirection='row' gap={'25px'} marginRight={'24px'}>
                    <Skeleton animation='wave' variant='circular' width={32} height={32} />
                    <Skeleton animation='wave' variant='circular' width={32} height={32} />
                </Box>
            }
        >
            <ListItemAvatar>
                <Skeleton animation='wave' variant='circular' width={50} height={50} />
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton animation='wave' height={20} width='35%' />}
                secondary={
                    <Typography className={classes.secondaryText} variant='body2'>
                        <Skeleton animation='wave' height={15} width='60%' />
                    </Typography>
                }
            />
        </ListItem>
    );
}

export default function SearchCard({
    patient,
    focused,
    onPatientClick,
    onPhoneClick,
    onMessageClick,
    onKeyDown,
    disabledMessenger,
    tabIndex
}: Props) {
    const classes = useStyles();
    const [patientHovered, setPatientHovered] = useState(false);

    const renderPhone = (phone?: string | null) => {
        if (phone) return <PhoneNumber>{phone}</PhoneNumber>;
        else return <span className={classes.invalidPhone}>no cell phone</span>;
    };

    const dateOfBirth = useMemo(() => {
        if (!patient.birthDate) return '';
        return formatToLocale(
            patient.birthDate,
            undefined,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            },
            false
        );
    }, [patient.birthDate]);

    const handlePatientClick = () => {
        onPatientClick && onPatientClick(Number(patient.id), patient.locationId);
    };
    const handleMessageClick = () => {
        patient.phone && onMessageClick && onMessageClick(patient.id, patient.phone);
    };

    const handlePhoneClick = () => {
        patient.phone && onPhoneClick && onPhoneClick(patient.phone);
    };

    const setPatientHoverState = (state: boolean) => {
        return () => {
            setPatientHovered(state);
        };
    };

    return (
        <ListItem
            component='div'
            className={classes.card}
            tabIndex={tabIndex}
            onKeyDown={onKeyDown}
            sx={{ background: focused ? '#EDEFF2' : 'white' }}
            secondaryAction={
                patient.phone && (
                    <Box display='flex' flexDirection='row' gap={'27px'} marginRight={'24px'}>
                        <IconButton
                            edge='end'
                            aria-label='make call'
                            className={classes.actionButton}
                            onClick={handlePhoneClick}
                        >
                            <CallIcon className={classes.actionIcons} />
                        </IconButton>
                        <IconButton
                            edge='end'
                            aria-label='send message'
                            className={classes.actionButton}
                            onClick={handleMessageClick}
                            disabled={disabledMessenger}
                        >
                            <ForumIcon className={classes.actionIcons} />
                        </IconButton>
                    </Box>
                )
            }
        >
            <ListItemAvatar>
                <Avatar
                    onClick={handlePatientClick}
                    alt='Avatar'
                    onMouseEnter={setPatientHoverState(true)}
                    onMouseLeave={setPatientHoverState(false)}
                    src={searchAvatar}
                    sx={{ border: patientHovered ? '2px solid #0675DD' : 'none' }}
                />
            </ListItemAvatar>
            <ListItemText
                onClick={handlePatientClick}
                primary={
                    <Typography
                        className={classes.primaryText}
                        variant='h6'
                        onMouseEnter={setPatientHoverState(true)}
                        onMouseLeave={setPatientHoverState(false)}
                        sx={{ color: patientHovered ? '#0675DD' : 'black' }}
                    >
                        {patient.name}
                    </Typography>
                }
                secondary={
                    <Typography className={classes.secondaryText} variant='body2'>
                        {patient.birthDate && (
                            <>
                                {dateOfBirth}
                                <Box component='span' m={1} display='inline'>
                                    |
                                </Box>
                            </>
                        )}
                        {renderPhone(patient.phone)}
                    </Typography>
                }
            />
        </ListItem>
    );
}
