import React, { createContext, useCallback, useContext, useState } from 'react';
import {
    TextTemplatesService,
    PaginatedTemplatesForMultipleLocations,
    TemplateForMultipleLocations
} from '../../services/text-templates-service/text-templates-service';
import { useOidcWithAdmin, useConfig } from '@revenuewell/front-end-bundle';

export interface TextTemplateContextProps {
    getTemplatesFromLocation: (locationId?: number) => TemplateForMultipleLocations[];
    fetchTemplates: (
        locationId: number,
        page?: number,
        pageSize?: number
    ) => Promise<PaginatedTemplatesForMultipleLocations>;
}

export const TextTemplateContext = createContext<TextTemplateContextProps>(null!);

export function TextTemplateProvider(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    const [textTemplatesData, setTextTemplatesData] = useState<PaginatedTemplatesForMultipleLocations>(null!);
    const textTemplateService = new TextTemplatesService(config, oidcService);

    const getTemplatesFromLocation = useCallback(
        (locationId?: number): TemplateForMultipleLocations[] => {
            if (!textTemplatesData) return [];

            if (!locationId) textTemplatesData.results;

            return textTemplatesData.results.filter(template => {
                return template.locations.length === 0 || template.locations.some(loc => locationId === loc);
            });
        },
        [textTemplatesData]
    );

    const fetchTemplates = async (locationId: number, page = 1, pageSize = 5) => {
        const claims = await oidcService.getClaims();
        const acctIdRes = claims.masterAccountId;
        if (!acctIdRes) return;

        const templates = await textTemplateService.getAllTemplatesForMultiLocations(
            acctIdRes,
            [locationId],
            page,
            pageSize
        );
        if (
            textTemplatesData?.currentPage &&
            templates?.currentPage &&
            templates?.currentPage > textTemplatesData?.currentPage
        ) {
            const newTemplates: PaginatedTemplatesForMultipleLocations = {
                ...templates,
                results: [...textTemplatesData.results, ...templates.results]
            };
            setTextTemplatesData(newTemplates);
            return newTemplates;
        }
        setTextTemplatesData(templates);
        return templates;
    };

    const context = {
        fetchTemplates,
        getTemplatesFromLocation
    } as TextTemplateContextProps;

    return <TextTemplateContext.Provider value={{ ...context }}>{props.children}</TextTemplateContext.Provider>;
}

export function useTextTemplates() {
    const context = useContext(TextTemplateContext);

    if (!context) {
        throw new Error('useTextTemplates must be used within the TextTemplateProvider');
    }

    return context;
}
