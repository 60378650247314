import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useState } from 'react';
import SidebarItem from '../../shared/sidebar/sidebar-item';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';
import { ReactComponent as FormsButtonSvg } from '../../../assets/menu/icon-forms2.svg';
import FormsService from '../../../services/forms-service/forms-service'
export type FormsButtonProps = {
    to: string;
    isActive?: boolean;
    onClick?: () => void;
};

export default function FormsButton({ to, isActive, onClick }: FormsButtonProps) {
    const { config } = useConfig();
    const [hasUnread, setHasUnread] = useState<boolean>();
    const oidc = useOidcWithAdmin();
    const { activeFrame } = useWrapperNavigation();
    const [lastFrame, setLastFrame] = useState<string>();

    useEffect(() => {
        if (lastFrame && lastFrame != activeFrame) checkAlert();

        setLastFrame(activeFrame);
    }, [activeFrame]);

    useEffect(() => {
        checkAlert();
        const interval = setInterval(() => checkAlert(), 120 * 1000);
        return () => clearInterval(interval);
    }, []);

    const checkAlert = async () => {
        const formsService = FormsService.getInstance(config, oidc.oidcService);
        
        try {
            const response = await formsService.getFormsUnread();
            setHasUnread(response);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <SidebarItem title='Forms' onClick={onClick} to={to} isActive={isActive} hasNotification={hasUnread}>
            <FormsButtonSvg />
        </SidebarItem>
    );
}
