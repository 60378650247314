import { ReactElement, createContext, useContext, useState } from 'react';
import { IMailboxWithLocation } from '../../services/mailbox-service/mailbox-service';

export const commTypes = ['Phone', 'SMS'] as const;
export type CommType = typeof commTypes[number];

export interface ICommsFilter {
    commType?: CommType;
    mailboxes?: IMailboxWithLocation[];
}

interface ICommsFilterContext {
    commsFilter: ICommsFilter;
    updateCommsFilter: (newFilter: Partial<ICommsFilter>) => Promise<void>;
    patientPhoneNumber: string | undefined;
    setPatientPhoneNumber: (phoneNumber: string | undefined) => void;
}

const CommsFilterContext = createContext<ICommsFilterContext | null>(null);

export const useCommsFilter = (): ICommsFilterContext => {
    const context = useContext(CommsFilterContext);

    if (!context)
        throw new Error('useCommsFilter must be used within an CommsFilterContext');

    return context;
};

export function CommsFilterProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const [commsFilter, setCommsFilter] = useState<ICommsFilter>({});
    const [patientPhoneNumber, setPatientPhoneNumber] = useState<string>();

    const updateCommsFilter = async (newFilter: Partial<ICommsFilter>): Promise<void> => {
        setCommsFilter((prevFilter) => ({
            ...prevFilter,
            ...newFilter,
        }));
    };

    return (
        <CommsFilterContext.Provider value={{ commsFilter, updateCommsFilter, patientPhoneNumber, setPatientPhoneNumber }}>
            {children}
        </CommsFilterContext.Provider>
    );
}

export default useCommsFilter;
