import { useEffect, useState } from 'react';
import { useConfig, useNotification, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import PublisherService from '../../services/publisher-service/publisher-service';
import { usePubnub } from '../use-pubnub/use-pubnub';
import { IMailboxWithLocation } from '../../services/mailbox-service/mailbox-service';

export const useMessageAction = () => {
    const { config } = useConfig();
    const { oidcService, getClaims } = useOidcWithAdmin();
    const { mailboxes } = usePubnub();
    const [currentMailbox, setCurrentMailbox] = useState<IMailboxWithLocation>();
    const { showError } = useNotification();

    useEffect(() => {
        (async () => {
            const curLocationId = (await getClaims()).locationId;
            const currentMailbox =
                mailboxes.filter(mailbox => mailbox.locationId == curLocationId).pop() || mailboxes[0];
            setCurrentMailbox(currentMailbox);
        })();
    }, [mailboxes]);

    return {
        addMessageHiddenReaction: async (phoneNumber: string, messageTimetoken: string) => {
            const publisherService = new PublisherService(config, oidcService, currentMailbox!.id);

            await publisherService
                .addMessageAction(phoneNumber, messageTimetoken, { type: 'reaction', value: 'hidden' })
                .catch(() => showError('Unable to hide the message.'));
        },
        removeMessageHiddenReaction: async (phoneNumber: string, messageTimetoken: string, actionTimetoken: string) => {
            const publisherService = new PublisherService(config, oidcService, currentMailbox!.id);

            await publisherService
                .removeMessageAction(phoneNumber, messageTimetoken, actionTimetoken)
                .catch(() => showError('Unable to unhide the message.'));
        }
    };
};
