import { NavigationService, AuthApiService, OidcService } from '@revenuewell/front-end-bundle';
import { TimeZone } from '../../types/timezone';

export class VoiceApi extends AuthApiService {
    private static instance: VoiceApi;
    private apiUrl: string;

    constructor(private config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.apiUrl = config.voice.apiUrl;
        return this;
    }

    public static initialize(config: any, oidcService: OidcService) {
        if (!VoiceApi.instance) VoiceApi.instance = new VoiceApi(config, oidcService);
        return VoiceApi.instance;
    }

    public static getInstance() {
        if (!VoiceApi.instance) throw new Error('VoiceApi should be initialized before first use.');
        return this.instance;
    }

    public async getLocations() {
        const { masterAccountId } = await this.oidcService.getClaims();
        const url = `${this.config.account.apiUrl}/master-accounts/${masterAccountId}/locations`;
        const result = await this.authFetch(url);
        return result.json() as Location[];
    }

    public async getKazooUserCredentials() {
        const url = `${this.apiUrl}/pbx-logins/user`;
        const result = await this.authFetch(url);
        return result.json() as KazooUserCredentials;
    }

    public async getUserInfo() {
        const claims = await this.oidcService.getClaims()
        const url = `${this.apiUrl}/users/${claims.id}`;
        const result = await this.authFetch(url);
        return result.json() as KazooUser;
      }

}

export interface KazooUser {
    kazooUserId: string;
    customerUserId: number;
    kazooUserRole: 'None' | 'Admin' | 'User';
}

export interface KazooUserCredentials {
    kazooLoginUrl: string;
    kazooApiUrl: string;
    kazooUserToken: string;
    kazooAccountId: string;
    kazooApiWssUrl: string;
}

export interface Location {
    id: number;
    name: string;
    crmAccountId: string;
    idMasterAccount: number;
    rwAccountId: string;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressPostalCode: string;
    addressState: string;
    phone: string;
    accountStatus: number;
    idNetwork: number;
    timezone: TimeZone;
}