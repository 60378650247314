import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  phoneNumber: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4px'
  },
  '& label': {
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '30px',
    letterSpacing: '0.15px',
    color: ' #2C3542'
  }
});