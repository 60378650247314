import { CallDirection, CallType, ChannelSearch, CommunicationType, LastCall, LastMessage } from '../../../services/inbox-service/types';
import { IMailboxWithLocation } from '../../../services/mailbox-service/mailbox-service';

export interface IInboxQuery {
    phones?: string[];
    isUnread?: boolean | null;
    isSaved?: boolean | null;
    isArchived?: boolean | null;
    commType?: CommunicationType | null;
    mailboxes?: IMailboxWithLocation[]
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InboxPreview extends ChannelSearch { }

export const transformPayloadToChannelPreview = (channel: ChannelSearch, commType?: CommunicationType | null): InboxPreview => {
    return {
        ...channel,
        isUnread:
            commType === CommunicationType.Sms
                ? channel.isUnreadMessenger
                : commType === CommunicationType.Phone
                    ? channel.isUnreadPhone
                    : channel.isUnread,
        lastMessage:
            commType === CommunicationType.Sms || (!commType && channel.timeToken === channel.lastMessage?.timeToken)
                ? channel.lastMessage
                : null,
        lastCall:
            commType === CommunicationType.Phone || (!commType && channel.timeToken === channel.lastCall?.timeToken)
                ? channel.lastCall
                : null
    }
};

const convertToCallDirection = (direction: string): CallDirection => {
    return direction?.toLowerCase() === 'inbound' ? CallDirection.Inbound : CallDirection.Outbound;
};

const convertToCallType = (callType: string): CallType => {
    const callTypeOptions: Record<string, CallType> = {
        callbridged: CallType.CallBridged,
        missedcall: CallType.MissedCall,
        fax: CallType.Fax,
        voicemail: CallType.Voicemail,
    };

    return callTypeOptions[callType?.toLowerCase()] || CallType.CallBridged;
};

export const transformNotificationToMessagePreview = (obj: any): ChannelSearch => {
    const lastMessage: LastMessage = {
        from: String(obj.lastMessageFrom || ''),
        to: String(obj.lastMessageTo || ''),
        body: String(obj.lastMessageBody || ''),
        date: new Date(obj.lastMessageDate).toISOString() || '',
        timeToken: String(obj.lastMessageTimetoken || ''),
        communicationId: String(obj.lastMessageCommunicationId || ''),
    };

    const lastCall: LastCall = {
        id: String(obj.lastCallId || ''),
        direction: convertToCallDirection(obj.lastCallDirection),
        callType: convertToCallType(obj.lastCallType) ,
        from: String(obj.lastCallFrom || ''),
        to: String(obj.lastCallTo || ''),
        date: String(obj.lastCallDate || ''),
        timeToken: String(obj.lastCallTimetoken || ''),
    };

    return {
        channelId: obj.channelId,
        mailboxId: Number(obj.mailboxId || 0),
        isUnread: obj.isUnread === 'True',
        isUnreadMessenger: obj.isUnreadMessenger === 'True',
        isUnreadPhone: obj.isUnreadPhone === 'True',
        isNewPatient: obj.isNewPatient === 'True',
        isSaved: obj.isSaved === 'True',
        isArchived: obj.isArchived === 'True',
        isHidden: obj.isHidden === 'True',
        hasMessages: obj.hasMessages === 'True',
        patientPhoneNumber: String(obj.phoneNo),
        timeToken: String(obj.timetoken || ''),
        lastMessage: lastMessage,
        lastCall: lastCall
    };
};