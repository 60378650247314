import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import * as rr from 'react-router';
import { useLocation } from 'react-router-dom';

type pathArg = string | { pathname: string; search: string; hash: string; state: any };

function getFirstPathSegment(path: pathArg) {
    const pathStr = typeof path == 'string' ? path : path.pathname;
    const segs = pathStr.split('/');
    if (segs.length > 1) return segs[1];
    else return segs[0];
}

function addParams(path: pathArg, params: string[]): pathArg {
    if (params.length === 0) return path;

    let url = new URL('https://baseurl.com'); // will not be a part of return value

    if (typeof path == 'string') {
        url = path.startsWith('http') ? new URL(path) : new URL('https://' + window.location.hostname + path);
    } else {
        url.pathname = path.pathname;
        url.search = path.search;
        url.hash = path.hash;
    }

    const searchParams = new URLSearchParams(url.search);

    params.forEach((param: string) => {
        const [key, value] = param.split('=');
        searchParams.set(key, value);
    });

    url.search = searchParams.toString();

    if(typeof path == 'string' && path.startsWith("http")){
        return url.toString();
    }else if (typeof path == 'string') {
        return url.pathname + url.search + url.hash;
    } else {
        path.search = url.search;
        return path;
    }
}

function hasParam(path: pathArg, paramName: string) {
    if (typeof path == 'string') return path.indexOf(paramName) >= 0;
    else return path.search?.indexOf(paramName) >= 0;
}

export function pathInterceptor(path: pathArg, query: URLSearchParams): pathArg {
    const customerId = query.get('customerId');
    const locationId = query.get('locationId');

    const addCustomerId = !hasParam(path, 'customerId') && customerId !== 'null';
    const addLocationId = !hasParam(path, 'locationId') && locationId !== 'null';

    const params = [];

    if (addCustomerId && customerId) params.push('customerId=' + customerId);
    if (addLocationId && locationId) params.push('locationId=' + locationId);

    return addParams(path, params);
}

export const HistoryInterceptContext = createContext<any>(null!);

export default function HistoryInterceptProvider({ children }: PropsWithChildren<{}>) {
    const history = rr.useHistory();
    const [loaded, setLoaded] = useState(false);

    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        const setup = async () => {
            const origPush = history.push;
            const origReplace = history.replace;
            history.push = (path: pathArg, state?: any) => {
                const titlePart = getFirstPathSegment(path);
                const titleElement = document.querySelector('title');
                if (titleElement) titleElement.textContent = 'RevenueWell - ' + titlePart;

                //console.log("HISTORY PUSH:", path, newState);
                origPush(pathInterceptor(path, query) as string, state);
            };
            history.replace = (path: pathArg, state?: any) => {
                const titlePart = getFirstPathSegment(path);
                const titleElement = document.querySelector('title');
                if (titleElement) titleElement.textContent = 'RevenueWell - ' + titlePart;

                //console.log("HISTORY REPLACE:", path, newState);
                origReplace(pathInterceptor(path, query) as string, state);
            };
            setLoaded(true);
        };
        setup();
    }, []);

    if (loaded) {
        return <HistoryInterceptContext.Provider value={history}>{children}</HistoryInterceptContext.Provider>;
    } else {
        return <></>;
    }
}

export function useHistory() {
    const ctx = useContext(HistoryInterceptContext);

    if (!ctx) throw new Error('useHistory must be used within HistoryInterceptProvider');

    return ctx;
}

export function useUrlBuilder() {
    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const buildUrl = useMemo(() => {
        return (path: pathArg): string => {
            const pathA = pathInterceptor(path, query);
            if (typeof pathA == 'string') return pathA;
            else return pathA.pathname + pathA.search + pathA.hash;
        };
    }, [query]);

    return { buildUrl: buildUrl };
}
