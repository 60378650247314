import { OidcService } from '@revenuewell/front-end-bundle';
import { AuthApiService } from '@revenuewell/front-end-bundle';
import { NavigationService } from '@revenuewell/front-end-bundle';
import { Message } from '../pubnub-service';
import { IMailboxWithLocation } from '../mailbox-service/mailbox-service';

export interface LegacyMessage {
    id: number;
    text: string;
    date: string;
    contactId: string | number;
    userSenderId: string;
    userSenderInfo: {
        firstName: string;
        lastName: string;
        email: string;
    };
    isInbound: boolean;
    isExternal: boolean;
    outboundStatus: string;
}

export default class LegacyMessengerService extends AuthApiService {
    private static instance: LegacyMessengerService;
    private config: any;
    private messengerUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;

        this.messengerUrl = config.messenger.apiUrl;

        if (this.messengerUrl.includes('//') === false) {
            this.messengerUrl = `${window.location.origin}${this.messengerUrl}`;
        }
    }

    private transformName(userSenderInfo?: LegacyMessage['userSenderInfo']) {
        const senderInfo = `${userSenderInfo?.firstName} ${userSenderInfo?.lastName}`;

        const hasNull = senderInfo.toLowerCase().match(/null/g);

        if (hasNull && hasNull.length >= 2) return 'RevenueWell Systems';

        return senderInfo;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!LegacyMessengerService.instance)
            LegacyMessengerService.instance = new LegacyMessengerService(config, oidcService);

        return LegacyMessengerService.instance;
    }

    public async fetchMessageHistory(
        phoneNumber: string,
        locationId: string,
        meta: { pageNumber: number; pageSize: number },
        mailbox?: IMailboxWithLocation
    ): Promise<Message[]> {
        const url = new URL(`${this.messengerUrl}/api/messages/${phoneNumber}`);

        url.search = new URLSearchParams({
            locationId: locationId,
            ...meta,
        } as any).toString();

        const response = await this.authFetch(url.toString());
        const data: LegacyMessage[] = (await response.json()) || [];

        return data.map((item) => this.mapToMessage(item, phoneNumber, mailbox));
    }

    private mapToMessage(legacyMessage: LegacyMessage, phoneNumber: string, mailbox?: IMailboxWithLocation): Message {
        const channelId = mailbox?.pubnubPrefix
            ? `${mailbox?.pubnubPrefix}.${phoneNumber}`
            : `legacy.${phoneNumber}`;

        return {
            channelId: channelId,
            body: legacyMessage.text,
            date: new Date(legacyMessage.date).toISOString(),
            from: legacyMessage.isInbound ? phoneNumber : this.transformName(legacyMessage.userSenderInfo),
            to: legacyMessage.isInbound ? this.transformName(legacyMessage.userSenderInfo) : phoneNumber,
            timetoken: new Date(legacyMessage.date).getTime(),
            messageType: legacyMessage.isInbound ? 'incoming' : 'direct',
            isLegacy: true,
        };
    }
}
