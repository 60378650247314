import React from 'react'
import { styled, Box, Typography } from '@mui/material'

export const Menu = styled('ul')({
  padding: 0,
  margin: 0,
  backgroundColor: 'rgba(255, 255, 255, 1)'
})

export const MenuItem = styled('li')({
  fontSize: '17px',
  color: 'rgba(28, 61, 100, 1)',
  height: '50px',
  borderBottom: '2px solid rgba(225, 228, 231, 1)',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  padding: '0 20px 0 25px',
  cursor: 'pointer',
  transition: '0.1s',
  '&:hover' : {
    backgroundColor: '#f9fafb',
    transition: '0.1s'
  },
  '&:first-of-type': {
    borderTop: '2px solid rgba(225, 228, 231, 1)',
  }
})

export const MenuItemIcon = styled('div')({
  fontSize: '20px',
  paddingRight: '10px'
})
