import { makeStyles } from '@mui/styles';
import { Typography, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import debounce from 'lodash.debounce';
import { Backspace } from '@mui/icons-material';
import FindPatientCard from './find-patient-card';
import { usePubnub } from '../../../../../hooks/use-pubnub/use-pubnub';
import UniversalPatientSearchService, {
    UniversalSearch
} from '../../../../../services/universal-patient-search-service/universal-patient-search-service';
import { IContactProfile } from '../../../../../services/api-client/interface';
import NoPatientCard from './no-patient-card';

const useClasses = makeStyles({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff'
    },
    titleContainer: {
        width: '100%',
        borderBottom: '1px solid #E1E4E7'
    },
    title: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        marginLeft: 16,
        marginBottom: 12,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '28px',
        color: '#000',
        padding: '8px 12px'
    },
    search: {
        padding: '20px 16px'
    }
});

export default function StartSMSWidget() {
    const classes = useClasses();
    const [searchText, setSearchText] = useState('');
    const [isloading, setIsLoading] = useState(false);
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const [contacts, setContacts] = useState<IContactProfile[]>([]);
    const { mailboxes } = usePubnub();

    const universalSearchService = useMemo(() => {
        if (!config || !oidcService) return null;
        return UniversalPatientSearchService.getInstance(config, oidcService);
    }, [config, oidcService]);

    const searchContacts = useCallback(
        async (searchText?: string) => {
            if (!universalSearchService || !searchText?.length) return undefined;
            setIsLoading(true);

            const searchRequest: UniversalSearch = {
                searchTerm: searchText,
                page: 1,
                networks: mailboxes.map(m => m.location.idNetwork),
                preferredLocationIds: mailboxes.map(m => m.location.id),
                sort: 'LastActivity',
                pageSize: 100,
                status: 'Active'
            };

            const searchContacts = await universalSearchService.search(searchRequest);

            searchContacts ? setContacts(searchContacts) : setContacts([]);
            setIsLoading(false);
        },
        [universalSearchService, mailboxes]
    );

    const debouncedSearch = debounce(() => {
        if (searchText.length > 3) searchContacts(searchText);
        else if (searchText.length === 0) setContacts([]);
    }, 300);

    const erase = useCallback(() => setSearchText(text => text.slice(0, -1)), [searchText]);

    const renderBackspace = () =>
        searchText.length > 0 ? (
            <IconButton onClick={erase} size='large'>
                <Backspace color='primary' height={18} fontSize='small' />
            </IconButton>
        ) : (
            <></>
        );

    useEffect(() => {
        debouncedSearch();

        return () => {
            debouncedSearch.cancel();
        };
    }, [searchText]);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant='h3' className={classes.title}>
                    New Message - Find Patient
                </Typography>
            </div>
            <div className={classes.search}>
                <TextField
                    fullWidth
                    id='start-sms-search-widget'
                    label='Enter a Name or Phone Number'
                    variant='outlined'
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon sx={{ color: '#616C79' }} />
                            </InputAdornment>
                        ),
                        endAdornment: renderBackspace()
                    }}
                />
            </div>
            {contacts.length > 0 ? (
                contacts.map(contact => {
                    return <FindPatientCard key={contact.id} contactInfo={contact} />;
                })
            ) : (
                <NoPatientCard searching={searchText.length > 2 && contacts.length == 0 && isloading == false} />
            )}
        </div>
    );
}
