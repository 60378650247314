import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabHeaderContainer: {
    minHeight: '54px',
    borderBottom: '2px solid #E1E4E7'
  },
  tabHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    color: '#000000'
  },
  details: {
    backgroundColor: 'white',
    padding: '0px 16px'
  },
  '& label': {
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '30px',
    letterSpacing: '0.15px',
    color: ' #2C3542'
  },
  dropdown: {
    width: '100%',
    marginTop: '8px'
  },
  dropDownText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px'
  }
});
