import { useSlideout } from '../../../hooks/use-slideout';
import MessengerSlideout from './messenger-widget';
import AlertsSlideout from './alerts-widget';
import InboxPreviewSlideout from './inbox-widget';
import InboxPreviewFilterSlideout from './filter-widget';
import CommunicationsFilterSlideout from './comm-filter-widget';
import { ReactElement, useState } from 'react';
import { Box, Card, Fade, Slide, styled } from '@mui/material';
import { useViewMode, ViewModeType } from '../../../hooks/use-view-mode';
import { createPortal } from 'react-dom';
import { useSlideOutCard } from '../../shared/slide-out-card/provider';
import DialpadSlideout from './dialpad-widget';
import StartSMSSlideout from './messenger-widget/start-sms';
import VoicemailSlideout from './voicemail-widget';
import SelectPatientSlideout from './select-patient-widget';
import { MfaCodesSlideout } from './mfa-codes-widget';
import { PatientProfileWidget } from './patient-profile-widget';

const Container = styled(Card)<{ hasexited: string; viewmode: ViewModeType }>(props => ({
    position: 'relative',
    height: '100%',
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    minWidth: props.hasexited == 'true' ? 0 : 320,
    ...(props.viewmode !== 'Desktop' && {
        position: 'absolute',
        right: 0,
        bottom: 0,
        top: 70,
        zIndex: 10000,
        height: 'calc(100vh - 70px)'
    }),
    ...(props.viewmode === 'Phone' && {
        top: 0,
        height: '100vh',
        minWidth: props.hasexited == 'true' ? 0 : 'calc(100vw - 16px)'
    })
}));

const Backdrop = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
});

const ContainerInside = styled(Box)({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    display: 'flex',
    flexDirection: 'column'
});

function SlideoutCardContainer({ children }: { children: ReactElement }) {
    const { portal } = useSlideOutCard();
    const { viewMode } = useViewMode();

    if (viewMode === 'Desktop' || !portal.current) return children;

    return createPortal(children, portal.current);
}

export default function SlideOut() {
    const {
        isVisibleAlerts,
        isVisiblePatient,
        isVisibleMessenger,
        isVisibleInboxPreview,
        isVisibleInboxPreviewFilter,
        isVisibleCommunications,
        isVisibleCommunicationsFilter,
        isVisibleDialpad,
        isVisibleStartSms,
        isVisibleVoicemail,
        isVisisbleSelectPatient,
        isVisibleMfaCodes,
        close
    } = useSlideout();
    const [hasExited, setHasExited] = useState('false');
    const { viewMode } = useViewMode();
    const isVisible =
        isVisibleAlerts ||
        isVisiblePatient ||
        isVisibleMessenger ||
        isVisibleInboxPreview ||
        isVisibleInboxPreviewFilter ||
        isVisibleCommunications ||
        isVisibleCommunicationsFilter ||
        isVisibleDialpad ||
        isVisibleStartSms ||
        isVisibleVoicemail ||
        isVisisbleSelectPatient ||
        isVisibleMfaCodes;

    return (
        <SlideoutCardContainer>
            <>
                {viewMode !== 'Desktop' && (
                    <Fade in={isVisible}>
                        <Backdrop onClick={close} />
                    </Fade>
                )}
                <Slide
                    direction='left'
                    in={isVisible}
                    mountOnEnter
                    exit={true}
                    onExited={() => setHasExited('true')}
                    onEnter={() => setHasExited('false')}
                >
                    <Container raised viewmode={viewMode} hasexited={hasExited.toString()}>
                        <ContainerInside>
                            <PatientProfileWidget />
                            <MessengerSlideout />
                            <AlertsSlideout />
                            <InboxPreviewSlideout />
                            <InboxPreviewFilterSlideout />
                            <CommunicationsFilterSlideout />
                            <DialpadSlideout />
                            <StartSMSSlideout />
                            <VoicemailSlideout />
                            <SelectPatientSlideout />
                            <MfaCodesSlideout />
                        </ContainerInside>
                    </Container>
                </Slide>
            </>
        </SlideoutCardContainer>
    );
}
