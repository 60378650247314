import { AuthApiService } from '@revenuewell/logic-http';
import { OidcService } from '@revenuewell/logic-oidc';
import { NavigationService } from '@revenuewell/logic-navigation';
import { ILocation } from '../location-service/location-service';

export class AccountService extends AuthApiService {
    private static instance: AccountService;
    private config: any;
    private featureFlagsUrl: any;

    constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.featureFlagsUrl = config.account.apiUrl;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!AccountService.instance) AccountService.instance = new AccountService(config, oidcService);

        return AccountService.instance;
    }

    getLocationsByMasterId = async (id: number): Promise<ILocation[]> => {
        const url = `${this.config.account.apiUrl}/master-accounts/${id}/locations`;
        const response = await this.authFetch(url, { method: 'GET' });
        return (response.status === 200)
            ? await response.json()
            : [];
    };
}
