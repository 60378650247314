import React from 'react'
import { styled } from '@mui/material'

export const BreadcrumbsWrapper = styled('div')({
  padding: '0px 10px 0px 10px',
  borderWidth: '1px 0px 1px 0px',
  borderStyle: 'solid',
  borderColor: 'rgba(189, 195, 202, 1)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
})