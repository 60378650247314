import { useConfig, useOidc } from '@revenuewell/front-end-bundle';
import { useKazoo } from '../use-kazoo/use-kazoo';
import {FaxApi, Faxbox} from '../../services/fax-service'
import {createContext, useContext, useState} from "react";
import { formatUSPhoneNumber, normaliseUSPhoneNumber } from '../../utils/formatter';
import {FaxStatus} from "./types";
export interface IFaxContext {
    fromNumbers: string[];
    faxStatus: FaxStatus;
    getFaxboxes: () => Promise<void>,
    fetchFaxFromNumbers: () => Promise<void>,
    sendFax: (document: File, fromNumber: string, toNumber: string) => Promise<void>
}

export const FaxContext = createContext<IFaxContext>(null!)

export function FaxProvider(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();
    const { oidcService } = useOidc();
    const { userCredentials, userInfo } = useKazoo();
    const [fromNumbers, setFromNumbers] = useState<string[]>([])
    const [faxBoxes, setFaxBoxes] = useState<Faxbox[]>([])
    const [faxStatus, setFaxStatus] = useState<FaxStatus>('initial')
    const faxApi = new FaxApi()

    const getFaxboxes = async () => {
        if (userCredentials) {
            try {
                const faxboxes = await faxApi.fetchFaxboxes(
                    userCredentials.kazooApiUrl,
                    userCredentials.kazooAccountId,
                    userCredentials.kazooUserToken
                );

                setFaxBoxes(faxboxes);

                faxboxes.length
                    ? setFaxStatus('configured')
                    : setFaxStatus('notConfigured');

            } catch (err) {
                console.log('Error occurred in fetching fax boxes');
            }
        }
    }

    const fetchFaxFromNumbers = async () => {
        if (userCredentials && userInfo?.kazooUserId) {
            const callflowsData = await faxApi.fetchCallFlows(
                userCredentials?.kazooApiUrl,
                userInfo?.kazooUserId,
                userCredentials?.kazooAccountId,
                userCredentials?.kazooUserToken
            );
            const phoneNumbers: string[] = [];

            callflowsData.forEach((phoneNumberWithOwner) => {
                if (phoneNumberWithOwner.type === 'faxing') {
                    if (
                        (phoneNumberWithOwner.name && phoneNumberWithOwner.name === 'MainFaxing') ||
                        (phoneNumberWithOwner.owner_id && phoneNumberWithOwner.owner_id === userInfo?.kazooUserId)
                    ) {
                        phoneNumberWithOwner.numbers.map((phoneNum) => {
                            phoneNumbers.push(formatUSPhoneNumber(normaliseUSPhoneNumber(phoneNum)));
                        });
                    }
                }
            });

            setFromNumbers(phoneNumbers);
        }
    }

    const sendFax = async (document: File, fromNumber: string, toNumber: string) => {
        if (!userCredentials) return;

        try {
            await faxApi.sendFax(
                userCredentials?.kazooApiUrl,
                userCredentials?.kazooAccountId,
                userCredentials?.kazooUserToken,
                document, fromNumber, toNumber);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <FaxContext.Provider value={{
            fromNumbers,
            faxStatus,
            
            fetchFaxFromNumbers,
            sendFax,
            getFaxboxes
        }}>
            {props.children}
        </FaxContext.Provider>
    )
}

export function useFax() : IFaxContext {
    const context = useContext(FaxContext);
    if (!context) {
        throw new Error('useFax must be used within FaxProvider');
    }
    
    return context;
}