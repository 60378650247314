import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
    menuHighlighted: {
      background: 'rgba(0, 0, 0, 0.08)',
    },
    menuItem: {
      display: 'flex',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      padding: '8px 16px',
      height: 48,
    },
    patientLinksWrapper: {
      maxHeight: 500
    },
    noScroll: {
      overflow: 'hidden',
    },
    careCreditLinkList: {
      backgroundColor: 'white'
    }
  });