import moment from 'moment-timezone';
import { TimeZone } from '../types/timezone';

const defaultLocale = 'en-US';
const defaultOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};
const MINUTE_SECONDS = 60000;

export type DateTime = number; // placeholder type for date/time values

export function now(): DateTime {
    return new Date().getTime();
}

export function formatInterval(ms: number) {
    const secTotal = ~~(ms / 1000);
    const sec = secTotal % 60;
    const minuteTotal = ~~(secTotal / 60);
    return `${('00' + minuteTotal).slice(-2)}:${('00' + sec).slice(-2)}`;
}

export function getAge(dob: any) {
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    const age = Math.abs(year - 1970);
    return age;
}

export function getFormattedDateForContactCard(dob: string | undefined) {
    if (dob?.length) {
        const dobArr = dob.split('-');

        if (dobArr?.length === 3) {
            return `${dobArr[2]}/${dobArr[1]}/${dobArr[0]}`;
        }
    }
}

export function formatToLocale(
    dateToFormat: string,
    defaultLocateSetting = defaultLocale,
    options = defaultOptions,
    useLocalTZ = true
): string {
    let formattedDate = new Date(dateToFormat);

    if (!useLocalTZ) {
        const userTimezoneOffset = formattedDate.getTimezoneOffset() * MINUTE_SECONDS;
        formattedDate = new Date(formattedDate.getTime() + userTimezoneOffset);
    }

    return formattedDate.toLocaleDateString(defaultLocateSetting, options); //eslin
}

export const gregorianToDate = (date: number) => {
    return moment((date - 62167219200) * 1000).toDate();
};

export const formatFromNow = (date?: string | Date) => {
    if (!date) return 'N/A';

    const current = moment();
    const msgTime = moment(date);

    if (msgTime.format('YYYY-MM-DD') === current.format('YYYY-MM-DD')) return 'Today';

    if (msgTime.format('YYYY-MM-DD') === moment(current).subtract(1, 'day').format('YYYY-MM-DD')) return 'Yesterday';

    if (msgTime.toDate() > moment(current).subtract(7, 'days').toDate()) return msgTime.format('dddd');

    return msgTime.format('L');
};

export const formatDate = (date: string | Date, format?: string, timezone?: TimeZone) => {
    const tzTimezone = getTzTimezone(timezone);

    return tzTimezone 
        ? moment(date).tz(tzTimezone).format(format) 
        : moment(date).format(format);
}

// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
const getTzTimezone = (timezone?: TimeZone) => {
    if(!timezone) return null;

    switch (timezone) {
        case 'AlaskanStandardTime':
            return 'US/Alaska';
        case 'CentralStandardTime':
            return 'US/Central'
        case 'EasternStandardTime':
            return 'US/Eastern';
        case 'HawaiianStandardTime':
            return 'US/Hawaii';
        case 'MountainStandardTime':
            return 'US/Mountain';
        case 'USMountainStandardTime':
            return 'US/Mountain';
        case 'PacificStandardTime':
            return 'US/Pacific';
        default: return null;
    }
}

