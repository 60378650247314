import { Box, Button, styled } from '@mui/material';

export const Window = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 564,
  backgroundColor: 'white',
  border: 'none',
  borderRadius: '6px',
  boxShadow:
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
});

export const Header = styled(Box)({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  lineHeight: '28px',
  fontSize: '24px',
  color: '#FFFFFF',
  cursor: 'default',
  backgroundColor: '#E31B0C',
  backgroundImage: 'none',
  padding: '16px 0',
  textAlign: 'center',
  borderBottom: 'none',
  borderRadius: '0',
  position: 'initial',
  height: 'auto',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px'
});

export const Content = styled(Box)({
  padding: '16px 48px 24px 48px',
  fontFamily: 'Roboto',
  fontStyle: 'normal'
});

export const Title = styled(Box)({
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '120%',
  color: '#000000',
  letterSpacing: '0.15px',
  cursor: 'default'
});

export const Description = styled(Box)({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  color: '#2C3542',
  marginTop: '4px',
  letterSpacing: '0.15px',
  cursor: 'default'
});

export const Toolbar = styled(Box)({
  marginTop: '24px',
  display: 'flex',
  justifyContent: 'flex-end'
});

export const SnoozeButton = styled(Button)({
  color: '#621B16',
  background: '#FBF0F0',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    color: 'white',
    backgroundColor: '#E25650',
    boxShadow: 'none'
  }
});

export const GetHelpButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  marginLeft: '16px',
  '&:hover': {
    boxShadow: 'none'
  }
});

export const FixNowButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  marginLeft: '16px',
  '&:hover': {
    boxShadow: 'none'
  }
});
