import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";

export const getCustomizedTextCount = (patientPreferences: PatientPreferencesResponse) => {
  let count = 0;
  patientPreferences.textAppointmentReminders !== false ? count++ : null;
  patientPreferences.textPatientReactivation !== false ? count++ : null;
  patientPreferences.textRecalls !== false ? count++ : null;
  patientPreferences.textReviewsAndSurveys !== false ? count++ : null;
  patientPreferences.textSpecialsAndNewsletters !== false ? count++ : null;
  patientPreferences.textTreatmentPlanFollowUps !== false ? count++ : null;
  return count;
};