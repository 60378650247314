import { InputBase, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const Search = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '20px',
    padding: '4px 23px',
    gap: '20px',
    backgroundColor: '#004763',
    '&:hover': {
        backgroundColor: '#265D7C'
    }
});

const IconWrapper = styled('div')({
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.with-pointer-events': {
        pointerEvents: 'auto'
    },
    '&:hover': {
        color: '#1EB9FB'
    },
    '&:focus-visible': {
        border: '2px solid #1EB9FB',
        borderRadius: '5px',
        outline: 'none'
    }
});

const StyledInputBase = styled(InputBase)({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        width: '100%',
        '&::placeholder': {
            color: '#D5D9DD',
            opacity: 1
        }
    }
});

const StyledCancelIcon = styled(CancelIcon)({
    color: '#80979F',
    '&:hover': {
        color: '#1EB9FB'
    }
});

interface Props {
    value: string;
    onChange: (value: string) => void;
    onBack?: () => void;
}

const SearchBar = React.forwardRef(({ onChange, value, onBack }: Props, ref: React.LegacyRef<HTMLDivElement>) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    const handleClear = () => {
        onChange('');
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleFocus = (isFocused: boolean) => {
        return () => setFocused(isFocused);
    };

    const isFilled = useMemo(() => {
        return !!value;
    }, [value]);

    useEffect(() => {
        if (onBack) inputRef.current?.focus();
    }, [onBack]);

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
        if (e.code === 'Enter') {
            handleClear();
        }
    };

    return (
        <Search sx={{ border: focused ? '2px solid #1EB9FB' : '2px solid transparent' }} ref={ref}>
            <IconWrapper className={onBack && 'with-pointer-events'}>
                {onBack ? (
                    <BackIcon onClick={onBack} sx={{ color: '#1EB9FB' }} />
                ) : (
                    <SearchIcon sx={{ color: focused ? '#1EB9FB' : '#D5D9DD' }} />
                )}
            </IconWrapper>
            <StyledInputBase
                inputRef={inputRef}
                onChange={handleChange}
                value={value}
                type='text'
                placeholder='Search for a patient…'
                inputProps={{ 'aria-label': 'search' }}
                onFocus={handleFocus(true)}
                onBlur={handleFocus(false)}
                autoFocus
            />
            <IconWrapper
                sx={{ cursor: 'pointer', pointerEvents: 'all', display: isFilled ? 'flex' : 'none' }}
                onClick={handleClear}
                onKeyDown={handleKeyDown}
                tabIndex={0}
            >
                <StyledCancelIcon />
            </IconWrapper>
        </Search>
    );
});

export default SearchBar;
