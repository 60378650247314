import { Box, ToggleButtonGroup, Chip, styled, TooltipProps, Tooltip, tooltipClasses } from '@mui/material';

/* ------------------ filter main page ----------------*/
export const FilterContainer = styled(Box)({
    borderTop: '1px solid #E1E4E7',
})

export const FilterToggleButtonGroup = styled(ToggleButtonGroup)({
    margin: '24px 16px',
    gap: 8,
    '& .MuiToggleButtonGroup-grouped': {
        boxShadow: '0 0 0 1px rgba(6, 117, 221, 0.5)',
        border: '0px !important',
        borderRadius: '4px !important',
        margin: '0px !important',
        color: '#0259AB !important',
        padding: '6px 16px',
        "&:hover": {
            backgroundColor: "transparent",
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#EBF3FA',
            boxShadow: 'none',
            paddingLeft: 10
        },
    },
})

export const FilterLocationSelect = styled(Box)({
    minHeight: 88,
    padding: 16,
})

/* ------------------ filter chips container ----------------*/
export const FilterChipsContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    padding: '12px 0',
    paddingTop: 0,
    gap: '4px',
    width: '100%'
})

export const FilterTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 200
    },
}));

export const FilterChip = styled(Chip)({
    maxWidth: 142,
    borderRadius: 4,
    color: '#0259AB',
    borderColor: 'rgba(6, 117, 221, 0.5)',
    deleteIconColor: '',
    '& .MuiChip-deleteIcon': {
        color: '#0259AB',
        fontSize: 'small'
    }
})
