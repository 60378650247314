import { styled } from '@mui/styles';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { MouseEvent } from 'react';

const AlertHeaderContainer = styled(Box)({
    fontFamily: 'Roboto',
    display: 'grid',
    gridTemplateColumns: '32px 1fr auto',
    gap: '8px 8px'
});

const DateContainer = styled(Box)({
    textAlign: 'right',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    color: '#2C3542',
    '&.alert-date-always-visible': {
        display: 'block !important'
    }
});
const CloseContainer = styled(Box)({
    position: 'absolute',
    display: 'none',
    top: 8,
    right: 6,
    color: '#616C79'
});

const TitleContainer = styled(Box)({
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000000'
});

interface AlertHeaderProps {
    icon: React.ReactNode;
    title: React.ReactNode;
    date: React.ReactNode;
    className?: string;
    onClose?: () => void;
}

export default function AlertHeader({ icon, title, date, onClose, className }: AlertHeaderProps) {
    const handleOnClose = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (onClose) onClose();
    };

    return (
        <AlertHeaderContainer className={className}>
            <Box>{icon}</Box>
            <TitleContainer className="alert-title">{title}</TitleContainer>
            <DateContainer className={`alert-date ${!onClose && 'alert-date-always-visible'}`}>{date}</DateContainer>
            <CloseContainer className='alert-close'>
                {onClose && (
                    <IconButton size='small' edge='start' color='inherit' aria-label='menu' onClick={handleOnClose}>
                        <Close />
                    </IconButton>
                )}
            </CloseContainer>
        </AlertHeaderContainer>
    );
}
