import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePubnub } from '../../../hooks/use-pubnub/use-pubnub';
import { ReactComponent as MessengerButtonSvg } from '../../../assets/menu/icon-messenger.svg';
import SidebarItem from '../../shared/sidebar/sidebar-item';
import PublisherService from '../../../services/publisher-service/publisher-service';
import { useNotifications } from '@revenuewell/uc-notifications-client';

export type MessengerButtonProps = {
    to: string;
    isActive?: boolean;
    onClick?: () => void;
};

export default function MessengerButton({ to, isActive, onClick }: MessengerButtonProps) {
    const { mailboxes } = usePubnub();
    const config = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const [hasUnread, setUnread] = useState(false);
    const { listen } = useNotifications();
    



    const textPublisher = useMemo(() => {
        return new PublisherService(config.config, oidcService, 0);
    }, [config, oidcService]);

    const fetchUnread = useCallback(async () => {
        if(!mailboxes.length) 
          return

        const { total } = await textPublisher.channelsUnreadCounts({
            mailboxIds: mailboxes.map(d => d.id)
        });
        setUnread(total > 0);
    }, [setUnread, textPublisher, mailboxes]);

    useEffect(() => {
        fetchUnread();

        return listen('channel-update', () => {
            fetchUnread();
        });
    }, [listen, fetchUnread]);

    return (
        <SidebarItem title='Messenger' onClick={onClick} to={to} isActive={isActive} hasNotification={hasUnread}>
            <MessengerButtonSvg />
        </SidebarItem>
    );
}
