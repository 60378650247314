import { FC } from "react";
import { PatientProfileHeader } from "../../header/header";
import { WidgetRoutePath } from "../../navigation/route-config";
import { Menu, MenuItem, MenuItemIcon } from "./main.styles";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import { KeyboardArrowRight, WarningAmber } from '@mui/icons-material'

export const MainView: FC = () => {
  const { goTo, patientInfo } = usePatientProfileWidget();

  const MenuConfig = [
    { title: "General", path: WidgetRoutePath.GENERAL, icon: null },
    { title: "Inbox", path: WidgetRoutePath.INBOX, icon: null },
    { title: "Communication Preferences", path: WidgetRoutePath.COMM_PREFERENCES, icon: null },
    { title: "Visits", path: WidgetRoutePath.VISITS, icon: null },
    { title: "Billing", path: WidgetRoutePath.BILLING, icon: null },
    {
      title: "Alerts & Tasks", path: WidgetRoutePath.ALERTS_TASKS,
      icon: patientInfo?.patientAlerts && patientInfo?.patientAlerts.length > 0
        ? <WarningAmber sx={{ color: 'rgba(249, 190, 40, 1)' }} /> 
        : null
    },
  ]

  return <>
    <PatientProfileHeader />
    <Menu>
      {MenuConfig.map((item, index) =>
        <MenuItem key={index} onClick={() => goTo(item.path)}>
          {item.icon ? <MenuItemIcon>{item.icon}</MenuItemIcon> : null}
          {item.title}
          <KeyboardArrowRight sx={{ marginLeft: 'auto' }} />
        </MenuItem>
      )}
    </Menu>
  </>
}