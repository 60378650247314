import { Avatar } from '@revenuewell/ui-icons';
import { IconButton, Typography } from '@mui/material';
import { PersonAddOutlined } from '@mui/icons-material';
import Scrollbars from 'react-custom-scrollbars-2';
import moment from 'moment';
import { useStyles } from './profile-selector.styles';
import { ContactSearchByNumber } from '../../../../../../services/api-client/contact-api';
import { useEventBus } from '../../../../../../hooks/use-event-bus';
import { FC } from 'react';
import { usePatientProfileWidget } from '../../hooks/use-patient-profile-widget';

export const ProfileSelectorView: FC = () => {
  const classes = useStyles();
  const { publish } = useEventBus();
  const { contactsByPhone, locationId, networkId } = usePatientProfileWidget();

  const renderResponsibleParty = (contact: ContactSearchByNumber) => {
    return contact.isResponsibleParty && <span> (RP)</span>;
  };

  const renderAge = (contact: ContactSearchByNumber) => {
    const hasDateOfBirth = contact.dateOfBirth && contact.dateOfBirth.length > 0;
    return hasDateOfBirth && <span>, {moment().diff(contact.dateOfBirth, 'years', false)}</span>;
  };

  return (
    <>
      <div className={classes.profilesSelector}>
        {contactsByPhone?.length === 0 && (
          <Typography className={classes.description} color={'secondary'} variant={'body2'}>
            Sorry no matching patients found
          </Typography>
        )}
        {contactsByPhone?.length > 0 && (
          <>
            <Typography className={classes.header} variant={'h6'} color={'primary'}>
              Select A Profile
            </Typography>
            <Typography className={classes.description} color={'secondary'} variant={'body1'}>
              Our system has matched this number to multiple profiles. Please select the one you’d like to
              see.
            </Typography>
            <Scrollbars autoHide style={{ flexGrow: 1 }}>
              <div className={classes.profiles}>
                {contactsByPhone.map(contact => {
                  return (
                    <div
                      className={classes.profile}
                      key={contact.id}
                      onClick={() => {
                        publish({
                          messageType: 'patientProfile',
                          data: {
                            patientId: contact.id,
                            locationId:
                              contact.networkId == networkId
                                ? contact.locationId
                                : locationId
                          }
                        });
                      }}
                    >
                      <Avatar size='l' />
                      <div className={classes.profileName}>
                        <Typography variant={'body1'}>
                          {contact.firstName} {contact.lastName}
                          {renderResponsibleParty(contact)}
                          {renderAge(contact)}
                        </Typography>
                        {networkId !== contact.networkId && (
                          <IconButton>
                            <PersonAddOutlined style={{ color: 'rgb(76, 175, 80)' }} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Scrollbars>
          </>
        )}
      </div>
    </>
  );
}
