import { Badge, BadgeProps, Box, IconButton, styled } from '@mui/material';
import { Inbox as InboxIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import InboxService from '../../../../services/inbox-service/inbox-service';
import { useNotifications } from '@revenuewell/uc-notifications-client';
import { useSlideout } from '../../../../hooks/use-slideout';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '20px'
    },
    actionMenu: {
        width: 48,
        height: 48,
        margin: '11px 0 11px 0',
        backgroundColor: '#004763',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#265D7C'
        },
        '&:focus-visible': {
            border: '2px solid #1EB9FB'
        }
    },
    actionMenuOpen: {
        backgroundColor: '#0675DD',
        '&:hover': {
            backgroundColor: '#0675DD'
        }
    }
});

const InboxBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        marginTop: 6,
        marginRight: -6,
        backgroundColor: '#1EB9FB',
        color: '#032F40'
    }
}));

export default function InboxButton() {
    const classes = useStyles();
    const { isVisibleInboxPreview } = useSlideout();
    const { publish } = useEventBus();

    const { mailboxes } = usePubnub();
    const config = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const [unreadCount, setUnreadCount] = useState(0);
    const { listen } = useNotifications();

    const showInbox = useCallback(() => {
        if (!isVisibleInboxPreview)
            publish({
                messageType: 'inboxPreview',
            });
    }, [publish, isVisibleInboxPreview]);

    const inboxService = useMemo(() => InboxService.getInstance(config, oidcService), [config, oidcService]);

    const fetchUnread = useCallback(async () => {
        if (!mailboxes.length)
            return;

        const { total } = await inboxService.channelsUnreadCounts({
            mailboxIds: mailboxes.map(d => d.id)
        });
        setUnreadCount(total);
    }, [setUnreadCount, inboxService, mailboxes]);

    useEffect(() => {
        fetchUnread();
        return listen('channel-update', () => {
            fetchUnread();
        });
    }, [listen, fetchUnread]);

    return (
        <Box className={classes.container}>
            <InboxBadge badgeContent={unreadCount} showZero={false} overlap='circular'>
                <IconButton
                    className={`${classes.actionMenu} ${isVisibleInboxPreview && classes.actionMenuOpen}`}
                    onClick={showInbox}
                >
                    <InboxIcon />
                </IconButton>
            </InboxBadge>
        </Box>
    );
}
