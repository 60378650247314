import { makeStyles } from '@mui/styles';
import { Switch, Typography } from '@mui/material';
import { FC } from 'react';

const useStyles = makeStyles({
  subSectionContainer: {
    padding: '8px 24px 8px'
  },
  subSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  promotionalSubSection: {
    marginLeft: '16px'
  },
  displayText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000'
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#008A06'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2DE13F'
    }
  }
});

interface CommunicationSubSectionProps {
  text: string
  isChecked: boolean
  onChange: (value: boolean) => void
  isPromotionalComSubSection?: boolean
}

const CommunicationSubSection: FC<CommunicationSubSectionProps> = (props) => {
  const { text, isChecked, onChange, isPromotionalComSubSection } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.subSectionContainer} ${isPromotionalComSubSection && classes.promotionalSubSection}`}>
      <div className={classes.subSection}>
        <Typography variant='body2' className={classes.displayText} component='div'>
          {text}
        </Typography>
        <Switch
          className={classes.switch}
          checked={isChecked}
          onChange={(e, checked) => onChange(checked)}
        />
      </div>
    </div>
  );
};

export default CommunicationSubSection;
