import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { usePatientProfileWidget } from '../../hooks/use-patient-profile-widget';
import { RouteConfig, RouteConfigItem, WidgetRoutePath } from '../route-config';
import { BreadcrumbsWrapper } from './breadcrumbs.styles';

export const NavigationBreadcrumbs = () => {
  const { route, goTo, breadcrumbExtensions, patientInfo } = usePatientProfileWidget();
  const [breadcrumbs, setBreadcrumbs] = useState<RouteConfigItem[]>([]);

  useEffect(() => {
    updateBreadcrumbs(route);
  }, [route]);

  const updateBreadcrumbs = (path: WidgetRoutePath | undefined) => {
    if(!path) return;

    if (path === WidgetRoutePath.MAIN)
      return setBreadcrumbs([]);

    const matchingPaths = RouteConfig.filter(obj =>
      path.startsWith(obj.path) && (path === obj.path || path[obj.path.length] === '/')
    );

    setBreadcrumbs(matchingPaths);
  }

  if (breadcrumbs.length === 0) return null;

  return <BreadcrumbsWrapper>
    {breadcrumbExtensions?.placeholers?.left?.component}
    {
      patientInfo &&
      <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ letterSpacing: 0, padding: '12px 0' }}>
        <Link underline="hover" onClick={() => goTo(WidgetRoutePath.MAIN)}>
          Profile
        </Link>
        {
          breadcrumbs.map((item: RouteConfigItem, index: number) => {
            return index === breadcrumbs.length - 1
              ? <Typography key={index} color="text.primary">{item.name}</Typography>
              : <Link key={index} underline="hover" onClick={() => goTo(item.path)}>
                {item.name}
              </Link>
          })
        }
      </Breadcrumbs>
    }
    {breadcrumbExtensions?.placeholers?.right?.component}
  </BreadcrumbsWrapper >
}