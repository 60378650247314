import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Alert, PracticeOnboarding } from "../../types/insurance-v2"
import { useConfig, useOidcWithAdmin } from "@revenuewell/front-end-bundle"
import InsuranceV2Service from "../../services/insuranc-service-v2/insurance-service-v2"
import { useFeatureFlags } from "../feature-flags/use-feature-flags"
import { useNotifications } from "@revenuewell/uc-notifications-client"
import { InsuranceVerificationUpdate } from "@revenuewell/uc-notifications-client/dist/types/notifications"
import { ILocation } from "../../services/location-service/location-service"

const REFRESH_INTERVAL = 1000 * 60 * 10; //10 minutes

const alertMessages: Record<string, string> = {
    missing_credentials: "Missing Credentials",
    invalid_credentials: "Invalid Credentials",
    mfa_failure: "MFA Required",
    missing_npi: "Missing NPI",
    missing_pin: "Missing PIN"
}

export type AlertsContextProps = {
    alerts: Map<number, Alert[]>
    getAlerts: (locationId: number) => Alert[];
    getTooltip: (locationId?: number, area?: string) => string
    showError: (locationId?: number, area?: string) => boolean;
    showWarning: (locationId?: number, area?: string) => boolean;
    refreshAlerts: (locationId: number) => Promise<void>;
}

export const AlertContext = createContext<AlertsContextProps>(null!);

export function InsuranceAlertsProvider(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();
    const {  getClaims, oidcService } = useOidcWithAdmin();
    const { contextReady, locationsWithFeature } = useFeatureFlags();
    const [alerts, setAlerts] = useState<Map<number, Alert[]>>(new Map());
    const [onboarding, setOnboarding] = useState<Map<number, PracticeOnboarding>>();
    const [locations, setLocations] = useState<ILocation[]>();
    const { listen } = useNotifications();


    const insuranceService = InsuranceV2Service.getInstance(config, oidcService);

    const getAlertsAsync = async (locationIds: number[]): Promise<Map<number, Alert[]>> => {
        const tmpAlerts: Map<number, Alert[]> = new Map();
        const alertList = await Promise.all(locationIds.map(locId => insuranceService.getAlerts(locId)));
        for(let i=0; i<locationIds.length; i++){
            tmpAlerts.set(locationIds[i],alertList[i]);
        }
        return tmpAlerts;
    }

    const getOnboardingAsync = async (locationIds: number[]): Promise<Map<number, PracticeOnboarding>> => {
        const tmpOnboarding: Map<number, PracticeOnboarding> = new Map();
        const onboardingList = await Promise.all(locationIds.map(locId => insuranceService.getPracticeOnboarding(locId)));
        for(let i=0; i<locationIds.length; i++){
            tmpOnboarding.set(locationIds[i], onboardingList[i]);
        }
        return tmpOnboarding;
    }
    
    useEffect(() => {
        if(!contextReady) return;
        const locations = locationsWithFeature("insuranceverification2");
        setLocations(locations);
        const loadAlerts = (async () => {
            const tmpAlerts = await getAlertsAsync(locations.map(loc => loc.id))
            setAlerts(tmpAlerts);
        });

        const loadOnboarding = (async () => {
            const onboarding =  await getOnboardingAsync(locations.map(loc => loc.id))
            setOnboarding(onboarding);
        });

        loadAlerts();
        loadOnboarding();
        const id = setInterval(() => { loadAlerts(); loadOnboarding(); }, REFRESH_INTERVAL);

        //cleanup
        return () => {
            clearInterval(id);
        }
    }, [contextReady]);

    useEffect(() => {
        return listen('insurance-verification-update', event => {
            const { data } = event as InsuranceVerificationUpdate;
            const locationId = event.locationId
            if(locationId)
                refreshAlerts(locationId);  
        });
    }, [listen]);

    const getAlerts = useCallback((locationId: number) => {
        const tmpAlerts = alerts.get(locationId);
        return tmpAlerts ? tmpAlerts : [];
    }, [alerts])


    const showError = useCallback((locationId?: number, area?: string): boolean => {
        if(!locationId)
            return !!locations?.some(loc => showError(loc.id));

        const tmpAlerts = getAlerts(locationId);
        const tmpOnboarding = onboarding?.get(locationId);
        const error = !area ? !!tmpAlerts?.some(alert => alert.level == 'error') 
            : !!tmpAlerts?.some(alert => alert.level == 'error' && alert.area == area);
          
        return error && !!(tmpOnboarding?.activationDate)
    }, [getAlerts, locations, onboarding])

    const showWarning = useCallback((locationId?: number, area?: string): boolean => {
        if(!locationId)
            return !!locations?.some(loc => showWarning(loc.id));

        const tmpAlerts = getAlerts(locationId);
        const tmpOnboarding = onboarding?.get(locationId);
        const error = !area ? !!tmpAlerts?.some(alert => alert.level == 'error') 
            : !!tmpAlerts?.some(alert => alert.level == 'error' && alert.area == area);
        const warning = !area ? !!tmpAlerts?.some(alert => alert.level == 'warning')
            : !!tmpAlerts?.some(alert => alert.level == 'warning' && alert.area == area)
        return ((error && !(tmpOnboarding?.activationDate)) || warning)
    }, [getAlerts, locations])

    const refreshAlerts = useCallback(async (locationId: number) => {
        const tmpAlerts = await getAlertsAsync([locationId]);
        setAlerts((alerts) => {
            const finalAlerts = new Map(alerts);
            finalAlerts.set(locationId, tmpAlerts.get(locationId)!)
            return finalAlerts;
        });
    },[alerts])

    const getTooltip = useCallback((locationId?: number, area?: string) => {
        const tmpAlerts = locationId ? alerts.get(locationId) : locations?.flatMap(loc => getAlerts(loc.id));
        const firstAlert = tmpAlerts?.filter(a => area ? a.area==area : true).pop();
        if(!firstAlert) return '';

        const message = alertMessages[firstAlert.code];
        return message ? message : '';

    },[alerts, locations]);

    const context = {
        alerts,
        getAlerts,
        showError,
        showWarning,
        refreshAlerts,
        getTooltip
    }
    
    return <AlertContext.Provider value={{ ...context }}>
    {props.children}
    </AlertContext.Provider>;
}

export function useInsuranceAlerts() {
    const context = useContext(AlertContext);

    if (!context) {
        throw new Error('useInsuranceAlerts must be used within the InsuranceAlertsProvider');
    }

    return context;
}