import { Box, Link, Typography, styled } from "@mui/material";
import { keyframes } from '@mui/system';
import {
    differenceInDays,
    differenceInMonths,
    differenceInWeeks,
    differenceInYears,
    format,
    getWeek,
    subDays,
    subMonths
} from 'date-fns';

const LabelContainer = styled(Box)({
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    '& .location': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        direction: 'rtl',
        flex: 'auto'
    }
});

const LabelText = styled(Typography)({
    color: '#2C3542',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 500,
    flex: 'none'
});

const ellipsis = keyframes`
    to {
        clip-path: inset(0 -3px 0 0)
    }`;

const SendingText = styled(LabelText)({
    display: 'inline-block',
    animation: `${ellipsis} 1s steps(4) infinite`,
    clipPath: 'inset(0 10px 0 0)'
});

interface CommCardLabelProps {
    direction: string;
    locationName: string;
    timestamp: string;
    status?: string;
    isPatientProfile?: boolean;
    onClickResend?: () => void;
}

export const formatLastContacted = (timeToken: string) => {
    const current = new Date();
    const msgTime = new Date(timeToken);

    if (format(msgTime, 'yyyy-MM-dd') === format(current, 'yyyy-MM-dd')) return 'Today';

    if (format(msgTime, 'yyyy-MM-dd') === format(subDays(current, 1), 'yyyy-MM-dd')) return 'Yesterday';

    const daysDiff = differenceInDays(current, msgTime);
    const weekDiff = getWeek(current) - getWeek(msgTime);
    if (!weekDiff && daysDiff >= 2 && daysDiff <= 6) return `${daysDiff} Days Ago`;

    if (weekDiff === 1 && differenceInWeeks(current, msgTime) < 2) return `Last Week`;

    if (format(msgTime, 'yyyy-MM') === format(current, 'yyyy-MM')) return 'This Month';

    if (format(msgTime, 'yyyy-MM') === format(subMonths(current, 1), 'yyyy-MM')) return 'Last Month';

    const monthsPast = differenceInMonths(current, msgTime);
    if (monthsPast >= 2 && monthsPast <= 11) return `${monthsPast} Months Ago`;

    const yearsPast = differenceInYears(current, msgTime);

    return `${yearsPast} Years Ago`;
};

const CommCardLabel = ({
    direction,
    locationName,
    timestamp,
    status,
    isPatientProfile,
    onClickResend
}: CommCardLabelProps) => {
    if (status === 'sending' && direction === 'me')
        return (
            <LabelContainer sx={{ justifyContent: 'flex-end' }}>
                <SendingText>Sending...</SendingText>
            </LabelContainer>
        );

    return (
        <>
            <LabelContainer>
                {direction === 'patient' ? (
                    <>
                        <LabelText whiteSpace='pre-wrap'>{`Cell → `}</LabelText>
                        <LabelText className='location'>{locationName}</LabelText>
                    </>
                ) : (
                    <>
                        <LabelText className='location'>{locationName}</LabelText>
                        <LabelText whiteSpace='pre-wrap'>{` → Cell`}</LabelText>
                    </>
                )}
                <LabelText whiteSpace='pre-wrap'> {` | `} </LabelText>
                <LabelText>
                    {isPatientProfile
                        ? formatLastContacted(timestamp)
                        : format(new Date(timestamp ? timestamp : 0), 'h:mm a')}
                </LabelText>
            </LabelContainer>
            {status === 'error' && (
                <LabelContainer sx={{ justifyContent: 'flex-end' }}>
                    <LabelText sx={{ color: 'red' }}>
                        {'Failed | '}
                        <Link component='button' color='inherit' onClick={onClickResend}>
                            Resend
                        </Link>
                    </LabelText>
                </LabelContainer>
            )}
        </>
    );
};

export default CommCardLabel;