import {KazooUserApi} from '../api-client/kazoo-user-api';

export class FaxApi extends KazooUserApi {

    public async fetchCallFlows(
        kazooApiUrl: string,
        kazooUserId: string,
        kazooAccountId: string,
        kazooAuthToken: string
    ) {
        const config = {
            headers: {
                'X-Auth-Token': kazooAuthToken,
            },
        };

        const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/callflows?page_size=300`;
        const resp = await this.fetchKazooApi(url, config);

        return resp.data as KazooPhoneNumbersWithOwner[];
    }

    public async fetchFaxboxes(kazooApiUrl: string, kazooAccountId: string, kazooAuthToken: string) {
        const config = {
            headers: {
                'X-Auth-Token': kazooAuthToken,
            },
        };

        const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/faxboxes?paginate=false`;
        const resp = await this.fetchKazooApi(url, config);

        return resp.data as Faxbox[];
    }    
    
    public async sendFax(
        kazooApiUrl: string,
        kazooAccountId: string,
        kazooAuthToken: string,
        document: File,
        fromNumber: string,
        toNumber: string
    ) {
        const jsonData = {
            data: {
                retries: 3,
                ['from_number']: fromNumber,
                ['to_number']: toNumber,
                attempts: 0,
            },
        };

        const json = JSON.stringify(jsonData);
        const blob = new Blob([json], {
            type: 'application/json',
        });
        const formData = new FormData();

        formData.append('jsonDocument', blob);

        formData.append(document.name, document);

        const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/faxes`;

        const config = {
            method: 'PUT',
            headers: {
                'X-Auth-Token': kazooAuthToken,
            },
            body: formData,
        };

        await this.fetchKazooApi(url, config);
    }
}

export interface KazooPhoneNumbersWithOwner {
    id: string;
    name?: string;
    type: string;
    numbers: string[];
    owner_id?: string;
}

export interface Faxbox {
    retries: number;
    name: string;
    caller_name: string;
    fax_header: string;
    owner_id: string;
    caller_id: string;
    fax_identity: string;
    ui_metadata: UIMetadata;
    attempts: number;
    media: {};
    smtp_permission_list: any[];
    id: string;
    _read_only: ReadOnly;
    fax_timezone?: string;
    notifications?: Notifications;
}

export interface ReadOnly {
    custom_smtp_address: string;
}

export interface Notifications {
    inbound: Inbound;
}

export interface Inbound {
    email: Email;
}

export interface Email {
    send_to: string;
}

export interface UIMetadata {
    version: string;
    ui: string;
    origin: string;
}