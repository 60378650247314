import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        blue: ColorPartial;
        disabled: PaletteColorOptions;
    }

    interface PaletteOptions {
        nav?: PaletteColorOptions;
        disabled?: PaletteColorOptions;
    }
}

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
    palette: {
        nav: {
            main: '#05374A',
            dark: '#032F40'
        },
        primary: {
            main: '#089af7',
            dark: '#007bd8',
            light: 'rgba(8,154,247,0.16)'
        },
        secondary: {
            main: '#F0F1F4',
            light: '#fff'
        },
        error: {
            main: '#E25650'
        },
        background: {
            default: '#F0F1F4',
            paper: '#E9EAEE'
        },
        text: {
            primary: '#1C3D64',
            secondary: '#7A869A'
        },
        divider: '#E9EAEE',
        action: {
            active: '#8993A4'
        },
        success: {
            main: '#4DCEA0'
        },
        blue: {
            50: 'rgba(112, 203, 248, 0.16)',
            500: '#089AF7'
        },
        disabled: {
            light: '#05374A',
            main: '#05374A'
        }
    },
    typography: {
        fontFamily: 'Roboto',
        caption: {
            lineHeight: 1,
            color: '#1C3D64',
            letterSpacing: '0.4px'
        },
        subtitle1: {
            fontSize: '16px',
            color: '#1C3D64'
        },
        subtitle2: {
            color: '#5E6C84',
            fontSize: '14px',
            fontWeight: 500
        },
        body2: {
            lineHeight: 1.43,
            fontSize: '14px',
            color: '#565F6F',
            letterSpacing: '0.15px'
        },
        h6: {
            lineHeight: 1
        },
        h4: {
            lineHeight: 1
        },
        body1: {
            letterSpacing: '0.15px',
            color: '#5E6C84'
        }
    }
});

export const callTheme = createTheme({
    palette: {
        background: {
            default: 'rgba(1, 54, 75, 0.8)',
            paper: 'rgba(1, 54, 75, 0.8)'
        },
        blue: {
            50: 'rgba(112, 203, 248, 0.16)',
            500: '#089AF7'
        },
        text: {
            primary: '#FFFFFF'
        },
        primary: {
            main: '#FFFFFF'
        },
        secondary: {
            main: '#06455F',
            light: '#089AF7'
        },
        success: {
            main: '#4DCEA0'
        },
        info: {
            main: '#95E2FF'
        },
        error: {
            main: '#F44948'
        },
        action: {
            disabled: '#698792'
        },
        disabled: {
            light: '#05374A',
            main: '#05374A'
        }
    }
});
