import { FC, useState } from "react";
import { NavigationBreadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../header/header";
import AccordionSection from "../../../../../shared/accordion/accordion-section";
import AccordionContent from "../../../../../shared/accordion/accordion-content";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import moment from "moment";
import { ViewContent } from "../shared/view-content/view-content";

export const VisitsView: FC = () => {
  const { patientInfo } = usePatientProfileWidget();

  const nextAppointment = patientInfo.nextAppointment
      ? moment(patientInfo.nextAppointment.date).format('MM/DD/YYYY h:mm a')
      : '-';
  const lastAppointment = patientInfo.lastAppointment
      ? moment(patientInfo.lastAppointment.date).format('MM/DD/YYYY h:mm a')
      : '-';
  const recallDate = patientInfo.recallDate ? moment(patientInfo.recallDate).format('MM/DD/YYYY h:mm a') : '-';

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContent sx={{ padding: '0 20px'}}>
      <AccordionSection header='Next Appointment'>
        <AccordionContent>
          <label>{nextAppointment}</label>
        </AccordionContent>
      </AccordionSection>
      <AccordionSection header='Last Appointment'>
        <AccordionContent>
          <label>{lastAppointment}</label>
        </AccordionContent>
      </AccordionSection>
      <AccordionSection header='Recall Date'>
        <AccordionContent>
          <label>{recallDate}</label>
        </AccordionContent>
      </AccordionSection>
    </ViewContent>
  </>
}