import { IPNKeys } from '../pubnub-service';
import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import LocationService, { ILocation } from '../location-service/location-service';

export interface IMailboxWithLocation {
    id: number;
    locationId: number;
    customerUserId: number;
    pubnubPrefix: string;
    location: ILocation;
}
export default class MailboxService extends AuthApiService {
    private static instance: MailboxService;
    private config: any;
    private locationService: LocationService;
    private configUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.locationService = LocationService.getInstance(config, oidcService);
        this.configUrl = this.config.config.apiUrl;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!MailboxService.instance) MailboxService.instance = new MailboxService(config, oidcService);

        return MailboxService.instance;
    }

    public async getPubnubCredentials(mailboxIds: number[]): Promise<IPNKeys> {
        const { masterAccountId } = await this.oidcService.getClaims();
        const url = `${this.configUrl}/master-accounts/${masterAccountId}/mailbox-auth`;

        const response = await this.authFetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mailboxes: mailboxIds
            })
        });
        const data: IPNKeys = await response.json();

        return data;
    }

    public async getMailboxes(): Promise<IMailboxWithLocation[]> {
        const { masterAccountId } = await this.oidcService.getClaims();
        const url = `${this.configUrl}/master-accounts/${masterAccountId}/mailboxes`;

        const response = await this.authFetch(url, { method: 'GET' });
        const mailboxes = await response.json();
        const data: IMailboxWithLocation[] = await Promise.all(
            mailboxes.map(async (mailbox: IMailboxWithLocation) => ({
                ...mailbox,
                location: await this.locationService.getLocation(mailbox.locationId)
            }))
        );

        return data;
    }
}
