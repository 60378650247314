import { Box, Typography, Divider } from '@mui/material';
import { CommsHeader, CommsTitle, PatientHeader } from '../communications-widget/styles/communcations-styles';
import CommsPatientCard from '../communications-widget/patient-card';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useKazoo } from '../../../../hooks/use-kazoo/use-kazoo';
import { Message, VoicemailApi } from '../../../../services/voicemail-service/voicemail-service';
import { useVoicemail } from '../../../../hooks/use-voicemail/use-voicemail';
import AudioPlayer from '../../../shared/audio-player';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import CallHistoryService from '../../../../services/call-history/call-history-service';
import { VoicemailBox } from '../../../../hooks/use-voicemail/types';
import VoicemailIcon from '@mui/icons-material/Voicemail';

const useStyles = makeStyles({
    container: {
        height: '100%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column'
    },
    audioPlayer: {
        padding: '16px 16px',
        borderTop: '1px solid var(--grayscale-gs-15, #E1E4E7)'
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 0px',
        justifyContent: 'space-between'
    },
    title: {
        color: ' var(--text-primary, #000)',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.15px'
    },
    data: {
        color: 'var(--text-secondary, #2C3542)',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.15px'
    },
    deleted: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '30px'
    }
});

export interface Status {
    isUnread?: boolean;
    isArchived?: boolean;
    isSaved?: boolean;
}

interface VoicemailWidgetProps {
    patientPhoneNumbers: string[];
    patientId?: number;
    locationId: number;

    callId: string;
}

export default function VoicemailWidget({ patientPhoneNumbers, patientId, locationId, callId }: VoicemailWidgetProps) {
    const classes = useStyles();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { unfilteredBoxes } = useVoicemail();
    const { userCredentials } = useKazoo();
    const voicemailApi = new VoicemailApi(config, oidcService);
    const [voicemailInfo, setVoicemailInfo] = useState<Message>();
    const [mediaUrl, setMediaUrl] = useState<string>('');
    const [mailboxName, setMailboxName] = useState<string>('');
    const [isEnabled, setIsEnabled] = useState<boolean>(null!);
    const callHistoryApi = CallHistoryService.getInstance(config, oidcService);
    const [mailbox, setMailbox] = useState<VoicemailBox>();
    const [isLoading, setIsLoading] = useState<boolean>(null!);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const callHistoryResponse = await callHistoryApi.get(callId);

            const voicemailInfo =
                userCredentials &&
                (await voicemailApi.fetchMessage(
                    userCredentials.kazooApiUrl,
                    userCredentials.kazooAccountId,
                    userCredentials.kazooUserToken,
                    callHistoryResponse.voicemailBox,
                    callHistoryResponse.voicemailId
                ));

            const mailbox = unfilteredBoxes.find(box => box.id === callHistoryResponse.voicemailBox);
            const { owner_id: ownerId } = jwt_decode(userCredentials?.kazooUserToken || '') as { owner_id: string };

            setMailbox(mailbox);
            setVoicemailInfo(voicemailInfo ? voicemailInfo : undefined);
            setMailboxName(mailbox?.name || '');
            setIsEnabled(mailbox?.ownerId == ownerId || !mailbox?.ownerId);
            setIsLoading(false);
        })();
    }, [userCredentials, callId]);

    useEffect(() => {
        if (userCredentials && voicemailInfo && mailbox) {
            voicemailApi
                .generateRawAudioUrl(
                    userCredentials.kazooApiUrl,
                    userCredentials.kazooAccountId,
                    userCredentials.kazooUserToken,
                    mailbox?.id,
                    voicemailInfo.media_id
                )
                .then(url => {
                    setMediaUrl(url);
                });
        }
    }, [userCredentials, voicemailInfo, mailbox]);

    return (
        <Box className={classes.container}>
            <>
                <PatientHeader>
                    <CommsPatientCard
                        phoneNumbers={patientPhoneNumbers}
                        patientId={patientId}
                        locationId={locationId}
                    />
                </PatientHeader>
                <CommsHeader>
                    <CommsTitle>
                        <Typography variant='body1' color='#000000'>
                            Voicemail
                        </Typography>
                    </CommsTitle>
                </CommsHeader>
                <Divider />
            </>
            {mediaUrl && voicemailInfo && (
                <Box className={classes.audioPlayer}>
                    <AudioPlayer src={mediaUrl} isEnabled={isEnabled} />
                    <Divider sx={{ padding: '8px' }} />
                    <Box className={classes.info}>
                        <Typography className={classes.title}>Time</Typography>
                        <Typography className={classes.data}>
                            {moment.unix(voicemailInfo.timestamp - 62167219200).format('LT')}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box className={classes.info}>
                        <Typography className={classes.title}>Date</Typography>
                        <Typography className={classes.data}>
                            {moment.unix(voicemailInfo.timestamp - 62167219200).format('MM/DD/YYYY')}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box className={classes.info}>
                        <Typography className={classes.title}>Mailbox</Typography>
                        <Typography className={classes.data}>{mailboxName}</Typography>
                    </Box>
                    <Divider />
                </Box>
            )}
            {!mediaUrl && !voicemailInfo && isLoading == false && (
                <Box className={classes.deleted}>
                    <VoicemailIcon fontSize={'large'} />
                    <Typography>This voicemail was deleted.</Typography>
                </Box>
            )}
        </Box>
    );
}
