import { Box, IconButton, Typography, Button, Menu, MenuItem, MenuList } from '@mui/material';
import { ChevronRight, ExitToApp as SignoutIcon, NotificationsOff } from '@mui/icons-material';
import { ReactComponent as AvatarIcon } from '../../../../assets/avatar.svg';
import { makeStyles } from '@mui/styles';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import useMenu from '../../../../hooks/use-menu';
import SnoozeMenu from './do-not-disturb-menu';
import { useViewMode } from '../../../../hooks/use-view-mode';
import SlideOutCard from '../../../shared/slide-out-card';
import { useLocations } from '../../../../hooks/use-locations';
import { useSettings } from '../../../../hooks/use-settings';

export const useStyles = makeStyles({
    actionMenu: {
        width: 48,
        height: 48,
        margin: '11px 0 11px 18px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#032F40'
        },
        '&:focus-visible': {
            border: '2px solid #1EB9FB'
        }
    },
    actionMenuOpen: {
        backgroundColor: '#0675DD',
        '&:hover': {
            backgroundColor: '#0675DD'
        }
    },
    menuPaper: {
        backgroundColor: 'white',
        padding: '10px',
        width: 'max-content'
    },
    hr: {
        border: 0,
        borderTop: '2px solid #F0F1F4'
    },
    signoutBtn: {
        textTransform: 'unset',
        fontSize: '15px',
        color: '#565F6F',
        '& .MuiButton-startIcon': {
            marginRight: 18
        }
    },
    locationText: {
        fontSize: 12,
        color: '#000',
        fontWeight: 400
    },
    menuItem: {
        margin: '-10px -10px',
        padding: '16px 28px',
        display: 'flex',
        color: '#2C3542',
        '&.active': {
            backgroundColor: '#EDEFF2'
        },
        '& p': {
            flex: 1
        }
    },
    menuItemIcon: {
        width: 20,
        height: 20,
        color: '#8D97A4',
        position: 'relative',
        marginRight: 22,
        top: 4,
        left: 7
    },
    menuList: {
        backgroundColor: '#fff',
        height: '100%'
    }
});
interface ProfileMenuProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

function ProfileMenuItems({ anchorEl, onClose }: ProfileMenuProps) {
    const classes = useStyles();

    const { getClaims, logoutRedirect } = useOidcWithAdmin();
    const [name, setName] = useState('');
    const { locations } = useLocations();
    const { snoozeExpirationDate } = useSettings();
    const [snoozeAnchor, setSnoozeAnchor] = useState<null | HTMLElement>(null);
    const { viewMode } = useViewMode();

    useEffect(() => {
        (async () => {
            const claims = await getClaims();
            const fullName = `${claims.firstName} ${claims.lastName}`;
            setName(fullName);
        })();
    }, [getClaims]);

    useLayoutEffect(() => {
        if (!anchorEl) {
            setSnoozeAnchor(null);
        }
    }, [anchorEl]);

    const handleDnDButton = (event: React.MouseEvent<HTMLLIElement>) => {
        console.debug('ET', event.target);
        setSnoozeAnchor(event.target as HTMLElement);
    };

    return (
        <>
            <Box>
                <Box display='flex' margin='10px 20px 15px'>
                    <AvatarIcon />
                    <Typography marginLeft={2} variant='h5'>
                        {name}
                    </Typography>
                </Box>
                <hr className={classes.hr} />
                {viewMode === 'Desktop' && (
                    <Box margin='10px 20px 0'>
                        <Typography className={classes.locationText}>Location</Typography>
                        <Typography variant='body2'>{locations?.[0]?.addressLine1}</Typography>
                        <Typography variant='body2'>
                            {locations?.[0]?.addressCity}, {locations?.[0]?.addressState}
                        </Typography>
                    </Box>
                )}
                {viewMode === 'Desktop' && <hr className={classes.hr} />}
                <MenuItem onClick={handleDnDButton} className={`${classes.menuItem} ${snoozeAnchor && ' active'}`}>
                    <Typography variant='inherit'>
                        <NotificationsOff className={classes.menuItemIcon} />
                        Do Not Disturb
                        {snoozeExpirationDate ? ' (on)' : ''}
                    </Typography>
                    <ChevronRight />
                </MenuItem>
                {viewMode === 'Desktop' && <hr className={classes.hr} />}
                <Box display='flex' margin='10px 20px 0'>
                    <Button
                        startIcon={<SignoutIcon />}
                        className={classes.signoutBtn}
                        color='inherit'
                        onClick={logoutRedirect}
                    >
                        Sign Out
                    </Button>
                </Box>
            </Box>
            <SnoozeMenu anchor={snoozeAnchor} onClose={onClose} />
        </>
    );
}

function ProfileMenuDesktop({ anchorEl, onClose }: ProfileMenuProps) {
    const classes = useStyles();

    return (
        <>
            <Menu
                sx={{ mt: '45px' }}
                classes={{ paper: classes.menuPaper }}
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                <ProfileMenuItems anchorEl={anchorEl} onClose={onClose} />
            </Menu>
        </>
    );
}

function ProfileMenu({ anchorEl, onClose }: ProfileMenuProps) {
    const styles = useStyles();

    return (
        <SlideOutCard isVisible={Boolean(anchorEl)} onClose={onClose}>
            <MenuList className={styles.menuList}>
                <ProfileMenuItems anchorEl={anchorEl} onClose={onClose} />
            </MenuList>
        </SlideOutCard>
    );
}

export default function UserButton() {
    const { anchorEl: anchorElUser, openMenu: openUserMenu, closeMenu: closeUserMenu } = useMenu();
    const classes = useStyles();
    const { viewMode } = useViewMode();

    return (
        <>
            <IconButton
                className={`${classes.actionMenu} ${!!anchorElUser && classes.actionMenuOpen}`}
                onClick={openUserMenu}
            >
                <AvatarIcon />
            </IconButton>
            {viewMode === 'Desktop' ? (
                <ProfileMenuDesktop anchorEl={anchorElUser} onClose={closeUserMenu} />
            ) : (
                <ProfileMenu anchorEl={anchorElUser} onClose={closeUserMenu} />
            )}
        </>
    );
}
