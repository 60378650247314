import { AuthApiService } from '@revenuewell/logic-http';
import { OidcService } from '@revenuewell/logic-oidc';
import { NavigationService } from '@revenuewell/logic-navigation';

export default class FeatureFlagService extends AuthApiService {
    private static instance: FeatureFlagService;
    private config: any;
    private featureFlagsUrl: any;

    constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.featureFlagsUrl = config.featureFlags.apiUrl;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!FeatureFlagService.instance) FeatureFlagService.instance = new FeatureFlagService(config, oidcService);

        return FeatureFlagService.instance;
    }

    getFeatureFlags = async (locationId: number): Promise<string[]> => {
        const url = `${this.featureFlagsUrl}/locations/${locationId}/feature-flags`;
        const response = await this.authFetch(url, { method: 'GET' });
        return response.json();
    };
}
