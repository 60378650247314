import { format } from 'date-fns';
import { AlertContainer, AlertContent, AlertMessage, IconBox } from './alert';
import AlertHeaderParent from './alert-header';
import AlertLocation from './alert-location';
import { styled } from '@mui/material';
import { useHistory } from '../../../hooks/use-history-intercept';
import { Avatar } from '@revenuewell/front-end-bundle';

const AlertIconBox = styled(IconBox)({
    backgroundColor: '#fff',
    '& .avatar': {
        width: '32px !important',
        height: '32px !important',
        backgroundSize: '32px !important'
    }
});

const AlertHeader = styled(AlertHeaderParent)({
    '&:hover .avatar': {
        borderRadius: '50%',
        border: '2px solid #0675DD'
    },
    '&:hover .alert-title': {
        color: '#0675DD !important'
    }
});

interface FormAlertProps {
    date: Date;
    appointmentDate: Date;
    name: string;
    message?: string | null;
    location?: string;
    locationId?: number;
    patientId?: string | null;
    onRemove?: () => void;
}

export default function AppointmentRequestResponseAlert({
    date,
    name,
    location,
    locationId,
    patientId,
    message,
    appointmentDate,
    onRemove
}: FormAlertProps) {
    const history = useHistory();

    return (
        <AlertContainer
            onClick={() => history.push(`/messenger/start-conversation/locations/${locationId}/patients/${patientId}`)}
        >
            <AlertHeader
                icon={
                    <AlertIconBox className="alert-icon-box">
                        <Avatar />
                    </AlertIconBox>
                }
                title={name}
                date={format(date, 'hh:mm a')}
                onClose={onRemove}
            />
            <AlertContent>
                {name} to be contacted about his/her family's appointments on{' '}
                {format(appointmentDate, "M/dd/yyyy 'at' hh:mm a")}.
            </AlertContent>
            {message && <AlertMessage>{message}</AlertMessage>}
            {!!location && <AlertLocation>{location}</AlertLocation>}
        </AlertContainer>
    );
}
