import React from "react";
import { useContext } from "react"
import { PatientProfileWidgetContext } from "./context";

export const usePatientProfileWidget = () => {
    const context = useContext(PatientProfileWidgetContext);

    if (!context)    
      throw new Error('usePatientProfileWidget must be used within a PatientProfileWidgetProvider');
    
    return context;
}
