import { CallDirection, CallType } from '../../../../services/inbox-service/types';
import InboxCallPreviewContent from './inbox-call-preview-content';
import { Avatar } from '@revenuewell/front-end-bundle';
import { ReactComponent as AutocommIcon } from '../../../../assets/rw-automation.svg';
import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import moment from 'moment';
import {
    InboxPreviewContainer,
    InboxPreviewGrid,
    InboxPreviewHeaderGrid,
    InboxPreviewAvatar,
    InboxPreviewTitle,
    InboxPreviewTimestamp,
    InboxPreviewContent,
    UnreadIndicator,
    StarredIndicator,
    ArchivedIndicator,
    InboxPreviewAutocommContent
} from './styles/inbox-preview-card-styles';

interface InboxPreviewProps {
    contact: string;
    isNewPatient: boolean;
    isUnread: boolean;
    isSaved: boolean;
    isArchived: boolean;
    timestamp: string;
    message?: string;
    direction?: CallDirection;
    callType?: CallType;
    communicationId?: string;
    onPreviewClick?: () => void;
}

const InboxPreviewCard = ({
    contact,
    isUnread,
    isSaved,
    isArchived,
    timestamp,
    message,
    direction,
    callType,
    communicationId,
    onPreviewClick
}: InboxPreviewProps) => {
    const isCallPreview = !!direction && !!callType;

    const content = (
        <InboxPreviewContainer onClick={onPreviewClick}>
            <InboxPreviewGrid>
                <InboxPreviewAvatar className="inbox-icon-box"> <Avatar /> </InboxPreviewAvatar>
                <InboxPreviewHeaderGrid>
                    <InboxPreviewTitle> {contact} </InboxPreviewTitle>
                    <InboxPreviewTimestamp isUnread={isUnread}>
                        {isArchived && <ArchivedIndicator />}
                        {isSaved && <StarredIndicator />}
                        {isUnread && <UnreadIndicator />}
                        {moment(timestamp).format('LT')}
                    </InboxPreviewTimestamp>
                </InboxPreviewHeaderGrid>
                {isCallPreview
                    ? (
                        <InboxCallPreviewContent
                            direction={direction!}
                            callType={callType!}
                        />
                    ) : (
                        !communicationId // TODO add check 'P' text
                            ? <InboxPreviewContent>{message}</InboxPreviewContent>
                            : <InboxPreviewAutocommContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <AutocommIcon width='16px' height='16px' />
                                    <Typography variant='body2' color='#2C3542'>
                                        Appt Confirmation
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', color: '#6B4904' }}>
                                    <InboxPreviewAvatar sx={{ backgroundColor: '#FFF0CA', width: 24, height: 16 }}>
                                        <WarningIcon sx={{ fontSize: '10px' }} />
                                    </InboxPreviewAvatar>
                                    <Typography variant='caption' sx={{ color: '#6B4904' }}>
                                        {message?.trim().toLowerCase() === 'p' ? "Call Requested" : "Unrecognized Response"}
                                    </Typography>
                                </Box>
                            </InboxPreviewAutocommContent>
                    )
                }
            </InboxPreviewGrid>
        </InboxPreviewContainer>
    )
    return content;
};

export default InboxPreviewCard;
