import { Badge, BadgeProps, Box, IconButton, styled } from '@mui/material';
import { NotificationsActive as NotificationIcon } from '@mui/icons-material';
import { useAlerts } from '../../../../hooks/use-alerts';
import { makeStyles } from '@mui/styles';
import { useSlideout } from '../../../../hooks/use-slideout';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { useCallback } from 'react';

const useStyles = makeStyles({
    notificationContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '20px'
    },
    actionMenu: {
        width: 48,
        height: 48,
        margin: '11px 0 11px 0',
        backgroundColor: '#004763',
        color: '#fff',

        '&:hover': {
            backgroundColor: '#265D7C'
        },
        '&:focus-visible': {
            border: '2px solid #1EB9FB'
        }
    },
    actionMenuOpen: {
        backgroundColor: '#0675DD',
        '&:hover': {
            backgroundColor: '#0675DD'
        }
    }
});

const NotificationBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        marginTop: 6,
        marginRight: -6,
        backgroundColor: '#1EB9FB',
        color: '#032F40'
    }
}));

export default function AlertButton() {
    const classes = useStyles();
    const { isVisibleAlerts } = useSlideout();
    const { alertsActive } = useAlerts();
    const { publish } = useEventBus();

    const showAlerts = useCallback(() => {
      if(!isVisibleAlerts)
        publish({messageType: "showAlerts"})
    },[publish, isVisibleAlerts]);

    return (
        <Box className={classes.notificationContainer}>
            <NotificationBadge badgeContent={alertsActive.length} showZero={false} overlap='circular'>
                <IconButton
                    className={`${classes.actionMenu} ${isVisibleAlerts && classes.actionMenuOpen}`}
                    onClick={showAlerts}
                >
                    <NotificationIcon />
                </IconButton>
            </NotificationBadge>
        </Box>
    );
}
