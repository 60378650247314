import { Contact, ContactSearchByNumber } from './../services/api-client/contact-api';
import { getAge } from './date-time';

export function getFullContactNamesList(contacts?: ContactSearchByNumber[]) {
    let contactNameList = '';

    contacts?.forEach(contact => {
        if (!contactNameList) {
            contactNameList = contact.firstName + ' ' + contact.lastName;
        } else {
            contactNameList = contactNameList + ', ' + contact.firstName + ' ' + contact.lastName;
        }
    });

    return contactNameList;
}

export interface ContactWithSeparator {
    group: string;
    children: Contact[];
}

export function getContactsWithSeparator(contacts: Contact[]) {
    const data = contacts.reduce((r: any, contact) => {
        // get first letter of lastName of current element
        const group = ((contact.lastName || '')[0] || ' ').toUpperCase();
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [contact] };
        // if there is push current element to children array for that letter
        else r[group].children.push(contact);
        // return accumulator
        return r;
    }, {});

    // since data at this point is an object, to get array of values
    // using Object.values method
    const result: ContactWithSeparator[] = Object.values(data);

    return result;
}

export function formatUSPhoneNumber(entry: string | undefined): string {
    if (entry?.length) {
        const regexMatch = entry?.replace(/\D+/g, '').match(/([^\d]*\d[^\d]*){1,}$/);
        const match = regexMatch?.length ? regexMatch[0] : '';

        if (match?.length == 11 && match[0] === '1') {
            const intlCode = '+1 ';
            const part1 = match.length > 2 ? `(${match.substring(1, 4)})` : match;
            const part2 = match.length > 3 ? ` ${match.substring(4, 7)}` : '';
            const part3 = match.length > 6 ? `-${match.substring(7, 11)}` : '';

            return `${intlCode}${part1}${part2}${part3}`;
        } else if(match?.length === 10) {
            const part1 = match?.length > 2 ? `(${match.substring(0, 3)})` : match;
            const part2 = match?.length > 3 ? ` ${match.substring(3, 6)}` : '';
            const part3 = match?.length > 6 ? `-${match.substring(6, 10)}` : '';
            const part4 = match?.length > 10 ? ` ${match.substring(10, match.length)}` : '';

            return `${part1}${part2}${part3}${part4}`;
        } else {
            return match;
        }
    }
    return '';
}

export function normaliseUSPhoneNumber(phoneNumberString: string | undefined) {
    if (phoneNumberString) {
        if (phoneNumberString?.length > 1 && phoneNumberString.startsWith('+1')) {
            phoneNumberString = phoneNumberString.substring(2);
        }

        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

        return cleaned;
    }

    return '';
}

export const formatToHoursMinutesSeconds = (duration: number) => {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
        ret += '' + String(hrs).padStart(2, '0') + ':';
    }

    ret += '' + String(mins || '').padStart(2, '0') + ':';
    ret += '' + String(secs || '').padStart(2, '0');
    return ret;
};

export function getFullName(contact: ContactSearchByNumber) {
    return contact.firstName + ' ' + contact.lastName;
}

export function getFullNameForGroup(contacts: ContactSearchByNumber[]) {
    return getFullName(contacts[0]) + (contacts.length > 1 ? ' + Others' : '');
}

export function getContactAge(contact: ContactSearchByNumber) {
    if (contact.dateOfBirth !== '') {
        return getAge(new Date(contact.dateOfBirth as string));
    }
    return '';
}

export function getFullNameAndAge(contact: ContactSearchByNumber | undefined) {
    if (contact) {
        return getFullName(contact) + ', ' + getContactAge(contact);
    }
    return '';
}

export function snoozeDateFormatter(snoozeExpiration?: number): string {
    if (!snoozeExpiration) {
        return '';
    }

    const dateObj = new Date(snoozeExpiration);

    const isAmPm = dateObj.getHours() > 12;
    const hours = isAmPm ? dateObj.getHours() - 12 : dateObj.getHours();

    return `${hours}:${dateObj.getMinutes()} ${isAmPm ? 'PM' : 'AM'}`;
}
