import { styled } from '@mui/styles';
import { Box } from '@mui/material';
import DomainIcon from '@mui/icons-material/Domain';

const AlertLocationContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    marginLeft: 40,
    marginTop: 8,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    color: '#8D97A4',
    letterSpacing: '0.4px'
})

const Domain = styled(DomainIcon)({
    width: 13,
    marginRight: 4
})

interface AlertLocationProps {
    children: React.ReactNode;
}

export default function AlertLocation ({ children }: AlertLocationProps) {
    return (
        <AlertLocationContainer>
            <Domain />{children}
        </AlertLocationContainer>
    )
} 