import { List, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SearchCard, { SearchCardLoading } from './search-card';
import { useEventBus } from '../../../hooks/use-event-bus';
import { EventJumpToMessage, EventMessengerWidget, EventPatientProfile } from '../../../types/event-bus';
import { useProduct, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import SearchEmpty from './search-empty';
import { useSearch } from '../../../hooks/use-search/use-search';
import SearchLoadMore from './search-load-more';
import { useViewMode } from '../../../hooks/use-view-mode';

interface Props {
    open: boolean;
    anchorEl: Element | null;
    query: string;
    onClose: () => void;
}

const useStyles = () => {
    const { viewMode } = useViewMode();
    const isTablet = viewMode === 'Tablet';
    const isPhone = viewMode === 'Phone';

    const useStyles = useMemo(
        () =>
            makeStyles({
                root: {
                    position: 'relative',
                    pointerEvents: 'all',
                    background: 'white',
                    borderRadius: '8px',
                    width: 'calc(40% - 20px)',
                    marginTop: 8,
                    ...(isTablet && {
                        width: 'calc(100% - 102px)'
                    }),
                    ...(isPhone && {
                        width: '100%',
                        maxWidth: '100%',
                        height: 'calc(100% - 56px)',
                        borderRadius: 0,
                        marginTop: 0,
                        top: '56px !important',
                        right: '0 !important',
                        left: '0 !important',
                        bottom: '0 !important'
                    })
                },
                backdrop: {
                    pointerEvents: 'none'
                },
                list: {
                    maxHeight: 'calc(100vh - 100px)',
                    overflow: 'auto',
                    ...(isPhone && {
                        maxHeight: '100%',
                        height: '100%'
                    })
                }
            }),
        [isTablet, isPhone]
    );

    return useStyles();
};

export default function SearchResults({ query, open, anchorEl, onClose }: Props) {
    const classes = useStyles();
    const { isLoading, setSearch, data, hasMore, loadMore, query: querySearch } = useSearch();
    const { publish } = useEventBus();
    const [position, setPosition] = useState(-1);
    const [locationId, setLocationId] = useState<number>(null!);
    const { oidcService } = useOidcWithAdmin();
    const { hasProduct } = useProduct();

    const { viewMode } = useViewMode();
    const isPhone = viewMode === 'Phone';

    const [isExpanded, setIsExpanded] = useState(isPhone);

    const handleLoadMore = useCallback(() => {
        setIsExpanded(true);
        loadMore();
    }, [setIsExpanded, loadMore]);

    useEffect(() => {
        setIsExpanded(isPhone);
    }, [isPhone]);

    const patients = useMemo(() => {
        if (isExpanded) return data;
        return data.slice(0, 5);
    }, [data]);

    useEffect(() => {
        (async () => {
            const claims = await oidcService.getClaims();
            const acctIdRes = claims.masterAccountId;
            const tokenRes = await oidcService.getAccessToken();
            if (!(acctIdRes && tokenRes)) return;
            setLocationId(parseInt(claims.locationId + ''));
        })();
    }, [oidcService]);

    useEffect(() => {
        setIsExpanded(isPhone);
        setSearch(query);
    }, [query, setSearch]);

    const handlePatientClick = (patientId: number, locationId: number) => {
        setTimeout(() => {
            setPosition(-1);
            publish({
                messageType: 'patientProfile',
                data: {
                    patientId,
                    locationId
                } as EventPatientProfile['data']
            });
            onClose();
        }, 1000);
    };

    const handleMessageClick = (patientId: number, phoneNumber: string) => {
        setTimeout(() => {
            setPosition(-1);
            if (hasProduct('messenger')) {
                publish({
                    messageType: 'jumpToMessage',
                    data: {
                        patientId
                    } as EventJumpToMessage['data']
                });
            } else {
                publish({
                    messageType: 'messengerWidget',
                    data: {
                        phoneNumber,
                        locationId
                    } as EventMessengerWidget['data']
                });
            }
            onClose();
        }, 1000);
    };

    const handlePhoneClick = (phoneNumber: string) => {
        setPosition(-1);
        publish({
            messageType: 'startCall',
            data: { phoneNumber: phoneNumber }
        });
        onClose();
    };

    const handleScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            if (hasMore && isExpanded) {
                const target = e.target as HTMLDivElement;
                if (target.scrollTop + target.clientHeight >= target.scrollHeight - 300) {
                    loadMore();
                }
            }
        },
        [loadMore, isExpanded, hasMore]
    );

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
        switch (e.code) {
            case 'ArrowDown':
                if (patients.length - 1 === position) return;
                setPosition(pos => pos + 1);
                break;
            case 'ArrowUp':
                if (position === 0) return;
                setPosition(pos => pos - 1);
                break;
            case 'Enter':
                handlePatientClick(patients[position].id, patients[position].location.id);
                break;
        }
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            hideBackdrop={true}
            disableAutoFocus={true}
            disablePortal={true}
            disableEscapeKeyDown={false}
            disableEnforceFocus={true}
            disableScrollLock={true}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            classes={{
                paper: classes.root,
                root: classes.backdrop
            }}
        >
            <List component='div' onScroll={handleScroll} className={classes.list}>
                {(!isLoading || isExpanded) &&
                    patients.map((patient, i) => (
                        <SearchCard
                            key={patient.id}
                            patient={{
                                id: patient.id,
                                name: `${patient.firstName} ${patient.lastName}`,
                                locationId: patient.location.id,
                                birthDate: patient.dateOfBirth,
                                phone: patient.cellPhone || patient.homePhone
                            }}
                            onPatientClick={handlePatientClick}
                            onPhoneClick={handlePhoneClick}
                            onMessageClick={handleMessageClick}
                            disabledMessenger={!patient.cellPhone}
                            tabIndex={i}
                            onKeyDown={handleKeyDown}
                            focused={i === position}
                        />
                    ))}

                {(isLoading || querySearch !== query) && (
                    <>
                        <SearchCardLoading />
                        <SearchCardLoading />
                        <SearchCardLoading />
                        {!isExpanded && (
                            <>
                                <SearchCardLoading />
                                <SearchCardLoading />
                            </>
                        )}
                    </>
                )}

                {!isLoading && !patients.length && querySearch === query && <SearchEmpty />}
                {!isExpanded && !isLoading && !!patients.length && <SearchLoadMore onClick={handleLoadMore} />}
            </List>
        </Popover>
    );
}
