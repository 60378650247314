import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";

export const getCustomizedDMCount = (patientPreferences: PatientPreferencesResponse) => {
  let count = 0;
  patientPreferences.dmAppointmentReminders !== false ? count++ : null;
  patientPreferences.dmBirthdayAndHolidayCards !== false ? count++ : null;
  patientPreferences.dmExpiringInsuranceReminder !== false ? count++ : null;
  patientPreferences.dmPatientReactivation !== false ? count++ : null;
  patientPreferences.dmRecalls !== false ? count++ : null;
  patientPreferences.dmSpecialsAndNewsletters !== false ? count++ : null;
  patientPreferences.dmTreatmentPlanFollowUps !== false ? count++ : null;
  return count;
};