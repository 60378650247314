import { FC, useMemo } from "react";
import { NavigationBreadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../header/header";
import { Autocomplete, TextField, AccordionDetails, Box, Paper } from '@mui/material';
import AccordionSectionType from "../../../../../shared/accordion/accordion-section-type";
import AccordionSection from "../../../../../shared/accordion/accordion-section";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import { useStyles } from "./comm-preferences.styles";
import { ContactApi, PatientLanguage } from "../../../../../../services/api-client/contact-api";
import { useConfig, useOidcWithAdmin } from "@revenuewell/front-end-bundle";
import CampaignCommunicationStatus from "./shared/campaign-communication-status/campaign-communication-status";
import { getEmailStatus } from "./utils/get-email-status";
import { getCustomizedEmailCount } from "./utils/get-customized-email-count";
import { getDefaultFamilyMember } from "./utils/get-default-family-member";
import { getCustomizedAppointmentCount } from "./utils/get-customized-appointment-count";
import { getTextStatus } from "./utils/get-text-status";
import { getCustomizedTextCount } from "./utils/get-customized-text-count";
import { CommunicationStatus } from "./types/communication-status";
import { isAddressEmpty } from "./utils/is-address-empty";
import { getDMStatus } from "./utils/get-dm-status";
import { getCustomizedDMCount } from "./utils/get-customized-dm-count";
import { getPhoneStatus } from "./utils/get-phone-status";
import { getCustomizedPhoneCount } from "./utils/get-customized-phone-count";
import { WidgetRoutePath } from "../../navigation/route-config";
import { ViewContent } from "../shared/view-content/view-content";

export const CommPreferencesView: FC = () => {
  const classes = useStyles();
  const { config } = useConfig();
  const { oidcService } = useOidcWithAdmin();
  const { goTo, patientPreferences, patientInfo, refreshPatientPreferences } = usePatientProfileWidget();
  const contactApi = useMemo(() => new ContactApi(config, oidcService), [config, oidcService]);
  const langOptions: PatientLanguage[] = [PatientLanguage.English, PatientLanguage.Spanish];

  const patientAddress = () => {
    const patientIndex = patientInfo.familyMembers?.findIndex(familyMember => familyMember.id === patientInfo.id);
    return patientIndex !== -1 ? patientInfo.familyMembers![patientIndex!].address : undefined;
  };

  const updateFamilyOptions = async (familyMemberId: number) => {
    await contactApi.patchPatientPreferences(patientInfo.id, {
      ...patientPreferences,
      familyCommunicationReceiverId: familyMemberId
    });
    await refreshPatientPreferences();
  };

  const updateLanguageOptions = async (language: PatientLanguage) => {
    await contactApi.patchPatientPreferences(patientInfo.id, {
      ...patientPreferences,
      language: language
    });
    await refreshPatientPreferences();
  };

  const DropDownPaper = (props: React.HTMLAttributes<HTMLElement>) => {
    return <Paper elevation={8} style={{ background: '#FFFFFF' }} {...props} />;
  };

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContent>
      <AccordionDetails className={classes.details}>
        <AccordionSectionType header='GENERAL'></AccordionSectionType>
        <AccordionSection
          showNavArrow={true}
          header='Appointment Reminders'
          onClick={() => goTo(WidgetRoutePath.COMM_PREFERENCES_REMINDERS)}
        >
          <label>{getCustomizedAppointmentCount(patientPreferences)} of 5 customized</label>
        </AccordionSection>
        {patientInfo.familyMembers && (
          <AccordionSection header='Family Messaging'>
            <Autocomplete
              className={classes.dropdown}
              autoComplete={false}
              disableClearable
              sx={{ WebkitTextFillColor: '#000000' }}
              PaperComponent={DropDownPaper}
              disablePortal
              defaultValue={getDefaultFamilyMember(patientPreferences, patientInfo.familyMembers)}
              size='small'
              id='family-options-dropdown'
              options={patientInfo.familyMembers}
              onChange={(item, value) => updateFamilyOptions(value.id)}
              getOptionLabel={option => `${option.firstName} ${option.lastName} ${option.isResponsibleParty ? ' (RP)' : ''}`}
              renderOption={(props, option) => (
                <Box component='li' {...props} sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}>
                  {`${option.firstName} ${option.lastName} ${option.isResponsibleParty ? ' (RP)' : ''
                    }`}
                </Box>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                  className={classes.dropDownText}
                  variant='outlined'
                />
              )}
            />
          </AccordionSection>
        )}
        <AccordionSection header='Preferred Language'>
          <Autocomplete
            className={classes.dropdown}
            autoComplete={false}
            disableClearable
            sx={{ WebkitTextFillColor: '#000000' }}
            PaperComponent={DropDownPaper}
            disablePortal
            defaultValue={patientPreferences.language}
            size='small'
            id='language-options-dropdown'
            options={langOptions}
            onChange={(item, value) => updateLanguageOptions(value)}
            getOptionLabel={option => option}
            renderOption={(props, option) => (
              <Box component='li' {...props} sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}>
                {option}
              </Box>
            )}
            renderInput={params => (
              <TextField {...params} className={classes.dropDownText} variant='outlined' />
            )}
          />
        </AccordionSection>
        <AccordionSectionType header='CAMPAIGN COMMUNICATIONS'></AccordionSectionType>
        <AccordionSection
          header='Email'
          showNavArrow={true}
          onClick={() => goTo(WidgetRoutePath.COMM_PREFERENCES_EMAIL)}
        >
          <CampaignCommunicationStatus
            status={getEmailStatus(patientPreferences)}
            label={`${getCustomizedEmailCount(patientPreferences)} of 9 active`}
          />
        </AccordionSection>
        <AccordionSection
          header='Text Message'
          showNavArrow={true}
          onClick={() => goTo(WidgetRoutePath.COMM_PREFERENCES_TEXT_MESSAGE)}
        >
          <CampaignCommunicationStatus
            status={getTextStatus(patientPreferences)}
            label={`${getCustomizedTextCount(patientPreferences)} of 6 active`}
          />
        </AccordionSection>
        <AccordionSection
          header='Direct Mail'
          disabled={isAddressEmpty(patientAddress())}
          showNavArrow={true}
          onClick={() => goTo(WidgetRoutePath.COMM_PREFERENCES_DIRECT_MAIL)}
        >
          <CampaignCommunicationStatus
            status={isAddressEmpty(patientAddress()) ? CommunicationStatus.none : getDMStatus(patientPreferences)}
            label={isAddressEmpty(patientAddress()) ? 'No Address Listed' : `${getCustomizedDMCount(patientPreferences)} of 7 active`}
          />
        </AccordionSection>
        <AccordionSection
          header='Phone Calls'
          showNavArrow={true}
          onClick={() => goTo(WidgetRoutePath.COMM_PREFERENCES_PHONE_CALL)}
        >
          <CampaignCommunicationStatus
            status={getPhoneStatus(patientPreferences)}
            label={`${getCustomizedPhoneCount(patientPreferences)} of 1 active`}
          />
        </AccordionSection>
      </AccordionDetails>
    </ViewContent>
  </>
}