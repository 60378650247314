import { Skeleton } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import {
    InboxPreviewContainer,
    InboxPreviewGrid,
    InboxPreviewHeaderGrid,
    InboxPreviewAvatar,
    InboxPreviewTitle,
    InboxPreviewTimestamp,
    InboxPreviewContent,
} from './styles/inbox-preview-card-styles';

function InboxPreviewCardLoading() {
    const theme = useTheme();

    return (
        <InboxPreviewContainer>
            <InboxPreviewGrid>
                <InboxPreviewAvatar className="inbox-icon-box">
                    <Skeleton animation='wave' variant='circular' width={theme.spacing(4)} height={theme.spacing(4)} />
                </InboxPreviewAvatar>
                <InboxPreviewHeaderGrid>
                    <InboxPreviewTitle>
                        <Skeleton animation="wave" width={120} height={16} />
                    </InboxPreviewTitle>
                    <InboxPreviewTimestamp isUnread>
                        <Skeleton animation='wave' width={40} height={12} />
                    </InboxPreviewTimestamp>
                </InboxPreviewHeaderGrid>
                <InboxPreviewContent>
                    <Skeleton animation="wave" width={200} height={12} />
                    <Skeleton animation="wave" width={200} height={12} />
                </InboxPreviewContent>
            </InboxPreviewGrid>
        </InboxPreviewContainer>
    );
}

export default InboxPreviewCardLoading;
