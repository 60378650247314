import { FC, useEffect, useState } from "react";
import { ViewContent } from "../shared/view-content/view-content";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import CommsFilterWidget from "../../../comm-filter-widget/comms-filter-widget";

export const InboxFiltersView: FC = () => {
  const { goBack } = usePatientProfileWidget();

  return <ViewContent>
    <CommsFilterWidget onFilterApplied={() => goBack()} />
  </ViewContent>
}
