import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";
import { CommunicationStatus } from "../types/communication-status";
import { getCustomizedEmailCount } from "./get-customized-email-count";

export const getEmailStatus = (patientPreferences: PatientPreferencesResponse) => {
  const count = getCustomizedEmailCount(patientPreferences);

  if (count === 0) return CommunicationStatus.none;
  if (count === 9) return CommunicationStatus.all;

  return CommunicationStatus.some;
};