import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useState } from 'react';
import { ReactComponent as SchedulerButtonSvg } from '../../../assets/menu/icon-scheduler.svg';
import SidebarItem from '../../shared/sidebar/sidebar-item';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';

export type SchedulerButtonProps = {
    to: string;
    isActive?: boolean;
    onClick?: () => void;
};

export default function SchedulerButton({ to, isActive, onClick }: SchedulerButtonProps) {
    const { config } = useConfig();
    const [hasUnread, setHasUnread] = useState<boolean>();
    const oidc = useOidcWithAdmin();
    const { activeFrame } = useWrapperNavigation();
    const [lastFrame, setLastFrame] = useState<string>();

    useEffect(() => {
        if (lastFrame && lastFrame != activeFrame) checkAlert();

        setLastFrame(activeFrame);
    }, [activeFrame]);

    useEffect(() => {
        checkAlert();
        const interval = setInterval(() => checkAlert(), 120 * 1000);
        return () => clearInterval(interval);
    }, []);

    const checkAlert = async () => {
        const claims = await oidc.getClaims();
        const url = config.scheduler.apiUrl! + '/api/locations/' + claims.locationId + '/new-requests-count';

        try {
            const response = await fetch(url);

            if (response.ok) {
                const appointmentRequestCount = await response.json();
                setHasUnread(appointmentRequestCount > 0);
            } else {
                console.error('Unable to check Scheduler Alerts');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <SidebarItem title='Scheduler' onClick={onClick} to={to} isActive={isActive} hasNotification={hasUnread}>
            <SchedulerButtonSvg />
        </SidebarItem>
    );
}
