import { useEffect, useMemo, useState } from 'react';
import { useHistory } from '../../../hooks/use-history-intercept';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';
import Sidebar from '../../shared/sidebar/sidebar';
import SidebarItem from '../../shared/sidebar/sidebar-item';
import SidebarItemWithSubMenu from '../../shared/sidebar/sidebar-item-with-sub-menu';
import { SubMenuType } from '../../shared/sidebar/sidebar-sub-menu';

import { ReactComponent as CoreButton } from '../../../assets/menu/icon-home.svg';
import { ReactComponent as PatientsButton } from '../../../assets/menu/icon-patients.svg';
import { ReactComponent as PhoneButton } from '../../../assets/menu/icon-voip.svg';
import { ReactComponent as MsgButton } from '../../../assets/menu/icon-messenger.svg';
import { ReactComponent as ReputationButton } from '../../../assets/menu/icon-thumb_up.svg';
import MessengerButton from './messenger-button';
import TeamChatButton from './teamchat-button';
import InsightsButton from './insights-button';
import { useProduct } from '@revenuewell/front-end-bundle';
import { ReactComponent as CampaignsButtons } from '../../../assets/menu/icon-campaign.svg';
import { ReactComponent as VirtualVisitsButton } from '../../../assets/menu/icon-vv.svg';
import { ReactComponent as EvaluateMyPracticeButton } from '../../../assets/menu/icon-evaluate-practice.svg';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import SchedulerButton from './scheduler-button';
import FormsButton from './forms-button'

export default function Menu({ onClick }: { onClick?: () => void }) {
    const history = useHistory();
    const { windowUrls } = useWrapperNavigation();
    const { hasProduct } = useProduct();
    const { getClaims } = useOidcWithAdmin();
    const hasMessenger2 = hasProduct('messenger2');
    const hasPhone2 = hasProduct('phone2');
    const hasPhone = hasProduct('phone') || hasProduct('phone1');
    const hasPatient2 = hasProduct('patients2');
    const hasPatient1 = hasProduct('patients1');
    const hasScheduler = hasProduct('scheduler');
    const hasCore = hasProduct('core');
    const hasReminders = hasProduct('reminders');
    const hasForms = hasProduct('forms');
    const hasReputationMamangement = hasProduct('reputationmanagement');
    const hasCampaigns2 = hasProduct('campaign');
    const hasForms2 = hasProduct('forms2');
    const hasVirtualVisits = hasProduct('virtualvisits');
    const hasEvaluateMyPractice = hasProduct('evaluatemypractice');
    const [locationId, setLocationId] = useState<number>();
    const hasTeamChat = hasProduct('teamchat');
    const hasInsights = hasProduct('insights');

    const activeFrame = useMemo(() => {
        const pathParts = history.location.pathname.split('/');
        if (pathParts.length > 1) return pathParts[1];
        else return '';
    }, [history.location]);

    useEffect(() => {
        (async () => {
            const claims = await getClaims();
            setLocationId(claims.locationId);
        })();
    }, []);

    return (
        <Sidebar>
            {(hasCore || hasReminders || hasForms || hasCampaigns2 || hasForms2) && (
                <SidebarItem title='Core' onClick={onClick} to={windowUrls.core} isActive={activeFrame === 'core'}>
                    <CoreButton />
                </SidebarItem>
            )}
            {hasScheduler && (
                <SchedulerButton onClick={onClick}
                    to={windowUrls.scheduler}
                    isActive={activeFrame === 'scheduler'} />
            )}
            {hasPatient2 && (
                <SidebarItem
                    title='Patients'
                    onClick={onClick}
                    to={windowUrls.contacts}
                    isActive={activeFrame === 'contacts'}
                >
                    <PatientsButton />
                </SidebarItem>
            )}
            {hasPatient1 && (
                <SidebarItem
                    title='Patients'
                    onClick={onClick}
                    to={windowUrls.contacts1}
                    isActive={activeFrame === 'contacts1'}
                >
                    <PatientsButton />
                </SidebarItem>
            )}
            {hasMessenger2 ? (
                <MessengerButton to={windowUrls.messenger} onClick={onClick} isActive={activeFrame === 'messenger'} />
            ) : (
                <SidebarItem
                    title='Messenger'
                    onClick={onClick}
                    to={windowUrls.messenger1}
                    isActive={activeFrame === 'messenger1'}
                >
                    <MsgButton />
                </SidebarItem>
            )}
            {hasPhone2 && (
                <SidebarItem title='Phone' onClick={onClick} to={windowUrls.phone} isActive={activeFrame === 'phone'}>
                    <PhoneButton />
                </SidebarItem>
            )}
            {hasPhone && (
                <SidebarItem title='Phone' onClick={onClick} to={windowUrls.phone1} isActive={activeFrame === 'phone1'}>
                    <PhoneButton />
                </SidebarItem>
            )}
            {hasReputationMamangement && (
                <SidebarItem
                    title='Reputation'
                    onClick={onClick}
                    to={windowUrls.reputation}
                    isActive={activeFrame === 'reputation'}
                >
                    <ReputationButton />
                </SidebarItem>
            )}
            {hasInsights && (
                <InsightsButton
                    onClick={onClick}
                    to={windowUrls.insights}
                    isActive={activeFrame === 'insights'} />
            )}
            {hasCampaigns2 && (
                <SidebarItem
                    title='Campaigns'
                    onClick={onClick}
                    to={windowUrls.campaigns}
                    isActive={activeFrame === 'campaigns'}
                >
                    <CampaignsButtons className="CampainButton" />
                </SidebarItem>
            )}
            {hasForms2 && (
                <FormsButton to={windowUrls.forms}  isActive={activeFrame === 'forms'} onClick={onClick}/>
            )}
            {hasVirtualVisits && (
                <SidebarItem
                    title='Virtual Visits'
                    onClick={onClick}
                    to={windowUrls.virtualvisits}
                    isActive={activeFrame === 'virtualvisits'}
                >
                    <VirtualVisitsButton />
                </SidebarItem>
            )}
            {hasEvaluateMyPractice && locationId && (
                <SidebarItem
                    title='Evaluate My Practice'
                    onClick={onClick}
                    to={windowUrls.evaluatemypractice + `/?locationId=${locationId}`}
                    isActive={activeFrame === 'evaluatemypractice'}
                >
                    <EvaluateMyPracticeButton />
                </SidebarItem>
            )}
            {hasTeamChat && (
                <TeamChatButton to={windowUrls.teamchat} onClick={onClick} isActive={activeFrame === 'teamchat'} />
            )}
            <SidebarItemWithSubMenu
                title='My Secure Practice'
                menuType={SubMenuType.MY_SECURE_PRACTICE}
                activeFrame={activeFrame}
                locationId={locationId}
            />
            <SidebarItemWithSubMenu
                title='PBHS Website'
                menuType={SubMenuType.PBHS_WEBSITE_ADMIN}
                activeFrame={activeFrame}
                locationId={locationId}
            />
        </Sidebar>
    );
}
