/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ResponseError } from '@revenuewell/front-end-bundle';

export interface KazooUserRaw {
    id: string;
    enabled: boolean;
    email: string;
    first_name: string;
    last_name: string;
}

export class KazooUserApi {
    constructor() {
        return this;
    }

    private formatApiErrorMessage = (url: RequestInfo, message: string): string => {
        return `Failed to fetch ${url} - ${message}`;
    };

    private tryParseResponseText = async (response: Response): Promise<string> => {
        return response
            .text()
            .then((body: any) => Promise.resolve(body.message ? body.message : body))
            .catch(() => Promise.reject());
    };

    private extractErrorFromResponse = async (response: Response): Promise<ResponseError> => {
        const result: ResponseError = {
            message: `Could not fetch ${response.url}. Status: ${response.status}`,
            status: response.status
        };

        try {
            const text = await this.tryParseResponseText(response);
            result.response = text;
        } catch (error) {
            result.response = 'Unable to parse response body.';
        }

        return Promise.resolve(result);
    };

    public fetchKazooApi = async (url: string, config: any): Promise<any> => {
        const resp = await fetch(url, config);

        if (!resp.ok) {
            const error = await this.extractErrorFromResponse(resp);

            switch (resp.status) {
                case 401:
                    error!.message = this.formatApiErrorMessage(url, 'authentication session is expired.');
                    break;
                case 403:
                    error!.message = this.formatApiErrorMessage(url, 'operation is forbidden.');
                    break;
                case 404:
                    error!.message = this.formatApiErrorMessage(url, 'cannot find');
                    return
                default:
                    error!.message = this.formatApiErrorMessage(url, 'internal server error.');
                    break;
            }

            return Promise.reject({ ...error });
        }

        const contentType = resp.headers.get('content-type');
        if (contentType && contentType !== 'application/json') {
            if (contentType.indexOf('pdf') > -1 || contentType.indexOf('tiff') > -1) {
                const fileName =
                    'fax.' + (contentType.indexOf('pdf') > -1 ? 'pdf' : contentType.indexOf('tiff') > -1 ? 'tiff' : '');

                // download file from blob
                const blob = await resp.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }

            return;
        }

        return resp.json();
    };
    
    public async fetchUsers(kazooApiUrl: string, kazooAccountId: string, kazooAuthToken: string) {
        const config = {
            headers: {
                'X-Auth-Token': kazooAuthToken
            }
        };

        const url = `${kazooApiUrl}/v2/accounts/${kazooAccountId}/users`;
        const resp = await this.fetchKazooApi(url, config);

        const userData = resp.data as KazooUserRaw[];
        console.debug('KAZOO USERS:', userData);

        return userData;
    }
}
