import { OidcService } from '@revenuewell/front-end-bundle';
import { AuthApiService } from '@revenuewell/front-end-bundle';
import { NavigationService } from '@revenuewell/front-end-bundle';
import { Message } from '../pubnub-service';
import { IMailboxWithLocation } from '../mailbox-service/mailbox-service';
import { Alert, PracticeOnboarding } from '../../types/insurance-v2';

export type InsuranceWebsiteV2 = {
    id: string
    url: string
    hasMfa: boolean
}

export type InsuranceConnectionV2 = {
    id: string,
    idWebsite: string
    website: InsuranceWebsiteV2
    username: string
    idConnectionStatus: number
    mfaPhoneNumber: string
}


export default class InsuranceServiceV2 extends AuthApiService {
    private static instance: InsuranceServiceV2;
    private config: any;
    private serviceUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;

        this.serviceUrl = config.insurance2.apiUrl;

        if (this.serviceUrl.includes('//') === false) {
            this.serviceUrl = `${window.location.origin}${this.serviceUrl}`;
        }
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!InsuranceServiceV2.instance)
            InsuranceServiceV2.instance = new InsuranceServiceV2(config, oidcService);

        return InsuranceServiceV2.instance;
    }

    public async getConnection(locationId: number, connectionId: string): Promise<InsuranceConnectionV2> {
        const url = new URL(`${this.serviceUrl}/practice/${locationId}/connection/${connectionId}`);
        const response = await this.authFetch(url.toString());
        const data: InsuranceConnectionV2 = await response.json()

        return data;
    }

    public async sendMfaCode(locationId: number, connectionId: string, mfaCode: string): Promise<void> {
        const url = new URL(`${this.serviceUrl}/practice/${locationId}/connection/${connectionId}/mfa-code`);
        const response = await this.authFetch(url.toString(), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({mfaCode: mfaCode})
        });
       
        return;
    }

    
    getAlerts = async (locationId: number): Promise<Alert[]> => {
        const url = `${this.serviceUrl}/practice/${locationId}/alerts`;
        const response = await this.authFetch(url);
        return response.json();
    }

    getPracticeOnboarding = async (locationId: number): Promise<PracticeOnboarding> => {
        const url = `${this.serviceUrl}/practice/${locationId}/onboarding`;
        const response = await this.authFetch(url);
        return response.json();
      }
    

}
