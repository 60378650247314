import { FC } from 'react';
import { Switch, Typography } from '@mui/material';
import { useStyles } from './all-promotional-sub-section.styles';

interface AllPromotionalSubSectionProps {
  text: string;
  isChecked: boolean;
  onChange: (value: boolean) => void;
}

const AllPromotionalSubSection: FC<AllPromotionalSubSectionProps> = (props) => {
  const { text, isChecked, onChange } = props;
  const classes = useStyles();

  return (
    <div className={classes.subSectionContainer}>
      <div className={classes.subSection}>
        <Typography variant='body2' className={classes.displayText} component='div'>
          {text}
        </Typography>
        <Switch
          className={classes.switch}
          checked={isChecked}
          onChange={(e, checked) => onChange(checked)}
        />
      </div>
    </div>
  );
};

export default AllPromotionalSubSection;
