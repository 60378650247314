import { useDesktop } from '@revenuewell/front-end-bundle';
import { useCallback, useEffect } from 'react';
import {sha256} from 'crypto-hash';

interface IRecentNotification {
    hash: string;
    timestamp: number;
}

export const useNotification = () => {
    // legacy apps based on messenger.frontend codebase send multiple notifications
    // in wrapper because all of them rendered in wrapper at once.
    // prevent diplaying the same notification for the 12 sec.
    const cleanupInterval = 6000;
    let recentNotifications: IRecentNotification[] = [];

    const { isDesktopApplication, showMainWindow } = useDesktop();

    useEffect(() => {
        const interval = setInterval(() => cleanRecentNotifications(), cleanupInterval);
        return () => clearInterval(interval);
    }, []);

    const isPermissionGranted = useCallback(() => {
        return !!(window.Notification?.permission === 'granted');
    }, []);

    const isPermissionDefault = useCallback(() => {
        return !!(window.Notification?.permission === 'default');
    }, []);

    const requestPermission = useCallback(() => {
        window.Notification?.requestPermission();
    }, []);

    const showNotification = useCallback(async (title: string, options?: NotificationOptions, onclick?: () => void) => {
        const hash = await sha256(title + options?.body ?? '');

        if(!isNotificationReceivedRecently(hash)) {
            const notification = new Notification(title, options);
            if (onclick) {
                notification.onclick = () => {
                    notification.close();
                    onclick();
                    if (isDesktopApplication()) showMainWindow();
                };
            }
            recentNotifications.push({ hash, timestamp: new Date().getTime()});
        }
    }, []);

    const isNotificationReceivedRecently = (hash: string) => {
        return recentNotifications.some(n => n.hash === hash);
    };

    const cleanRecentNotifications = () => {
        const currentTime = new Date().getTime();

        recentNotifications = recentNotifications.filter(n => {
            const entryTime = n.timestamp;
            const timeDifference = currentTime - entryTime;
            return timeDifference <= cleanupInterval;
        });
    };

    return {
        isPermissionGranted,
        isPermissionDefault,
        requestPermission,
        showNotification
    };
};
