import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Help as HelpIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { ReactComponent as RwLogoFull } from '../../../../assets/logo.svg';
import { makeStyles } from '@mui/styles';
import { ReactElement, useMemo, useRef, useState } from 'react';
import SearchBar from '../../../shared/search-bar/search-bar';
import SearchResults from '../../../shared/search-results/search-results';
import { useWrapperNavigation } from '../../../../hooks/use-wrapper-navigation';
import AlertButton from './alert-button';
import InboxButton from './inbox-button';
import UserButton from './user-button';
import { useFeatureFlag } from '@revenuewell/front-end-bundle';
import config from '../../../../config/config.json';
import TopNavMobile from './top-nav-mobile';
import TopNavTablet from './top-nav-tablet';
import { useViewMode } from '../../../../hooks/use-view-mode';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';
import SettingsButton from './settings-button';
import { InsuranceAlertsProvider } from '../../../../hooks/use-insurance-alerts/use-insurance-alerts';

export const useStyles = makeStyles({
    actionMenu: {
        width: 48,
        height: 48,
        margin: '11px 0 11px 0px',
        borderRadius: 12,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#265D7C'
        },
        '&:focus-visible': {
            border: '2px solid #1EB9FB'
        }
    },
    actionMenuGrey: {
        color: '#80979F'
    },
    actionMenuOpen: {
        backgroundColor: '#032F40',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#032F40'
        }
    }
});

export default function TopNav(): ReactElement {
    const { viewMode } = useViewMode();
    const isTablet = viewMode === 'Tablet';
    const isPhone = viewMode === 'Phone';
    const classes = useStyles();
    const { windowUrls, activeFrame } = useWrapperNavigation();
    const { publish } = useEventBus();

    const [query, setQuery] = useState('');
    const searchRef = useRef<HTMLDivElement>(null);

    const { hasFlag } = useFeatureFlag();
    const hasUniversalSearch = hasFlag('UniversalSearch');
    const hasAlerts = hasFlag('Alerts2');
    const hasUnifiedInbox = hasFlag('UnifiedInbox');

    const showResults = useMemo(() => !!query.length, [query]);

    if (isPhone) return <TopNavMobile />;
    if (isTablet) return <TopNavTablet />;

    return (
        <AppBar position='relative' sx={{ backgroundColor: 'nav.main', boxShadow: 'none' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box>
                    <RwLogoFull />
                </Box>
                <Box width={'40%'} display='flex'>
                    {hasUniversalSearch && (
                        <>
                            <SearchBar onChange={setQuery} value={query} ref={searchRef} />
                            <SearchResults
                                query={query}
                                open={showResults}
                                anchorEl={searchRef.current}
                                onClose={() => {
                                    setQuery('');
                                }}
                            />
                        </>
                    )}
                </Box>

                <Box display='flex' alignItems='center'>
                    {hasUnifiedInbox && <InboxButton />}
                    {hasAlerts && <AlertButton />}
                    <Box sx={{ width: '2px', height: '48px', backgroundColor: 'nav.dark' }} />
                    <SettingsButton/>
                    
                    <IconButton
                        className={`${classes.actionMenu} ${classes.actionMenuGrey}`}
                        onClick={() => {
                            publish({
                                messageType: 'navigate',
                                data: {
                                    pathname: config.support.url,
                                    crossFrame: true,
                                    replaceState: false
                                }
                            });
                        }}
                    >
                        <HelpIcon />
                    </IconButton>
                    <UserButton />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
