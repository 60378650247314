import { MainView } from '../views/main/main';
import { GeneralView } from '../views/general/general-info';
import { FC, ReactNode } from 'react';
import { CommPreferencesView } from '../views/comm-preferences/comm-preferences';
import { TextMessagePreferencesView } from '../views/comm-preferences/views/text-messages/text-message-preferences';
import { InboxView } from '../views/inbox/inbox';
import { VisitsView } from '../views/visits/visits';
import { BillingView } from '../views/billing/billing';
import { AlertsAndTasksView } from '../views/alerts-tasks/alerts-tasks';
import { ProfileSelectorView } from '../views/profile-selector/profile-selector';
import { EmailPreferencesView } from '../views/comm-preferences/views/email/email-preferences';
import { DirectMailPreferencesView } from '../views/comm-preferences/views/direct-mail/direct-mail-preferences';
import { PhoneCallPreferencesView } from '../views/comm-preferences/views/phone-call/phone-call-preferences';
import { AppointmentRemindersView } from '../views/comm-preferences/views/appointment-reminders/appointment-reminders';
import { InboxFiltersView } from '../views/inbox/inbox-filters';

export enum WidgetRoutePath {
  MAIN = '/',
  GENERAL = '/general',
  INBOX = '/inbox',
  INBOX_FILTERS = '/inbox/filters',
  COMM_PREFERENCES = '/comm-preferences',
  COMM_PREFERENCES_REMINDERS = '/comm-preferences/reminders',
  COMM_PREFERENCES_EMAIL = '/comm-preferences/email',
  COMM_PREFERENCES_TEXT_MESSAGE = '/comm-preferences/text-messages',
  COMM_PREFERENCES_DIRECT_MAIL = '/comm-preferences/direct-mail',
  COMM_PREFERENCES_PHONE_CALL = '/comm-preferences/phone-call',
  VISITS = '/visits',
  BILLING = '/billing',
  ALERTS_TASKS = '/alerts-tasks',
  PROFILE_SELECTOR = '/profile-selector',
}

export type RouteConfigItem = { path: WidgetRoutePath, name: string, component: FC };

export const RouteConfig: RouteConfigItem[] = [
  { path: WidgetRoutePath.MAIN, component: MainView, name: 'Profile' },
  { path: WidgetRoutePath.GENERAL, component: GeneralView, name: 'General' },
  { path: WidgetRoutePath.INBOX, component: InboxView, name: 'Inbox'},
  { path: WidgetRoutePath.INBOX_FILTERS, component: InboxFiltersView, name: 'Inbox Filters'},
  { path: WidgetRoutePath.COMM_PREFERENCES, component: CommPreferencesView, name: 'Communication Preferences' },
  { path: WidgetRoutePath.COMM_PREFERENCES_REMINDERS, component: AppointmentRemindersView, name: 'Appointment Reminders' },
  { path: WidgetRoutePath.COMM_PREFERENCES_EMAIL, component: EmailPreferencesView, name: 'Email' },
  { path: WidgetRoutePath.COMM_PREFERENCES_TEXT_MESSAGE, component: TextMessagePreferencesView, name: 'Text Message' },
  { path: WidgetRoutePath.COMM_PREFERENCES_DIRECT_MAIL, component: DirectMailPreferencesView, name: 'Direct Mail' },
  { path: WidgetRoutePath.COMM_PREFERENCES_PHONE_CALL, component: PhoneCallPreferencesView, name: 'Phone Calls' },
  { path: WidgetRoutePath.VISITS, component: VisitsView, name: 'Visits'},
  { path: WidgetRoutePath.BILLING, component: BillingView, name: 'Billing'},
  { path: WidgetRoutePath.ALERTS_TASKS, component: AlertsAndTasksView, name: 'Alerts & Tasks'},
  { path: WidgetRoutePath.PROFILE_SELECTOR, component: ProfileSelectorView, name: 'Select Profile'},
]