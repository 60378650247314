import { createContext, PropsWithChildren, useState, useEffect, useCallback, useMemo } from 'react';
import { EventBus, EventInboxCommunicationsWidget } from '../../types/event-bus';
import { useEventBus } from '../use-event-bus';
import { useProduct } from '@revenuewell/logic-product';
import { useWrapperNavigation } from '../use-wrapper-navigation';
import { useFeatureFlag } from '@revenuewell/front-end-bundle';

interface SlideoutContextProps {
    isVisiblePatient: boolean;
    isVisibleMessenger: boolean;
    isVisibleAlerts: boolean;
    isVisibleInboxPreview: boolean;
    isVisibleInboxPreviewFilter: boolean;
    isVisibleCommunications: boolean;
    isVisibleCommunicationsFilter: boolean;
    isVisibleDialpad: boolean;
    isVisibleStartSms: boolean;
    isVisibleVoicemail: boolean;
    isVisisbleSelectPatient: boolean;
    isVisibleMfaCodes: boolean;
    back: () => void;
    close: () => void;
    currentEvent?: EventBus;
    hasHistory: boolean;
}

export const SlideoutContext = createContext<SlideoutContextProps>(null!);

export function SlideoutProvider({ children }: PropsWithChildren<{}>) {
    const { listen } = useEventBus();
    const [eventHistory, setEventHistory] = useState<EventBus[]>([]);
    // eslint-disable-next-line
    const [slideoutActive, setSlideoutActive] = useState(false);
    const { hasProduct } = useProduct();
    const { navigate } = useWrapperNavigation();
    const { publish } = useEventBus();
    const { hasFlag } = useFeatureFlag();

    const close = useCallback(() => {
        setEventHistory([]);
        setSlideoutActive(false);
    }, []);

    const back = useCallback(() => {
        setEventHistory(eventHistory => {
            const newEventHistory = [...eventHistory];
            if (eventHistory.length) newEventHistory.pop();
            return newEventHistory;
        });
    }, []);

    useEffect(() => {
        const updateHistory = (event: EventBus) => {
            setEventHistory(eventHistory => {
                let newEventHistory = [...eventHistory];
                if (eventHistory.length > 10) {
                    newEventHistory = eventHistory.slice(1);
                }
                return [...newEventHistory, event];
            });
        };

        const listener = listen('patientProfile', event => {
            updateHistory(event);
        });

        const listener2 = listen('messengerWidget', (event: any) => {
            console.log('** -- MESSENGER_WIDGET:', event);
            if (hasFlag('UnifiedInbox')) {
                publish({
                    messageType: 'inboxCommunications',
                    data: {
                        phoneNumber: event.data?.phoneNumber,
                        locationId: event.data?.locationId,
                        timestamp: event.data?.timestamp,
                        patientId: event.data?.patientId
                    } as EventInboxCommunicationsWidget['data']
                });
            } else if (hasProduct('messenger2')) updateHistory(event);
        });

        const listener3 = listen('jumpToMessage', (event: any) => {
            console.log('** -- JUMP_TO_MESSAGE:', event);
            if (hasFlag('UnifiedInbox')) {
                publish({
                    messageType: 'inboxCommunications',
                    data: {
                        phoneNumber: event.data?.phoneNumber,
                        locationId: event.data?.locationId,
                        timestamp: event.data?.timestamp,
                        patientId: event.data?.patientId
                    } as EventInboxCommunicationsWidget['data']
                });
            } else if (hasProduct('messenger2')) {
                updateHistory(event);
            } else if (hasProduct('messenger')) {
                if (event.data?.patientId)
                    navigate('messenger1', `/messenger1-frame/#/patient/${event.data.patientId}?view=default`);
                else if (event.data?.conversationId)
                    publish({
                        messageType: 'navigate',
                        data: {
                            pathname: `/messenger1-frame/#/conversation/${event.data.conversationId}`,
                            crossFrame: true,
                            replaceState: false
                        }
                    });
            }
        });

        const listener4 = listen('showAlerts', event => {
            updateHistory(event);
        });

        const listener5 = listen('inboxPreview', event => {
            updateHistory(event);
        });

        const listener6 = listen('inboxPreviewFilter', event => {
            updateHistory(event);
        });

        const listener7 = listen('inboxCommunications', event => {
            updateHistory(event);
        });

        const listener8 = listen('inboxCommunicationsFilter', event => {
            updateHistory(event);
        });

        const listener9 = listen('dialpadWidget', event => {
            updateHistory(event);
        });

        const listener10 = listen('closeWidget', () => {
            close();
        });

        const listener11 = listen('startSmsWidget', event => {
            updateHistory(event);
        });

        const listener12 = listen('voicemailWidget', event => {
            updateHistory(event);
        });

        const listener13 = listen('selectPatient', event => {
            updateHistory(event);
        });

        const listener14 = listen('mfaCodesWidget', event => {
            updateHistory(event);
        });

        return () => {
            listener();
            listener2();
            listener3();
            listener4();
            listener5();
            listener6();
            listener7();
            listener8();
            listener9();
            listener10();
            listener11();
            listener12();
            listener13();
            listener14();
        };
    }, [listen]);

    const currentEvent = useMemo(() => {
        return eventHistory.length ? eventHistory[eventHistory.length - 1] : undefined;
    }, [eventHistory]);

    const active = !!currentEvent;

    const isVisiblePatient = active && currentEvent.messageType == 'patientProfile';
    const isVisibleMessenger =
        active && (currentEvent.messageType == 'messengerWidget' || currentEvent.messageType == 'jumpToMessage');
    const isVisibleAlerts = active && currentEvent.messageType == 'showAlerts';
    const isVisibleInboxPreview = active && currentEvent.messageType == 'inboxPreview';
    const isVisibleInboxPreviewFilter = active && currentEvent.messageType == 'inboxPreviewFilter';
    const isVisibleCommunications = active && currentEvent.messageType == 'inboxCommunications';
    const isVisibleCommunicationsFilter = active && currentEvent.messageType == 'inboxCommunicationsFilter';
    const isVisibleDialpad = active && currentEvent.messageType == 'dialpadWidget';
    const isVisibleStartSms = active && currentEvent.messageType == 'startSmsWidget';
    const isVisibleVoicemail = active && currentEvent.messageType == 'voicemailWidget';
    const isVisisbleSelectPatient = active && currentEvent.messageType == 'selectPatient';
    const isVisibleMfaCodes = active && currentEvent.messageType == 'mfaCodesWidget';

    const hasHistory = eventHistory.length > 1;

    return (
        <SlideoutContext.Provider
            value={{
                hasHistory,
                isVisibleAlerts,
                isVisiblePatient,
                isVisibleMessenger,
                isVisibleInboxPreview,
                isVisibleInboxPreviewFilter,
                isVisibleCommunications,
                isVisibleCommunicationsFilter,
                isVisibleDialpad,
                isVisibleStartSms,
                isVisibleVoicemail,
                isVisisbleSelectPatient,
                isVisibleMfaCodes,
                back,
                close,
                currentEvent
            }}
        >
            {children}
        </SlideoutContext.Provider>
    );
}
