import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { createContext, FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Location, VoiceApi } from '../../services/api-client/voice-api';
import UniversalPatientSearchService, {
    UniversalSearch
} from '../../services/universal-patient-search-service/universal-patient-search-service';
import debounce from 'lodash.debounce';
import { uniqBy } from 'lodash';
import { IContactProfile } from '../../services/api-client/interface';

interface ISearch {
    query: string;
    data: IContactProfile[];
    isLoading: boolean;
    hasMore: boolean;
    setSearch: (search: string) => void;
    loadMore: () => Promise<void>
}

const SearchContext = createContext<ISearch | null>(null);

export const useSearch = (): ISearch => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within an SearchContext');
    }
    return context;
};

export const SearchProvider: FC = ({ children }): ReactElement => {
    const [locations, setLocations] = useState<Location[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<IContactProfile[]>([]);
    const [query, _setQuery] = useState('');

    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    const voiceApiService = useMemo(() => {
        if (!config || !oidcService) return null;
        return VoiceApi.initialize(config, oidcService);
    }, [config, oidcService]);

    const universalSearchService = useMemo(() => {
        if (!config || !oidcService) return null;
        return UniversalPatientSearchService.getInstance(config, oidcService);
    }, [config, oidcService]);

    useEffect(() => {
        if (voiceApiService) {
            voiceApiService.getLocations().then(setLocations);
        }
    }, [voiceApiService]);

    // search in locations always reset page to 1 and set hasMore to true
    const search = useCallback(
        async (searchTerm: string, page = 1, pageSize = 30) => {
            if (locations.length) {
                const search: UniversalSearch = {
                    searchTerm,
                    page,
                    networks: locations.map(l => l.idNetwork),
                    preferredLocationIds: locations.map(l => l.id),
                    sort: 'LastActivity',
                    pageSize,
                    status: 'Active'
                };

                setLoading(true);
                setHasMore(false);

                try {
                    const response = await universalSearchService?.search(search);
                    setHasMore(!(response?.length === 0));

                    if (page === 1) {
                        setData(response || []);
                    } else {
                        setData(data => uniqBy([...data, ...(response || [])], 'id'));
                    }
                } catch {
                    //
                }
                setLoading(false);
            }
        },
        [locations, universalSearchService]
    );

    useEffect(() => {
        if (query) {
            setPage(1);
            search(query);
        }
    }, [query, search]);

    const setSearch = useMemo(
        () =>
            debounce(async (query: string) => {
                _setQuery(query);
            }, 500),
        []
    );

    const loadMore = useCallback(async () => {
        if (hasMore) {
            setPage(page => page + 1);
            await search(query, page + 1);
        }
    }, [page, query, hasMore])

    return (
        <SearchContext.Provider
            value={{
                query,
                data,
                isLoading,
                hasMore,
                setSearch,
                loadMore
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};
