import { stringify } from 'query-string';
import { AuthApiService, NavigationService, OidcService } from '@revenuewell/front-end-bundle';
import { orderBy } from 'lodash';
import { IContactProfile } from './interface';
import { dedupeContacts } from '../../utils/contact';

interface IContactSearchParams {
    pattern: string;
    pageSize?: string;
    pageNumber?: string;
    sortBy: 'firstName' | 'lastName';
    sortOrder: 'asc' | 'desc';
}

export class ContactApi extends AuthApiService {
    private static instance: ContactApi;
    private apiUrl: string;

    constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.apiUrl = config.contact.apiUrl;
        return this;
    }

    public static initialize(config: any, oidcService: OidcService) {
        if (!ContactApi.instance) ContactApi.instance = new ContactApi(config, oidcService);
        return ContactApi.instance;
    }

    public static getInstance() {
        if (!ContactApi.instance) throw new Error('VoiceApi should be initialized before first use.');
        return this.instance;
    }

    public async getContacts({
        searchText = '',
        limit,
        sortBy,
        pageNumber = 1,
        locationIds = ''
    }: ContactSearchParameters) {
        const result = await this.masterAccountGet<DataWrapper<Contact[]>>({
            pattern: searchText,
            sortBy,
            pageNumber,
            pageSize: limit,
            locationIds
        });
        return result;
    }

    public async searchContacts(params: IContactSearchParams): Promise<{ total: number, totalLoaded: number, data: IContactProfile[] }> {
        const { masterAccountId } = await this.oidcService.getClaims();
        let url = `${this.apiUrl}/master-accounts/${masterAccountId}/contacts?`;
        url += new URLSearchParams(params as any).toString();

        const response = await this.authFetch(url.toString());
        const responseJson = await response.json()
        const data = responseJson.data as IContactProfile[];

        const dedupedData = dedupeContacts(data).map(contact => ({
            ...contact,
            phoneNo: contact.cellPhone || contact.homePhone
        }));

        return {
            total: responseJson.total,
            totalLoaded: data.length,
            data: orderBy(dedupedData, ['isResponsibleParty', 'firstName', 'lastName'], ['desc', 'asc', 'asc'])
        }
    }

    private async masterAccountGet<T>(query: Record<string, string | number>) {
        const { masterAccountId } = await this.oidcService.getClaims();
        return await this.get<T>(`${this.apiUrl}/master-accounts/${masterAccountId}/contacts`, query);
    }

    private async get<T>(baseUrl: string, query: Record<string, string | number | string[]>) {
        const url = `${baseUrl}?${stringify(query)}`;
        const result = await this.authFetch(url);
        return result.json() as T;
    }

    private async masterAccountGetByPhoneNumbers<T>(query: Record<string, string | string[]>) {
        const { masterAccountId } = await this.oidcService.getClaims();
        return await this.get<T>(`${this.apiUrl}/master-accounts/${masterAccountId}/contacts-by-phones`, query);
    }

    public async getContactsByPhoneNumbers(phones: string | string[]) {
        const result = await this.masterAccountGetByPhoneNumbers<Record<string, ContactSearchByNumber[]>>({ phones });
        return result;
    }

    public async getIdenticalContacts(contactId: number) {
        const result = await this.authFetch(`${this.apiUrl}/contacts/${contactId}/identical-contacts`);
        return result.json() as ContactLocationInfo[];
    }

    public async getContactDetailsById(contactId: number) {
        const result = await this.authFetch(`${this.apiUrl}/contacts/${contactId}`);
        return result.json() as Contact;
    }

    public async getPatientInfoById(contactId: number) {
        const result = await this.authFetch(`${this.apiUrl}/contacts/${contactId}/patientInfo`);
        return result.json() as PatientInfo;
    }

    public async getPatientPreferences(contactId: number) {
        const result = await this.authFetch(`${this.apiUrl}/patient-preferences/${contactId}`);
        return result.json() as PatientPreferencesResponse;
    }

    public async patchPatientPreferences(contactId: number, patientPreferences: PatientPreferencesRequest): Promise<PatientPreferencesResponse> {
        const result = await this.authFetch(`${this.apiUrl}/patient-preferences/${contactId}`, {
            method: 'PATCH',
            body: JSON.stringify(patientPreferences),
            headers: { 'Content-Type': 'application/json' }
        });
        return result.json() as PatientPreferencesResponse;
    }
}

interface ContactSearchParameters {
    searchText?: string;
    pageNumber?: number;
    limit: number;
    sortBy: string;
    locationIds?: string;
}

export interface DataWrapper<T> {
    data: T;
    total: number;
}

export interface Contact {
    id: number;
    firstName: string;
    lastName: string;
    cellPhone?: string;
    homePhone?: string;
    workPhone?: string;
    email?: string;
    address: Address;
    dateOfBirth?: string;
    location?: Location;
    gender: string;
    isResponsibleParty?: boolean;
    networkId: number;
}

export interface ContactSearchByNumber {
    id: number;
    firstName: string;
    lastName: string;
    dateOfBirth?: string;
    locationId?: number;
    networkId: number;
    isResponsibleParty?: boolean;
    responsiblePartyId: number;
    cellPhone?: string;
    homePhone?: string;
    workPhone?: string;
}

export interface Address {
    address1: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface Location {
    id: number;
    name: string;
    address: Address;
}

export interface ContactLocationInfo {
    id: number;
    locationId: number;
    locationName?: string;
    networkId: number;
}

export interface PatientInfo {
    id: number;
    familyMembers?: Contact[];
    patientAlerts?: PatientAlert[];
    visits?: Visit[];
    nextAppointment: Visit;
    lastAppointment: Visit;
    recallDate?: string;
    insurance?: string;
    primBenefitsRemaining?: number;
    outstandingBalance30Days?: number;
    outstandingBalance60Days?: number;
    outstandingBalance90Days?: number;
    treatmentPlans?: TreatmentPlan[];
    communicationHistory?: Communication[];
    phoneNumbers: {
        cellPhone?: string,
        homePhone?: string,
        workPhone?: string
    }
}

export interface Visit {
    date: string;
    notes?: string;
    provider?: string;
    location: Location;
    status: AppointmentStatus[];
    amount: number;
}

export enum AppointmentStatus {
    Unconfirmed,
    Confirmed,
    Cancelled,
    Deleted
}

export interface PatientAlert {
    patientId: number;
    locationId?: number;
    firstName?: string;
    lastName?: string;
    alert?: string;
}

export interface TreatmentPlan {
    id: number;
    description: string;
    status: TreatmentPlanStatus;
    created: string;
    isInFollowUp: boolean;
}

export enum TreatmentPlanStatus {
    proposed,
    referred,
    accepted,
    rejected,
    completed,
    other,
    deleted,
    scheduled
}

export interface Communication {
    type: number;
    status: number;
    date: string;
    note: string;
    senderLocation: Location;
}

export interface PatientPreferencesResponse {
    patientId: number;
    emailAppointmentReminders: boolean;
    emailBirthdayAndHolidayCards: boolean;
    emailExpiringInsuranceReminder:	boolean;
    emailPatientReactivation: boolean;
    emailPostOpInstructions: boolean;
    emailRecalls: boolean;
    emailReviewsAndSurveys:	boolean;
    emailSpecialsAndNewsletters: boolean;
    emailTreatmentPlanFollowUps: boolean;
    dmAppointmentReminders:	boolean;
    dmBirthdayAndHolidayCards: boolean;
    dmExpiringInsuranceReminder: boolean;
    dmPatientReactivation: boolean;
    dmRecalls: boolean;
    dmSpecialsAndNewsletters: boolean;
    dmTreatmentPlanFollowUps: boolean;
    textAppointmentReminders: boolean;
    textPatientReactivation: boolean;
    textRecalls: boolean;
    textReviewsAndSurveys: boolean;
    textSpecialsAndNewsletters:	boolean;
    textTreatmentPlanFollowUps:	boolean;
    pcAppointmentConfirmations:	boolean;
    language: PatientLanguage;
    firstReminderScheduleStatus: PatientCustomReminderSchedule;
    secondReminderScheduleStatus: PatientCustomReminderSchedule;
    saveDateReminderScheduleStatus: PatientCustomReminderSchedule;
    postCardReminderScheduleStatus: PatientCustomReminderSchedule;
    sameDayReminderScheduleStatus: PatientCustomReminderSchedule;
    familyCommunicationReceiverId: number
}

export interface PatientPreferencesRequest {
    emailAppointmentReminders?: boolean;
    emailBirthdayAndHolidayCards?: boolean;
    emailExpiringInsuranceReminder?:	boolean;
    emailPatientReactivation?: boolean;
    emailPostOpInstructions?: boolean;
    emailRecalls?: boolean;
    emailReviewsAndSurveys?:	boolean;
    emailSpecialsAndNewsletters?: boolean;
    emailTreatmentPlanFollowUps?: boolean;
    dmAppointmentReminders?:	boolean;
    dmBirthdayAndHolidayCards?: boolean;
    dmExpiringInsuranceReminder?: boolean;
    dmPatientReactivation?: boolean;
    dmRecalls?: boolean;
    dmSpecialsAndNewsletters?: boolean;
    dmTreatmentPlanFollowUps?: boolean;
    textAppointmentReminders?: boolean;
    textPatientReactivation?: boolean;
    textRecalls?: boolean;
    textReviewsAndSurveys?: boolean;
    textSpecialsAndNewsletters?:	boolean;
    textTreatmentPlanFollowUps?:	boolean;
    pcAppointmentConfirmations?:	boolean;
    language: PatientLanguage;
    firstReminderScheduleStatus: PatientCustomReminderSchedule;
    secondReminderScheduleStatus: PatientCustomReminderSchedule;
    saveDateReminderScheduleStatus: PatientCustomReminderSchedule;
    postCardReminderScheduleStatus: PatientCustomReminderSchedule;
    sameDayReminderScheduleStatus: PatientCustomReminderSchedule;
    familyCommunicationReceiverId?: number
}

export enum PatientLanguage {
    English = "English",
    Spanish = "Spanish"
}

export enum PatientCustomReminderSchedule {
    Global = "Global",
    On = "On",
    Off = "Off"
}