export interface ChannelSearchParams {
  mailboxIds: number[];
  phoneNumbers: string[];
  isUnread: null | boolean;
  isArchived: null | boolean;
  isSaved: null | boolean;
  isNewPatient: null | boolean;
  isHidden: null | boolean;
  hasMessages: null | boolean;
  communicationType: null | CommunicationType;
  start: string;
  end: string;
  pageSize: number;
}

export interface ChannelSearch {
  channelId: string;
  mailboxId: number;
  isUnreadMessenger: boolean;
  isUnreadPhone: boolean;
  isUnread: boolean;
  isSaved: boolean;
  isNewPatient: boolean;
  isArchived: boolean;
  isHidden: boolean;
  patientPhoneNumber: string;
  hasMessages: boolean;
  timeToken: string;
  lastMessage: LastMessage | null;
  lastCall: LastCall | null;
}

export interface LastMessage {
  from: string;
  to: string;
  body: string;
  date: string;
  timeToken: string;
  communicationId: string;
}

export interface LastCall {
  id: string;
  direction: CallDirection;
  callType: CallType;
  from: string;
  to: string;
  date: string;
  timeToken: string;
}

export interface ChannelsUnreadCountsParams {
  mailboxIds: number[];
}

export interface ChannelsUnreadCounts {
  total: number;
  newPatient: number;
  saved: number;
}

export enum CallDirection {
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}

export enum CallType
{
    CallBridged = 'CallBridged',
    MissedCall = 'MissedCall',
    Fax = 'Fax',
    Voicemail = 'Voicemail'
}

export enum CommunicationType {
  Sms = 'Sms',
  Phone = 'Phone'
}