import { AuthApiService } from '@revenuewell/logic-http';
import { OidcService } from '@revenuewell/logic-oidc';
import { NavigationService } from '@revenuewell/logic-navigation';

export default class FormsService extends AuthApiService {
    private static instance: FormsService;
    private config: any;

    constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!FormsService.instance) FormsService.instance = new FormsService(config, oidcService);
 
        return FormsService.instance;
    }

    getFormsUnread = async (): Promise<boolean> => {
        const url = `${await this.config.forms2.apiUrl}/api/FormSubmissions/GetSubmissionsAnyUnread`;
        const response = await this.authFetch(url);

        return await response.json();
    };
}
