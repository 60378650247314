import OutboundCallIcon from '@mui/icons-material/CallMade';
import MissedCallIcon from '@mui/icons-material/CallMissed';
import InboundCallIcon from '@mui/icons-material/CallReceived';
import DownloadFax from '@mui/icons-material/FileDownloadOutlined';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography, styled } from '@mui/material';
import { CallType } from '../../../../hooks/use-communications/types/comms-call-types';
import { useCommunications } from '../../../../hooks/use-communications/use-communications';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';
import CommCardLabel from './communications-card-label';

type Caller = 'me' | 'patient';

const Row = styled('div', {
    shouldForwardProp: prop => prop !== 'caller'
})<{ caller: Caller; showreduced?: string }>(({ caller, showreduced }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: showreduced === 'true' ? '85%' : '100%',
    minWidth: 0,
    alignItems: showreduced === 'true' ? 'flex-start' : caller === 'me' ? 'flex-end' : 'flex-start',
    paddingLeft: showreduced === 'true' ? '24px' : caller === 'me' ? '20%' : '0',
    paddingRight: showreduced === 'true' ? '0' : caller === 'me' ? '0' : '20%'
}));

const CallCard = styled(ListItem)({
    border: '1px solid #E1E4E7',
    borderRadius: '8px',
    backgroundColor: '#F9FAFB',
    '& .MuiListItemAvatar-root': {
        minWidth: 48,
        alignSelf: 'flex-start'
    },
    '& .MuiListItemText-root': {
        margin: 0
    }
});

const colorClassMap = {
    'Inbound Call': { background: '#D8F8D9', color: '#005E05' },
    'Outbound Call': { background: '#D8F8D9', color: '#005E05' },
    'Missed Call': { background: '#F7CECD', color: '#621B16' },
    Voicemail: { background: '#CEE8FF', color: '#0259AB' },
    'Inbound Fax': { background: '#70CBF829', color: '#089AF7' },
    'Outbound Fax': { background: '#70CBF829', color: '#089AF7' }
};

const iconMap = {
    'Inbound Call': <InboundCallIcon sx={{ fontSize: 16 }} />,
    'Outbound Call': <OutboundCallIcon sx={{ fontSize: 16 }} />,
    'Missed Call': <MissedCallIcon sx={{ fontSize: 16 }} />,
    Voicemail: <VoicemailIcon sx={{ fontSize: 16 }} />,
    'Inbound Fax': <DownloadFax sx={{ fontSize: 16 }} />,
    'Outbound Fax': <DownloadFax sx={{ fontSize: 16 }} />
};

interface CommunicationsCallCardProps {
    callType: CallType;
    duration: string;
    caller: Caller;
    locationName: string;
    timestamp: string;
    showReduced?: string;
    isPatientProfile?: boolean;
    patientId?: number;
    locationId?: number;
    phoneNumber: string;
    callId: string;
    faxTransferredPages?: number;
}

const CommunicationsCallCard = ({
    caller,
    callType,
    duration,
    locationName,
    timestamp,
    showReduced,
    isPatientProfile,
    patientId,
    locationId,
    phoneNumber,
    callId,
    faxTransferredPages
}: CommunicationsCallCardProps) => {
    const { publish } = useEventBus();
    const { downloadFax } = useCommunications();

    const renderSecondaryText = () => {
        if (callType === 'Inbound Call' || callType === 'Outbound Call') {
            return (
                <Typography variant='caption' color='#2C3542' fontSize='12px'>
                    {duration}
                </Typography>
            );
        } else

        if (callType === 'Inbound Fax' || callType === 'Outbound Fax') {
            return (
                <Typography variant='caption' color='#2C3542' fontSize='12px'>
                    {faxTransferredPages} pages
                </Typography>
            );
        }
    }

    const handleAvatarClick = async () => {
        if (callType === 'Inbound Fax' || callType === 'Outbound Fax') {
            downloadFax(callId);
        }
    }


    const content = (
        <Row caller={caller} showreduced={showReduced}>
            <CallCard
                onClick={() => {
                    callType == 'Voicemail' &&
                        publish({
                            messageType: 'voicemailWidget',
                            data: {
                                locationId: locationId,
                                patientId: patientId,
                                phoneNumber: phoneNumber,
                                callId: callId
                            }
                        });
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        style={{ ...colorClassMap[callType], cursor:'pointer' }}
                        sx={{ height: 32, width: 32 }}
                        onClick={handleAvatarClick}
                    >
                        {iconMap[callType]}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography variant='body2' color='#000000'>
                            {callType}
                        </Typography>
                    }
                    secondary={renderSecondaryText()}
                />
            </CallCard>
            <CommCardLabel
                direction={caller}
                locationName={locationName}
                timestamp={timestamp}
                isPatientProfile={isPatientProfile}
            />
        </Row>
    );
    return content;
};

export default CommunicationsCallCard;
