import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as InsightsButtonSvg } from '../../../assets/menu/icon-insights.svg';
import SidebarItem from '../../shared/sidebar/sidebar-item';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';
import InsightsAuthService from '../../../services/insights-service/insights-service';

export type SchedulerButtonProps = {
    to: string;
    isActive?: boolean;
    onClick?: () => void;
};

export default function InsightsButton({ to, isActive, onClick }: SchedulerButtonProps) {
    const { config } = useConfig();
    const [hasLeads, setLeads] = useState<boolean>();
    const oidc = useOidcWithAdmin();
    const { activeFrame } = useWrapperNavigation();
    const [lastFrame, setLastFrame] = useState<string>();

    const apiService = useMemo(() => {
        return InsightsAuthService.getInstance(oidc.oidcService, config.insights.apiUrl);
    }, [oidc]);

    useEffect(() => {
        if (lastFrame && lastFrame != activeFrame) hasNewLeads();

        setLastFrame(activeFrame);
    }, [activeFrame]);

    useEffect(() => {
        hasNewLeads();
        const interval = setInterval(() => hasNewLeads(), 120 * 1000);
        return () => clearInterval(interval);
    }, []);

    const hasNewLeads = async () => {
        const res = await apiService.hasNewLeads();
        setLeads(res);
    };

    return (
        <SidebarItem title='Insights' onClick={onClick} to={to} isActive={isActive} hasNotification={hasLeads}>
            <InsightsButtonSvg />
        </SidebarItem>
    );
}
