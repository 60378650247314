
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbExtensionProps, PatientProfileWidgetContext } from "./context";
import { RouteConfig, WidgetRoutePath } from "../../navigation/route-config";
import {
  Contact, ContactApi, ContactLocationInfo, ContactSearchByNumber,
  PatientInfo, PatientPreferencesResponse
} from "../../../../../../services/api-client/contact-api";
import LocationService from "../../../../../../services/location-service/location-service";
import { useConfig, useOidcWithAdmin } from "@revenuewell/front-end-bundle";
import { EventInboxCommunicationsWidget, EventPatientProfile, EventPatientProfileWithPhoneNumber } from "../../../../../../types/event-bus";
import { useCommsFilter } from "../../../../../../hooks/use-communications";
import { importantContacts } from "../../../../../../utils/contact";

export interface PatientProfileWidgetProviderProps {
  patientIdEvent: EventPatientProfile | undefined;
  patientPhoneEvent: EventPatientProfileWithPhoneNumber | undefined;
  patientInboxEvent: EventInboxCommunicationsWidget | undefined;
}

export const PatientProfileWidgetProvider: FC<PatientProfileWidgetProviderProps> = (props) => {
  const { patientIdEvent, patientPhoneEvent, patientInboxEvent } = props;
  const { config } = useConfig();
  const { oidcService } = useOidcWithAdmin();
  const { updateCommsFilter, setPatientPhoneNumber } = useCommsFilter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [route, setRoute] = useState<WidgetRoutePath>();
  const [routes, setRoutes] = useState<WidgetRoutePath[]>([]);
  const [hasHistory, setHasHistory] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<number>(0);
  const [networkId, setNetworkId] = useState<number>(0);
  const [patientId, setPatientId] = useState(0);
  const [contactDetails, setContactDetails] = useState<Contact>(null!);
  const [contactLocationInfo, setContactLocationInfo] = useState<ContactLocationInfo[]>(null!);
  const [contactsByPhone, setContactsByPhone] = useState<ContactSearchByNumber[]>(null!);
  const [patientInfo, setPatientInfo] = useState<PatientInfo>(null!);
  const [patientPreferences, setPatientPreferences] = useState<PatientPreferencesResponse>(null!);
  const [isSkinnyProfile, setIsSkinnyProfile] = useState<boolean>(false);
  const [breadcrumbExtensions, setBreadcrumbExtensions] = useState<BreadcrumbExtensionProps>();
  const locationApi = useMemo(() => LocationService.getInstance(config, oidcService), [config, oidcService]);
  const contactApi = ContactApi.getInstance();

  useEffect(() => {
    (async () => {
      if(!patientIdEvent && !patientPhoneEvent && !patientInboxEvent) return;

      setIsLoading(true);

      if (patientIdEvent) {
        setRoutes([WidgetRoutePath.MAIN]);
        await loadPatientDataById(patientIdEvent.data.patientId);
      }
      else if (patientPhoneEvent) {
        setRoutes([WidgetRoutePath.MAIN]);
        await loadPatientDataByPhone(patientPhoneEvent.data.phoneNumber, patientPhoneEvent.data.locationId);
      }
      else if (patientInboxEvent) {
        setRoutes([WidgetRoutePath.INBOX]);
        if(patientInboxEvent.data.patientId)
          await loadPatientDataById(patientInboxEvent.data.patientId);
        else if(patientInboxEvent.data.phoneNumber && patientInboxEvent.data.locationId)
          await loadPatientDataByPhone(patientInboxEvent.data.phoneNumber, patientInboxEvent.data.locationId);
      }
      
      setIsLoading(false);
    })();
  }, [patientIdEvent, patientPhoneEvent, patientInboxEvent]);

  useEffect(() => {
    setHasHistory(routes.length > 1);
    setRoute(routes[routes.length - 1]);
    setBreadcrumbExtensions(undefined);
  }, [routes]);

  useEffect(() => {
    contactDetails && patientInfo && locationId && contactLocationInfo
      && !contactLocationInfo.map(location => `${location.locationId}`).includes(`${locationId}`)
      ? setIsSkinnyProfile(true)
      : setIsSkinnyProfile(false);
  }, [contactDetails, patientInfo, locationId, isSkinnyProfile, contactLocationInfo, setIsSkinnyProfile]);

  const loadPatientDataById = async (patientId: number) => {
    const [contactDetails, identicalContacts, patientInfo, patientPreferences] = await Promise.all([
      contactApi.getContactDetailsById(patientId), 
      contactApi.getIdenticalContacts(patientId),
      contactApi.getPatientInfoById(patientId),
      contactApi.getPatientPreferences(patientId)
    ]);

    let networkId = contactDetails.networkId;
    let finalLocationId = locationId || contactDetails.location?.id || 0;

    // we are checking if we have access to the location we are trying to retrieve.
    // It possible we don't have access to the location that was passed in.
    try {
      const location = await locationApi.getLocation(finalLocationId);
      networkId = location.idNetwork;
    } catch {
      finalLocationId = contactDetails.location?.id || 0;
    }

    const contactLocationInfo = [...identicalContacts, {
      id: contactDetails.id,
      locationId: contactDetails.location!.id,
      locationName: contactDetails.location!.name,
      networkId: contactDetails.networkId
    }].sort((a, b) => a.locationName!.localeCompare(b.locationName!));

    try {
      setContactDetails(contactDetails);
      setPatientInfo(patientInfo);
      setContactLocationInfo(contactLocationInfo);
      setPatientPreferences(patientPreferences);
      setLocationId(finalLocationId);
      setNetworkId(networkId);
      setPatientId(patientId);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const loadPatientDataByPhone = async (phoneNumber: string, locationId: number) => {
    try {
      const contactsByPhone = await contactApi.getContactsByPhoneNumbers(phoneNumber);
      const location = await locationApi.getLocation(locationId);

      setLocationId(location.id);

      if (contactsByPhone[phoneNumber].length === 1) {
        await loadPatientDataById(contactsByPhone[phoneNumber][0].id);
      } else {
        const finalContacts = importantContacts(contactsByPhone[phoneNumber], patientId, location.idNetwork);
        setContactsByPhone(finalContacts);

        if(patientInboxEvent) {
          await loadPatientDataById(finalContacts[0].id);
        } else {
          setRoutes([WidgetRoutePath.PROFILE_SELECTOR]);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const refreshPatientPreferences = useCallback(async () => {
    if (patientId) {
      const patientPreferences = await contactApi.getPatientPreferences(patientId);
      setPatientPreferences(patientPreferences);
    }
  }, [patientId, contactApi])

  const goTo = (newRoute: WidgetRoutePath) => {
    if (newRoute === route) return;

    resetInboxFilters();

    if(contactDetails && newRoute === WidgetRoutePath.MAIN)
      setRoutes([WidgetRoutePath.MAIN]);
    else if(!contactDetails && newRoute === WidgetRoutePath.INBOX)
      setRoutes([WidgetRoutePath.INBOX]);
    else
      setRoutes(prev => [...prev, newRoute]);
  }

  const goBack = () => {
    if (!route || route === WidgetRoutePath.MAIN || routes.length < 2) return;

    const prevRoute = route?.split('/').slice(0, -1).join('/');

    resetInboxFilters();

    if (prevRoute?.split('/').length === 1 && contactDetails)
      return setRoutes([WidgetRoutePath.MAIN]);
    else if(prevRoute?.split('/').length === 2 && !contactDetails)
      return setRoutes([WidgetRoutePath.INBOX]);

    const routeConfigItem = RouteConfig.find(obj => obj.path === prevRoute);

    if (routeConfigItem) setRoutes(prev => [...prev, routeConfigItem.path]);
  }
  
  const resetInboxFilters = () => {
    if(route !== WidgetRoutePath.INBOX && route !== WidgetRoutePath.INBOX_FILTERS) {
      updateCommsFilter({ commType: undefined, mailboxes: undefined });
      setPatientPhoneNumber(undefined);
    }
  }

  return (
    <PatientProfileWidgetContext.Provider value={{
      events: {
        patientIdEvent,
        patientPhoneEvent,
        patientInboxEvent
      },
      route,
      hasHistory,
      goTo,
      goBack,
      isLoading,
      contactDetails,
      contactLocationInfo,
      contactsByPhone,
      patientInfo,
      patientPreferences,
      refreshPatientPreferences,
      locationId,
      networkId,
      isSkinnyProfile,
      breadcrumbExtensions,
      setBreadcrumbExtensions
    }}>
      {props.children}
    </PatientProfileWidgetContext.Provider>
  );
};