import Scrollbars from 'react-custom-scrollbars-2';
import { makeStyles } from '@mui/styles';
import { Box, Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CallHistoryItem } from '../../../../hooks/use-communications/types/comms-call-types';
import { useCommunications } from '../../../../hooks/use-communications/use-communications';
import { useCommsChat } from '../../../../hooks/use-communications';
import CommunicationsMessageCard from './communcations-message-card';
import CommunicationsCallCard from './communcations-call-card';
import { Message } from '../../../../services/pubnub-service';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { CommsOverlineText, CommsSectionSeparator } from './styles/communcations-styles';
import { formatToLocale } from '../../../../utils/date-time';
import InboxSearchEmpty from '../inbox-widget/inbox-search-empty';

const useClasses = makeStyles({
    scroll: {
        borderTop: '1px solid #E1E4E7'
    },
    chatSkeleton: {
        backgroundColor: '#f5f5f5'
    }
});

function isMessage(item: Message | CallHistoryItem): item is Message {
    return (item as Message).body !== undefined;
}

function isCallHistory(item: Message | CallHistoryItem): item is CallHistoryItem {
    return (item as CallHistoryItem).callStarted !== undefined;
}

interface CommunicationsPanelProps {
    timestamp?: number;
}

export default function CommunicationsPanel({ timestamp }: CommunicationsPanelProps) {
    const { data, isLoading, hasMore, loadMore } = useCommunications();
    const { sendMessageToServer } = useCommsChat();
    const { mailboxes } = usePubnub();
    const [lastTimetoken, setLastTimetoken] = useState<number | undefined>();
    const [scrollHeight, setScrollHeight] = useState<number | undefined>();
    const [scrollToIndex, setScrollToIndex] = useState(-1);
    const classes = useClasses();
    const scrollbarRef: any = useRef<Scrollbars>(null);
    const dates = new Set<string>();

    const loadNextPage = useCallback(() => {
        if (!isLoading && hasMore) {
            loadMore();
        }
    }, [loadMore, isLoading, hasMore]);

    const onScrollTop = () => {
        const scroll = scrollbarRef.current;
        const values = scroll.getValues();

        if (values.scrollTop === 0) {
            setScrollHeight(values.scrollHeight);
            loadNextPage();
        }
    };

    const loading = () => {
        return (
            <Box display='flex' alignItems='flex-end' flexDirection='column' padding={2}>
                <Skeleton animation='wave' width={'50%'} height={35} className={classes.chatSkeleton} />
                <Skeleton animation='wave' width={'50%'} height={10} className={classes.chatSkeleton} />
            </Box>
        );
    };

    useEffect(() => {
        const curTimetoken = data.length && data[data.length - 1].timetoken;
        const scroll = scrollbarRef.current;
        const values = scroll.getValues();
        if (lastTimetoken !== curTimetoken) {
            setLastTimetoken(curTimetoken);
            scroll.scrollToBottom();
            setScrollHeight(values.scrollHeight);
        } else if (scrollHeight && values.scrollHeight - scrollHeight > 25) {
            scroll.scrollTop(values.scrollHeight - scrollHeight);
            setScrollHeight(values.scrollHeight);
        }
    }, [data]);

    const resend = useCallback(
        (message: Message, mailboxId?: number) => {
            if (mailboxId) {
                const now = new Date();
                const newMessage: Message = {
                    ...message,
                    date: now.toISOString(),
                    timetoken: now.getTime(),
                    status: 'sending'
                };
                sendMessageToServer(newMessage, mailboxId);
            }
        },
        [sendMessageToServer]
    );

    const renderCommunications = useCallback(
        (item: Message | CallHistoryItem) => {
            if (isMessage(item)) {
                const curMailbox = mailboxes.find(d => d.pubnubPrefix === item.channelId.split('.')[0]);
                const locationName = curMailbox ? curMailbox.location.name : '';
                const images = item.media?.map(({ link }) => link) || [];
                return (
                    <CommunicationsMessageCard
                        sender={
                            item.messageType === 'direct'
                                ? 'me'
                                : item.messageType === 'incoming'
                                ? 'patient'
                                : item.messageType === 'campaign'
                                ? 'system'
                                : 'system'
                        }
                        from={item.from}
                        text={item.body}
                        timeStamp={item.date}
                        senderName={item.senderUserName || ''}
                        to={item.to}
                        locationName={locationName}
                        timestamp={item.date}
                        status={item.status}
                        images={images}
                        phoneNumber={item.channelId.split('.')[1]}
                        reaction={item.reaction}
                        timetokenString={item.timetokenString}
                        onClickResend={() => resend(item, curMailbox?.id)}
                    />
                );
            }

            if (isCallHistory(item)) {
                const curMailbox = mailboxes.find(m => m.locationId === item.locationId);
                const locationName = curMailbox ? curMailbox.location.name : '';
                const patientPhoneNumber = item.callDirection === 'Inbound' ? item.callerNumber : item.calleeNumber;
                let sanitizedNumber = patientPhoneNumber && patientPhoneNumber.replace(/[^\d]/g, '');

                if (sanitizedNumber && sanitizedNumber.length > 10 && sanitizedNumber[0] === '1') {
                    sanitizedNumber = sanitizedNumber.substring(1);
                }

                return (
                    <CommunicationsCallCard
                        caller={item.callDirection === 'Inbound' ? 'patient' : 'me'}
                        callType={item.callType}
                        duration={item.duration}
                        locationName={locationName}
                        timestamp={item.callStarted}
                        locationId={item.locationId}
                        phoneNumber={sanitizedNumber}
                        callId={String(item.id)}
                        faxTransferredPages={item.faxTransferredPages}
                    />
                );
            }

            return null;
        },
        [mailboxes]
    );

    const renderDateDevider = useCallback(
        (timetoken: string | null) => {
            if (timetoken && !dates.has(timetoken)) {
                dates.add(timetoken);
                return (
                    <CommsSectionSeparator>
                        <CommsOverlineText variant='overline'>{formatToLocale(timetoken)}</CommsOverlineText>
                    </CommsSectionSeparator>
                );
            }
            return null;
        },
        [dates]
    );

    useEffect(() => {
        if (!isLoading && scrollToIndex !== -1) {
            const item = scrollbarRef.current.view.querySelectorAll('.data-item')[scrollToIndex];
            if (item) {
                item.scrollIntoView({ block: 'center' });
                setScrollToIndex(-1);
            }
        }
    }, [scrollbarRef, isLoading, scrollToIndex, data]);

    useEffect(() => {
        if (timestamp && !isLoading) {
            const transformedDate = +new Date(isNaN(Number(timestamp)) ? timestamp : +timestamp);
            const firstIndexIsLessThanDate = data[0] && +new Date((data[0].timetoken) || '') < transformedDate;

            // sometimes the transformed date can be slightly further in the future than the most recent message causing an infinite scroll 
            if (data && data[data.length - 1] && data[data.length - 1].timetoken! < transformedDate) {
                return
            }

            const findMessageByDate =
                !firstIndexIsLessThanDate && hasMore
                    ? -1
                    : data.findIndex(data => {
                          const messageDate = +new Date(data.timetoken || '');
                          return messageDate >= timestamp;
                      });

            if (findMessageByDate !== -1) {
                setScrollToIndex(findMessageByDate - 1);
            } else if (hasMore) {
                scrollbarRef.current.scrollTop(0);
                loadNextPage();
            }
        }
    }, [data, isLoading, scrollbarRef]);

    return (
        <Scrollbars autoHide ref={scrollbarRef} onScroll={onScrollTop} className={classes.scroll}>
            {isLoading ? loading() : null}
            {data.map((item, index) => {
                const timetoken = isMessage(item)
                    ? new Date(item.date).toLocaleDateString()
                    : isCallHistory(item)
                    ? new Date(item.callStarted).toLocaleDateString()
                    : null;

                return (
                    <React.Fragment key={index}>
                        {renderDateDevider(timetoken)}
                        <Box
                            className='data-item'
                            alignItems='center'
                            justifyContent='center'
                            display='flex'
                            marginY='24px'
                            marginX='16px'
                        >
                            {renderCommunications(item)}
                        </Box>
                    </React.Fragment>
                );
            })}
            {!isLoading && !data.length && <InboxSearchEmpty />}
        </Scrollbars>
    );
}
