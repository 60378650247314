import React from "react"
import { useContext } from 'react';
import { LocationsContext } from './provider';

export default function useLocations() {
    const context = useContext(LocationsContext);

    if (!context) throw new Error('useLocations must be used within a LocationsProvider');

    return context;
}
