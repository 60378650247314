import { useConfig } from '@revenuewell/front-end-bundle';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { EventFrameActivate } from '../../types/event-bus';
import { useEventBus } from '../use-event-bus';
import { useWrapperNavigation } from '../use-wrapper-navigation';
import { WrapperFrame, WrapperFramesContextProps } from './types';
import { useViewMode } from '../use-view-mode';

export const WrapperFramesContext = createContext<WrapperFramesContextProps>(null!);

export function WrapperFramesProvider({ children }: PropsWithChildren<{}>) {
    const { config } = useConfig();
    const { publishViewMode } = useViewMode();
    const { publishToIframes } = useEventBus();
    const { frameUrls, activeFrame } = useWrapperNavigation();
    const [allFramesLoaded, setAllFramesLoaded] = useState(false);
    const [frames, setFrames] = useState<WrapperFrame[]>(
        config.frames.map((frameName: string) => {
            return {
                name: frameName,
                defaultSrc: frameUrls[frameName],
                render: false,
                isLoaded: false
            };
        })
    );

    useEffect(() => {
        sendActivationEvent(activeFrame);
        setTimeout(publishViewMode, 500);
    }, [activeFrame, publishViewMode]);

    useEffect(() => {
        const framesToRender = frames.filter(f => f.render);
        setAllFramesLoaded(framesToRender.length > 0 && framesToRender.every(f => f.isLoaded));
    }, [frames]);

    useEffect(() => {
        if (allFramesLoaded) {
            sendActivationEvent(activeFrame);
            setTimeout(publishViewMode, 500);
        }
    }, [allFramesLoaded, publishViewMode]);

    const getFrame = (frameName: string) => {
        return frames.filter(({ name }) => name === frameName)[0];
    };

    const updateFrame = (frame: WrapperFrame) => {
        setFrames(array => array.map(f => (f.name === frame.name ? { ...frame } : f)));
    };

    const sendActivationEvent = (frameName: string) => {
        publishToIframes({
            messageType: 'frame-activate',
            data: { frameName: frameName }
        } as EventFrameActivate);
    };

    return (
        <WrapperFramesContext.Provider value={{ frames, allFramesLoaded, getFrame, updateFrame, sendActivationEvent }}>
            {children}
        </WrapperFramesContext.Provider>
    );
}
