import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { useViewMode } from '../../../hooks/use-view-mode';

const useStyles = () => {
    const { viewMode, heightMode } = useViewMode();
    const isSmall = viewMode === 'Phone'
    const isShort = heightMode === 'Short'
    const isMedium = heightMode === 'Medium'

    const useStyles = makeStyles(() => ({
        link: {
            position: 'relative',
            marginTop: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 48,
            width: 48,
            borderRadius: 14,
            transition: 'all 0.1s ease-in-out',
            '& svg': {
                width: 20,
                height: 20,
                opacity: 0.7,
                ...(isShort && {
                    width: 12,
                    height: 12
                }),
                ...(isMedium && {
                    width: 15,
                    height: 15
                })
            },
            '& svg.CampainButton': {
                width: 36,
                height: 36,
                marginLeft: -8,
                marginRight: -8,
                opacity: 0.7,
                ...(isShort && {
                    width: 20,
                    height: 20,
                    marginLeft: -4,
                    marginRight: -4
                }),
                ...(isMedium && {
                    width: 26,
                    height: 26,
                    marginLeft: -6,
                    marginRight: -6
                })
            },
            '& svg.TeamsButton': {
                width: 26,
                height: 26,
                opacity: 0.7,
                ...(isShort && {
                    width: 16,
                    height: 16
                }),
                ...(isMedium && {
                    width: 20,
                    height: 20
                })
            },
            '&:hover': {
                backgroundColor: '#265D7C'
            },
            '&:focus-visible': {
                boxShadow: '0px 0px 0px 3px #1EB9FB',
                outline: 'none'
            },
            ...(isShort && {
                marginTop: 3,
                width: 25,
                height: 25,
                padding: '2px',
                borderRadius: 8
            }),
            ...(isMedium && {
                marginTop: 5,
                width: 30,
                height: 30,
                padding: '5px',
                borderRadius: 10
            }),
            ...(isSmall && {
                width: 'auto',
                height: 'auto',
                padding: '18px',
                borderRadius: 8
            })
        },
        linkActive: {
            background: '#003E57',
            '& svg': {
                opacity: 1
            }
        },
        blueDot: {
            position: 'absolute',
            top: -3,
            right: -3,
            borderRadius: '100%',
            backgroundColor: '#0675DD',
            display: 'block',
            border: '3px solid #004763',
            width: 8,
            height: 8
        },
        title: {
            display: 'none',
            ...(isSmall && {
                marginLeft: 16,
                display: 'block',
                flex: 1,
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: 400
            })
        }
    }));

    return useStyles();
}

interface SidebarItem {
    children: React.ReactNode;
    to: string;
    title?: string;
    replace?: boolean;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    hasNotification?: boolean;
}

export default function SidebarItem({ children, title, to, replace, isActive, onClick, hasNotification }: SidebarItem) {
    const classes = useStyles();
    return (
        <Link
            to={to}
            replace={replace}
            className={`${classes.link} ${isActive && classes.linkActive}`}
            onClick={onClick}
            title={title}
        >
            {children}
            {hasNotification && <span className={classes.blueDot} />}
            <span className={classes.title}>{title}</span>
        </Link>
    );
}
