import { useContext } from 'react';
import { SlideoutContext } from '.';

export default function useSlideout() {
    const ctx = useContext(SlideoutContext);

    if (!ctx) throw new Error('useSlideout must be used within SlideoutProvider');

    return ctx;
}
