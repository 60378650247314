import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Inside, Line, PlayPause, Seek, Seekable, CircularProgress } from './styles';
import { PauseRounded, PlayArrow } from '@mui/icons-material';
import { formatToHoursMinutesSeconds } from '../../../utils/formatter';
import Tooltip from '@mui/material/Tooltip';

type AudioPlayerProps = {
    src: string;
    isLoading?: boolean;
    isEnabled: boolean;
    onClick?: () => void;
};

export default function AudioPlayer({ src, isLoading, isEnabled, onClick }: AudioPlayerProps) {
    const ref = useRef<HTMLAudioElement>(null);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [status, setStatus] = useState<'idle' | 'playing' | 'paused'>('idle');

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (ref.current) ref.current.currentTime = Number(event.target.value);
        },
        [currentTime]
    );

    useEffect(() => {
        if (ref.current) {
            const loadMetadata = () => {
                if (ref.current?.duration) setDuration(ref.current.duration);
            };

            const setPlaying = () => {
                setStatus('playing');
            };

            const setPause = () => {
                setStatus('paused');
            };

            const timeUpdate = () => {
                if (ref.current?.currentTime) setCurrentTime(ref.current.currentTime);
            };

            ref.current.addEventListener('loadedmetadata', loadMetadata);
            ref.current.addEventListener('play', setPlaying);
            ref.current.addEventListener('pause', setPause);
            ref.current.addEventListener('stop', setPause);
            ref.current.addEventListener('timeupdate', timeUpdate);

            return () => {
                ref.current?.removeEventListener('loadedmetadata', loadMetadata);
                ref.current?.removeEventListener('play', setPlaying);
                ref.current?.removeEventListener('pause', setPause);
                ref.current?.removeEventListener('stop', setPause);
                ref.current?.removeEventListener('timeupdate', timeUpdate);
            };
        }
    }, [ref.current]);

    const togglePlayPause = useCallback(() => {
        if (status === 'playing') ref.current?.pause();
        else ref.current?.play();
        onClick && onClick();
    }, [status]);

    return (
        <Container>
            <audio ref={ref} src={src} />
            <Tooltip title='You do not have access to this mailbox.' disableHoverListener={isEnabled}>
                <PlayPause
                    onClick={() => (isEnabled ? togglePlayPause() : null)}
                    style={{ backgroundColor: isEnabled ? 'rgba(6, 117, 221, 1)' : 'rgb(0,0,0,0.25)' }}
                >
                    {isLoading ? <CircularProgress /> : status === 'playing' ? <PauseRounded /> : <PlayArrow />}
                </PlayPause>
            </Tooltip>

            <Inside>
                {formatToHoursMinutesSeconds(currentTime)}
                <Line>
                    <Seek
                        disabled={!isEnabled}
                        onChange={onChange}
                        type='range'
                        value={currentTime}
                        min={0}
                        step={0.0001}
                        max={duration}
                    />
                    <Seekable
                        style={{
                            width: `calc(${(currentTime / duration) * 100}% - 2.2px)`
                        }}
                    />
                </Line>
                {formatToHoursMinutesSeconds(duration)}
            </Inside>
        </Container>
    );
}
