import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { useSlideout } from '../../../../hooks/use-slideout';
import SlideOutCard from '../../../shared/slide-out-card';
import {
  MfaMessage, MfaMessageSignature, MfaMessageText,
  NoMfaMessages,
  SlideoutContent, SlideoutHeader, SlideoutMessages
} from './styles';
import Scrollbars from 'react-custom-scrollbars-2';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { Message } from '../../../../services/pubnub-service';
import { EventMfaCodesWidget } from '../../../../types/event-bus';
import { useNotification } from '@revenuewell/front-end-bundle';
import { formatDate } from '../../../../utils/date-time';
import { useLocations } from '../../../../hooks/use-locations';
import { Location } from '../../../../services/api-client/voice-api';


export const MfaCodesSlideout = () => {
  const { publish } = useEventBus();
  const { showError } = useNotification();
  const { locations } = useLocations();
  const { getMfaCodes, mailboxes } = usePubnub();
  const { isVisibleMfaCodes, back, currentEvent } = useSlideout();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<Location>();
  const [mfaCodes, setMfaCodes] = useState<Message[]>([]);
  const scrollbarRef: any = useRef<Scrollbars>(null);

  useEffect(() => {
    (async () => {
      if (!currentEvent || !mailboxes || mailboxes.length === 0) return;
      if(currentEvent.messageType != "mfaCodesWidget") return;

      const event = currentEvent as EventMfaCodesWidget;
      const location = locations.find(location => location.id === event.data?.locationId);

      setLocation(location);
      await loadMfaCodes(event.data.locationId);
      scrollbarRef.current?.scrollToBottom();
    })();
  }, [mailboxes, currentEvent])

  const loadMfaCodes = async (locationId: number) => {
    try {
      setLoading(true);
      const mailbox = mailboxes.find(mailbox => mailbox.location?.id === locationId);

      if (!mailbox) throw new Error("MFA Codes Slideout - no mailbox found for locationId: " + locationId);

      const mfaCodes = await getMfaCodes(mailbox.id);
      setMfaCodes(mfaCodes);
    }
    catch (error) {
      showError("Unable to load MFA codes.")
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <SlideOutCard
      isVisible={isVisibleMfaCodes}
      onClose={() => publish({ messageType: 'closeWidget' })}
      onBackClick={() => back()}
    >
      <SlideoutContent>
        <SlideoutHeader>MFA Codes</SlideoutHeader>
        <SlideoutMessages>
          {loading && <CircularProgress size={60} sx={{ margin: 'auto' }} />}

          {!loading && mfaCodes.length === 0 &&
            <NoMfaMessages>No MFA codes have been received yet.</NoMfaMessages>
          }

          {!loading && mfaCodes.length > 0 &&
            <Scrollbars autoHide ref={scrollbarRef} style={{ overflowX: 'hidden' }} >
              {mfaCodes.map((message, index) => {
                const date = formatDate(message.date, 'h:mm A', location?.timezone);

                return <MfaMessage key={index}>
                  <MfaMessageText>{message.body}</MfaMessageText>
                  <MfaMessageSignature>
                    {`${mailboxes[0].location?.name} | ${date}`}
                  </MfaMessageSignature>
                </MfaMessage>
              })}
            </Scrollbars>
          }
        </SlideoutMessages>
      </SlideoutContent>
    </SlideOutCard >
  );
}
