import { FC, useEffect } from 'react';
import { useEventBus } from '../../../../hooks/use-event-bus';
import SlideOutCard from '../../../shared/slide-out-card';
import { useSlideout } from '../../../../hooks/use-slideout';
import { usePatientProfileWidget } from './hooks/use-patient-profile-widget';
import { PatientProfileRouter } from './navigation/router';
import { PatientProfileWidgetWrapper } from './patient-profile-widget.styles';
import { MainViewSkeleton } from './views/main/main.skeleton';
import { useCommsFilter } from '../../../../hooks/use-communications';

export const PatientProfileWidgetComponent: FC = () => {
  const { publish } = useEventBus();
  const { isVisiblePatient, isVisibleCommunications, hasHistory, back } = useSlideout();
  const { goBack, hasHistory: hasPatientProfileHistory } = usePatientProfileWidget();
  const { setPatientPhoneNumber, updateCommsFilter } = useCommsFilter  ();
  const { isLoading } = usePatientProfileWidget();

  const handleClose = () => {
    publish({ messageType: 'closeWidget' });
  };

  const handleBackClick = () => {
    resetInboxFilters();
    hasPatientProfileHistory ? goBack() : back();
  }

  const resetInboxFilters = () => {
    updateCommsFilter({ commType: undefined, mailboxes: undefined });
    setPatientPhoneNumber(undefined);
  }

  return (
    <SlideOutCard
      isVisible={isVisiblePatient || isVisibleCommunications}
      onClose={handleClose}
      onBackClick={hasHistory || hasPatientProfileHistory ? handleBackClick : undefined}>
      <PatientProfileWidgetWrapper>
        {isLoading ? <MainViewSkeleton /> : <PatientProfileRouter />}
      </PatientProfileWidgetWrapper>
    </SlideOutCard>
  );
}
