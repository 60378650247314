import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Dialog, Typography, Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DialogContent, DialogToolbar, TextFieldBox } from './styles';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import InsuranceServiceV2, { InsuranceConnectionV2 } from '../../../services/insuranc-service-v2/insurance-service-v2';
import { useEventBus } from '../../../hooks/use-event-bus';
import { EventMfaCodeDialog } from '../../../types/event-bus';


type EventAndConnection = {
    event: EventMfaCodeDialog
    connection: InsuranceConnectionV2
}

export const MfaDialog: FC = () => {
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { listen, publish } = useEventBus();
    const [event, setEvent] = useState<EventAndConnection>();
    const [mfaCode, setMfaCode] = useState<string>('');

    const insuranceService = InsuranceServiceV2.getInstance(config, oidcService);

    useEffect(() => {
        return listen('mfaCodeDialog', incomingEvent => {
            (async () => {
                const mfaEvent = incomingEvent as EventMfaCodeDialog
                //if the dialog is already open requeue the mfa event
                if(event){
                    window.setTimeout(() => publish(mfaEvent), 30000)
                    return;
                }
                //close it after a minute with no response
                window.setTimeout(() => {
                    const tmp = event;
                    setEvent(event => {
                        if(event === tmp) return undefined;
                        else return event;
                    })
                }, 60000)
                //console.log("####MFA:", mfaEvent);
                const { locationId, entityId } = mfaEvent.data;
                const connection = await insuranceService.getConnection(locationId, entityId);
                
                setEvent({event: mfaEvent, connection: connection});
            })();
        });
    }, [])

    const onClose = useCallback(() => {
        setEvent(undefined);
        setMfaCode('');
    }, [])

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMfaCode(event.target.value);
    }, [])

    const onSubmit = useCallback(() => {
        if(!event) return;
        const { locationId, entityId } = event.event.data;
        insuranceService.sendMfaCode(locationId, entityId, mfaCode!);
        onClose();
    }, [event, mfaCode])

    return (
        <Dialog open={Boolean(event)}>
        <DialogContent>
            <Typography variant='h6'>
            Insurance Verification - Mult-Factor Authentication Code Required
            </Typography>
            <br />
            <Box>
                <Typography variant='body1' sx={{color: "black"}}>
                    Please enter the code you've received in the space provided below and click "reconnect."
                </Typography>
            </Box>
            <br />
            <Box>
                <Typography variant='body1' sx={{color: "black"}}>
                    Portal: <b>{event?.connection.website?.url}</b>
                </Typography>
            </Box>
            <br />
            <TextFieldBox>
                <TextField sx={{width: 150}} size="small" variant="outlined" value={mfaCode} onChange={onChange}/>
            </TextFieldBox>
            <br />
            <DialogToolbar>
            <Button
                sx={{backgroundColor: "rgba(0, 125, 255, 0.08)", color: "rgb(8, 154, 247)"}}
                color="secondary"
                variant='contained'
                onClick={onClose}
                disableElevation>
                Cancel
            </Button>
            <Button
                color="primary"
                variant='contained'
                onClick={onSubmit}
                disableElevation
                disabled = {mfaCode ? mfaCode.length < 4 : true }>
                Send Code
            </Button>
            </DialogToolbar>
        </DialogContent>
        </Dialog>
    );
};