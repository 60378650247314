export interface CallHistory {
    callDirection: CallDirectionType;
    callEnded?: string;
    callIds: string[];
    callStarted: string;
    calleeDevice: string;
    calleeNumber: string;
    calleeUser: string;
    callerDestinationNumber: string;
    callerDevice: string;
    callerName: string;
    callerNumber: string;
    callerUser: string;
    hasVoicemail: boolean;
    id: string;
    isMissedCall: boolean;
    kazooAccountId: string;
    locationId: number;
    masterAccountId: number;
    missedCallAcknowledged: boolean;
    rootCallId: string;
    voicemailAccessed: boolean;
    voicemailDuration: number;
    voicemailId: string;
    voicemailBox: string;
    hasFaxError: boolean | null;
    faxId?: string;
    faxTransferredPages?: number;
    hasFax?: boolean;
  }
  
  export type CallDirection = 'inbound' | 'outbound';
  export interface Mailbox {
    name: string;
    value: string;
  }
  
  export const mailboxOpts: Mailbox[] = [
    { value: 'user_mailbox', name: 'My Calls' },
    { value: 'practice_mailbox', name: 'All Practice Calls' },
  ];
  
  export interface CallHistorySearch {
    locationIds?: string[];
    searchText?: string;
    mailbox?: Mailbox;
    hasVoicemail?: boolean;
    pageSize?: number;
    isFax?: boolean;
    direction?: 'Inbound' | 'Outbound';
    timeStartTo?: string;
    callDirection?: CallDirection | null;
    isMissedCall?: boolean | null;
    timeStart?: number;
    timeEnd?: number;
  }

  export const CallDirectionTypes = {
    INBOUND: 'Inbound',
    OUTBOUND: 'Outbound',
    MISSED: 'Missed',
  } as const;

  export type CallDirectionType = typeof CallDirectionTypes[keyof typeof CallDirectionTypes];