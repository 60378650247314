import React, { useEffect, useState, useRef, useCallback, ChangeEvent } from 'react';
import Dialog from '@mui/material/Dialog';
import {
    Typography,
    Box,
    styled as materialStyled,
    TextField,
    IconButton,
    Button,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withTheme from '@mui/styles/withTheme';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { formatUSPhoneNumber, normaliseUSPhoneNumber } from '../../../utils/formatter';
import { Autocomplete } from '@mui/material';
import { fromNumberDropDownTheme } from './fax-theme';
import CloseIcon from '@mui/icons-material/Close';
import styled, { css } from 'styled-components';
import { useKazoo } from '../../../hooks/use-kazoo/use-kazoo';
import { useFax } from '../../../hooks/use-fax/use-fax';


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}


const StyledWrapper = materialStyled(Box)({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    paddingBottom: '10px',
});

const StyledLocationDropDownWrapper = materialStyled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingTop: '15px',
});

const UploadedFileTextContainer = materialStyled(Box)({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    paddingBottom: '10px',
    marginTop: '20px',
});

const ButtonWrapper = materialStyled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
});

const OptionDiv = materialStyled(Box)({
    padding: '6px 16px 6px 16px',
});

const Container = materialStyled(Box)({
    margin: '48px',
});

const TextFieldContainer = materialStyled(Box)({
    paddingTop: '20px',
});

const TextFieldStyled = materialStyled(TextField)({
    width: '504px',
});

const FileUploadContainer = materialStyled(Box)((style: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 16px',
    position: 'static',
    width: '504px',
    height: '206px',
    top: '104px',
    background: `${style.theme.palette.info.light}`,
    border: `1px dashed ${style.theme.palette.primary.main}`,
    borderRadius: '4px',
    marginTop: '20px',
    boxSizing: 'border-box'
}));

const UploadContainer = withTheme(styled.div<FileUploadSuccessWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  position: static;
  background: ${(props) =>
    props.success ? props.theme.palette.secondary.contrastText : props.theme.palette.action.focus};
  border-radius: 4px;
  width: 504px;
  height: 48px;
`);

type FileUploadSuccessWrapperProps = {
    success?: boolean;
};

const UploadSuccessContainer = materialStyled(Box)((style: any) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 16px',
    position: 'static',
    background: style.theme.palette.secondary.contrastText,
    borderRadius: '4px',
    width: '504px',
    height: '48px',
}));

const UploadFailureContainer = materialStyled(Box)((style: any) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 16px',
    position: 'static',
    background: style.theme.palette.action.focus,
    borderRadius: '4px',
    width: '504px',
    height: '48px',
}));

const FileUploadLeftContainer = materialStyled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const FileUploadRightContainer = materialStyled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
});

const useStyles = makeStyles((theme: any) => ({
    attachFileIcon: {
        position: 'static',
        width: '64px',
        height: '64px',
        marginBottom: '10px',
        background: theme.palette.secondary.light,
        borderRadius: '100px',
        color: theme.palette.primary.main,
    },
    sendButton: {
        marginLeft: '15px',
        marginTop: '30px',
    },
    cancelButton: {
        marginLeft: '15px',
        marginTop: '30px',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
    },
    uploadedIcon: {
        width: '18.33px',
        height: '18.33px',
        color: theme.palette.secondary.dark,
        marginRight: '12px',
    },
    uploadedFileClearIcon: {
        width: '18.33px',
        height: '18.33px',
        color: 'rgb(30, 70, 32)',
        marginLeft: 'auto',
    },
    uploadedFileClearIconFailed: {
        width: '18.33px',
        height: '18.33px',
        color: 'rgb(97, 26, 21)',
        marginLeft: 'auto',
    },
    uploadFailedIcon: {
        width: '18.33px',
        height: '18.33px',
        color: theme.palette.secondary.main,
        marginRight: '12px',
    },
    linkTextStyle: {
        textDecorationLine: 'underline',
    },
    successText: {
        color: 'rgb(30, 70, 32)',
    },
    failureText: {
        color: 'rgb(97, 26, 21)',
    },
}));

export function SendFaxDialog(props: { open: boolean; onClose: () => void }) {
    const locationsLabel = 'From';
    const { open, onClose } = props;
    const classes = useStyles();
    const { userCredentials } = useKazoo();
    const { fromNumbers, fetchFaxFromNumbers, sendFax } = useFax();
    const inputFile = useRef(document.createElement('input'));

    const [selectedFromNumber, setSelectedFromNumber] = useState(
        fromNumbers.length ? normaliseUSPhoneNumber(fromNumbers[0]) : ''
    );
    const [recipientNumber, setRecipientNumber] = useState('');
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadFailed, setIsUploadFailed] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const displayRecipientNumber = formatUSPhoneNumber(recipientNumber);

    useEffect(() => {
        setSelectedFromNumber(fromNumbers.length ? normaliseUSPhoneNumber(fromNumbers[0]) : '');
    }, [fromNumbers]);

    useEffect(() => {
        if (userCredentials && !fromNumbers.length) {
            fetchFaxFromNumbers();
        }
    }, [userCredentials]);

    const reset = () => {
        setRecipientNumber('');
        setIsUploaded(false);
        setIsUploadFailed(false);
        setSelectedFile(null);
        setSelectedFromNumber(fromNumbers.length ? normaliseUSPhoneNumber(fromNumbers[0]) : '');
    };

    const resetFileUpload = () => {
        setIsUploaded(false);
        setIsUploadFailed(false);
        setSelectedFile(null);
    };

    const resetAndClose = () => {
        reset();
        onClose();
    };

    const handleClose = () => {
        resetAndClose();
    };

    const fileInputClicked = () => {
        inputFile.current.click();
    };

    const setUploadedStatus = (isUploaded: boolean) => {
        setIsUploaded(isUploaded);
        setIsUploadFailed(!isUploaded);
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            setSelectedFile(event.target.files[0]);
            setUploadedStatus(true);
        } else {
            setUploadedStatus(false);
        }
    };

    const handleClickCancel = () => {
        resetAndClose();
    };

    const handleClickSend = async () => {
        if (
            userCredentials?.kazooAccountId &&
            userCredentials?.kazooUserToken &&
            selectedFile &&
            selectedFromNumber
        ) {
            sendFax(selectedFile, selectedFromNumber, recipientNumber);
        } else {
            console.log('Error occurred in fetching kazoo account details');
        }
        resetAndClose();
    };

    // functions for Recipient Number box
    const updateRecipientNumber = useCallback((getRecipientNumber: (oldRecipientNumber: string) => string) => {
        setRecipientNumber((oldRecipientNumber) => {
            const newRecipientNumber = getRecipientNumber(oldRecipientNumber);
            return newRecipientNumber;
        });
    }, []);

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            updateRecipientNumber(() => e.target.value.replace(/\D+/g, ''));
        },
        [updateRecipientNumber]
    );

    // functions for drag and drop file
    const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const validateFile = (file: File) => {
        const validTypes = ['application/pdf', 'image/tiff'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        return true;
    };

    const handleFile = (file: File) => {
        if (validateFile(file)) {
            setSelectedFile(file);
            setUploadedStatus(true);
        } else {
            setUploadedStatus(false);
        }
    };

    const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFile(files[0]);
        }
    };

    const handleDropDownChange = (selectedNum: string | null) => {
        if (selectedNum) setSelectedFromNumber(normaliseUSPhoneNumber(selectedNum));
    };

    const renderLocationDropDown = () =>
        fromNumbers.length && fromNumbers.length > 1 ? (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={fromNumberDropDownTheme}>
                    <StyledLocationDropDownWrapper>
                        <Autocomplete
                            data-testid="send-fax-from"
                            options={fromNumbers}
                            value={formatUSPhoneNumber(selectedFromNumber)}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            style={{ width: '504px' }}
                            renderInput={(params) => <TextField {...params} variant="outlined" label={locationsLabel} />}
                            onChange={(item, value) => handleDropDownChange(value)}
                        />
                    </StyledLocationDropDownWrapper>
                </ThemeProvider>
            </StyledEngineProvider>
        ) : (
            <></>
        );

    const renderFileUploadedContainer = (isSuccess: boolean) => {
        return (
            <UploadContainer success={isSuccess}>
                <FileUploadLeftContainer>
                    {isSuccess ? (
                        <IconButton className={classes.uploadedIcon} size="large">
                            <CheckCircleOutlineRoundedIcon />
                        </IconButton>
                    ) : (
                        <IconButton className={classes.uploadFailedIcon} size="large">
                            <ErrorOutlineRoundedIcon />
                        </IconButton>
                    )}
                    {isSuccess ? (
                        <Typography variant="body2" data-testid="send-fax-file-upload-success" className={classes.successText}>
                            {selectedFile?.name}
                        </Typography>
                    ) : (
                        <Typography variant="body2" data-testid="send-fax-file-upload-fail" className={classes.failureText}>
                            Unsupported file format. Try uploading again.
                        </Typography>
                    )}
                </FileUploadLeftContainer>
                <FileUploadRightContainer>
                    <IconButton
                        data-testid="send-fax-file-clear-button"
                        onClick={resetFileUpload}
                        className={isSuccess ? classes.uploadedFileClearIcon : classes.uploadedFileClearIconFailed}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </FileUploadRightContainer>
            </UploadContainer>
        );
    };

    return <>
        <Dialog open={open} onClose={handleClose}>
            <Container>
                <StyledWrapper>
                    <Typography variant="h6">Send Fax</Typography>
                </StyledWrapper>
                <StyledWrapper>
                    <Typography variant="body1">Add a recipient and attach a file to send a fax.</Typography>
                </StyledWrapper>

                {renderLocationDropDown()}

                <TextFieldContainer>
                    <TextFieldStyled
                        data-testid="send-fax-recipient"
                        label="Recipient"
                        variant="outlined"
                        value={displayRecipientNumber}
                        onChange={handleChange}
                    />
                </TextFieldContainer>

                {(!isUploaded || isUploadFailed) && (
                    <div>
                        <FileUploadContainer
                            data-testid="send-fax-file-upload-container"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop}
                        >
                            <input
                                type="file"
                                accept="application/pdf,image/tiff"
                                id="file"
                                ref={inputFile}
                                onChange={(e) => onFileUpload(e)}
                                data-testid="send-fax-file"
                            />
                            <IconButton
                                data-testid="send-fax-select-file-button"
                                className={classes.attachFileIcon}
                                onClick={fileInputClicked}
                                size="large">
                                <AttachFileRoundedIcon />
                            </IconButton>
                            <StyledWrapper>
                                <Typography variant="subtitle2">
                                    Drag and drop a file or{' '}
                                    <a data-testid="send-fax-select-file-link" onClick={fileInputClicked}>
                                        <span className={classes.linkTextStyle}>attach a file</span>
                                    </a>
                                    .
                                </Typography>
                            </StyledWrapper>
                            <StyledWrapper>
                                <Typography variant="body2">Attach a PDF or TIFF.</Typography>
                            </StyledWrapper>
                        </FileUploadContainer>
                    </div>
                )}

                {(isUploaded || isUploadFailed) && (
                    <div>
                        <UploadedFileTextContainer data-testid="send-fax-file-uploaded-container">
                            <Typography variant="subtitle2">Uploaded File</Typography>
                        </UploadedFileTextContainer>
                    </div>
                )}

                {isUploaded && renderFileUploadedContainer(isUploaded)}

                {isUploadFailed && renderFileUploadedContainer(!isUploadFailed)}

                <ButtonWrapper>
                    <Button
                        data-testid="send-fax-submit-button"
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.sendButton}
                        onClick={handleClickSend}
                        disabled={!selectedFile || recipientNumber.length != 10}
                    >
                        SEND FAX
                    </Button>
                    <Button
                        data-testid="send-fax-cancel-button"
                        variant="contained"
                        size="medium"
                        className={classes.cancelButton}
                        onClick={handleClickCancel}
                    >
                        CANCEL
                    </Button>
                </ButtonWrapper>
            </Container>
        </Dialog>
    </>;
}
