import { makeStyles } from '@mui/styles';
import { Avatar } from '@revenuewell/ui-icons';
import moment from 'moment';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { ChannelSearch } from '../../../../services/inbox-service/types';
import InboxService from '../../../../services/inbox-service/inbox-service';
import { ContactSearchByNumber } from '../../../../services/api-client/contact-api';
import { EventPatientProfile } from '../../../../types/event-bus';
import { formatUSPhoneNumber } from '../../../../utils/formatter';

const useClasses = makeStyles({
    container: {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 0px',
        borderBottom: '1px solid lightgrey',
        margin: '0px 20px'
    },
    avatar: {
        paddingLeft: '10px'
    },
    contactInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 10px'
    },
    name: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '28px',
        color: '#000',
        padding: '0px 12px'
    },
    subTextContainer: { display: 'flex', flexDirection: 'column', padding: '0px 12px' },
    subText: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '28px',
        color: '#565F6F'
    },
    invalidPhone: {
        color: '#E25650'
    }
});

interface FindPatientCardProps {
    contactInfo: ContactSearchByNumber;
}

export default function SelectPatientCard({ contactInfo }: FindPatientCardProps) {
    const classes = useClasses();
    const { publish } = useEventBus();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { mailboxes } = usePubnub();
    const [recentComm, setRecentComm] = useState<ChannelSearch>();

    const renderPhone = () => {
        if (contactInfo.cellPhone) return formatUSPhoneNumber(contactInfo.cellPhone);
        else return <span className={classes.invalidPhone}>no cell phone</span>;
    };

    const fetchRecentComms = async (contactInfo: ContactSearchByNumber) => {
        const phoneNumber = contactInfo.cellPhone || contactInfo.homePhone || contactInfo.workPhone;
        if (!phoneNumber) return;

        const currentMailbox = mailboxes.find(mailbox => mailbox.locationId === contactInfo.locationId);

        if (!currentMailbox) return;

        const searchRequest = {
            mailboxIds: [currentMailbox.id],
            phoneNumbers: [phoneNumber]
        };

        const inboxService = InboxService.getInstance(config, oidcService);
        const inboxResponse = await inboxService.channelSearch(searchRequest);

        setRecentComm(inboxResponse[0]);
    };

    const getLastContactedDate = () => {
        if (!recentComm) return;

        let mostRecent = '';
        if (recentComm.lastCall && recentComm.lastMessage) {
            if (recentComm.lastCall.timeToken > recentComm.lastMessage.timeToken) {
                mostRecent = 'lastCall';
            } else {
                mostRecent = 'lastMessage';
            }
        }

        if ((recentComm.lastMessage && mostRecent === '') || (recentComm.lastMessage && mostRecent === 'lastMessage')) {
            return recentComm.lastMessage.date;
        }

        if ((recentComm.lastCall && mostRecent === '') || (recentComm.lastCall && mostRecent === 'lastCall')) {
            return recentComm.lastCall.date;
        }
    };

    const handlePatientClick = () => {
        publish({
            messageType: 'patientProfile',
            data: {
                patientId: contactInfo.id,
                locationId: contactInfo.locationId
            } as EventPatientProfile['data']
        });
    };

    useEffect(() => {
        fetchRecentComms(contactInfo);
    }, []);

    return (
        <Box
            className={classes.container}
            onClick={handlePatientClick}
            sx={{
                opacity: !contactInfo.cellPhone ? 0.4 : 1,
                '&:hover': {
                    backgroundColor: !contactInfo.cellPhone ? 'inherited' : '#f0f1f4'
                },
                '&:active': {
                    backgroundColor: '#fff'
                }
            }}
        >
            <div className={classes.avatar}>
                <Avatar size='l' />
            </div>

            <div className={classes.contactInfoContainer}>
                <label className={classes.name}>{`${contactInfo.firstName} ${contactInfo.lastName}`}</label>
                <div className={classes.subTextContainer}>
                    <Typography variant='caption' className={classes.subText}>
                        {contactInfo.dateOfBirth && (
                            <>
                                {moment(contactInfo.dateOfBirth).format('MM/DD/YYYY')}
                                <Box component='span' m={1} display='inline'>
                                    |
                                </Box>
                            </>
                        )}
                        {renderPhone()}
                    </Typography>
                    {recentComm && (
                        <Typography variant='caption' className={classes.subText}>
                            Last contacted: {moment(getLastContactedDate()).format('MM/DD/YYYY')}
                        </Typography>
                    )}
                </div>
            </div>
        </Box>
    );
}
