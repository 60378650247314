import React, { useMemo } from 'react';
import { Menu, MenuItem, MenuItemProps, Typography } from '@mui/material';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import InboxService from '../../../../services/inbox-service/inbox-service';
import { Status } from './commincations-widget';

const IS_UNREAD = 'isUnread';
const IS_ARCHIVED = 'isArchived';
const IS_SAVED = 'isSaved';

interface CommsActionsMenuListProps {
    patientPhoneNumbers: string[];
    patientMailboxIds: number[];
    channelStatus: Status;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
};

export default function CommsActionsMenuList({
    patientPhoneNumbers,
    patientMailboxIds,
    channelStatus,
    anchorEl,
    handleClose
}: CommsActionsMenuListProps) {
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    const inboxService = useMemo(() => InboxService.getInstance(config, oidcService), [config, oidcService]);

    if (!patientMailboxIds) return null;

    const handleItemSelect = async (event: React.MouseEvent<HTMLLIElement>, value: OptionValue) => {
        event.stopPropagation();

        const caseHandler: any = {
            [IS_ARCHIVED]: async () => {
                await Promise.all(patientPhoneNumbers.flatMap(patientPhoneNumber => patientMailboxIds.map((mailboxId) => inboxService.applyChannelAction(patientPhoneNumber, mailboxId, {
                    isArchived: !channelStatus.isArchived
                }))));
            },
            [IS_SAVED]: async () => {
                await Promise.all(patientPhoneNumbers.flatMap(patientPhoneNumber => patientMailboxIds.map((mailboxId) => inboxService.applyChannelAction(patientPhoneNumber, mailboxId, {
                    isSaved: !channelStatus.isSaved
                }))));
            },
            [IS_UNREAD]: async () => {
                await Promise.all(patientPhoneNumbers.flatMap(patientPhoneNumber => patientMailboxIds.map((mailboxId) => inboxService.applyChannelAction(patientPhoneNumber, mailboxId, {
                    isUnread: !channelStatus.isUnread,
                    isUnreadMessenger: !channelStatus.isUnread,
                    isUnreadPhone: !channelStatus.isUnread
                }))));
            },
        };
        await caseHandler[value]();
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{ sx: { backgroundColor: '#FFFFFF' } }}
        >
            <Option
                clickHandler={handleItemSelect}
                flag={channelStatus.isUnread}
                options={['Mark Read', 'Mark Unread']}
                value={IS_UNREAD}
            />
            <Option
                clickHandler={handleItemSelect}
                flag={channelStatus.isSaved}
                options={['Unsave', 'Save']}
                value={IS_SAVED}
            />
            <Option
                clickHandler={handleItemSelect}
                flag={channelStatus.isArchived}
                options={['Unarchive Conversation', 'Archive Conversation']}
                value={IS_ARCHIVED}
            />
        </Menu>
    );
}

type OptionValue = typeof IS_UNREAD | typeof IS_SAVED | typeof IS_ARCHIVED;

type OptionProps = {
    clickHandler: (event: React.MouseEvent<HTMLLIElement>, value: OptionValue) => void;
    flag?: boolean;
    options: string[];
    value: OptionValue;
};

export const Option = React.forwardRef(
    ({ clickHandler, flag, options, value }: OptionProps, ref: MenuItemProps['ref']) => {
        return (
            <MenuItem ref={ref} onClick={(e: any) => clickHandler(e, value)}>
                {flag ? <Typography>{options[0]}</Typography> : <Typography>{options[1]}</Typography>}
            </MenuItem>
        );
    }
);

