import { AuthApiService, OidcService, NavigationService } from '@revenuewell/front-end-bundle';
import { stringify } from 'query-string';

export class TextTemplatesService extends AuthApiService {
  private config: any;

  constructor(config: any, oidcService: OidcService) {
    super(oidcService, new NavigationService());
    this.config = config;
  }

  public async getAllTemplatesForMultiLocations(
    masterAccountId: number,
    locationIds: number[],
    page: number,
    pageSize: number
  ) {
    const query: Record<string, string | number> = { page, pageSize };
    const url = `${this.config.voice.apiUrl}/settings/customers/${masterAccountId}/text-templates?${stringify(query)}`;
    const templateRecs = await this.authFetch(url);
    const templates = await templateRecs.json() as PaginatedTemplatesForMultipleLocations;

    return { 
      ...templates,
      results: templates.results.filter((template) => {
        return template.locations.length === 0 || template.locations.some((loc) => locationIds.includes(loc));
      })
    }
  }
}

export interface TemplateForMultipleLocations {
  name: string;
  text: string;
  id: number;
  masterAccountId?: number;
  locations: number[];
}

export interface PaginatedTemplatesForMultipleLocations {
  totalRows: number;
  currentPage: number;
  pageSize: number;
  pageCount: number;
  results: TemplateForMultipleLocations[];
}
