import { CallHistoryItem, CallType, CallTypes, getDiffBetweenDates } from "../types/comms-call-types"

export const mapCallNotificationToCallHistoryItem = (notificationData: any,
    locationId: number, masterAccountId: number): CallHistoryItem => {
    const callStatedDate = new Date(notificationData.call_started);
    const callEndedDate = new Date(notificationData.call_ended);

    return {
        id: notificationData.call_id,
        callDirection: notificationData.direction === 'inbound' ? "Inbound" : "Outbound",
        calleeNumber: notificationData.direction === 'inbound' ? notificationData.to : notificationData.from,
        calleeUser: '',
        callerDestinationNumber: '',
        callerName: '',
        callerNumber: notificationData.direction === 'inbound' ? notificationData.from : notificationData.to,
        callerUser: '',
        callStarted: notificationData.call_started,
        callType: mapCallType(notificationData),
        duration: getDiffBetweenDates(callStatedDate, callEndedDate),
        locationId: locationId,
        masterAccountId: masterAccountId,
        timetoken: new Date(notificationData.call_started).getTime(),
        callEnded: notificationData.call_ended
    }
}

const mapCallType = (notificationData: any): CallType => {
    if(notificationData.has_voicemail && notificationData.has_voicemail.toLowerCase() === "true") return CallTypes.VOICEMAIL;
    if(notificationData.call_missed && notificationData.call_missed.toLowerCase() === "true") return CallTypes.MISSED_CALL;
    if(notificationData.direction === 'inbound') return CallTypes.INBOUND_CALL;
    if(notificationData.direction === 'outbound') return CallTypes.OUTBOUND_CALL;
    return CallTypes.INBOUND_CALL;
}