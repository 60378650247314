import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useEventBus } from '../use-event-bus';

export type ViewModeType = 'Phone' | 'Tablet' | 'Desktop';
export type HeightModeType = 'Short' | 'Medium' | 'Tall';

export enum ViewModeEnum {
    Phone = 480,
    Tablet = 768,
    Desktop = 1024
}

export enum HeightMode {
    Short = 760,
    Medium = 1030,
    Tall = 1600
}

interface ViewModeContextProps {
    viewMode: ViewModeType;
    heightMode: HeightModeType;
    publishViewMode: () => void;
}

export const ViewModeContext = createContext<ViewModeContextProps>(null!);

export default function ViewModeProvider({ children }: PropsWithChildren<{}>) {
    const [viewMode, setViewMode] = useState<ViewModeType>('Desktop');
    const [heightMode, setHeightMode] = useState<HeightModeType>('Tall');
    const { publishToIframes } = useEventBus();

    useEffect(() => {
        publishToIframes({
            messageType: 'wrapper-view-mode',
            data: { viewMode: viewMode }
        });
    }, [viewMode]);

    const publishViewMode = useCallback(() => {
        publishToIframes({
            messageType: 'wrapper-view-mode',
            data: { viewMode: viewMode }
        });
    }, [viewMode]);

    useEffect(() => {
        const checkMobile = window.matchMedia(`screen and (max-width: ${ViewModeEnum.Phone}px)`);
        const checkTablet = window.matchMedia(
            `screen and (min-width: ${ViewModeEnum.Tablet}px) and (max-width: ${ViewModeEnum.Desktop - 1}px)`
        );
        const checkDesktop = window.matchMedia(`screen and (min-width: ${ViewModeEnum.Desktop}px)`);

        const checkShort = window.matchMedia(`screen and (max-height: ${HeightMode.Short}px)`);
        const checkMedium = window.matchMedia(
            `screen and (min-height: ${HeightMode.Short + 1}px) and (max-height: ${HeightMode.Medium}px)`
        );
        const checkTall = window.matchMedia(`screen and (min-height: ${HeightMode.Medium + 1}px)`);

        const changeViewEvent = (type: ViewModeType) => {
            return (e: MediaQueryListEvent) => {
                if (e.matches) {
                    setViewMode(type);
                }
            };
        };

        const changeHeightEvent = (type: HeightModeType) => {
            return (e: MediaQueryListEvent) => {
                if (e.matches) {
                    setHeightMode(type);
                }
            };
        };

        if (checkMobile.matches) {
            changeViewEvent('Phone')({ matches: true } as MediaQueryListEvent);
        } else if (checkTablet.matches) {
            changeViewEvent('Tablet')({ matches: true } as MediaQueryListEvent);
        } else if (checkDesktop.matches) {
            changeViewEvent('Desktop')({ matches: true } as MediaQueryListEvent);
        }

        if (checkShort.matches) {
            setHeightMode('Short');
        } else if (checkMedium.matches) {
            setHeightMode('Medium');
        } else {
            setHeightMode('Tall');
        }

        checkMobile.addEventListener('change', changeViewEvent('Phone'));
        checkTablet.addEventListener('change', changeViewEvent('Tablet'));
        checkDesktop.addEventListener('change', changeViewEvent('Desktop'));

        checkShort.addEventListener('change', changeHeightEvent('Short'));
        checkMedium.addEventListener('change', changeHeightEvent('Medium'));
        checkTall.addEventListener('change', changeHeightEvent('Tall'));
    }, []);

    return (
        <ViewModeContext.Provider value={{ viewMode, heightMode, publishViewMode }}>
            {children}
        </ViewModeContext.Provider>
    );
}
