import * as React from 'react';
import { Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  section: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E9EAEE',
  },
  arrowSection: {
    cursor: 'pointer',
    transition: '0.1s',
    '&:hover': {
      backgroundColor: '#f9fafb',
      transition: '0.1s',
    }
  },
  container: {
    padding: '12px 15px 12px 15px',
    flexGrow: 2,
    '& label': {
      color: '#565F6F',
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '20px'
    }
  },
  header: {
    color: '#000000',
    fontFamily: 'Roboto',
    marginBottom: '4px',
    fontSize: '16px',
  },
  headerDisabled: {
    color: '#73777D',
    fontFamily: 'Roboto',
    marginBottom: '4px'
  },
  navNext: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});

export interface IProps {
  header: string | React.ReactElement;
  showNavArrow?: boolean;
  disabled?: boolean;
  onClick?(): void;
}

const AccordionSection: React.FC<IProps> = props => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.section} ${props.showNavArrow ? classes.arrowSection : ''}`}
      onClick={() => props.onClick && props.disabled !== true ? props.onClick() : null}
    >
      <div className={classes.container}>
        {props.header !== '' ? (
          <div>
            <Typography
              variant='subtitle2'
              className={props.disabled ? classes.headerDisabled : classes.header}
            >
              {props.header}
            </Typography>
          </div>
        ) : null}
        {props.children}
      </div>
      {props.showNavArrow && props.disabled !== true ? (
        <div className={classes.navNext}>
          <NavigateNextIcon sx={{ color: '#616C79' }}></NavigateNextIcon>
        </div>
      ) : null}
    </div>
  );
};

export default AccordionSection;
