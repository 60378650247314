import { FC, useState } from "react";
import { NavigationBreadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../header/header";
import moment from "moment";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import AccordionSection from "../../../../../shared/accordion/accordion-section";
import AccordionContent from "../../../../../shared/accordion/accordion-content";
import { PhoneComponent } from "./phone-component/phone-component";
import { PhoneType } from "./types";
import { FamilyMembers } from "./family-members/family-members";
import { ViewContent } from "../shared/view-content/view-content";

export const GeneralView: FC = () => {
  const { contactDetails, patientInfo, isSkinnyProfile } = usePatientProfileWidget();

  const dateOfBirth = contactDetails.dateOfBirth
    ? moment(contactDetails.dateOfBirth).format('MMMM DD, YYYY')
    : '-';
  const email = contactDetails.email ? contactDetails.email : '-';

  const patientAddress = () => {
    const patientIndex = patientInfo.familyMembers?.findIndex(familyMember => familyMember.id === patientInfo.id);
    return patientIndex !== -1 ? patientInfo.familyMembers![patientIndex!].address : null;
  };

  const addressLine1 = patientAddress()?.address1 ? patientAddress()?.address1 : null;
  const addressLine2 = patientAddress()?.address2 ? patientAddress()?.address2 : null;
  const addressLine3 = patientAddress()?.city && patientAddress()?.state && patientAddress()?.zip
    ? `${patientAddress()?.city}, ${patientAddress()?.state} ${patientAddress()?.zip}`
    : null;

  const familyMembers = () => {
    const members = patientInfo.familyMembers;
    const filteredMembers = members?.filter(member => member.id !== contactDetails.id);
    return filteredMembers!;
  };

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContent sx={{ padding: '0 20px'}}>
      <AccordionSection header='Date of Birth'>
        <AccordionContent>
          <label>{dateOfBirth}</label>
        </AccordionContent>
      </AccordionSection>
      <AccordionSection header='Phone Numbers'>
        <AccordionContent>
          <PhoneComponent phoneNumber={contactDetails.cellPhone} phoneType={PhoneType.Mobile} />
          <PhoneComponent phoneNumber={contactDetails.homePhone} phoneType={PhoneType.Home} />
          <PhoneComponent phoneNumber={contactDetails.workPhone} phoneType={PhoneType.Work} />
        </AccordionContent>
      </AccordionSection>
      <AccordionSection header='Email'>
        <AccordionContent>
          <label>{email}</label>
        </AccordionContent>
      </AccordionSection>
      {!isSkinnyProfile && (
        <>
          <AccordionSection header='Address'>
            <AccordionContent>
              {addressLine1 ? (
                <>
                  <label>{addressLine1}</label>
                  <label>{addressLine2}</label>
                  <label>{addressLine3}</label>
                </>
              ) : (
                '-'
              )}
            </AccordionContent>
          </AccordionSection>
          <AccordionSection header='Family'>
            <AccordionContent>
              {patientInfo.familyMembers && <FamilyMembers familyMembers={familyMembers()} />}
            </AccordionContent>
          </AccordionSection>
        </>
      )}
    </ViewContent>
  </>
}