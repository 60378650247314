import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useNotification, useSms, useVistualVisit, VirtualVisit } from '@revenuewell/front-end-bundle'
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle'
import { StartVirtualVisits } from '@revenuewell/widget-virtual-visit-starter'
import { useEventBus } from '../../../hooks/use-event-bus'
import { EventStartVirtualVisit } from '../../../types/event-bus'
import { Contact } from './types'

export const VirtualVisitInvite: FC = (): ReactElement => {
    const { sendSms } = useSms()
    const { listen } = useEventBus()
    const { showError, showWarning } = useNotification()
    const { getClaims, signinRedirect } = useOidcWithAdmin()
    const { startVirtualVisit } = useVistualVisit()
    const [visible, setVisible] = useState<boolean>(false)
    const [contact, setContact] = useState<Contact>()
    const [virtualVisit, setVirtualVisit] = useState<VirtualVisit>()
    const [invintationMessage, setInvintationMessage] = useState<string>("")
    
    useEffect(() => {
        return listen('start-virtual-visit', event => {
            if (event.messageType !== 'start-virtual-visit') return
            handleStartVirtualVisitMessage(event)
        })
    }, [listen])

    const handleStartVirtualVisitMessage = async (event: EventStartVirtualVisit) => {
        const claims = await getClaims()
        const virtualVisit = await startVirtualVisit(
            claims.id, event.data.contactLocationId, event.data.contactId)

        setVirtualVisit(virtualVisit)
        setContact({ locationId: event.data.contactLocationId, cellPhone: event.data.contactCellPhone })
        setInvintationMessage(event.data.contactCellPhone
            ? `For our Virtual Visit, please go here: ${virtualVisit.links.roomParticipantLink}`
            : virtualVisit.links.roomParticipantLink)
       
        setVisible(true)
    }

    const sendInvintationSms = (text: string) => {
        if(contact && contact.cellPhone) {
            sendSms(contact.locationId, contact.cellPhone, text)
                .catch((error) => {
                    switch(error.status) {
                        case 401:
                            signinRedirect()
                            break;
                        default:
                            showError("Unable to send SMS invitation.")
                    }
                })
        } else {
            showWarning("An invintation mssage was not sent to patient because cell phone is not provided.")
        }
    }

    return (
        <>
            {visible &&
                <StartVirtualVisits
                    message={invintationMessage}
                    open={visible}
                    onStart={(message: string) => {
                        window.open(virtualVisit?.links.roomProviderLink, "VideoVisit", "height=970,width=1400")
                        sendInvintationSms(message)
                        setVisible(false)
                    }}
                    onCancel={() => setVisible(false)}
                />
            }
        </>
    )
}
