import { createContext, ReactElement, useCallback, useContext } from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import PublisherService from '../../services/publisher-service/publisher-service';
import { Message } from '../../services/pubnub-service';
import { usePubnub } from '../use-pubnub/use-pubnub';
import { useCommunications } from './use-communications';

interface ICommsChatContext {
    sendMessage: (message: string, channelId: string) => void;
    sendMessageToServer: (message: Message, mailboxId: number) => void
}

const CommsChatContext = createContext<ICommsChatContext | null>(null);

export const useCommsChat = (): ICommsChatContext => {
    const context = useContext(CommsChatContext);
    if (!context)
        throw new Error('useCommsChat must be used within an CommsChatContext');

    return context;
};

export function CommsChatProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const { config } = useConfig();
    const { oidcService, getClaims } = useOidcWithAdmin();
    const { phoneNumbers, mailboxes } = usePubnub();
    const { addOrUpdateMessage } = useCommunications();

    const sendMessageToServer = useCallback(
        (message: Message, mailboxId: number) => {
            const publisherService = new PublisherService(config, oidcService, mailboxId);       
        
            publisherService
                ?.sendMessage(message.from, message.to, message.body)
                .then(() => {
                    addOrUpdateMessage({ ...message, status: 'sent' });
                })
                .catch(() => {
                    // here can be case when we don't get status update from pubnub
                    if (message.status !== 'error') addOrUpdateMessage({ ...message, status: 'error' });
                });
        },
        []
    );

    const sendMessage = useCallback(
        async (message: string, channelId: string) => {
            const curMailbox = mailboxes.find(d => d.pubnubPrefix === channelId.split('.')[0]);
            if (!curMailbox)
                return;

            const user = await getClaims();
            const patientPhoneNumber = channelId.split('.')[1];
            const locationPhoneNumber = phoneNumbers[curMailbox.locationId]
                .filter((phone) => phone.isPrimaryOutbound).pop()?.phoneNumber;
            const from = locationPhoneNumber ? locationPhoneNumber.replaceAll('-', '') : '';
            const now = new Date();

            const newMessage: Message = {
                channelId: channelId,
                to: patientPhoneNumber,
                from: from,
                body: message,
                date: now.toISOString(),
                timetoken: now.getTime(),
                messageType: 'direct',
                senderUserId: user.id,
                status: 'sending',
                senderUserName: `${user.firstName} ${user.lastName}`
            };

            sendMessageToServer(newMessage, curMailbox.id);
        },
        [mailboxes, phoneNumbers, getClaims]
    );

    return (
        <CommsChatContext.Provider value={{ sendMessage, sendMessageToServer }}>
            {children}
        </CommsChatContext.Provider>
    );
}