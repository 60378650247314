import { OidcService, AuthApiService, NavigationService } from '@revenuewell/front-end-bundle';

export enum FormType {
  'Predefined Form',
  'Health History',
  'Printed Form',
  'Custom Form',
  'Imported Form',
}

export interface FormLink {
  id: number;
  patientId?: number;
  name?: string;
  type: number;
  link?: string;
  isOnPatientPortal: boolean;
  isOnPublicPortal: boolean;
}

export default class QuickActionsService extends AuthApiService {
  private static instance: QuickActionsService;
  private formsUrl: string;
  private forms2Url: string;
  private patientUrl: string;
  private appointmentUrl: string;
  private paymentUrl: string;

  private constructor(config: any, oidcService: OidcService) {
    super(oidcService, new NavigationService());
    this.formsUrl = config.forms.apiUrl;
    this.patientUrl = config.patient.apiUrl;
    this.appointmentUrl = config.appointment.apiUrl;
    this.paymentUrl = config.payment.apiUrl;
    this.forms2Url = config.forms2.apiUrl;
    return this;
  }

  public static getInstance(config: any, oidcService: OidcService) {
    if (!QuickActionsService.instance) QuickActionsService.instance = new QuickActionsService(config, oidcService);

    return QuickActionsService.instance;
  }

  private baseUrl(locationId: number) {
    return this.formsUrl + '/locations/' + locationId;
  }

  private baseUrlPatient(locationId: number) {
    return this.patientUrl + '/locations/' + locationId;
  }
  private baseUrlAppointments(locationId: number) {
    return this.appointmentUrl + '/locations/' + locationId;
  }

  private baseUrlPayments(locationId: number) {
    return this.paymentUrl + '/locations/' + locationId;
  }

  public async getPatientFormLink(locationId: number, patientId?: number, formTypes?: number[]): Promise<FormLink[]> {
    try {
      let baseUrl = this.baseUrl(locationId);
      //handle wrapper proxy
      if (!baseUrl.startsWith('https')) baseUrl = window.location.origin + baseUrl;

      const url = new URL(`${baseUrl}/forms`);
      url.search = new URLSearchParams({
        'form-status': '1',
        ...(patientId && { 'patient-id': String(patientId) }),
        ...(formTypes && { 'form-type': formTypes.map((f) => String(f)).join(',') }),
      }).toString();
      const response = await this.authFetch(url.toString(), {});

      const data: FormLink[] = await response.json();

      return data.filter((d) => !!d.link);
    } catch (error: any) {
      console.error(error);
      return [];
    }
  }

  public async getPaymentLink(locationId: number): Promise<string> {
    try {
      const url = `${this.baseUrlPayments(locationId)}/patient-payment-link`;

      const response = await this.authFetch(url);
      const data = (await response.text()).replace(/"/g, '');

      return data;
    } catch (error: any) {
      return '';
    }
  }

  public async getLoginPortalLink(locationId: number): Promise<string> {
    try {
      const url = `${this.baseUrlPatient(locationId)}/patient-login-link`;

      const response = await this.authFetch(url);
      const data = (await response.text()).replace(/"/g, '');

      return data;
    } catch (error: any) {
      return '';
    }
  }

  public async getOnlineSchedulingLink(locationId: number): Promise<string> {
    try {
      const url = `${this.baseUrlAppointments(locationId)}/patient-book-appointment-link`;

      const response = await this.authFetch(url);

      const data = (await response.text()).replace(/"/g, '');
      return data;
    } catch (error: any) {
      return '';
    }
  }

  public async getIsMigratedToForms2(locationId: number): Promise<boolean> {
    try {
      const url = `${this.forms2Url}/api/locations/getIsMigratedToForms2?locationId=${locationId}`;
      const response = await this.authFetch(url);

      const data = await response.text();
      return data.toLowerCase() === 'true';
    } catch (error: any) {
      return false;
    }
  }

  public async getForms2Links(locationId: number): Promise<FormLink[]> {
    try {
      const url = `${this.forms2Url}/api/forms/getFormsLinksByLocationId?locationId=${locationId}`;
      const response = await this.authFetch(url);
      const data: FormLink[] = await response.json();

      return data.filter((d) => !!d.link);
    } catch (error: any) {
      return [];
    }
  }

  public async getCareCreditQuickLinks(masterAcctountId: number, locationId: number, phoneNumber: string): Promise<CareCreditQuickLinks | null> {
    try {
      const url = `${this.paymentUrl}/carecredit/${masterAcctountId}/quick-links/${locationId}?phoneNumber=${phoneNumber}`;
      const response = await this.authFetch(url);
      return await response.json();
    } catch (error: any) {
      console.error("CareCredit Quick Links response error: ", error);
      return null;
    }
  }
}

export type CareCreditQuickLinks = {
  locationLink?: string,
  patientLinks?: { fullName: string, link: string }[]
}
