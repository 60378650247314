import { Phone as PhoneIcon } from '@mui/icons-material';
import { Box, ListItem, ListItemText, Typography, styled } from "@mui/material";
import moment from 'moment';
import { useMemo } from "react";
import { useContactState } from '../../../../hooks/use-contacts';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { EventPatientProfile, EventSelectPatientWidget } from '../../../../types/event-bus';
import { importantContacts } from '../../../../utils/contact';
import { formatUSPhoneNumber } from '../../../../utils/formatter';
import { InboxIconButton } from '../inbox-widget/styles/inbox-styles';

const PatientCard = styled(ListItem)({
    padding: '8px 16px',
    border: '1px solid #D5D9DD',
    borderRadius: '8px'
});

const PatientName = styled(Typography)({
    color: '#000000',
    height: '24px',
    fontSize: '16px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '70%',
    '&:hover': {
        color: '#0675DD',
        cursor: 'pointer'
    }
});

const PhoneNumber = styled(Typography)({
    color: '#000000',
    height: '24px',
    fontSize: '16px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '70%'
});

interface CommsPatientCardProps {
    phoneNumbers: string[];
    patientId?: number;
    locationId: number;
}

const CommsPatientCard = ({ phoneNumbers, patientId, locationId }: CommsPatientCardProps) => {
    const { mailboxes } = usePubnub();
    const { contactCache } = useContactState();
    const { publish } = useEventBus();

    const mailbox = mailboxes.find(mb => mb.locationId === locationId);
    const contacts = useMemo(() => {
        const tmpContacts = phoneNumbers.flatMap(phoneNumber =>
            contactCache[phoneNumber] ? contactCache[phoneNumber].data : []
        );
        return tmpContacts;
    }, [phoneNumbers]);


    const finalContacts = useMemo(() => {
        if (contacts && contacts.length) {
            const finalContacts = importantContacts(contacts, patientId, mailbox?.location.idNetwork);
            return finalContacts;
        }
        return [];
    }, [contacts, mailbox, patientId]);

    const contactInfo = useMemo(() => {
        if(patientId){
            return finalContacts.length ? finalContacts.filter((contact) => contact.id == patientId).pop() : null;
        }else{
            return finalContacts.length ? finalContacts[0] : null;
        }

    },[finalContacts, patientId])

    const phoneNumber = useMemo(() => {
        if(contactInfo?.cellPhone) return contactInfo.cellPhone;
        return phoneNumbers[0];
    }, [contactInfo, phoneNumbers])

    const contactName = contactInfo
        ? `${contactInfo.firstName} ${contactInfo.lastName}${finalContacts.length > 1 ? ' + Others' : ''}`
        : formatUSPhoneNumber(phoneNumbers[0]);

    const dateOfBirth = useMemo(() => {
        return contactInfo?.dateOfBirth ? moment(contactInfo.dateOfBirth).format('MM/DD/YYYY') : '';
    }, [contactInfo?.dateOfBirth]);

    const handlePhoneClick = () => {
        publish({
            messageType: 'startCall',
            data: { phoneNumber: phoneNumber }
        });
    };

    const handlePatientClick = (patientId: number, locationId: number | undefined) => {
        finalContacts.length > 1
            ? publish({
                  messageType: 'selectPatient',
                  data: {
                      contacts: finalContacts
                  } as EventSelectPatientWidget['data']
              })
            : publish({
                  messageType: 'patientProfile',
                  data: {
                      patientId,
                      locationId
                  } as EventPatientProfile['data']
              });
    };

    return (
        <PatientCard
            secondaryAction={
                <Box display='flex' flexDirection='row'>
                    <InboxIconButton onClick={handlePhoneClick}>
                        <PhoneIcon fontSize='small' />
                    </InboxIconButton>
                </Box>
            }
        >
            <ListItemText
                primary={
                    finalContacts.length ? (
                        <PatientName
                            variant='h5'
                            onClick={() => handlePatientClick(finalContacts[0].id, finalContacts[0].locationId)}
                        >
                            {contactName}
                        </PatientName>
                    ) : (
                        <PhoneNumber variant='h5'>{contactName}</PhoneNumber>
                    )
                }
                secondary={
                    <Typography variant='body2' color='#2C3542' fontSize='12px'>
                        {contactInfo?.dateOfBirth && (
                            <>
                                {dateOfBirth}
                                <Box component='span' m={1} display='inline'>
                                    {' '}
                                    |{' '}
                                </Box>
                            </>
                        )}
                        {phoneNumbers.length > 0 ? formatUSPhoneNumber(phoneNumber) : ''}
                    </Typography>
                }
            />
        </PatientCard>
    );
};

export default CommsPatientCard;