import { TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { UIEvent, useCallback, useRef } from 'react';
import { useAlerts } from '../../../../hooks/use-alerts';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { Alert, AlertCategories } from '../../../../services/alerts-service/alerts-service';
import FormAlert from '../../../shared/alert/form-alert';
import AlertsEmpty from './alerts-empty';
import minBy from 'lodash/minBy';
import AppointmentRequestResponseAlert from '../../../shared/alert/appointment-request-response-alert';
import CampaignsUnsubscribedAlert from '../../../shared/alert/campaigns-unsubscribed-alert';
import NewEmailFromPatientAlert from '../../../shared/alert/new-email-from-patient-alert';

const useClasses = makeStyles({
    container: {
        height: '100%',
        backgroundColor: '#fff'
    },
    title: {
        marginLeft: 16,
        marginBottom: 12,
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '28px',
        color: '#000'
    },
    tabList: {
        borderTop: '1px solid #E1E4E7',
        borderBottom: '1px solid #E1E4E7'
    },
    tabItem: {
        textTransform: 'none'
    },
    tabPanel: {
        width: '100%',
        padding: 0,
        overflow: 'auto',
        position: 'absolute',
        top: 156,
        bottom: 0
    }
});

interface AlertsPanelProps {
    type: 'active' | 'dismissed';
}

export default function AlertsPanel({ type }: AlertsPanelProps) {
    const { alertsActive, alertsDismissed, dismiss, search } = useAlerts();
    const { mailboxes } = usePubnub();
    const classes = useClasses();
    const isLoading = useRef(false);

    const alerts = type === 'active' ? alertsActive : alertsDismissed;

    const handleOnScroll = useCallback(
        (event: UIEvent<HTMLDivElement>) => {
            const scroll = event.currentTarget;

            if (scroll.offsetHeight + scroll.scrollTop >= scroll.scrollHeight - 82 && isLoading.current === false) {
                isLoading.current = true;
                const minTime = minBy(alerts, a => new Date(a.serverCreationTimeStamp))?.serverCreationTimeStamp;
                search({ isDismissed: type === 'dismissed', startTime: minTime }).finally(
                    () => (isLoading.current = false)
                );
            }
        },
        [alerts, type, search]
    );

    const RenderAlert = useCallback(
        ({ alert }: { alert: Alert }) => {
            const location =
                mailboxes?.length > 1
                    ? mailboxes.find(mb => mb.locationId === alert.idLocation)?.location?.name
                    : undefined;

            const onRemove = alert.isDismissed ? undefined : () => dismiss(alert.id);

            if (alert.idCategory === AlertCategories.Forms) {
                return (
                    <FormAlert
                        onRemove={onRemove}
                        date={new Date(alert.serverCreationTimeStamp)}
                        count={Number(alert.customFieldValue1)}
                        location={location}
                    />
                );
            }

            if (alert.idCategory === AlertCategories.AppointmentRequestResponse) {
                return (
                    <AppointmentRequestResponseAlert
                        onRemove={onRemove}
                        date={new Date(alert.serverCreationTimeStamp)}
                        name={alert.customFieldValue1 as string}
                        appointmentDate={new Date(alert.customFieldValue5!)}
                        location={location}
                        locationId={alert.idLocation}
                        message={alert.customFieldValue4}
                        patientId={alert.customFieldValue2}
                    />
                );
            }

            if (alert.idCategory === AlertCategories.CampaignsUnsubscribed) {
                return (
                    <CampaignsUnsubscribedAlert
                        onRemove={onRemove}
                        date={new Date(alert.serverCreationTimeStamp)}
                        name={alert.customFieldValue1 as string}
                        patientId={alert.customFieldValue2}
                        location={location}
                    />
                );
            }

            if (alert.idCategory === AlertCategories.NewEmailFromPatient) {
                return (
                    <NewEmailFromPatientAlert
                        onRemove={onRemove}
                        date={new Date(alert.serverCreationTimeStamp)}
                        name={alert.customFieldValue1 as string}
                        patientId={alert.customFieldValue2 as string}
                        email={alert.customFieldValue4 as string}
                        location={location}
                    />
                );
            }

            return null;
        },
        [mailboxes, dismiss]
    );

    return (
        <TabPanel onScroll={handleOnScroll} className={classes.tabPanel} value={type}>
            {alerts.length === 0 && <AlertsEmpty type={type} />}
            {alerts.map(alert => {
                return <RenderAlert key={`alert-active-${alert.id}`} alert={alert} />;
            })}
        </TabPanel>
    );
}
