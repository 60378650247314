import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import { Typography } from '@mui/material';
import { useStyles } from './styles';
import { CareCreditQuickLinks } from '../../../../../../services/quick-actions-service/quick-actions-service';

interface IProps {
  onSelect: (val: string, e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  quickLinks: CareCreditQuickLinks;
}

export default function CareCreditLinkQuickAction({ onSelect, quickLinks }: IProps) {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose: MenuProps['onClose'] = (event) => {
    const e = event as React.MouseEvent<HTMLElement>;
    e.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleSelectLink = (link: string, e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onSelect(link || '', e);
    handleMenuClose(e, 'backdropClick');
  };

  const singleItemComponent = (title: string, link: string, key?: number) => {
    return <MenuItem
      key={key}
      data-testid="care-credit-quick-link"
      className={`${classes.menuItem} ${Boolean(menuAnchorEl) && classes.menuHighlighted}`}
      onClick={(e) => handleSelectLink(link, e)}
    >
      <Typography variant="body1" >&#8288;{title}</Typography>
    </MenuItem>
  }

  const patientLinksComponent = (patientLinks: { fullName: string, link: string }[]) => {
    if(!patientLinks || patientLinks.length === 0) return null;
    if(patientLinks.length === 1) return singleItemComponent('Insert CareCredit Link', patientLinks[0].link);

    return <>
      <MenuItem
        data-testid="care-credit-quick-link"
        className={`${classes.menuItem} ${Boolean(menuAnchorEl) && classes.menuHighlighted}`}
        onClick={handleMenuOpen}
      >
        <Typography variant="body1">&#8288;Insert CareCredit Link</Typography>
        <ChevronRightIcon />
      </MenuItem>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.noScroll}
        classes={{ list: classes.careCreditLinkList }}
      >
        <Box className={classes.patientLinksWrapper}>
          <Box>
            {patientLinks.map((pl, index) => singleItemComponent(pl.fullName, pl.link, index))}
          </Box>
        </Box>
      </Menu>
    </>
  }

  if (!quickLinks || (!quickLinks.patientLinks && !quickLinks.locationLink)) return null;

  return (
    <>
      {quickLinks.locationLink && singleItemComponent('Insert CareCredit Link', quickLinks.locationLink)}
      {quickLinks.patientLinks && patientLinksComponent(quickLinks.patientLinks!)}
    </>
  );
}
