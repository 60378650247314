import { Box, styled } from '@mui/material';
import StarredIcon from '@mui/icons-material/Star';
import ArchivedIcon from '@mui/icons-material/ArchiveRounded';

export const InboxPreviewContainer = styled(Box)({
    display: 'block',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    position: 'relative',
    padding: 16,
    borderBottom: '1px solid #E1E4E7',
    backgroundColor: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#EDEFF2',
    }
})

export const InboxPreviewGrid = styled(Box)({
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '38px 1fr',
    gridTemplateRows: '20px 1fr',
    gridTemplateAreas: `'avatar header' 'avatar content'`,
    gap: '4px 6px',
});

export const InboxPreviewHeaderGrid = styled(Box)({
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `'title timestamp'`,
    gap: '8px',
    gridArea: 'header'
});

export const InboxPreviewAvatar = styled(Box)({
    width: 32,
    height: 32,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gridArea: 'avatar',
    backgroundColor: '#FFFFFF',
    '& .avatar': {
        width: '32px !important',
        height: '32px !important',
        backgroundSize: '32px !important'
    }
});

export const InboxPreviewTitle = styled(Box)({
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
    color: '#000000',
    gridArea: 'title',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

export const InboxPreviewTimestamp = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isUnread"
})<{ isUnread: boolean }>(({ isUnread }) => ({
    textAlign: 'right',
    fontWeight: isUnread ? 600 : 400,
    fontSize: 12,
    lineHeight: '18px',
    color: isUnread ? '#0675DD' : '#2C3542',
    gridArea: 'timestamp',
    letterSpacing: '0.4px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
}));

export const StarredIndicator = styled(StarredIcon)({
    color: '#F9BE28',
    height: 14,
    width: 'auto'
});

export const ArchivedIndicator = styled(ArchivedIcon)({
    color: '#8D97A4',
    height: 14,
    width: 'auto'
});

export const UnreadIndicator = styled("span")({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: '#0675DD'
});

export const InboxPreviewContent = styled(Box)({
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#2C3542',
    gridArea: 'content',
    overflow: 'hidden',
    wordWrap: 'break-word'
})

export const InboxPreviewCallContentGrid = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    gridTemplateAreas: `'avatar content'`,
    gap: '8px',
    gridArea: 'content',
    paddingTop: 8
})

export const InboxPreviewAutocommContent = styled(Box)({
    borderRadius: 8,
    border: '1px solid #D5D9DD',
    background: '#F9FAFB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 12,
    gap: 8
})


