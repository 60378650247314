import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";

export const getCustomizedEmailCount = (patientPreferences: PatientPreferencesResponse) => {
  let count = 0;
  patientPreferences.emailTreatmentPlanFollowUps !== false ? count++ : null;
  patientPreferences.emailReviewsAndSurveys !== false ? count++ : null;
  patientPreferences.emailBirthdayAndHolidayCards !== false ? count++ : null;
  patientPreferences.emailSpecialsAndNewsletters !== false ? count++ : null;
  patientPreferences.emailExpiringInsuranceReminder !== false ? count++ : null;
  patientPreferences.emailAppointmentReminders !== false ? count++ : null;
  patientPreferences.emailPostOpInstructions !== false ? count++ : null;
  patientPreferences.emailRecalls !== false ? count++ : null;
  patientPreferences.emailPatientReactivation !== false ? count++ : null;
  return count;
};