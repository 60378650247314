import { EmojiEmotionsOutlined as EmojiIcon, Send as SendIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useConfig, useOidcWithAdmin, useProduct } from '@revenuewell/front-end-bundle';
import Picker, { EmojiClickData } from 'emoji-picker-react';
import debounce from 'lodash.debounce';
import { FormEvent, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useCommsChat } from '../../../../hooks/use-communications';
import { useIntercomApi } from '../../../../hooks/use-intercom';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { useTextTemplates } from '../../../../hooks/use-text-templates/use-text-templates';
import { IMailboxWithLocation } from '../../../../services/mailbox-service/mailbox-service';
import QuickActionsService, { CareCreditQuickLinks } from '../../../../services/quick-actions-service/quick-actions-service';
import CommLocationSelect from './location-select';
import PhoneNumberSelect from './phone-number-select';
import { QuickActionsMenu } from './quick-actions/quick-actions-menu';

const MAX_LENGHT = 1000;

const useStyles = makeStyles({
    textField: {
        '& .MuiInputAdornment-root': {
            flexDirection: 'column',
            alignItems: 'center'
        },
        '& .MuiInputBase-root': {
            padding: '15px 40px 0px 10px'
        },
        position: 'relative'
    },
    adornment: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
});

interface CommsChatBoxProps {
    phoneNumbers: string[];
    initialPhoneNumber: string;
    patientId?: number;
    locationId?: number;
}

export interface FormLink {
    id: number;
    patientId?: number;
    name?: string;
    type: number;
    link?: string;
    isOnPatientPortal: boolean;
    isOnPublicPortal: boolean;
}

export interface QuickLinks {
    formLinks: FormLink[];
    loginPortalURL?: string;
    paymentURL?: string;
    onlineSchedulingURL?: string;
    careCredit?: CareCreditQuickLinks | null;
}

const CommsChatBox = ({ phoneNumbers, patientId, locationId, initialPhoneNumber }: CommsChatBoxProps) => {
    const classes = useStyles();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { mailboxes } = usePubnub();
    const { sendMessage } = useCommsChat();
    const [isMultiLocation, setIsMultiLocation] = useState<boolean>(false);
    const [message, setMessageState] = useState('');
    // eslint-disable-next-line
    const [lastCursorPosition, setLastCursorPosition] = useState(0);
    const [metaKeyPressed, setMetaKeyPressed] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentMailbox, setCurrentMailbox] = useState<IMailboxWithLocation>();
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>(initialPhoneNumber);
    const { getClaims } = useOidcWithAdmin();
    const formRef = useRef<HTMLFormElement>(null);
    const quickActions = QuickActionsService.getInstance(config, oidcService);
    const { hasProduct } = useProduct();
    const [quickLinks, setQuickLinks] = useState<QuickLinks>();
    const { fetchTemplates } = useTextTemplates();
    const { submitEvent } = useIntercomApi();
    const [isFocused, setIsFocused] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            const curLocationId = locationId ? locationId : (await getClaims()).locationId;
            const currentMailbox =
                mailboxes.filter(mailbox => mailbox.locationId == curLocationId).pop() || mailboxes[0];
            setCurrentMailbox(currentMailbox);
        })();
    }, [mailboxes, locationId, getClaims]);

    useEffect(() => {
        setIsMultiLocation(mailboxes.length > 1);
    }, [mailboxes]);

    const onSelectPhoneNumber = useCallback(
        (phoneNumber: string) => {
            setCurrentPhoneNumber(phoneNumber);
        },
        [setCurrentPhoneNumber]
    );

    useEffect(() => {
        if (!currentMailbox) return;

        fetchQuickLinks(currentMailbox, currentPhoneNumber);

        const setTemplatesData = async () => {
            if (!locationId) return;
            await fetchTemplates(locationId, 1, 200);
        };

        setTemplatesData();
    }, [currentMailbox, currentPhoneNumber]);

    const handleClickOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsFocused(true);
        setIsModalOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseModal = () => {
        setIsFocused(true);
        setIsModalOpen(false);
        setAnchorEl(null);
    };

    const setMessage = (message: string | ((old: string) => string)) => {
        if (typeof message === 'function') {
            setMessageState(oldMessage => message(oldMessage).slice(0, MAX_LENGHT));
        } else {
            setMessageState(message.slice(0, MAX_LENGHT));
        }
    };

    const updatePubnubPrefix = useCallback(
        (pubnubPrefix: string) => {
            const currentMailbox = mailboxes.filter(mailbox => mailbox.pubnubPrefix == pubnubPrefix).pop();
            setCurrentMailbox(currentMailbox);
        },
        [mailboxes]
    );

    const sendMessageCallback = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const messageTrimmed = message.trim();

            if (!messageTrimmed || !currentMailbox || !currentPhoneNumber || !sendMessage) return;

            const channelId = `${currentMailbox.pubnubPrefix}.${currentPhoneNumber}`;
            sendMessage(messageTrimmed, channelId);
            setMessage('');
            submitEvent('unified-inbox-message-sent');

            return false;
        },
        [currentPhoneNumber, currentMailbox, message]
    );

    const onEmojiClick = (emojiObject: EmojiClickData) => {
        setMessage(m => {
            return `${m || ''} ${emojiObject.emoji} `.trimStart();
        });
        handleCloseModal();
    };

    const handleMetaKeyUp: KeyboardEventHandler<HTMLDivElement> = e => {
        if (e.key === 'Meta') setMetaKeyPressed(false);
    };

    const handleMetaKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
        const isMac = navigator.platform === 'MacIntel';
        if (e.key === 'Meta' && isMac) setMetaKeyPressed(true);

        if (e.key === 'Enter') {
            if ((e.ctrlKey && !isMac) || metaKeyPressed) {
                setMessage(m => `${m}\n`);
                setTimeout(() => {
                    const target = e.target as any;
                    target.scrollTop = target.scrollHeight;
                }, 0);
            } else if (!e.shiftKey) {
                e.preventDefault();
                formRef.current?.requestSubmit();
            }
        }
    };

    const handleOnBlur: React.FocusEventHandler<HTMLTextAreaElement> = e => {
        if (isModalOpen) return;

        setLastCursorPosition(e.target.selectionStart);
        setIsFocused(false);
    };

    const handleOnFocus: React.FocusEventHandler<HTMLTextAreaElement> = () => {
        setIsFocused(true);
    };

    const insertQuickAction = (val: string) => {
        const textBefore = message.substr(0, lastCursorPosition).trim();
        const textAfter = message.substr(lastCursorPosition, message.length).trim();
        const updatedText = `${textBefore} ${val} ${textAfter}`;
        setMessage(updatedText);
        handleCloseModal();
    };

    const setQuickActionsModal = (val: boolean) => {
        setIsModalOpen(val);
        setIsFocused(val);
    };

    const fetchQuickLinks = debounce(async (curMailbox: IMailboxWithLocation, phoneNumber: string) => {
        let patientFormsLink;
        if (hasProduct('Forms2') && (await quickActions.getIsMigratedToForms2(curMailbox.location.id))) {
            patientFormsLink = quickActions.getForms2Links(curMailbox.location.id);
        } else if (hasProduct('Forms')) {
            patientFormsLink = quickActions.getPatientFormLink(curMailbox.locationId, patientId);
        }

        const formLinks = await Promise.all([
            patientFormsLink ? patientFormsLink : Promise.resolve([]),
            quickActions.getLoginPortalLink(curMailbox.location.id),
            quickActions.getOnlineSchedulingLink(curMailbox.location.id),
            phoneNumber 
                ? await quickActions.getCareCreditQuickLinks(curMailbox.location.idMasterAccount, curMailbox.locationId, phoneNumber)
                : Promise.resolve(null)
        ]);

        setQuickLinks({
            formLinks: formLinks[0],
            loginPortalURL: formLinks[1],
            onlineSchedulingURL: formLinks[2],
            careCredit: formLinks[3]
        });
    }, 100);

    if (!currentMailbox) return <></>;

    return (
        <Box
            component='form'
            ref={formRef}
            onSubmit={sendMessageCallback}
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', gap: 1 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                <TextField
                    placeholder='Type a message...'
                    className={classes.textField}
                    multiline
                    variant='outlined'
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    onKeyUp={handleMetaKeyUp}
                    onKeyDown={handleMetaKeyDown}
                    fullWidth={true}
                    maxRows={23}
                    minRows={isFocused ? 5 : 1}
                    InputProps={{
                        style: { paddingTop: 8, paddingBottom: 8 },
                        endAdornment: (
                            <InputAdornment position='end' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={classes.adornment}>
                                    {isFocused ? (
                                        <>
                                            {quickLinks && (
                                                <QuickActionsMenu
                                                    quickLinks={quickLinks}
                                                    locationId={locationId}
                                                    onSelect={insertQuickAction}
                                                    setModalOpen={setQuickActionsModal}
                                                />
                                            )}
                                            <IconButton onMouseDown={handleClickOpenModal}>
                                                <EmojiIcon sx={{ color: '#616C79' }} />
                                            </IconButton>
                                            <Popover
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={handleCloseModal}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                }}
                                                style={{ top: -8 }}
                                            >
                                                <Picker
                                                    searchDisabled={true}
                                                    previewConfig={{ showPreview: false }}
                                                    onEmojiClick={onEmojiClick}
                                                    height='350px'
                                                    width='100%'
                                                />
                                            </Popover>
                                        </>
                                    ) : null}

                                    <IconButton edge='end' disabled={!message.trim()} type='submit'>
                                        <SendIcon fontSize='small' sx={{ color: '#0675DD', paddingRight: '12px' }} />
                                    </IconButton>
                                </div>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            {isMultiLocation && (
                <CommLocationSelect
                    pubnubPrefix={currentMailbox.pubnubPrefix}
                    updatePubnubPrefix={updatePubnubPrefix}
                />
            )}
            {phoneNumbers.length && (
                <PhoneNumberSelect
                    key={'' + locationId + patientId + initialPhoneNumber}
                    currentMailbox={currentMailbox}
                    currentPhoneNumber={currentPhoneNumber}
                    phoneNumbers={phoneNumbers}
                    patientId={patientId}
                    onSelectPhoneNumber={onSelectPhoneNumber}
                />
            )}
        </Box>
    );
};

export default CommsChatBox;
