import Pubnub from 'pubnub';
import { IMailboxWithLocation } from './mailbox-service/mailbox-service';

export type MessageStatus = 'sent' | 'delivered' | 'error' | 'expired' | 'received' | 'sending';

export interface IPNKeys {
    publishKey: string;
    subscribeKey: string;
    authKey?: string;
    cipherKey?: string;
}

interface IChannelMetaFilter {
    count: number;
    cursor?: string;
    phones?: string[];
}

export interface Media {
    type: string;
    link: string;
}

export interface Message {
    channelId: string;
    from: string;
    to: string;
    body: string;
    media?: Media[] | null;
    date: string;
    commnucationId?: string;
    isResponseAwaiting?: boolean;
    messageType?: 'direct' | 'campaign' | 'incoming';
    expirationDate?: string;
    timetoken?: number;
    senderUserId?: number;
    senderUserName?: string;
    status?: MessageStatus;
    isLegacy?: boolean;
    reaction?: IMessageReaction;
    timetokenString?: string;
}

export interface IMessagePreview {
    lastMessage: Message;
    channelId: string;
    patientPhoneNo: string;
    isSaved: boolean;
    isArchived: boolean;
    isUnread: boolean;
}

export interface IMessageReaction {
    hidden?: IMessageHiddenReaction[];
}

export interface IMessageHiddenReaction {
    uuid: string;
    actionTimetoken: string;
}

export const timeTokenToNumber = (token: string | number): number => {
    let tokenString = token.toString();
    if (tokenString.length > 13) {
        tokenString = tokenString.substring(0, 13) + '.' + tokenString.substring(13);
    }

    return parseFloat(tokenString);
};

export const timeTokenToString = (token: string | number) => {
    return Number.parseFloat(token.toString()).toFixed(4).replace('.', '');
};

export const transformMetadata = (obj: any) => {
    const lastMessage: Message = {
        channelId: obj.id,
        from: String(obj.custom?.lastMessageFrom || ''),
        to: String(obj.custom?.lastMessageTo || ''),
        body: String(obj.custom?.lastMessageBody || ''),
        media: (obj.custom?.lastMessageMedia as unknown) as Media[],
        date: new Date(obj.custom?.lastMessageDate || Date.now()).toISOString(),
        timetoken: timeTokenToNumber(
            obj.custom?.lastMessageTimetoken ? obj.custom?.lastMessageTimetoken : new Date().getTime()
        )
    };

    return {
        lastMessage,
        channelId: obj.id,
        patientPhoneNo: String(obj.custom?.patientPhoneNo),
        isNewPatient: Boolean(obj.custom?.isNewPatient),
        isSaved: Boolean(obj.custom?.isSaved),
        isArchived: Boolean(obj.custom?.isArchived),
        isUnread: Boolean(obj.custom?.isUnread)
    };
};

export default class PNService {
    public client: Pubnub;
    private channelWildcard: string[] = [];
    private subscriptionWildcard: string[] = [];
    private fromFilter: string[] = [];
    private config: any;

    constructor(config: any, private uuid: string, keys: IPNKeys) {
        // setup Listeners
        this.client = new Pubnub({
            uuid,
            ...keys
        });
        this.config = config;
    }

    public setWildcard(wildcard: string[]) {
        console.debug('PUBNUB SETTING WILDCARD:', wildcard);
        this.channelWildcard = wildcard;
    }

    public setFromFilter(from: string[]): void {
        this.fromFilter = from;
    }

    public setupSubscriptions(): void {
        console.debug('EVENTS - SETTING UP SUBSCRIPTION:', this.channelWildcard);

        if (!this.channelWildcard?.length) throw new Error("can't call subscribe until you set the channel wildcard");
        /**
         * only subscribe or unsubscribe to a channel if needed
         */
        const toSubscribe = this.channelWildcard.filter(chann => !this.subscriptionWildcard.includes(chann));
        const toUnsubscribe = this.subscriptionWildcard.filter(chann => !this.channelWildcard.includes(chann));

        console.debug('EVENTS - SUBSCRITPIONS BEING SETUP:', this.subscriptionWildcard, toUnsubscribe, toSubscribe);

        if (toUnsubscribe.length > 0) {
            console.debug('EVENTS - UNSUBSCRIBE FITLER WILDCARD:', toUnsubscribe);
            this.client.unsubscribe({
                channels: toUnsubscribe
            });
        }

        if (toSubscribe.length > 0) {
            console.debug('EVENTS - SUBSCRIBING TO CHANNELS WITH WILDCARD:', toSubscribe);
            this.client.subscribe({
                channels: toSubscribe
            });
        }
    }

    private buildFilter(filter: string) {
        if (!this.channelWildcard?.length) throw new Error("can't call subscribe until you set the channel wildcard");

        console.debug('FILTER:', filter);
        if (filter.length > 0) {
            filter = `(${filter})`;
        }

        if (this.fromFilter.length > 0) {
            const fromFilter = this.fromFilter
                .map(phone => {
                    const from = phone.replace(/[^0-9]/gi, '');
                    return `(custom.lastMessageFrom LIKE '${from}' || custom.lastMessageTo LIKE '${from}')`;
                })
                .join(' || ');
            filter = `${filter} && (${fromFilter})`;
        }

        if (this.channelWildcard.length > 0) {
            const locationFilter = this.channelWildcard.map(w => `id LIKE '${w}'`).join(' || ');
            // fetch only metadata for channels specified by wildcard
            filter = `${filter} && (${locationFilter})`;
        }

        console.debug('WILDCARD BUILD FILTER:', this.channelWildcard);

        filter = `${filter} && custom.patientPhoneNo != null && custom.lastMessageTimetoken != "0"`;

        return filter;
    }

    public async fetchAllChannelMeta(filter: string, limit?: number, nextPage?: string) {
        const meta = await this.client.objects.getAllChannelMetadata({
            include: {
                customFields: true,
                totalCount: true
            },
            limit: limit || 30,
            sort: {
                name: 'desc'
            },
            ...(nextPage && {
                page: {
                    next: nextPage
                }
            }),
            ...(filter && {
                filter
            })
        });

        return meta;
    }

    public async mailboxHasMessages() {
        const res = await this.client.objects.getAllChannelMetadata({
            limit: 1,
            include: {
                totalCount: true
            }
        });

        return !!res.totalCount;
    }

    private async setChannelMeta(
        channel: string,
        data: Record<string, string | number | boolean>,
        isPublishMessage = false
    ) {
        const meta = await this.client.objects
            .getChannelMetadata({
                channel,
                include: {
                    customFields: true
                }
            })
            .catch(() => {
                return null;
            });

        await this.client.objects.setChannelMetadata({
            channel,
            data: {
                name: String(isPublishMessage ? data.lastMessageTimetoken : meta?.data.name || Date.now()),
                description: channel,
                custom: {
                    ...meta?.data.custom,
                    ...data
                }
            }
        });
    }

    async sendMessagePN(channel: string, patientPhoneNo: string, body: string): Promise<number> {
        const message: Message = {
            channelId: channel,
            from: this.uuid,
            to: patientPhoneNo,
            body,
            date: new Date().toUTCString(),
            messageType: 'direct',
            isResponseAwaiting: true
        };

        const res = await this.client.publish({
            channel,
            storeInHistory: true,
            message
        });

        const meta = {
            lastMessageFrom: this.uuid,
            lastMessageTo: patientPhoneNo,
            lastMessageBody: body,
            lastMessageDate: new Date().toUTCString(),
            lastMessageTimetoken: res.timetoken,
            isUnread: false,
            patientPhoneNo
        };

        await this.setChannelMeta(channel, meta, true);

        return res.timetoken;
    }

    private generatePhoneFilter(phones?: string[]) {
        const phonesFilter =
            phones && phones.length > 0
                ? `(${phones
                      .map(phone => {
                          if (String(phone).length < 10) {
                              return `custom.patientPhoneNo LIKE '*${phone}*'`;
                          }

                          return `custom.patientPhoneNo == '${phone}'`;
                      })
                      .join(' || ')}) &&`
                : '';
        return phonesFilter;
    }

    async fetch(
        { phones, count = 30, cursor }: IChannelMetaFilter,
        filter = 'custom.patientPhoneNo != null && custom.lastMessageTimetoken != "0"'
    ): Promise<{
        data: Array<IMessagePreview>;
        cursor?: string;
        count?: number;
    }> {
        console.debug('PUBNUB UTILS FETCH:', phones, count, cursor);
        const phonesFilter = this.generatePhoneFilter(phones);
        const filterQuery = this.buildFilter(`${phonesFilter} ${filter}`);

        const meta = await this.fetchAllChannelMeta(filterQuery, count, cursor);

        const res: Array<IMessagePreview> = meta.data.map(transformMetadata);

        return {
            data: res,
            cursor: meta.next,
            count: meta.totalCount
        };
    }

    async fetchRecentlyContacted({
        phones,
        count = 30,
        cursor
    }: IChannelMetaFilter): Promise<{
        data: Array<IMessagePreview>;
        cursor?: string;
    }> {
        return this.fetch({ phones, count, cursor }, '(custom.isArchived != true)');
    }

    async fetchUnread(
        { phones, count = 30, cursor }: IChannelMetaFilter,
        extraFilter?: string
    ): Promise<{
        data: Array<IMessagePreview>;
        cursor?: string;
        count?: number;
    }> {
        return this.fetch(
            { phones, count, cursor },
            `(custom.isUnread == true && custom.isArchived != true) ${extraFilter ? ` && (${extraFilter})` : ''}`
        );
    }

    async fetchSaved({
        phones,
        count = 30,
        cursor
    }: IChannelMetaFilter): Promise<{
        data: Array<IMessagePreview>;
        cursor?: string;
    }> {
        return this.fetch({ phones, count, cursor }, '(custom.isSaved == true && custom.isArchived != true)');
    }

    async fetchArchived({
        phones,
        count = 30,
        cursor
    }: IChannelMetaFilter): Promise<{
        data: Array<IMessagePreview>;
        cursor?: string;
    }> {
        return this.fetch({ phones, count, cursor }, '(custom.isArchived == true)');
    }

    async fetchNewPatients({
        phones,
        count = 30,
        cursor
    }: IChannelMetaFilter): Promise<{
        data: Array<IMessagePreview>;
        cursor?: string;
    }> {
        return this.fetch({ phones, count, cursor }, '(custom.isNewPatient == true && custom.isArchived != true)');
    }

    async savePatient(channel: string, isSaved: boolean): Promise<void> {
        await this.setChannelMeta(channel, { isSaved });
    }

    async archivePatient(channel: string, isArchived: boolean): Promise<void> {
        await this.setChannelMeta(channel, { isArchived });
    }

    async toggleIsNewPatient(channel: string, isNewPatient: boolean) {
        await this.setChannelMeta(channel, { isNewPatient });
    }

    async toggleRead(channel: string, isUnread: boolean): Promise<void> {
        await this.setChannelMeta(channel, {
            isUnread
        });
    }

    public async fetchMessageHistory(channelId: string, start?: string, count = 25): Promise<Message[]> {
        const formatedStart = start && { start: timeTokenToString(start) };
        const res = await this.client.fetchMessages({
            channels: [channelId],
            includeMeta: true,
            includeMessageActions: true,
            count,
            ...formatedStart
        });

        if (!res.channels[channelId]) return [];

        return res.channels[channelId].map(data => {
            // console.log('MESSAGE DATA:', data);
            const status = data?.actions ? data.actions['Status'] : undefined;
            const error = status ? status['Error'] : undefined;

            return {
                ...data.message,
                channelId: channelId,
                timetoken: timeTokenToNumber(data.timetoken),
                timetokenString: data.timetoken,
                reaction: data.actions?.reaction,
                status: error ? 'error' : undefined,
                date: new Date(Math.trunc(timeTokenToNumber(data.timetoken))).toISOString()
            };
        }) as Message[];
    }

    public async getPatientLastContacted(phone: string, locations: IMailboxWithLocation[]) {
        const phonesFilter = `custom.patientPhoneNo == '${phone}'`;
        const channels = await this.fetchAllChannelMeta(phonesFilter);
        return locations
            .map(location => {
                const channel = channels.data.find(channel => new RegExp(`${location.pubnubPrefix}*`).test(channel.id));
                return {
                    ...location,
                    lastContactedTime: channel?.custom ? String(channel.custom['lastMessageDate']) : ''
                };
            })
            .sort((location1, location2) =>
                location1.lastContactedTime > location2.lastContactedTime
                    ? -1
                    : location1.lastContactedTime < location2.lastContactedTime
                    ? 1
                    : 0
            );
    }

    public dispose(): void {
        this.client.unsubscribeAll();
    }
}
