import { useConfig, useOidc } from '@revenuewell/front-end-bundle';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Message, VoicemailBox, VoicemailStatus } from './types';
import jwt_decode from 'jwt-decode';
import { normaliseUSPhoneNumber } from '../../utils/formatter';
import { gregorianToDate } from '../../utils/date-time';
import { useKazoo } from '../use-kazoo/use-kazoo';
import { VoicemailApi } from '../../services/voicemail-service/voicemail-service';

export interface IVoicemailContext {
    voicemailsBoxes: VoicemailBox[];
    unfilteredBoxes: VoicemailBox[];
    messages: Message[];
    status: VoicemailStatus;
    error: string | null;
    getMessages: (params: string[]) => Promise<void>;
    getVoicemailBoxes: () => Promise<void>;
    deleteMessage: (message: Message) => Promise<void>;
}

export const VoicemailContext = createContext<IVoicemailContext>(null!);

export function VoicemailProvider(props: React.PropsWithChildren<any>) {
    const { config } = useConfig();
    const { oidcService } = useOidc();
    const { userCredentials } = useKazoo();
    const [voicemailsBoxes, setVoicemailsBoxes] = useState<VoicemailBox[]>([]);
    const [unfilteredBoxes, setUnfilteredBoxes] = useState<VoicemailBox[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [status, setStatus] = useState<VoicemailStatus>('idle');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null);
    const voicemailApi = new VoicemailApi(config, oidcService);

    useEffect(() => {
        if (userCredentials) {
            getVoicemailBoxes();
        }
    }, [userCredentials]);

    const getMessages = async (params: string[]) => {
        setStatus('loading');

        const allMessages = await Promise.all(
            params.map(mailboxId => {
                return voicemailApi.fetchMessages(
                    userCredentials?.kazooApiUrl || '',
                    userCredentials?.kazooAccountId || '',
                    userCredentials?.kazooUserToken || '',
                    mailboxId
                );
            })
        );

        const messagesSorted = allMessages
            .reduce((acc, response, index) => {
                if (acc.length === 0) {
                    return response.data.map(d => ({
                        ...d,
                        mailboxId: params[index]
                    }));
                }

                return [
                    ...acc,
                    ...response.data.map(d => ({
                        ...d,
                        mailboxId: params[index]
                    }))
                ].sort((a, b) => b.timestamp - a.timestamp);
            }, [] as any)
            .map((message: any) => ({
                timestamp: gregorianToDate(message.timestamp),
                from: message.from,
                to: message.to,
                callerIdNumber: message.caller_id_number ? normaliseUSPhoneNumber(message.caller_id_number) : '',
                callerIdName: message.caller_id_name,
                callId: message.call_id,
                folder: message.folder,
                length: message.length,
                mediaId: message.media_id,
                mailboxId: message.mailboxId
            }));

        setMessages(messagesSorted);
        setStatus('succeeded');
    };

    const getVoicemailBoxes = async () => {
        setStatus('loading');

        const { owner_id: ownerId } = jwt_decode(userCredentials?.kazooUserToken || '') as { owner_id: string };
        const boxes = await voicemailApi.fetchBoxes(
            userCredentials?.kazooApiUrl || '',
            userCredentials?.kazooAccountId || '',
            userCredentials?.kazooUserToken || ''
        );

        setUnfilteredBoxes(
            boxes.data.map(box => ({
                ownerId: box.owner_id,
                id: box.id,
                name: box.name
            }))
        );

        const boxesFiltered = {
            ...boxes,
            data: boxes.data.filter(d => !d.owner_id || d.owner_id === ownerId)
        };

        setVoicemailsBoxes(
            boxesFiltered.data.map(box => ({
                ownerId: box.owner_id,
                id: box.id,
                name: box.name
            }))
        );
        setStatus('succeeded');
    };

    const deleteMessage = async (message: Message) => {
        await voicemailApi.deleteVoicemail(
            userCredentials?.kazooApiUrl || '',
            userCredentials?.kazooAccountId || '',
            userCredentials?.kazooUserToken || '',
            message.mailboxId,
            message.mediaId
        );

        setMessages(messages.filter(({ mediaId }) => mediaId !== message.mediaId));
    };

    return (
        <VoicemailContext.Provider
            value={{
                voicemailsBoxes,
                unfilteredBoxes,
                messages,
                status,
                error,
                getMessages,
                getVoicemailBoxes,
                deleteMessage
            }}
        >
            {props.children}
        </VoicemailContext.Provider>
    );
}

export function useVoicemail() {
    const context = useContext(VoicemailContext);

    if (!context) throw new Error('useVoicemail must be used within the VoicemailProvider');

    return context;
}
