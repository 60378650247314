import { useEffect, useState } from 'react';
import { useSlideout } from '../../../../hooks/use-slideout';
import SlideOutCard from '../../../shared/slide-out-card';
import SelectPatientWidget from './select-patient-widget';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';
import { EventSelectPatientWidget } from '../../../../types/event-bus';
import { ContactSearchByNumber } from '../../../../services/api-client/contact-api';

export default function SelectPatientSlideout() {
    const { isVisisbleSelectPatient, close, hasHistory, back } = useSlideout();
    const { listen } = useEventBus();
    const [contacts, setContacts] = useState<ContactSearchByNumber[]>([]);

    const handleClose = () => {
        close();
    };

    useEffect(() => {
        const listener = listen('selectPatient', event => {
            const { data } = event as EventSelectPatientWidget;
            setContacts(data.contacts);
        });

        return () => {
            listener();
        };
    }, [listen]);

    return (
        <SlideOutCard
            isVisible={isVisisbleSelectPatient}
            onClose={handleClose}
            onBackClick={hasHistory ? back : undefined}
        >
            <SelectPatientWidget contacts={contacts} />
        </SlideOutCard>
    );
}
