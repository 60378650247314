import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useCallback, useContext } from 'react';
import { useLocations } from '../use-locations';
import { ContactApi } from '../../services/api-client/contact-api';
import { ContactsContext, searchParams } from './provider';
import UniversalPatientSearchService from '../../services/universal-patient-search-service/universal-patient-search-service';

const CACHE_EXPIRY_TIME = 10 * 60 * 1000;

export default function useCallState() {
    const context = useContext(ContactsContext);
    const locations = useLocations();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    if (!context) throw new Error('useCallState must be used within a ContactsProvider');

    const loadMore = useCallback(async () => {
        const result = await new ContactApi(config, oidcService).getContacts({
            limit: searchParams.contactListPageSize,
            sortBy: searchParams.defaultSortByField,
            pageNumber: context.pagination.currentPageNumber,
            searchText: context.searchText,
            locationIds: locations.selectedLocationIds.join()
        });

        context.setContactSearchResult(res => [...res, ...result.data]);
        const maxPages = Math.ceil(result.total / result.data.length);
        context.setPagination(pagination => ({
            ...pagination,
            currentPageNumber: pagination.currentPageNumber + 1,
            hasMore: maxPages > pagination.currentPageNumber
        }));
    }, [config, context, locations.selectedLocationIds, oidcService]);

    const searchByNumber = useCallback( 
        async (phoneNumber: string) => {
            const cachedData = context.contactCache[phoneNumber];

            const fetchedTime = cachedData?.fetchedTime || 0;

            const isCacheDataValid = Date.now() - fetchedTime < CACHE_EXPIRY_TIME;

            if (isCacheDataValid || cachedData?.status === 'inprogress') return;

            context.updateContactCache(phoneNumber, { data: [], status: 'inprogress' });

            try {
                const searchInstance = UniversalPatientSearchService.getInstance(config, oidcService);
                const networkIds = locations.locations.map(location => location.idNetwork);
                const result = await searchInstance.searchByPhoneNumbers([phoneNumber], networkIds);
                if (!result?.[phoneNumber]?.length) {
                    context.updateContactCache(phoneNumber, { data: [], status: 'notfound', fetchedTime: Date.now() });
                    return;
                } else {
                    context.updateContactCache(phoneNumber, {
                        data: result[phoneNumber],
                        status: 'found',
                        fetchedTime: Date.now()
                    });
                }
            } catch (err) {
                context.updateContactCache(phoneNumber);
            }
        },
        [locations, config, context, oidcService]
    );

    const loadContactsByPhoneNumbers = useCallback(
        async (phoneNumbers: string[]) => {
            const nonCachedPhoneNumbers = phoneNumbers.filter((pn) => {
                const detailsInCache = context.contactCache[pn];
                return !(
                    detailsInCache?.data?.length &&
                    (detailsInCache.status === 'found' || detailsInCache.status === 'notfound') &&
                    (Date.now() - detailsInCache.fetchedTime) / 60000 > 10
                );
            });

            if (!nonCachedPhoneNumbers.length) return;
            const searchInstance = UniversalPatientSearchService.getInstance(config, oidcService);

            const networkIds = locations.locations.map(location => location.idNetwork);
            const result = await searchInstance.searchByPhoneNumbers(nonCachedPhoneNumbers, networkIds);
           for (const phoneNumber of nonCachedPhoneNumbers) {
                if (result && result[phoneNumber] && result[phoneNumber].length) {
                    context.updateContactCache(phoneNumber, {
                        data: result[phoneNumber],
                        status: 'found',
                        fetchedTime: Date.now()
                    });
                } else {
                    context.updateContactCache(phoneNumber, {
                        data: [],
                        status: 'notfound',
                        fetchedTime: Date.now()
                    });
                }
            }
        },
        [config, context, oidcService]
    );

    return { ...context, loadMore, searchByNumber, loadContactsByPhoneNumbers };
}
