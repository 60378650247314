import React, { FC } from 'react';
import { HeaderWrapper } from './header.styles';
import { PatientInfo, PatientName } from './contact-info/contact-info.styles';
import { Box } from '@mui/material';
import { formatPhoneNumber } from '../utils/format-phone-number';
import { InboxIconButton } from '../../inbox-widget/styles/inbox-styles';
import { useEventBus } from '../../../../../hooks/use-event-bus';
import PhoneIcon from '@mui/icons-material/Phone';

export interface PhoneHeaderProps {
  phoneNumber: string
}

export const PhoneHeader: FC<PhoneHeaderProps> = (props) => {
  const { publish } = useEventBus();

  const handlePhoneClick = () => {
    publish({
      messageType: 'startCall',
      data: { phoneNumber: props.phoneNumber }
    });
  };

  return <>
    <HeaderWrapper>
      <PatientInfo sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <PatientName>{formatPhoneNumber(props.phoneNumber)}</PatientName>
          <Box>{formatPhoneNumber(props.phoneNumber)}</Box>
        </Box>
        <InboxIconButton onClick={handlePhoneClick}>
          <PhoneIcon fontSize='small' />
        </InboxIconButton>
      </PatientInfo>
      <br />
    </HeaderWrapper>
  </>
}