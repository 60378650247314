import Autocomplete from '../../../shared/autocpmplete';
import { IMailboxWithLocation } from '../../../../services/mailbox-service/mailbox-service';
import { FilterLocationSelect } from './styles/inbox-filter-styles';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';

interface LocationsSelectorProps {
    value: IMailboxWithLocation[];
    onChange: (value: IMailboxWithLocation[]) => void;
}

export function LocationsSelector({ value, onChange }: LocationsSelectorProps) {
    const { mailboxes } = usePubnub();

    return (
        <FilterLocationSelect>
            <Autocomplete
                label={value.length ? 'Locations' : 'All Locations'}
                id="select-locations"
                type="text"
                options={mailboxes}
                value={value}
                getOptionLabel={(option: IMailboxWithLocation) => option?.location?.name || option?.pubnubPrefix}
                onChange={(_, value: IMailboxWithLocation[]) => {
                    onChange(value);
                }}
            />
        </FilterLocationSelect>
    );
}