import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useState } from 'react';
import { ReactComponent as TeamChatButtonSvg } from '../../../assets/menu/icon-team-chat.svg';
import SidebarItem from '../../shared/sidebar/sidebar-item';
import PNService from '../../../services/teamchat/team-chat-pubnub-service';
import TeamChatAuthService from '../../../services/teamchat/team-chat-auth-service';
import { debounce } from 'lodash';
import { useWrapperNavigation } from '../../../hooks/use-wrapper-navigation';

export type MessengerButtonProps = {
    to: string;
    isActive?: boolean;
    onClick?: () => void;
};

const fetchMemberships = async (client: PNService) => {
    const memberships = await client.fetchMemberships();
    return memberships;
};

const fetchUnreadCounts = debounce(
    async (client: PNService, setUnread: (unread: boolean) => void, membership?: { string: string }) => {
        const memberships = membership ? membership : await client.fetchMemberships();
        const count = await client.fetchUnreadCount(memberships);
        if (count > 0) setUnread(true);
        else setUnread(false);
    },
    5000
);

export default function TeamchatButton({ to, isActive, onClick }: MessengerButtonProps) {
    const { config } = useConfig();
    const oidc = useOidcWithAdmin();
    const [client, setClient] = useState<PNService>();
    const [hasUnread, setUnread] = useState(false);
    const { activeFrame } = useWrapperNavigation();
    const [initialFrame, setInitialFrame] = useState<string>();
    const [memberships, setMemeberships] = useState<{ string: string }>();

    //Here we pull membership when you first hit a frame that is not teamchat
    //we clear it when you are on the team chat frame so the membership
    //updates normally.  This setup makes sure that we still get the unread
    //dot when we get a message on the channel we are sitting on in the hidden
    //frame.
    useEffect(() => {
        if (client && initialFrame == undefined && activeFrame != 'teamchat') {
            fetchMemberships(client).then(mb => setMemeberships(mb));
            setInitialFrame(activeFrame);
        }
        if (client && activeFrame == 'teamchat') {
            setInitialFrame(undefined);
            setMemeberships(undefined);
            fetchUnreadCounts(client, setUnread);
        }
    }, [client, activeFrame, initialFrame]);

    useEffect(() => {
        const setup = async () => {
            const claims = await oidc.getClaims();
            const teamChatApiUrl = config.teamChat.apiUrl;
            const teamChatAuthUrl = teamChatApiUrl + '/customers/' + claims.masterAccountId + '/authorize';

            const teamChatAuthService = teamChatAuthUrl
                ? TeamChatAuthService.getInstance(oidc.oidcService, teamChatAuthUrl)
                : null;
            if (teamChatAuthService) {
                const keys = await teamChatAuthService.getAuthCredentials();
                const client = new PNService(keys);
                client.setupSubscription();
                setClient(client);
            }
        };
        setup();
    }, []);

    useEffect(() => {
        if (client) {
            fetchUnreadCounts(client, setUnread);
        }
    }, [client]);

    useEffect(() => {
        if (client) {
            const listeners = {
                message() {
                    fetchUnreadCounts(client, setUnread, memberships);
                },
                membership() {
                    fetchUnreadCounts(client, setUnread, memberships);
                }
            };
            client.client.addListener(listeners);

            return () => {
                client.client.removeListener(listeners);
            };
        }
    }, [client, memberships]);

    return (
        <SidebarItem title='Team Chat' onClick={onClick} to={to} isActive={isActive} hasNotification={hasUnread}>
            <TeamChatButtonSvg />
        </SidebarItem>
    );
}
