import { makeStyles } from '@mui/styles';
import { TextField, Autocomplete, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { PatientCustomReminderSchedule } from '../../../../../../../../services/api-client/contact-api';

const useStyles = makeStyles({
  appointmentSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderBottom: '1px solid #E1E4E7'
  },
  appointmentHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#000000'
  },
  dropdown: {
    width: '100%',
    marginTop: '8px'
  },
  dropDownText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000'
  }
});

interface AppointmentSectionProps {
  header: string;
  preference: PatientCustomReminderSchedule;
  onChange: (value: PatientCustomReminderSchedule) => void;
}

const AppointmentSection = ({ header, preference, onChange }: AppointmentSectionProps) => {
  const classes = useStyles();
  const options: PatientCustomReminderSchedule[] = [
    PatientCustomReminderSchedule.Global,
    PatientCustomReminderSchedule.On,
    PatientCustomReminderSchedule.Off
  ];

  const DropDownPaper = (props: React.HTMLAttributes<HTMLElement>) => {
    return <Paper elevation={8} style={{ background: '#FFFFFF' }} {...props} />;
  };

  return (
    <div className={classes.appointmentSection}>
      <Typography variant='h5' className={classes.appointmentHeader} component='div'>
        {header}
      </Typography>
      <Autocomplete
        className={classes.dropdown}
        autoComplete={false}
        disableClearable
        PaperComponent={DropDownPaper}
        disablePortal
        defaultValue={preference}
        size='small'
        id='appointment-reminder-dropdown'
        options={options}
        onChange={(item, value) => onChange(value)}
        renderInput={params => <TextField {...params} className={classes.dropDownText} variant='outlined' />}
      />
    </div>
  );
};

export default AppointmentSection;
