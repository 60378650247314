import { useState } from 'react';
import { IconButton, MenuItem, Menu, Box } from '@mui/material';
import { QuickLinks } from '../communications-chat-box';
import { FormsSubmenu } from './forms-submenu';
import { TextTemplateSubmenu } from './text-template-submenu';
import makeStyles from '@mui/styles/makeStyles';
import BoltIcon from '@mui/icons-material/Bolt';
import CareCreditLinkQuickAction from './care-credit/care-credit-link-quick-action';

const useStyles = makeStyles(() => ({
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        height: 48
    },
    menu: {
        width: 320
    }
}));

interface QuickActionsMenuProps {
    quickLinks: QuickLinks;
    locationId?: number;
    onSelect: (val: string) => void;
    setModalOpen:(val: boolean) => void
}

export const QuickActionsMenu = ({ quickLinks, locationId, onSelect, setModalOpen }: QuickActionsMenuProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setModalOpen(true)
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setModalOpen(false)
        setAnchorEl(null);
    };

    const handleSelect = (link: string) => {
        onSelect(link);
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-controls='unified-inbox-quick-actions-menu-button'
                aria-haspopup='true'
                onMouseDown={handleOpen}
            >
                <BoltIcon />
            </IconButton>
            <Menu
                id='unified-inbox-quick-actions-menu'
                data-testid='unified-inbox-quick-actions-menu'
                anchorEl={anchorEl}
                keepMounted
                PaperProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box className={classes.menu}>
                    {quickLinks?.onlineSchedulingURL && (
                        <MenuItem
                            data-testid='unified-inbox-quick-actions-menu-online-scheduling'
                            onClick={() => handleSelect(quickLinks.onlineSchedulingURL!)}
                            className={classes.menuItem}
                        >
                            Insert Online Scheduling Link
                        </MenuItem>
                    )}
                    {quickLinks?.paymentURL && (
                        <MenuItem
                            data-testid='unified-inbox-quick-actions-menu-payments'
                            onClick={() => handleSelect(quickLinks.paymentURL!)}
                            className={classes.menuItem}
                        >
                            Insert Payment Link
                        </MenuItem>
                    )}
                    {quickLinks?.loginPortalURL && (
                        <MenuItem
                            data-testid='unified-inbox-quick-actions-menu-login-portal'
                            onClick={() => handleSelect(quickLinks.loginPortalURL!)}
                            className={classes.menuItem}
                        >
                            Insert Patient Login Link
                        </MenuItem>
                    )}
                    <FormsSubmenu quickLinks={quickLinks} onSelect={handleSelect} />
                    <TextTemplateSubmenu locationId={locationId} onSelect={handleSelect} />
                    {quickLinks?.careCredit && 
                        <CareCreditLinkQuickAction quickLinks={quickLinks.careCredit} onSelect={handleSelect} />
                    }
                </Box>
            </Menu>
        </>
    );
};
