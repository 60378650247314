import SlideOutCard from '../../../shared/slide-out-card';
import InboxFilterWidget from './inbox-filter-widget';
import { useSlideout } from '../../../../hooks/use-slideout';
import { useEventBus } from '../../../../hooks/use-event-bus';

export default function InboxPreviewFilterSlideout() {
    const { isVisibleInboxPreviewFilter, back } = useSlideout();
    const { publish } = useEventBus();

    const handleClose = () => {
        publish({
            messageType: 'closeWidget'
        });
    };
    
    const handleBackClick = () => {
        back();
    };

    return (
        <SlideOutCard isVisible={isVisibleInboxPreviewFilter} onClose={handleClose} onBackClick={handleBackClick}>
            <InboxFilterWidget onFilterApplied={handleBackClick}/>
        </SlideOutCard>
    );
}

