import { FC, useState } from "react";
import { NavigationBreadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../header/header";
import { ViewContent } from "../shared/view-content/view-content";
import AccordionDetails from "../../../../../shared/accordion/accordion-details";
import AccordionSection from "../../../../../shared/accordion/accordion-section";
import AccordionContent from "../../../../../shared/accordion/accordion-content";
import { useStyles } from "./billing.styles";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import { AttachMoney } from '@mui/icons-material';
import { Chip } from "../../../../../shared/chip";

export const BillingView: FC = () => {
  const classes = useStyles();
  const { patientInfo } = usePatientProfileWidget();

  const insurance = patientInfo.insurance ? patientInfo.insurance : '-';
  const primBenefitsRemaining = patientInfo.primBenefitsRemaining ? `$${patientInfo.primBenefitsRemaining}` : '-';
  const balance30 = patientInfo.outstandingBalance30Days ? patientInfo.outstandingBalance30Days : 0;
  const balance60 = patientInfo.outstandingBalance60Days ? patientInfo.outstandingBalance60Days : 0;
  const balance90 = patientInfo.outstandingBalance90Days ? patientInfo.outstandingBalance90Days : 0;

  const getColorClass = (balance: number) => {
    if (balance === 0)
      return { primary: '#616161', secondary: '#F0F1F4' };
    return balance < 0
      ? { primary: '#621B16', secondary: '#F7CECD' }
      : { primary: '#005E05', secondary: '#D8F8D9' };
  };

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContent>
      <AccordionDetails>
        <AccordionSection header='Insurance'>
          <AccordionContent>
            <label>{insurance}</label>
          </AccordionContent>
        </AccordionSection>
        <AccordionSection header='Insurance Remaining'>
          <AccordionContent>
            <label>{primBenefitsRemaining}</label>
          </AccordionContent>
        </AccordionSection>
        <AccordionSection header='Balances'>
          <AccordionContent>
            <div className={classes.balanceSection}>
              <Chip icon={<AttachMoney />} color={getColorClass(balance30)} />
              <label>{`30 Day - $${balance30}`}</label>
            </div>
            <div className={classes.balanceSection}>
              <Chip icon={<AttachMoney />} color={getColorClass(balance60)} />
              <label>{`60 Day - $${balance60}`}</label>
            </div>
            <div className={classes.balanceSection}>
              <Chip icon={<AttachMoney />} color={getColorClass(balance90)} />
              <label>{`90 Day - $${balance90}`}</label>
            </div>
          </AccordionContent>
        </AccordionSection>
      </AccordionDetails>
    </ViewContent>
  </>
}