import { makeStyles } from '@mui/styles';
import { useViewMode } from '../../../hooks/use-view-mode';

const useStyles = () => {
    const { viewMode, heightMode } = useViewMode();
    const isSmall = viewMode === 'Phone'
    const isShort = heightMode === 'Short'
    const isMedium = heightMode === 'Medium'

    const useStyles = makeStyles(() => ({
        sidebar: {
            color: 'white',
            overflow: 'hidden',
            position: 'relative',
            height: '100%',
            backgroundColor: '#004763',
            display: 'flex',
            flex: '80px',
            flexGrow: 0,
            flexShrink: 0,
            flexDirection: 'column',
            flexGlow: 'column',
            alignItems: 'center',
            zIndex: 2,
            ...(isSmall && {
                display: 'block',
                overflow: 'initial',
                padding: '24px 16px'
            }),
            ...(isShort && {
                flex: '50px'
            }),
            ...(isMedium && {
                flex: '60px'
            })
        }
    }));

    return useStyles();
}

interface SidebarProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function Sidebar({ children }: SidebarProps) {
    const classes = useStyles();
    return <nav className={classes.sidebar}>{children}</nav>;
}
