import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    sectionContainer: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #E9EAEE',
        width: '100%'
    },
    sectionType: {
        padding: '17px 0px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        letterSpacing: '1px',
        color: '#2C3542'
    }
});

export interface IProps {
    header: string | React.ReactElement;
}

const AccordionSectionType: React.FC<IProps> = props => {
    const classes = useStyles();

    return (
        <div className={classes.sectionContainer}>
            <div className={classes.sectionType}>
                <label>{props.header}</label>
            </div>
            {props.children}
        </div>
    );
};

export default AccordionSectionType;
