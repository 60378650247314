import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const useStyles = makeStyles(() => ({
    searchTextInput: {
        height: '40px'
    }
}));

interface SubmenuSearchProps {
    searchText: string;
    onChange: (e: any) => void;
}

export const SubmenuSearch = ({ searchText, onChange }: SubmenuSearchProps) => {
    const classes = useStyles();

    return (
        <>
            <Divider />
            <Box sx={{ padding: '1rem' }}>
                <TextField
                    data-testid='unified-inbox-quick-action-submenu-search'
                    label='Search'
                    type='search'
                    variant='outlined'
                    autoFocus={true}
                    fullWidth
                    onKeyDown={e => {
                        e.stopPropagation();
                    }}
                    InputProps={{
                        className: classes.searchTextInput,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    value={searchText}
                    onChange={onChange}
                />
            </Box>
        </>
    );
};
