import { Box, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import styledComponent from 'styled-components';
import { theme } from '../material-theme';
import { CircularProgress as CircularProgressMUI } from '@mui/material';

export const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    audio: {
        display: 'none'
    }
});

export const Inside = styled(Box)({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px'
});

export const CircularProgress = styled(CircularProgressMUI)((style) => ({
  color: style.theme.palette.secondary.light,
  width: '24px !important',
  height: '24px !important',
}));

export const Line = styled(Box)((style) => ({
  margin: '0 4px',
  flex: 1,
  height: 4,
  position: 'relative',
  backgroundColor: style.theme.palette.background.paper,
  '&:before, &:after': {
    content: "''",
    height: 12,
    backgroundColor: style.theme.palette.background.paper,
    width: 2,
    display: 'block',
    position: 'absolute',
    top: -4,
  },
  '&:after': {
    right: 0,
  },
}));

export const Seekable = styled(Box)((style) => ({
  flex: 1,
  height: 4,
  margin: '0 2px',
  position: 'relative',
  pointerEvents: 'none',
  backgroundColor: style.theme.palette.primary.main,
  top: -20,
}));

export const PlayPause = styled(IconButton)((style) => ({
  backgroundColor: style.theme.palette.primary.main,
  color: style.theme.palette.secondary.light,
  marginRight: 16,
  '&:hover': {
    backgroundColor: style.theme.palette.primary.main,
  },
}));

export const Volume = styled(IconButton)((style) => ({
  marginLeft: 16,
  backgroundColor: style.theme.palette.primary.light,
  color: style.theme.palette.primary.main,
  '&:hover': {
    backgroundColor: style.theme.palette.primary.light,
  },
}));

export const Seek = styledComponent.input`
  height: 12px;
  -webkit-appearance: none;
  width: calc(100% - 4px);
  position: relative;
  top: -7px;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: ${theme.palette.background.paper};
    border-radius: 0px;
    border: none;
  }

  &::-webkit-slider-thumb {
    border: none;
    height: 12px;
    width: 4px;
    border-radius: 0px;
    background: ${theme.palette.primary.main};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  &::-moz-range-thumb {
    border: none;
    height: 12px;
    width: 4px;
    border-radius: 0px;
    background: ${theme.palette.primary.main};
    cursor: pointer;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${theme.palette.background.paper};
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: ${theme.palette.primary.main};
    border-radius: 0px;
    border: none;
  }

`;
