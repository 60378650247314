import { Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useNotifications } from '@revenuewell/uc-notifications-client';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEffect, useMemo, useState } from 'react';
import { useWrapperNavigation } from '../../../../hooks/use-wrapper-navigation';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useInsuranceAlerts } from '../../../../hooks/use-insurance-alerts/use-insurance-alerts';
import CallerIdService from '../../../../services/caller-id-service/caller-id-service';

const useStyles = makeStyles({
    actionMenu: {
        width: 48,
        height: 48,
        margin: '11px 0 11px 18px',
        borderRadius: 12,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#265D7C'
        },
        '&:focus-visible': {
            border: '2px solid #1EB9FB'
        }
    },
    actionMenuGrey: {
        color: '#80979F'
    },
    actionMenuOpen: {
        backgroundColor: '#032F40',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#032F40'
        }
    },
    settingsBadge: {
        position: "relative",
        right: 18,
        top: -11,
        width: 14,
        height: 14
    }
});

export default function SettingsButton() {
    const classes = useStyles();
    const { windowUrls, activeFrame } = useWrapperNavigation();
    const { showError } = useInsuranceAlerts();
    const { oidcService, getClaims } = useOidcWithAdmin();
    const { config } = useConfig();
    const { listen } = useNotifications();
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const error = useMemo(() => showError(), [showError])
    const callerIdService = useMemo(() => CallerIdService.getInstance(config, oidcService), [config, oidcService]);

    useEffect(() => {
        const fetchCallerIdInfo = async () => {
            const { locationId } = await getClaims();
            const { isMainPhoneNumberVerified, currentCallerId, mainPhoneNumber } = await callerIdService.getCallerIdInfo(locationId) || {};

            if (!isMainPhoneNumberVerified || currentCallerId !== mainPhoneNumber) 
                setShowWarning(true); 
        };

        fetchCallerIdInfo();
    }, []);

    useEffect(() => {
        return listen('caller-id-verified', () => {
            setShowWarning(false);
        });
    }, [listen]);

    return (
        <Box>
            <IconButton
                className={`${classes.actionMenu} ${classes.actionMenuGrey} ${
                activeFrame === 'settings' && classes.actionMenuOpen
                }`}
                component={Link}
                to={windowUrls.settings}>
                <SettingsIcon />
            </IconButton>
            {error ? <ErrorIcon className={classes.settingsBadge} color="error"/> : <></>}
            {!error && showWarning ? <WarningIcon className={classes.settingsBadge} color="warning" /> : <></>}
        </Box>
    );
}
