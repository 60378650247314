import { useSlideout } from '../../../../hooks/use-slideout';
import SlideOutCard from '../../../shared/slide-out-card';
import AlertsWidget from './alerts-widget';

export default function AlertsSlideout() {
    const { isVisibleAlerts, close, hasHistory, back } = useSlideout();

    const handleClose = () => {
        close();
    };

    return (
        <SlideOutCard isVisible={isVisibleAlerts} onClose={handleClose} onBackClick={hasHistory ? back : undefined}>
            <AlertsWidget />
        </SlideOutCard>
    );
}
