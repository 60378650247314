import { useSlideout } from '../../../../../hooks/use-slideout';
import SlideOutCard from '../../../../shared/slide-out-card';
import StartSMSWidget from './start-sms-widget';

export default function StartSMSSlideout() {
    const { isVisibleStartSms, close, hasHistory, back } = useSlideout();

    const handleClose = () => {
        close();
    };

    return (
        <SlideOutCard isVisible={isVisibleStartSms} onClose={handleClose} onBackClick={hasHistory ? back : undefined}>
            <StartSMSWidget />
        </SlideOutCard>
    );
}
