import { useState, useEffect, useMemo } from 'react';
import { useCommunications } from '../../../../hooks/use-communications';
import { useSlideout } from '../../../../hooks/use-slideout';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { EventVoicemailWidget } from '../../../../types/event-bus';
import SlideOutCard from '../../../shared/slide-out-card';
import { useContactState } from '../../../../hooks/use-contacts';
import { importantContacts } from '../../../../utils/contact';
import LocationService, { ILocation } from '../../../../services/location-service/location-service';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { ContactApi } from '../../../../services/api-client/contact-api';
import VoicemailWidget from './voicemail-widget';

export default function VoicemailSlideout() {
    const { isVisibleVoicemail, currentEvent, back } = useSlideout();
    const { setSearchByPhoneNumbers } = useCommunications();
    const { contactCache, loadContactsByPhoneNumbers } = useContactState();
    const { publish } = useEventBus();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const [patientPhoneNumbers, setPatientPhoneNumbers] = useState<string[]>(null!);
    const [initialPhoneNumber, setInitialPhoneNumber] = useState<string>(null!);
    const [patientId, setPatientId] = useState<number>(null!);
    const [locationId, setLocationId] = useState<number>(null!);
    const [lookupPhoneNumbers, setLookupPhoneNumbers] = useState<string[]>();
    const [networkId, setNetworkId] = useState<number>();
    const [callId, setCallId] = useState<string>();

    const resetWidget = () => {
        setPatientPhoneNumbers(null!);
        setLocationId(null!);
        setSearchByPhoneNumbers(null!);
        setPatientId(null!);
        setInitialPhoneNumber(null!);
    };

    const handleBackToPreviewPage = () => {
        resetWidget();
        back();
    };

    const handleClose = () => {
        publish({
            messageType: 'closeWidget'
        });
    };

    const getPhoneNumbers = useMemo(
        () => async (phoneNumbers: string[], patientId?: number, networkId?: number) => {
            const contacts = phoneNumbers
                .flatMap(phoneNumber => contactCache[phoneNumber]?.data)
                .filter(contact => contact);
            if (contacts && contacts.length) {
                const finalContacts = importantContacts(contacts, patientId, networkId);

                //first check patient matching id, next first responsible party in network and last first reponsible party
                const phoneNumbers: string[] = [];
                finalContacts.map(patient => {
                    if (patient?.cellPhone && phoneNumbers.indexOf(patient.cellPhone) == -1)
                        phoneNumbers.push(patient.cellPhone);
                    if (patient?.homePhone && phoneNumbers.indexOf(patient.homePhone) == -1)
                        phoneNumbers.push(patient.homePhone);
                    if (patient?.workPhone && phoneNumbers.indexOf(patient.workPhone) == -1)
                        phoneNumbers.push(patient.workPhone);
                });
                return phoneNumbers;
            }
            return phoneNumbers;
        },
        [contactCache]
    );

    useEffect(() => {
        (async () => {
            if (!isVisibleVoicemail) return;

            const { data } = currentEvent as EventVoicemailWidget;

            console.log('** - EVENT', currentEvent);

            resetWidget();

            if (data.callId) {
                setCallId(data.callId);
            }

            let location: ILocation | undefined = undefined;
            if (data.locationId) {
                setLocationId(data.locationId);
                location = await LocationService.getInstance(config, oidcService).getLocation(data.locationId);
                setNetworkId(location?.idNetwork);
            }

            if (data.phoneNumber) {
                await loadContactsByPhoneNumbers([data.phoneNumber]);
                setLookupPhoneNumbers([data.phoneNumber]);
                setInitialPhoneNumber(data.phoneNumber);
            } else if (data.patientId) {
                const phoneNumbers: string[] = [];
                const contactApi = ContactApi.initialize(config, oidcService);
                const patient = await contactApi.getContactDetailsById(data.patientId);
                if (patient.cellPhone && phoneNumbers.indexOf(patient.cellPhone) == -1)
                    phoneNumbers.push(patient.cellPhone);
                if (patient?.homePhone && phoneNumbers.indexOf(patient.homePhone) == -1)
                    phoneNumbers.push(patient.homePhone);
                if (patient?.workPhone && phoneNumbers.indexOf(patient.workPhone) == -1)
                    phoneNumbers.push(patient.workPhone);

                await loadContactsByPhoneNumbers(phoneNumbers);
                setLookupPhoneNumbers(phoneNumbers);
            }

            if (data.patientId) setPatientId(patientId);
        })();
    }, [currentEvent, isVisibleVoicemail]);

    useEffect(() => {
        if (!lookupPhoneNumbers) return;
        (async () => {
            const finalPhoneNumbers = await getPhoneNumbers(lookupPhoneNumbers, patientId, networkId);
            setPatientPhoneNumbers(finalPhoneNumbers);
            setSearchByPhoneNumbers(finalPhoneNumbers);
            if (!initialPhoneNumber) setInitialPhoneNumber(finalPhoneNumbers[0]);
        })();
    }, [lookupPhoneNumbers]);

    return (
        <SlideOutCard isVisible={isVisibleVoicemail} onClose={handleClose} onBackClick={handleBackToPreviewPage}>
            {patientPhoneNumbers && locationId && callId && (
                <VoicemailWidget
                    patientPhoneNumbers={patientPhoneNumbers}
                    locationId={locationId}
                    patientId={patientId}
                    callId={callId}
                />
            )}
        </SlideOutCard>
    );
}
