/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Location, VoiceApi } from '../../services/api-client/voice-api';

export interface LocationsCtx {
    locations: Location[];
    selectedLocationIds: number[];
    setLocationIds: (locationIds: number[]) => void;
}

export const LocationsContext = createContext<LocationsCtx>(null!);

export default function LocationsProvider({ children }: PropsWithChildren<{}>) {
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([]);
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();

    useEffect(() => {
        if (!(config && oidcService)) return;
        (async () => {
            const result = await new VoiceApi(config, oidcService).getLocations();
            setLocations(result);
        })();
    }, [config, oidcService]);

    return (
        <LocationsContext.Provider value={{ locations, selectedLocationIds, setLocationIds: setSelectedLocationIds }}>
            {children}
        </LocationsContext.Provider>
    );
}
