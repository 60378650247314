import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { OidcService } from '@revenuewell/logic-oidc';

export class NotificationApi extends AuthApiService {
    private config: any;

    constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        return this;
    }

    private async getUserId() {
        const { id } = await this.oidcService.getClaims();
        return id;
    }

    public async getNotificationStatus(): Promise<{
        teamChat: boolean;
        messenger: boolean;
        snoozeExpirationDate: string;
    } | null> {
        const userId = await this.getUserId();

        const url = `${this.config.config.apiUrl}/settings/users/${userId}/notifications`;
        try {
            const response = await this.authFetch(url, { method: 'GET' });
            const json = await response.json();
            return json;
        } catch (error) {
            if ((error as any).status === 404) return null;
            throw error;
        }
    }

    public async setSnooze(snoozeExpirationDate: Date | null): Promise<void> {
        const userId = await this.getUserId();

        const url = `${this.config.config.apiUrl}/settings/users/${userId}/notifications`;
        await this.authFetch(url, this.request({ snoozeExpirationDate: snoozeExpirationDate }, 'PATCH'));
    }

    private request(body: any, verb: 'DELETE' | 'POST' | 'PUT' | 'PATCH' = 'PATCH') {
        return {
            method: verb,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };
    }
}
