import { FC, useMemo, useState } from "react";
import { NavigationBreadcrumbs } from "../../../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../../../header/header";
import { ViewContentWrapper } from "../../../shared/view-content/view-content.styles";
import { PreferencesViewWrapper } from "../../shared/preferences-view-wrapper/preferences-view-wrapper";
import { useConfig, useNotification, useOidcWithAdmin } from "@revenuewell/front-end-bundle";
import { usePatientProfileWidget } from "../../../../hooks/use-patient-profile-widget";
import { ContactApi, PatientPreferencesRequest, PatientPreferencesResponse } from "../../../../../../../../services/api-client/contact-api";
import CommunicationSubSection from "../../shared/sub-section/communication-sub-section";

export const PhoneCallPreferencesView: FC = () => {
  const { config } = useConfig();
  const { oidcService } = useOidcWithAdmin();
  const { showSuccess } = useNotification();
  const { patientPreferences, refreshPatientPreferences, goBack } = usePatientProfileWidget();
  const [localPreferences, setLocalPreferences] = useState<PatientPreferencesResponse>(patientPreferences);
  const contactApi = useMemo(() => new ContactApi(config, oidcService), [config, oidcService]);

  const updateLocalPreferences = (state: Partial<PatientPreferencesResponse>) => {
    const newState = { ...localPreferences, ...state };
    setLocalPreferences(newState);
  }

  const updatePhoneCallPreferences = async () => {
    await contactApi.patchPatientPreferences(
      localPreferences.patientId,
      localPreferences as PatientPreferencesRequest
    );
    await refreshPatientPreferences();
    showSuccess("Phone call preferences successfully saved");
    goBack();
  };

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContentWrapper>
      <PreferencesViewWrapper onSave={updatePhoneCallPreferences}>
        <CommunicationSubSection
          text='Appointment Confirmations'
          isChecked={localPreferences.pcAppointmentConfirmations}
          onChange={value => updateLocalPreferences({ pcAppointmentConfirmations: value })}
        />
      </PreferencesViewWrapper>
    </ViewContentWrapper>
  </>
}