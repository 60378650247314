import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { ChannelSearch, ChannelSearchParams, ChannelsUnreadCounts, ChannelsUnreadCountsParams } from './types';

export default class InboxService extends AuthApiService {
    private static instance: InboxService;
    private config: any;
    private publisherUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.publisherUrl = `${config.publisher.apiUrl}`;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!InboxService.instance)
            InboxService.instance = new InboxService(config, oidcService);

        return InboxService.instance;
    }

    public async channelsUnreadCounts(params: ChannelsUnreadCountsParams): Promise<ChannelsUnreadCounts> {
        const url = `${this.publisherUrl}/mailboxes/channels/unreadcounts/universal-inbox`;

        const response = await this.authFetch(url, {
            method: 'POST',
            body: JSON.stringify({
                ...params,
            }),
            headers: { 'Content-Type': 'application/json' }
        });

        const data: ChannelsUnreadCounts = await response.json();

        return data;
    }

    public async channelSearch(params?: Partial<ChannelSearchParams>): Promise<ChannelSearch[]> {
        const url = `${this.publisherUrl}/mailboxes/channels/search`;

        const response = await this.authFetch(url, {
            method: 'POST',
            body: JSON.stringify({
                ...params
            }),
            headers: { 'Content-Type': 'application/json' },
        });

        const data: ChannelSearch[] = await response.json();

        return data;
    }

    public async applyChannelAction(
        phoneNumber: string,
        mailboxId: number,
        action: {
            isArchived?: boolean;
            isSaved?: boolean;
            isUnread?: boolean;
            isUnreadMessenger?: boolean;
            isUnreadPhone?: boolean;
        }
    ): Promise<void> {
        const url = `${this.publisherUrl}/mailboxes/${mailboxId}/channels/${phoneNumber}`;

        await this.authFetch(url, {
            method: 'PATCH',
            body: JSON.stringify(action),
            headers: { 'Content-Type': 'application/json' },
        });
    }

    public async markAllChannelAsRead (mailboxId: number): Promise<void> {
        const url =`${this.publisherUrl}/mailboxes/${mailboxId}/channels/mark-all-read`

        await this.authFetch(url, {
            method: 'PATCH',
        })
    }
}
