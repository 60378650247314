import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  subSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '22px 24px 10px',
    borderBottom: '1px solid #E1E4E7',
    width: '100%'
  },
  displayText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#2C3542',
    letterSpacing: '1px'
  }
});

interface CommunicationSubSectionProps {
  displayText: string;
}

const CommunicationSubSectionHeader = ({ displayText }: CommunicationSubSectionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.subSectionContainer}>
      <Typography variant='body2' className={classes.displayText} component='div'>
        {displayText}
      </Typography>
    </div>
  );
};

export default CommunicationSubSectionHeader;
