import { TabContext, TabList } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SyntheticEvent, useState } from 'react';
import AlertsPanel from './alerts-panel';

const useClasses = makeStyles({
    container: {
        height: '100%',
        backgroundColor: '#fff'
    },
    title: {
        marginLeft: 16,
        marginBottom: 12,
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '28px',
        color: '#000'
    },
    tabList: {
        borderTop: '1px solid #E1E4E7',
        borderBottom: '1px solid #E1E4E7'
    },
    tabItem: {
        textTransform: 'none'
    }
});

export default function AlertsWidget() {
    const classes = useClasses();
    const [value, setValue] = useState<'active' | 'dismissed'>('active');

    const handleChange = (event: SyntheticEvent, newValue: 'active' | 'dismissed') => {
        setValue(newValue);
    };

    return (
        <Box className={classes.container}>
            <Typography variant='h3' className={classes.title}>
                Alerts
            </Typography>
            <TabContext value={value}>
                <TabList className={classes.tabList} variant='fullWidth' onChange={handleChange}>
                    <Tab className={classes.tabItem} label='Active' value='active' />
                    <Tab className={classes.tabItem} label='Dismissed' value='dismissed' />
                </TabList>
                <AlertsPanel type='active' />
                <AlertsPanel type='dismissed' />
            </TabContext>
        </Box>
    );
}
