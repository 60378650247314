import { FC, useEffect, useState } from "react";
import { NavigationBreadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../header/header";
import { ViewContent } from "../shared/view-content/view-content";
import { CommunicationsSlideout } from "../../../communications-widget";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import { EventInboxCommunicationsWidget } from "../../../../../../types/event-bus";
import { PhoneHeader } from "../../header/phone-header";

export const InboxView: FC = () => {
  const { events, patientInfo, locationId } = usePatientProfileWidget();
  const [inboxEvent, setInboxEvent] = useState<EventInboxCommunicationsWidget | undefined>();

  useEffect(() => {
    if (events.patientInboxEvent) {
      setInboxEvent(events.patientInboxEvent);
    } else if (patientInfo?.phoneNumbers?.cellPhone) {
      setInboxEvent({
        messageType: 'inboxCommunications',
        data: {
          patientId: patientInfo.id,
          phoneNumber: patientInfo.phoneNumbers.cellPhone,
          locationId: locationId
        }
      });
    }
  }, [events, patientInfo]);

  return <>
    {patientInfo
      ? <PatientProfileHeader />
      : <PhoneHeader phoneNumber={events.patientInboxEvent?.data.phoneNumber || ''} />
    }
    <NavigationBreadcrumbs />
    <ViewContent>
      {inboxEvent &&
        <CommunicationsSlideout
          phoneNumber={inboxEvent.data.phoneNumber}
          patientId={inboxEvent.data.patientId}
          locationId={inboxEvent.data.locationId}
          timestamp={inboxEvent.data.timestamp} />}
    </ViewContent>
  </>
}
