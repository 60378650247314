import { Box, Button, IconButton, Typography, styled } from '@mui/material';

export const InboxContainer = styled(Box)({
    height: '100%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column'
})

/* ------------------ top of the page ----------------*/
export const InboxHeader = styled(Box)({
    marginRight: 24,
    marginLeft: 24,
    paddingBottom: 12,
    display: 'flex',
    flexDirection: 'row'
});

export const InboxTitle = styled(Typography)({
    fontWeight: 500,
    fontSize: 24,
    color: '#000000',
    flex: 1
});

export const InboxIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "toggled"
})<{ toggled?: boolean }>(({ toggled }) => ({
    width: 32,
    height: 32,
    backgroundColor: toggled ? '#0675DD' : '#EBF3FA',
    color: toggled ? '#FFFFFF' : '#0259AB',
    marginLeft: 8,
    '&:hover': {
        backgroundColor: toggled ? '#0675DD' : '#D6E7F5',
        color: toggled ? '#FFFFFF' : '#0259AB',
        transition: '0.2s'
    }
}));

/* ------------------ bottom of the page ----------------*/
export const InboxPreviewFooter = styled(Box)({
    height: 68,
    gap: 6,
    borderTop: '1px solid #E1E4E7',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 'auto'
});

interface FooterButtonOverrides {
    withFaxButton?: boolean;
}

export const InboxFooterButton = styled(Button)<FooterButtonOverrides>(({ withFaxButton }) => ({
    ...(withFaxButton ? { fontSize: 12} : { width: 132, fontSize: 14}),
    height: 36,
    letterSpacing: 0.4,
    textTransform: 'none',
    backgroundColor: '#0675DD',
    '&:hover': {
        backgroundColor: '#0675DD'
    }
}));

export const InboxFooterButtonLight = styled(InboxFooterButton)({
    color: '#0675DD',
    backgroundColor: '#EBF3FA',
    '&:hover': {
        backgroundColor: '#EBF3FA'
    }
});

/* ------------------ section separator ----------------*/
export const InboxSectionSeparator = styled(Box)({
    padding: '0px 16px',
    height: 32,
    background: '#F9FAFB',
})

export const OverlineText = styled(Typography)({
    fontSize: 12,
    color: '#2C3542',
    letterSpacing: 1
})

