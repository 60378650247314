import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";
import { CommunicationStatus } from "../types/communication-status";
import { getCustomizedDMCount } from "./get-customized-dm-count";

export const getDMStatus = (patientPreferences: PatientPreferencesResponse) => {
  const count = getCustomizedDMCount(patientPreferences);

  if (count === 0) return CommunicationStatus.none;
  if (count === 7) return CommunicationStatus.all;
  return CommunicationStatus.some;
};