import { Button, Typography } from '@mui/material';
import { usePatientProfileWidget } from '../../../../hooks/use-patient-profile-widget';
import { useStyles } from './preferences-view-wrapper.styles';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import Scrollbars from 'react-custom-scrollbars-2';

export interface IProps {
  headerSubText?: string;
  onSave: () => Promise<void>;
}

export const PreferencesViewWrapper: React.FC<IProps> = props => {
  const classes = useStyles();
  const { goBack } = usePatientProfileWidget();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    await props.onSave();
    setIsLoading(false);
  }

  return (
    <div className={classes.widgetContainer}>
      <Scrollbars autoHide style={{ flexGrow: 1 }}>
      <div className={classes.body}>
        {props.children}
      </div>
      </Scrollbars>
      <div className={classes.footer}>
        <Button variant='contained' className={`${classes.button} ${classes.cancel}`} onClick={goBack} disableElevation>
          Cancel
        </Button>
        <LoadingButton variant='contained' loading={isLoading} className={classes.button} onClick={handleSave} disableElevation>
          Save
        </LoadingButton>
      </div>
    </div>
  );
};
