import React, { ReactNode, createContext } from "react";
import { WidgetRoutePath } from "../../navigation/route-config";
import { Contact, ContactLocationInfo, ContactSearchByNumber, PatientInfo, PatientPreferencesResponse } from "../../../../../../services/api-client/contact-api";
import { EventInboxCommunicationsWidget, EventPatientProfile, EventPatientProfileWithPhoneNumber } from "../../../../../../types/event-bus";

export interface BreadcrumbExtensionProps {
  placeholers?: {
    left?: {
      component: ReactNode
    }
    right?: {
      component: ReactNode
    }
  }
}

export interface PatientProfileWidgetContextProps {
  events: {
    patientIdEvent?: EventPatientProfile | undefined;
    patientPhoneEvent?: EventPatientProfileWithPhoneNumber | undefined;
    patientInboxEvent?: EventInboxCommunicationsWidget | undefined;
  }
  route: WidgetRoutePath | undefined
  hasHistory: boolean
  goTo: (route: WidgetRoutePath) => void
  goBack: () => void
  isLoading: boolean
  contactDetails: Contact
  contactLocationInfo: ContactLocationInfo[]
  contactsByPhone: ContactSearchByNumber[]
  patientInfo: PatientInfo
  patientPreferences: PatientPreferencesResponse
  refreshPatientPreferences: () => Promise<void>
  locationId: number
  networkId: number
  isSkinnyProfile: boolean
  breadcrumbExtensions: BreadcrumbExtensionProps | undefined
  setBreadcrumbExtensions: (extensions: BreadcrumbExtensionProps) => void
}

export const PatientProfileWidgetContext = createContext<PatientProfileWidgetContextProps>(null!)