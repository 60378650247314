import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import NoPatientCard from '../messenger-widget/start-sms/no-patient-card';
import { ContactSearchByNumber } from '../../../../services/api-client/contact-api';
import SelectPatientCard from './select-patient-card';

const useClasses = makeStyles({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff'
    },
    titleContainer: {
        width: '100%',
        borderBottom: '1px solid #E1E4E7'
    },
    title: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        marginLeft: 16,
        marginBottom: 12,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '28px',
        color: '#000',
        padding: '8px 12px'
    },
    search: {
        padding: '20px 16px'
    }
});

interface SelectPatientWidgetProps {
    contacts: ContactSearchByNumber[];
}

export default function SelectPatientWidget({ contacts }: SelectPatientWidgetProps) {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant='h3' className={classes.title}>
                    Select Patient
                </Typography>
            </div>
            {contacts.length > 0 ? (
                contacts.map(contact => {
                    return <SelectPatientCard key={contact.id} contactInfo={contact} />;
                })
            ) : (
                <NoPatientCard searching={false} />
            )}
        </div>
    );
}
