import { Box } from '@mui/material'
import { styled } from '@mui/styles'

const SearchEmptyContainer = styled(Box)({
    fontFamily: 'Roboto',
    padding: '24px',
    textAlign: 'center',
    color: '#2C3542',
    fontWeight: 400,
    fontSize: '16px'
})

export default function SearchEmpty({ isMore }: { isMore?: boolean }) {
    return (
        <SearchEmptyContainer>
            {isMore 
                ? 'There are no more records matching your search terms.' 
                : 'There are no records matching your search terms.'}
        </SearchEmptyContainer>
    )
}