import React, { useEffect, useState } from 'react';

import { IconButton, TextField, Autocomplete, Box, Paper } from '@mui/material';
import { useProduct } from '@revenuewell/front-end-bundle';
import { useStyles } from './navbar.styles';
import { useEventBus } from '../../../../../../hooks/use-event-bus';
import { EventJumpToMessage, EventMessengerWidget, EventPatientProfile } from '../../../../../../types/event-bus';
import { usePatientProfileWidget } from '../../hooks/use-patient-profile-widget';
import { WidgetRoutePath } from '../../navigation/route-config';

export const DropDownPaper = (props: React.HTMLAttributes<HTMLElement>) => {
  return <Paper elevation={8} style={{ background: '#FFFFFF' }} {...props} />;
};

export default function HeaderNavbar() {
  const classes = useStyles();
  const { publish, publishToIframes } = useEventBus();
  const { hasProduct } = useProduct();
  const { contactDetails, contactLocationInfo, locationId, networkId, goTo, route } = usePatientProfileWidget();
  const [isMultiLocation, setIsMultiLocation] = useState<boolean>(null!);

  useEffect(() => {
    if(contactLocationInfo) {
      setIsMultiLocation(
        contactLocationInfo.length > 1 || !contactLocationInfo.filter(c => c.networkId === networkId).length
      );
    }
  }, [contactLocationInfo, networkId]);

  const isCellPhoneValid = () => {
    return contactDetails.cellPhone && contactDetails.cellPhone.length > 0;
  };

  const arePhonesValid = () => {
    if (
      (contactDetails.cellPhone && contactDetails.cellPhone.length > 0) ||
      (contactDetails.workPhone && contactDetails.workPhone.length > 0) ||
      (contactDetails.homePhone && contactDetails.homePhone.length > 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.navContainer}>
      <div className={classes.navButtons}>
        {contactDetails && arePhonesValid() && (hasProduct('phone') || hasProduct('phone1') || hasProduct('phone2')) && (
          <IconButton
            color='primary'
            data-testid='patient-widget-header-phone-button'
            className={classes.headerIconButton}
            onClick={() =>
              publish({
                messageType: 'startCall',
                data: {
                  phoneNumber: contactDetails.cellPhone! || contactDetails.homePhone!
                }
              })
            }
          >
            <i className={classes.call} />
          </IconButton>
        )}

        {contactDetails && isCellPhoneValid() 
          && (hasProduct('messenger') || hasProduct('messenger2') 
          && route != WidgetRoutePath.INBOX) && (
          <IconButton
            color='primary'
            data-testid='patient-widget-header-messenger-button'
            className={classes.headerIconButton}
            onClick={() => {
              if (hasProduct('messenger')) {
                publish({
                  messageType: 'jumpToMessage',
                  data: {
                    patientId: contactDetails.id
                  } as EventJumpToMessage['data']
                });
              } else {
                goTo(WidgetRoutePath.INBOX);
              }
            }}
          >
            <i className={classes.message} />
          </IconButton>
        )}

        {contactDetails && hasProduct('virtualvisits') && (
          <IconButton
            color='primary'
            data-testid='patient-widget-header-virtual-visits-button'
            className={classes.headerIconButton}
            onClick={() =>
              publish({
                messageType: 'start-virtual-visit',
                data: {
                  contactId: contactDetails.id,
                  contactLocationId: contactDetails.location!.id,
                  contactCellPhone: contactDetails.cellPhone!
                }
              })
            }
          >
            <i className={classes.video} />
          </IconButton>
        )}
        {contactDetails && hasProduct('core') && !hasProduct('patients2') && (
          <IconButton
            color='primary'
            data-testid='patient-widget-header-patinet-profile-button'
            className={classes.headerIconButton}
            onClick={() =>
              publish({
                messageType: 'navigate',
                data: {
                  pathname: `/core/patient/PatientSummaryInfo/${contactDetails.id}_0`,
                  crossFrame: true,
                  replaceState: false
                }
              })
            }
          >
            <i className={classes.core} />
          </IconButton>
        )}

        {contactDetails && hasProduct('patients2') && (
          <IconButton
            color='primary'
            data-testid='patient-widget-header-patient-profile-button'
            className={classes.headerIconButton}
            onClick={() => {
              publish({
                messageType: 'navigate',
                data: {
                  pathname: `/contacts/contacts/${contactDetails.id}`,
                  crossFrame: true,
                  replaceState: false
                }
              });
              window.setTimeout(() => {
                publishToIframes({
                  messageType: 'frame-activate',
                  data: {
                    frameName: 'contacts'
                  }
                });
              }, 500);
            }}
          >
            <i className={classes.patient} />
          </IconButton>
        )}
      </div>
      
      {isMultiLocation && (
        <Autocomplete
          className={classes.locations}
          autoComplete={false}
          disableClearable
          sx={{ WebkitTextFillColor: '#000000' }}
          PaperComponent={DropDownPaper}
          options={contactLocationInfo}
          autoHighlight
          defaultValue={
            contactLocationInfo[
            contactLocationInfo.findIndex(location => location.locationId == locationId)
            ]
          }
          getOptionLabel={option => option.locationName!}
          onChange={(item, value) => {
            publish({
              messageType: 'patientProfile',
              data: {
                patientId: value.id,
                locationId: value.locationId
              } as EventPatientProfile['data']
            });
          }}
          renderOption={(props, option) => (
            <Box component='li' {...props} sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}>
              {option.locationName}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant='outlined'
              label='Locations'
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
        />
      )}

    </div>
  );
}
