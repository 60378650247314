import config from '../../../config/config.json';

export interface SubMenuItem {
    label: string;
    url: string;
}

export const mySecurePracticeSubMenuItems = (locationId: number): SubMenuItem[] => {
    return [
        {
            label: 'Secure Mail',
            url: `${config.pbhs.url}securemail${config.pbhs.queryParams}${locationId}`
        },
        {
            label: 'ADA TV',
            url: `${config.pbhs.url}ada-tv${config.pbhs.queryParams}${locationId}`
        },
        {
            label: 'Log In to MySecurePractice',
            url: `${config.mySecurePractice.url}${config.mySecurePractice.queryParams}${locationId}`
        }
    ];
};

export const pbhsWebisteAdminSubMenuItems = (locationId: number): SubMenuItem[] => {
    return [
        {
            label: 'Website Design',
            url: `${config.pbhs.url}dental-website-design?${config.pbhs.queryParams}${locationId}`
        },
        {
            label: 'Search Engine Optimization',
            url: `${config.pbhs.url}dental-seo${config.pbhs.queryParams}${locationId}`
        },
        {
            label: 'PPC/Google Advertising',
            url: `${config.pbhs.url}ppc-dental-marketing${config.pbhs.queryParams}${locationId}`
        },
        {
            label: 'Social Media Marketing',
            url: `${config.pbhs.url}social-media-marketing${config.pbhs.queryParams}${locationId}`
        },
        {
            label: 'Website Chat',
            url: `${config.pbhs.url}our-services/products-and-applications/launch-chat${config.pbhs.queryParams}${locationId}`
        }
    ];
};
