import { FC } from "react";
import { NavigationBreadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import { PatientProfileHeader } from "../../header/header";
import { ViewContent } from "../shared/view-content/view-content";
import { StatusList, StatusListItem } from "./alerts-tasks.styles";
import { usePatientProfileWidget } from "../../hooks/use-patient-profile-widget";
import { getStatusIcon } from "./status-icon-factory";
import { useEventBus } from "../../../../../../hooks/use-event-bus";
import { EventPatientProfile } from "@revenuewell/front-end-bundle";
import { Link } from "@mui/material";

export const AlertsAndTasksView: FC = () => {
  const { publish } = useEventBus();
  const { patientInfo } = usePatientProfileWidget();

  const responsiblePartyClick = (patientId: number) => {
    publish({
      messageType: 'patientProfile',
      data: { patientId } as EventPatientProfile['data']
    });
  }

  return <>
    <PatientProfileHeader />
    <NavigationBreadcrumbs />
    <ViewContent>
      <StatusList>
        { !patientInfo?.patientAlerts || patientInfo.patientAlerts.length === 0 &&
          <StatusListItem text="Patient has no alerts or tasks" />
        }
        {patientInfo.patientAlerts && patientInfo.patientAlerts.map((x, i) =>
          <StatusListItem key={i} text={x.alert} icon={getStatusIcon(x.alert).icon} iconcolor={getStatusIcon(x.alert).color}>
            {x.patientId != patientInfo.id &&
              <Link onClick={() => responsiblePartyClick(x.patientId)} sx={{ marginTop: '6px', display: 'flex' }}>
                {`${x.firstName} ${x.lastName}`}
              </Link>
            }
          </StatusListItem>
        )}
      </StatusList>
    </ViewContent>
  </>
}