import * as React from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Menu from '../../../pages/menu/menu';
import { Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Help as HelpIcon, Settings as SettingsIcon, Close as CloseIcon } from '@mui/icons-material';
import { useWrapperNavigation } from '../../../../hooks/use-wrapper-navigation';
import UserButton from './user-button';
import config from '../../../../config/config.json';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';

import { useStyles as useStylesTopNav } from './top-nav';

export const useStyles = makeStyles({
    container: {
        backgroundColor: '#004763',
        borderRadius: 0,
        zIndex: 1000,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    topMenu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: '#05374A',
        padding: '0px 24px 10px 0'
    },
    close: {
        background: '#05374A',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 24px 0'
    },
    products: {
        height: 'calc(100% - 130px)',
        overflow: 'auto'
    }
});

const TopNavMenuMobile = React.forwardRef<HTMLDivElement, { onClose: () => void }>(({ onClose }, ref) => {
    const style = useStyles();
    const classes = useStylesTopNav();
    const { windowUrls, activeFrame } = useWrapperNavigation();
    const { publish } = useEventBus();

    return (
        <Paper className={style.container} ref={ref}>
            <Box className={style.close}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box className={style.topMenu}>
                <IconButton
                    className={`${classes.actionMenu} ${classes.actionMenuGrey} ${
                        activeFrame === 'settings' && classes.actionMenuOpen
                    }`}
                    component={Link}
                    to={windowUrls.settings}
                    onClick={onClose}
                >
                    <SettingsIcon />
                </IconButton>
                <IconButton
                    className={`${classes.actionMenu} ${classes.actionMenuGrey}`}
                    onClick={() => {
                        publish({
                            messageType: 'navigate',
                            data: {
                                pathname: config.support.url,
                                crossFrame: true,
                                replaceState: false
                            }
                        });
                    }}
                >
                    <HelpIcon />
                </IconButton>
                <UserButton />
            </Box>
            <Box className={style.products}>
                <Menu onClick={onClose} />
            </Box>
        </Paper>
    );
});

export default TopNavMenuMobile;
