import { Box, Avatar, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { WarningAmber as NoResultIcon } from '@mui/icons-material';

const InboxSearchEmptyContainer = styled(Box)({
    padding: 16,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 32,
    top: 150,
    gap: 8,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
})

export default function InboxSearchEmpty() {
    return (
        <InboxSearchEmptyContainer>
            <Avatar sx={{ bgcolor: '#EDEFF2', width: 80, height: 80 }}>
                <NoResultIcon sx={{ fontSize: 30, color: '#BDC3CA' }} />
            </Avatar>
            <Typography variant = 'h6' color = '#000000'>
                Your filters returned no results
            </Typography>
        </InboxSearchEmptyContainer>
    )
}