import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Tooltip, TextField } from '@mui/material';
import { useCallback } from 'react';

const ChipThemed = withStyles((theme) => ({
  root: {
    backgroundColor: '#EDF5FE',
    maxWidth: '140px !important'
  },
  label: {
    color: theme.palette.primary.main,
  },
  deleteIcon: {
    color: theme.palette.primary.main,
  },
}))(Chip);

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '288px',
    height: 56,
    backgroundColor: '#FFFFFF',
  },
  listbox: {
    padding: '8px 0 !important',
    backgroundColor: '#FFFFFF',
  },
  inputRoot: {
    flexWrap: 'nowrap',
  },
});

type AutocompleteProps = {
  label: string;
  id: string;
  options: any[];
  value: any;
  type: string;
  multiple?: boolean;
  getOptionLabel: (value: any) => any;
  onChange: (event: any, values: any[] | any, reason: string) => any;
};
export interface AutocompleteHandles {
  clear: () => void;
}

const Autocomplete: React.ForwardRefRenderFunction<AutocompleteHandles, AutocompleteProps> = (
  { label, id, options, value, type, multiple = true, getOptionLabel, onChange },
  ref
) => {
  const classes = useStyles();
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const handleOnChange = useCallback(
    (event, value, reason) => {
      if (['clear', 'remove-option'].includes(reason) && document.activeElement) {
        const activeElement = document.activeElement as HTMLInputElement;
        activeElement.blur();
      }

      if (onChange) onChange(event, value, reason);
    },
    [onChange]
  );

  useImperativeHandle(ref, () => {
    return {
      clear: () => {
        const button = autocompleteRef.current?.querySelector('.MuiAutocomplete-clearIndicator') as HTMLButtonElement;
        if (button) {
          button.click();
          (window.document.activeElement as any)?.blur();
        }
      },
    };
  });

  const fullText: string = useMemo((): string => {
    if (!value.length) return '';
    return getOptionLabel(value[0]);
  }, [value]);

  return (
    <MUIAutocomplete
      multiple={multiple}
      ref={autocompleteRef}
      limitTags={1}
      id={id}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      onChange={handleOnChange}
      classes={classes}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return (
            <Tooltip title={fullText} >
              <ChipThemed label={getOptionLabel(option)} {...getTagProps({ index })} key={index} />
            </Tooltip>
          )
        })
      }
      renderInput={(params) => <TextField {...params} type={type} variant="outlined" label={label} />}
    />
  );
};

export default forwardRef(Autocomplete);
