import { Typography } from '@mui/material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    }
});

const AccordionContent: React.FC = props => {
    const classes = useStyles();

    return (
        <Typography variant='body2' component='div' className={classes.content}>
            {props.children}
        </Typography>
    );
};

export default AccordionContent;
