import { FC } from "react";
import { Skeleton } from '@mui/material';
import { PatientProfileHeaderSkeleton } from "../../header/header.skeleton";
import { Box } from '@mui/system';

export const MainViewSkeleton: FC = () => {
  return <>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <PatientProfileHeaderSkeleton />
      {Array.from({ length: 8 }).map((_, index) => (
        <Skeleton key={index} variant="rounded" width={280} height={50} sx={{ margin: '0 auto 10px auto' }} />
      ))}
    </Box>
  </>
}