import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCommsFilter, useCommunications } from '../../../../hooks/use-communications';
import { useContactState } from '../../../../hooks/use-contacts';
import { ContactApi } from '../../../../services/api-client/contact-api';
import LocationService, { ILocation } from '../../../../services/location-service/location-service';
import { importantContacts } from '../../../../utils/contact';
import CommuncationsWidget from './commincations-widget';

export interface CommunicationsSlideoutevent {
    phoneNumber: string,
    patientId?: number,
    locationId?: number,
    timestamp?: number
}

export const CommunicationsSlideout: FC<CommunicationsSlideoutevent> = (props) => {
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { setSearchByPhoneNumbers } = useCommunications();
    const { contactCache, loadContactsByPhoneNumbers } = useContactState();
    const { patientPhoneNumber, setPatientPhoneNumber, updateCommsFilter } = useCommsFilter();
    const [patientPhoneNumbers, setPatientPhoneNumbers] = useState<string[]>(null!);
    const [initialPhoneNumber, setInitialPhoneNumber] = useState<string>(null!);
    const [patientId, setPatientId] = useState<number>(null!);
    const [locationId, setLocationId] = useState<number>(null!);
    const [lookupPhoneNumbers, setLookupPhoneNumbers] = useState<string[]>();
    const [networkId, setNetworkId] = useState<number>();
    const [timestamp, setTimestamp] = useState<number>();

    useEffect(() => {
        (async () => {
            resetWidget();
            setPatientPhoneNumber(props.phoneNumber);

            let location: ILocation | undefined = undefined;
            if (props.locationId) {
                try {
                    location = await LocationService.getInstance(config, oidcService).getLocation(props.locationId);
                    setLocationId(props.locationId);
                    setNetworkId(location?.idNetwork);
                } catch (error: any) {
                    console.log(error.message);
                }
            }

            props.timestamp ? setTimestamp(props.timestamp) : setTimestamp(null!);

            if (props.patientId) setPatientId(props.patientId);

            if (props.phoneNumber) {
                await loadContactsByPhoneNumbers([props.phoneNumber]);
                setLookupPhoneNumbers([props.phoneNumber]);
                setInitialPhoneNumber(props.phoneNumber);
            } else if (props.patientId) {
                const phoneNumbers: string[] = [];
                const contactApi = ContactApi.initialize(config, oidcService);
                const patient = await contactApi.getContactDetailsById(props.patientId);
                if (patient.cellPhone && phoneNumbers.indexOf(patient.cellPhone) == -1) {
                    phoneNumbers.push(patient.cellPhone);
                    setInitialPhoneNumber(patient.cellPhone);
                }
                if (patient?.homePhone && phoneNumbers.indexOf(patient.homePhone) == -1)
                    phoneNumbers.push(patient.homePhone);
                if (patient?.workPhone && phoneNumbers.indexOf(patient.workPhone) == -1)
                    phoneNumbers.push(patient.workPhone);

                await loadContactsByPhoneNumbers(phoneNumbers);
                setLookupPhoneNumbers(phoneNumbers);
            }
        })();
    }, []);

    const resetWidget = () => {
        setPatientPhoneNumbers(null!);
        setLocationId(null!);
        setSearchByPhoneNumbers(null!);
        setPatientId(null!);
        setInitialPhoneNumber(null!);
        setLookupPhoneNumbers(null!);

        if(patientPhoneNumber && patientPhoneNumber !== props.phoneNumber) {
            updateCommsFilter({ commType: undefined, mailboxes: undefined });
        }
    };

    const getPhoneNumbers = useMemo(
        () => async (phoneNumbers: string[], patientId?: number, networkId?: number) => {
            const contacts = phoneNumbers
                .flatMap(phoneNumber => contactCache[phoneNumber]?.data)
                .filter(contact => contact);
            if (contacts && contacts.length) {
                const finalContacts = importantContacts(contacts, patientId, networkId);
                //first check patient matching id, next first responsible party in network and last first reponsible party
                const phoneNumbers: string[] = [];
                finalContacts.map(patient => {
                    if (patient?.cellPhone && phoneNumbers.indexOf(patient.cellPhone) == -1)
                        phoneNumbers.push(patient.cellPhone);
                    if (patient?.homePhone && phoneNumbers.indexOf(patient.homePhone) == -1)
                        phoneNumbers.push(patient.homePhone);
                    if (patient?.workPhone && phoneNumbers.indexOf(patient.workPhone) == -1)
                        phoneNumbers.push(patient.workPhone);
                });
                return phoneNumbers;
            }
            return phoneNumbers;
        },
        [contactCache]
    );

    useEffect(() => {
        if (!lookupPhoneNumbers) return;
        (async () => {
            const finalPhoneNumbers = await getPhoneNumbers(lookupPhoneNumbers, patientId, networkId);
            setPatientPhoneNumbers(finalPhoneNumbers);
            setSearchByPhoneNumbers(finalPhoneNumbers);
            if (!initialPhoneNumber) setInitialPhoneNumber(finalPhoneNumbers[0]);
        })();
    }, [lookupPhoneNumbers, patientId, networkId]);

    return (<>
        {patientPhoneNumbers && initialPhoneNumber && (
            <CommuncationsWidget
                patientPhoneNumbers={patientPhoneNumbers}
                initialPhoneNumber={initialPhoneNumber}
                locationId={locationId!}
                timestamp={timestamp}
                patientId={patientId}
            />
        )}
    </>
    );
}
