import { useCallback, useState } from 'react';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as One } from '../../../../assets/dialpad/one.svg';
import { ReactComponent as Two } from '../../../../assets/dialpad/two.svg';
import { ReactComponent as Three } from '../../../../assets/dialpad/three.svg';
import { ReactComponent as Four } from '../../../../assets/dialpad/four.svg';
import { ReactComponent as Five } from '../../../../assets/dialpad/five.svg';
import { ReactComponent as Six } from '../../../../assets/dialpad/six.svg';
import { ReactComponent as Seven } from '../../../../assets/dialpad/seven.svg';
import { ReactComponent as Eight } from '../../../../assets/dialpad/eight.svg';
import { ReactComponent as Nine } from '../../../../assets/dialpad/nine.svg';
import { ReactComponent as Star } from '../../../../assets/dialpad/star.svg';
import { ReactComponent as Zero } from '../../../../assets/dialpad/zero.svg';
import { ReactComponent as Pound } from '../../../../assets/dialpad/pound.svg';
import CallIcon from '@mui/icons-material/Call';
import { useEventBus } from '../../../../hooks/use-event-bus/use-event-bus';
import { Backspace } from '@mui/icons-material';

const useClasses = makeStyles({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff'
    },
    titleContainer: {
        width: '100%',
        borderBottom: '1px solid #E1E4E7'
    },
    title: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        marginLeft: 16,
        marginBottom: 12,
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '28px',
        color: '#000',
        padding: '8px 12px'
    },
    dialerContainer: {
        padding: '40px 24px',
        '& .MuiTextField-root': {
            width: '100%'
        }
    },
    dialpad: {
        paddingTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    callButtonContainer: {
        paddingTop: '32px'
    },
    callButton: {
        width: '100%',
        height: '42px',
        fontSize: '15px',
        lineHeight: '26px',
        letterSpacing: 0.4,
        color: '#FFFFFF',
        backgroundColor: '#0675DD',
        '&:hover': {
            backgroundColor: '#0675DD'
        }
    }
});

export default function DialpadWidget() {
    const classes = useClasses();
    const { publish } = useEventBus();
    const [phoneNumber, setPhoneNumber] = useState('');
    const dialpadButtons = [
        { icon: <One />, value: '1' },
        { icon: <Two />, value: '2' },
        { icon: <Three />, value: '3' },
        { icon: <Four />, value: '4' },
        { icon: <Five />, value: '5' },
        { icon: <Six />, value: '6' },
        { icon: <Seven />, value: '7' },
        { icon: <Eight />, value: '8' },
        { icon: <Nine />, value: '9' },
        { icon: <Star />, value: '*' },
        { icon: <Zero />, value: '0' },
        { icon: <Pound />, value: '#' }
    ];

    const formatPhoneNumber = (phoneNumber: String) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        let formattedPhoneNumber = '';

        if (cleaned.length < 4) {
            formattedPhoneNumber = cleaned;
        } else if (cleaned.length < 7) {
            formattedPhoneNumber = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
        } else {
            formattedPhoneNumber = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
        }

        return formattedPhoneNumber;
    };

    const erase = useCallback(() => setPhoneNumber(text => text.slice(0, -1)), [phoneNumber]);

    const renderBackspace = () =>
        phoneNumber.length > 0 ? (
            <IconButton onClick={erase} size='large'>
                <Backspace color='primary' height={18} fontSize='small' />
            </IconButton>
        ) : (
            <></>
        );

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant='h3' className={classes.title}>
                    Placing Call
                </Typography>
            </div>
            <div className={classes.dialerContainer}>
                <TextField
                    id='phoneNumberInput-widget'
                    placeholder='Type Number Here...'
                    value={formatPhoneNumber(phoneNumber)}
                    InputProps={{
                        endAdornment: renderBackspace()
                    }}
                    onChange={e => setPhoneNumber(e.target.value)}
                />
                <div className={classes.dialpad}>
                    {dialpadButtons.map(button => (
                        <IconButton
                            key={button.value}
                            data-testid={`dialpad-button-${button.value}`}
                            onClick={() => {
                                setPhoneNumber(phoneNumber + button.value);
                            }}
                        >
                            {button.icon}
                        </IconButton>
                    ))}
                </div>
                <div className={classes.callButtonContainer}>
                    <Button
                        className={classes.callButton}
                        data-testid={'call-button'}
                        startIcon={<CallIcon />}
                        onClick={() => {
                            publish({
                                messageType: 'startCall',
                                data: {
                                    phoneNumber: phoneNumber
                                }
                            });
                        }}
                    >
                        Call
                    </Button>
                </div>
            </div>
        </div>
    );
}
