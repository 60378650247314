import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';
import { OidcService } from '@revenuewell/logic-oidc';

export default class InsightsAuthService extends AuthApiService {
  private static instance: InsightsAuthService
  private serviceUrl: string

  private constructor(oidcService: OidcService, serviceUrl: any) {
    super(oidcService, new NavigationService());
    this.serviceUrl = serviceUrl
    return this
  }

  public static getInstance(oidcService: OidcService, serviceUrl: string) {
    if (!InsightsAuthService.instance)
    InsightsAuthService.instance = new InsightsAuthService(oidcService, serviceUrl)

    return InsightsAuthService.instance
  }

  public hasNewLeads = async () => {
    const claims = await this.oidcService.getClaims();
    const url = this.serviceUrl! + "/locations/" + claims.locationId + "/insights/web-tracking/has-new-leads";

    try {
        const response = await this.authFetch(url);

        if (response.ok) {
            const hasNewLeads: boolean = await response.json();
            return hasNewLeads;
        } else {
            console.error('Unable to check Insights new leads');
            return false;
        }
    } 
    catch (error) {
      console.error(error)
    }
};

}
