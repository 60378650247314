import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useNotifications } from '@revenuewell/uc-notifications-client';
import Pubnub from 'pubnub';
import { ReactElement, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CallHistoryService from '../../services/call-history/call-history-service';
import { CommunicationType } from '../../services/inbox-service/types';
import LegacyMessengerService from '../../services/legacy-message/legacy-messenger-service';
import { IMailboxWithLocation } from '../../services/mailbox-service/mailbox-service';
import { Message, MessageStatus, timeTokenToNumber } from '../../services/pubnub-service';
import { usePubnub } from '../use-pubnub/use-pubnub';
import { mapCallNotificationToCallHistoryItem } from './mappers/call';
import { CallHistoryItem, transformPayloadToCallHistoryItem } from './types/comms-call-types';
import useCommsFilter from './use-communications-filter';

const DEFAULT_PAGE_ITEM_COUNT = 25;

interface LegacySearchParams {
    pageNumber: number;
    pubnubComplete: boolean;
    legacyComplete: boolean;
    legacyData: Message[];
}

interface CommuncationsQuery {
    phoneNumbers?: string[];
    commType?: CommunicationType | null;
    mailboxes?: IMailboxWithLocation[];
}

interface ICommuncationsContext {
    data: (Message | CallHistoryItem)[];
    isLoading: boolean;
    hasMore: boolean;
    setSearchByPhoneNumbers: (patientPhoneNumbers: string[]) => void;
    loadMore: () => Promise<void>;
    addOrUpdateMessage: (message: Message) => void;
    downloadFax: (faxId: string) => void;
}

function isMessage(item: Message | CallHistoryItem): item is Message {
    return (item as Message).body !== undefined;
}

const CommuncationsContext = createContext<ICommuncationsContext | null>(null);

export const useCommunications = (): ICommuncationsContext => {
    const context = useContext(CommuncationsContext);
    if (!context) throw new Error('useCommunications must be used within an CommuncationsContext');

    return context;
};

export function CommunicationsProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const { listen } = useNotifications();
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [patientPhoneNumbers, setPatientPhoneNumbers] = useState<string[]>(null!);
    const [query, setQuery] = useState<CommuncationsQuery>({});
    const [data, setData] = useState<(Message | CallHistoryItem)[]>([]);
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { client, mailboxes } = usePubnub();
    const { commsFilter } = useCommsFilter();

    const callHistoryApi = useMemo(() => CallHistoryService.getInstance(config, oidcService), [config, oidcService]);
    const legacyClient = useMemo(() => LegacyMessengerService.getInstance(config, oidcService), [config, oidcService]);

    const [legacyParams, setLegacyParams] = useState<LegacySearchParams>({
        pageNumber: 1,
        pubnubComplete: false,
        legacyComplete: false,
        legacyData: []
    });

    // set initial search
    const setSearchByPhoneNumbers = useCallback(
        (phoneNumbers: string[]) => {
            if (mailboxes.length === 0) return;

            setPatientPhoneNumbers(phoneNumbers);
        },
        [mailboxes]
    );

    const performPubnubSearch = useCallback(
        async (channelIds: string[], start: string | undefined) => {
            if (!client) return;

            const messageResponses = await Promise.all(channelIds.map(id => client.fetchMessageHistory(id, start)));
            const messageData = messageResponses.reduce((acc, res) => [...acc, ...res], []);
            return messageData;
        },
        [client]
    );

    const performLegacySearch = useCallback(
        async (phoneNumber: string, currMailboxes: IMailboxWithLocation[], pageNumber: number) => {
            if (!legacyClient) return;

            const legacyMessagesRequest = currMailboxes.map(currMailbox => {
                const locationId = currMailbox.locationId.toString();
                return legacyClient.fetchMessageHistory(
                    phoneNumber,
                    locationId,
                    {
                        pageNumber: pageNumber,
                        pageSize: DEFAULT_PAGE_ITEM_COUNT
                    },
                    currMailbox
                );
            });

            const legacyMessageResponses = await Promise.all(legacyMessagesRequest);
            const legacyMessageData = legacyMessageResponses.reduce((acc, res) => [...acc, ...res], []);

            return legacyMessageData;
        },
        [legacyClient]
    );

    const performCallHistorySearch = useCallback(
        async (locationIds: string[], phoneNumber: string, start: string | undefined) => {
            if (!callHistoryApi) return;
            const callHistoryResponse = await callHistoryApi.search({
                searchText: phoneNumber,
                locationIds: locationIds,
                timeStartTo: start ? new Date(parseFloat(start)).toISOString() : undefined,
                pageSize: DEFAULT_PAGE_ITEM_COUNT
            });

            return callHistoryResponse.map(item => transformPayloadToCallHistoryItem(item));
        },
        [callHistoryApi]
    );

    // search for inbox history list
    const searchInboxHistory = useCallback(
        async (query: CommuncationsQuery, legacySearchParams: LegacySearchParams, start?: string) => {
            const { phoneNumbers, commType, mailboxes } = query;
            const { pubnubComplete, legacyComplete, legacyData, pageNumber } = legacySearchParams;

            if (!phoneNumbers || !mailboxes) return;

            const channelIds = phoneNumbers.flatMap(phoneNumber =>
                mailboxes.map(item => `${item.pubnubPrefix}.${phoneNumber}`)
            );
            const locationIds = mailboxes.map(item => item.locationId.toString()) || [];

            setIsLoading(true);

            let messageData: Message[] = [];
            if (commType !== CommunicationType.Phone) {
                // search message history
                if (!pubnubComplete && channelIds?.length)
                    messageData = (await performPubnubSearch(channelIds, start)) || [];

                const isPubnubComplete = pubnubComplete || messageData.length < DEFAULT_PAGE_ITEM_COUNT;
                const isLegacySearch = !legacyComplete && isPubnubComplete;

                // search legacy message history if message history is complete
                let legacyMessageData: Message[] = [];
                let page = pageNumber;

                if (isLegacySearch && locationIds?.length) {
                    const remainingLegacyData = legacyData.filter(legacyMessage => !data.includes(legacyMessage));
                    messageData = [...remainingLegacyData, ...messageData];

                    if (remainingLegacyData.length < DEFAULT_PAGE_ITEM_COUNT) {
                        legacyMessageData =
                            (
                                await Promise.all(
                                    phoneNumbers.map(phoneNumber =>
                                        performLegacySearch(phoneNumber, mailboxes, pageNumber)
                                            .then(msgs => msgs)
                                            .catch(err => {
                                                console.error(err);
                                                const msgs: Message[] = [];
                                                return msgs;
                                            })
                                    )
                                )
                            ).flatMap(x => (x ? x : [])) || [];
                        messageData = [...legacyMessageData, ...messageData];
                        page = page + 1;
                    }
                }

                setLegacyParams({
                    pubnubComplete: isPubnubComplete,
                    legacyComplete: legacyMessageData.length < DEFAULT_PAGE_ITEM_COUNT,
                    pageNumber: page,
                    legacyData: [...legacyMessageData, ...legacyData]
                });
            }

            // search call history
            const callData =
                (commType !== CommunicationType.Sms
                    ? (
                          await Promise.all(
                              phoneNumbers.map(phoneNumber => performCallHistorySearch(locationIds, phoneNumber, start))
                          )
                      ).flatMap(x => (x ? x : []))
                    : []) || [];

            const dedupedCallData = callData.reduce<CallHistoryItem[]>((acc, obj) => {
                if (!acc.some(item => item.id === obj.id)) {
                    acc.push(obj);
                }
                return acc;
            }, []);

            const newData = [...messageData, ...dedupedCallData].sort((a, b) => a.timetoken! - b.timetoken!).slice(-25);

            setHasMore(newData.length > 0);
            setData(prevData => (start ? [...newData, ...prevData] : newData));
            setIsLoading(false);
        },
        [performPubnubSearch, performLegacySearch, performCallHistorySearch]
    );

    // set search by patient phone number and filter
    useEffect(() => {
        const currMailboxes = commsFilter.mailboxes?.length ? commsFilter.mailboxes : mailboxes;

        const commType =
            commsFilter.commType === 'SMS'
                ? CommunicationType.Sms
                : commsFilter.commType === 'Phone'
                ? CommunicationType.Phone
                : undefined;

        const query = patientPhoneNumbers
            ? {
                  phoneNumbers: patientPhoneNumbers,
                  mailboxes: currMailboxes,
                  commType
              }
            : {};

        const legacySearchParams: LegacySearchParams = {
            pageNumber: 1,
            pubnubComplete: false,
            legacyComplete: false,
            legacyData: []
        };

        setData([]);
        setQuery(query);
        setLegacyParams(legacySearchParams);

        if (patientPhoneNumbers) searchInboxHistory(query, legacySearchParams);
    }, [patientPhoneNumbers, mailboxes, commsFilter, searchInboxHistory]);

    const addCallHistoryItem = useCallback(
        (item: CallHistoryItem) => {
            setData(prevData => {
                const curIndex = prevData.findIndex((value: any) => value.id == item.id);
                if (curIndex == -1) {
                    const newData = [...prevData];
                    newData.push(item);
                    return newData;
                } else {
                    const newData = [...prevData];
                    newData[curIndex] = item;
                    return newData;
                }
            });
        },
        [data]
    );

    useEffect(() => {
        return listen('phone-call', async (event: any) => {
            if (event && event.data && event.data.type === 'call-ended') {
                if(patientPhoneNumbers.includes(event.data.to) 
                    || patientPhoneNumbers.includes(event.data.from)) {
                    const item = mapCallNotificationToCallHistoryItem(event.data, event.locationid, event.masteraccountid);
                    addCallHistoryItem(item);
                }
            }
        });
    }, [listen, addCallHistoryItem]);

    // load next page
    const loadMore = useCallback(async () => {
        if (query && hasMore) {
            const timeStart = data[0]?.timetoken ? (data[0].timetoken - 1).toString() : undefined;

            await searchInboxHistory(query, legacyParams, timeStart);
        }
    }, [query, data, hasMore, legacyParams, searchInboxHistory]);

    // listen for message update
    useEffect(() => {
        const { phoneNumbers, commType, mailboxes } = query;
        if (!client || !phoneNumbers || !mailboxes || commType === CommunicationType.Phone) return;

        const channelIds = phoneNumbers.flatMap(phoneNumber =>
            mailboxes.map(item => `${item.pubnubPrefix}.${phoneNumber}`)
        );

        const pubnubListener: Pubnub.ListenerParameters = {
            message: (msgObj: Pubnub.MessageEvent) => {
                if (!channelIds.includes(msgObj.channel)) return;

                const isInbound = msgObj.channel.split('.')[1] === msgObj.message.to;
                const status = isInbound && !msgObj.message.status ? 'sending' : msgObj.message.status;

                const message: Message = {
                    ...msgObj.message,
                    status: status,
                    channelId: msgObj.channel,
                    timetoken: timeTokenToNumber(msgObj.timetoken)
                };

                addOrUpdateMessage(message);
            },
            messageAction: (msgActionObj: Pubnub.MessageActionEvent) => {
                if (!channelIds.includes(msgActionObj.channel)) return;

                if (msgActionObj.data.type === 'Status') {
                    setData(prevData => {
                        const newTimeToken = timeTokenToNumber(msgActionObj.data.messageTimetoken);
                        const updatedData = prevData.map(item => {
                            if (isMessage(item) && newTimeToken - item.timetoken! < 3000)
                                return { ...item, status: msgActionObj.data.value.toLowerCase() as MessageStatus };

                            return item;
                        });

                        return updatedData;
                    });
                }
            }
        };
        client.client.addListener(pubnubListener);

        return () => {
            client.client.removeListener(pubnubListener);
        };
    }, [client, query]);

    const addOrUpdateMessage = useCallback(
        (message: Message) => {
            setData(prevData => {
                // handle duplicates for first motd where a template is applied to the message
                const templateDuplicate = prevData.findIndex(
                    c =>
                        isMessage(c) &&
                        message.body != c.body &&
                        c.body.includes(message.body) &&
                        message.timetoken! - c.timetoken! < 5000
                );

                // handle duplicates when switching between message status'
                const index = prevData.findIndex(
                    c =>
                        isMessage(c) &&
                        c.body === message.body &&
                        c.senderUserId == message.senderUserId &&
                        message.timetoken! - c.timetoken! < 5000
                );

                if (templateDuplicate >= 0) {
                    // updating the status of the message we want to keep with the status of the message duplicate
                    const data = prevData.filter(element => element.timetoken !== message.timetoken) as Message[];
                    data[templateDuplicate] = { ...data[templateDuplicate], status: message.status };

                    return data;
                } else if (index >= 0) {
                    const newData = [...prevData];
                    newData.splice(index, 1, message);
                    return newData;
                } else {
                    return [...prevData, message];
                }
            });
        },
        [data]
    );

    const downloadFax = async (faxId: string) => {
        try {
            const blob = await callHistoryApi.getFax(faxId);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
      
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = url;
            a.download = faxId;
            a.click();
            
            window.URL.revokeObjectURL(url);
          } catch (err) {
            console.log('Error downloading fax.', err);
          }
    } 


    return (
        <CommuncationsContext.Provider
            value={{ data, isLoading, hasMore, setSearchByPhoneNumbers, loadMore, addOrUpdateMessage, downloadFax }}
        >
            {children}
        </CommuncationsContext.Provider>
    );
}
