import { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import InboxService from '../../services/inbox-service/inbox-service';
import { InboxPreview, IInboxQuery, transformPayloadToChannelPreview, transformNotificationToMessagePreview } from './types/inbox-preview-types';
import { ChannelUpdateEvent } from '@revenuewell/uc-notifications-client/dist/types/notifications';
import { useNotifications } from '@revenuewell/uc-notifications-client';
import { useContactState } from '../use-contacts';
import { usePubnub } from '../use-pubnub/use-pubnub';
import { uniqBy } from 'lodash';
import { sub } from 'date-fns';
import { CommunicationType } from '../../services/inbox-service/types';

interface InboxPreviewContext {
    data: InboxPreview[];
    query: IInboxQuery;
    isLoading: boolean;
    hasMore: boolean;
    setSearch: (query: IInboxQuery) => void;
    loadMore: () => Promise<void>;
}

const InboxPreviewContext = createContext<InboxPreviewContext | null>(null);

export const useInbox = (): InboxPreviewContext => {
    const context = useContext(InboxPreviewContext);

    if (!context)
        throw new Error('useInbox must be used within an InboxPreviewContext');

    return context;
};

export function InboxPreviewProvider({ children }: React.PropsWithChildren<{}>): ReactElement | null {
    const [data, setData] = useState<InboxPreview[]>([]);
    const [query, setQuery] = useState<IInboxQuery>({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const { searchByNumber, loadContactsByPhoneNumbers } = useContactState();
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const { mailboxes } = usePubnub();
    const { listen } = useNotifications();

    const inboxService = useMemo(() => InboxService.getInstance(config, oidcService), [config, oidcService]);

    // search for inbox preview list
    const search = useCallback(
        async (params: IInboxQuery, timeEnd?: string) => {
            setIsLoading(true);

            const { phones: phoneNumbers, isUnread, isSaved, isArchived, commType } = params;

            if (phoneNumbers && phoneNumbers.length === 0) {
                setData([]);
                setIsLoading(false);
                return;
            }

            const currentMailboxes = params.mailboxes?.length ? params.mailboxes : mailboxes;

            const searchRequest = {
                mailboxIds: currentMailboxes.map((m) => m.id),
                phoneNumbers: phoneNumbers,
                start: sub(new Date(), { months: 24 }).getTime().toString().padEnd(17, "0"),
                end: timeEnd
                    ? String(BigInt(timeEnd) - BigInt(1))
                    : new Date().getTime().toString().padEnd(17, "0"),
                pageSize: 30,
                isUnread: isUnread,
                isSaved: isSaved,
                isArchived: isArchived || false,
                communicationType: commType
            };

            const response = await inboxService.channelSearch(searchRequest);

            setHasMore(response.length > 0);

            if (response.length > 0) {
                const phones = response.map(item => item.patientPhoneNumber);
                await loadContactsByPhoneNumbers(phones);
            }

            // transorm to inbox previews
            const newData: InboxPreview[] = response.map(channel => {
                return transformPayloadToChannelPreview(channel, commType);
            });

            setData(prevData => (timeEnd ? uniqBy([...prevData, ...newData], 'channelId') : newData));
            setIsLoading(false);

        }, [mailboxes, inboxService]
    );

    useEffect(() => {
        if (mailboxes.length) {
            setData([]);
            search(query);
        }
    }, [
        query.commType,
        query.isArchived,
        query.isSaved,
        query.isUnread,
        query.mailboxes,
        query.phones,
        mailboxes,
        search
    ]);

    const setSearch = useCallback(async (query: IInboxQuery) => {
        setQuery(query);
    }, []);

    const loadMore = useCallback(async () => {
        if (hasMore) {
            await search(query, data[data.length - 1].timeToken);
        }
    }, [data, query, hasMore]);

    // listen 'channel-update' event to update inbox previews
    useEffect(() => {
        return listen('channel-update', async (event) => {
            const { data } = event as ChannelUpdateEvent;
            const { phones, isUnread, isSaved, isArchived, commType } = query;

            const curMailbox = (query.mailboxes?.length ? query.mailboxes : mailboxes)
                .find(d => d.pubnubPrefix === data.channelId.split(".")[0]);

            if (!curMailbox || (phones && !phones.includes(data.phoneNo))) return;

            const preview = transformPayloadToChannelPreview(transformNotificationToMessagePreview(data), commType);
            preview.mailboxId = curMailbox.id;

            if ((commType === CommunicationType.Sms && !preview.lastMessage)
                || (commType === CommunicationType.Phone && !preview.lastCall)) return;

            const previewMatches = (isUnread ? preview.isUnread === isUnread : true)
                && (isArchived ? preview.isArchived === isArchived : true)
                && (isSaved ? preview.isSaved === isSaved : true);

            if (previewMatches) {
                await searchByNumber(preview.patientPhoneNumber);

                setData(prevData => {
                    // if channel needs to be replaced or added
                    const newData = uniqBy([preview, ...prevData], 'channelId');

                    const getTimeToken = (item: InboxPreview) => commType === CommunicationType.Sms ? item.lastMessage?.timeToken
                        : commType === CommunicationType.Phone ? item.lastCall?.timeToken : item.timeToken;

                    newData.sort((a, b) => (getTimeToken(a)! < getTimeToken(b)!) ? 1 : ((getTimeToken(a)! > getTimeToken(b)!) ? -1 : 0));
                    return newData;
                });
            } else {
                setData(prevData => {
                    // if channel exists in the list and needs to be removed
                    const index = prevData.findIndex(c => c.channelId === preview.channelId);
                    if (index > -1)
                        prevData.splice(index, 1);
                    // if nothing has been changed    
                    return [...prevData];
                });
            }
        });
    }, [listen, inboxService, mailboxes, query]);

    return (
        <InboxPreviewContext.Provider value={{ data, query, isLoading, hasMore, loadMore, setSearch }}>
            {children}
        </InboxPreviewContext.Provider>
    );
}