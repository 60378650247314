import { useSlideout } from '../../../../hooks/use-slideout';
import SlideOutCard from '../../../shared/slide-out-card';
import DialpadWidget from './dialpad-widget';

export default function DialpadSlideout() {
    const { isVisibleDialpad, close, hasHistory, back } = useSlideout();

    const handleClose = () => {
        close();
    };

    return (
        <SlideOutCard isVisible={isVisibleDialpad} onClose={handleClose} onBackClick={hasHistory ? back : undefined}>
            <DialpadWidget />
        </SlideOutCard>
    );
}
