import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';

export enum AlertCategories {
    Forms = 41,
    AppointmentRequestResponse = 38,
    CampaignsUnsubscribed = 8,
    NewEmailFromPatient = 31
}

export interface Alert {
    id: string;
    idCategory: AlertCategories;
    isDismissed: boolean;
    serverCreationTimeStamp: Date;
    idLocation: number;
    idAdminAlert: null | string;
    customFieldValue1: null | string;
    customFieldName1: null | string;
    customFieldValue2: null | string;
    customFieldName2: null | string;
    customFieldValue3: null | string;
    customFieldName3: null | string;
    customFieldValue4: null | string;
    customFieldName4: null | string;
    customFieldValue5: null | string;
    customFieldName5: null | string;
    legacyText: string;
    isPostponed: boolean;
    systemLastModified: Date;
}

export interface AlertParameters {
    locationIds: number[];
    startTime: Date | string;
    endTime: Date | string;
    count: number;
    excludeTypes: number[];
    isDismissed: boolean;
}

export default class AlertsService extends AuthApiService {
    private static instance: AlertsService;

    private config: any;
    private alertsUrl: string;

    private constructor(config: any, oidcService: OidcService) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.alertsUrl = `${config.alerts.apiUrl}`;
        return this;
    }

    public static getInstance(config: any, oidcService: OidcService) {
        if (!AlertsService.instance) AlertsService.instance = new AlertsService(config, oidcService);

        return AlertsService.instance;
    }

    public async search(parameters: AlertParameters): Promise<Alert[]> {
        const url = `${this.alertsUrl}/legacy/alerts/search`;

        const response = await this.authFetch(url, {
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { 'Content-Type': 'application/json' }
        });
        const data: Alert[] = await response.json();

        return data;
    }

    public async dismiss(alertId: string): Promise<void> {
        const url = `${this.alertsUrl}/legacy/alerts/${alertId}`;

        await this.authFetch(url, {
            method: 'DELETE'
        });
    }
}
