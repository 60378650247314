import React, { FC } from 'react';
import { ContactInfo } from './contact-info/contact-info';
import HeaderNavbar from './navbar/navbar';
import { HeaderWrapper } from './header.styles';
import { usePatientProfileWidget } from '../hooks/use-patient-profile-widget';
import { Alert } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';

export const PatientProfileHeader: FC = () => {
  const { isSkinnyProfile } = usePatientProfileWidget();

  return <>
    <HeaderWrapper>
      <ContactInfo />
      <HeaderNavbar />
      {isSkinnyProfile &&
        <Alert severity='success' icon={<PersonAdd />} sx={{ display: 'flex', alignItems: 'center' }}>
          {`This is a new patient for this location. We only have general information about them. To see more information choose another location above.`}
        </Alert>
      }
    </HeaderWrapper>
  </>
}