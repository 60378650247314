/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useConfig } from '@revenuewell/front-end-bundle';
import { useOidcWithAdmin } from '@revenuewell/logic-oidc';
import debounce from 'lodash.debounce';
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocations } from '../use-locations';
import { Contact, ContactApi, ContactLocationInfo, ContactSearchByNumber } from '../../services/api-client/contact-api';

export interface CacheEntry<T> {
    data: T;
    status: 'inprogress' | 'found' | 'notfound';
    fetchedTime: number;
}

export interface ContactCard {
    identicalContactsDetails?: Contact[];
    identicalContactLocations?: ContactLocationInfo[];
    selectedContactFromLocation?: Contact;
    mergedContactByLocations?: Contact;
    shouldShowLocationDropDown?: boolean;
    selectedLocation?: ContactLocationInfo;
}

export interface Pagination {
    currentPageNumber: number;
    hasMore: boolean;
}

export interface ContactInStore {
    contactSearchResult: Contact[];
    selectedContact?: Contact;
    contactCard?: ContactCard;
    contactCache: Record<string, CacheEntry<ContactSearchByNumber[]>>;
    pagination: Pagination;
    loaded: boolean;
    searchText: string;
    isContactSelected: boolean;
    setSelectedContact: React.Dispatch<React.SetStateAction<Contact | undefined>>;
    setContactSearchResult: React.Dispatch<React.SetStateAction<Contact[]>>;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
    setContactCard: React.Dispatch<React.SetStateAction<ContactCard>>;
    setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    updateContactCache: (key: string, entry?: Partial<CacheEntry<ContactSearchByNumber[]>>) => void;
}

export const ContactsContext = createContext<ContactInStore>(null!);

export const searchParams = {
    contactListPageSize: 50,
    defaultSortByField: 'lastName'
};

export default function ContactsProvider({ children }: PropsWithChildren<any>) {
    const [contactSearchResult, setContactSearchResult] = useState<Contact[]>([]);
    const [selectedContact, setSelectedContact] = useState<Contact | undefined>(null!);
    const [contactCard, setContactCard] = useState<ContactCard>(null!);
    const [contactCache, setContactCache] = useState<Record<string, CacheEntry<ContactSearchByNumber[]>>>({});
    const [pagination, setPagination] = useState({
        currentPageNumber: 1,
        hasMore: false
    });
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { config } = useConfig();
    const { oidcService } = useOidcWithAdmin();
    const locations = useLocations();

    const updateContactCache: ContactInStore['updateContactCache'] = useCallback((key, entry) => {
       if (!entry) {
            setContactCache(c => {
                const val = { ...c };
                delete val[key];
                return val;
            });
        }
        setContactCache(c => ({ ...c, [key]: { ...c[key], ...entry } }));
    }, []);

    useEffect(() => {
        const fetchContacts = async () => {
            // update searchText for all keypress event in search box
            const contactsAPI = new ContactApi(config, oidcService);

            const result = await contactsAPI.getContacts({
                searchText,
                limit: searchParams.contactListPageSize,
                sortBy: searchParams.defaultSortByField,
                locationIds: locations.selectedLocationIds.join()
            });

            const maxPages = Math.ceil(result.total / result.data.length);
            // replace contact list with the searched value
            setContactSearchResult(c => [...c, ...result.data]);
            setPagination(pageData => ({
                ...pageData,
                currentPageNumber: 1,
                hasMore: maxPages > 1
            }));
        };

        debounce(fetchContacts, 500)();
    }, [config, locations.selectedLocationIds, oidcService, searchText]);

    return (
        <ContactsContext.Provider
            value={{
                contactSearchResult,
                isContactSelected: !!selectedContact,
                selectedContact,
                contactCard,
                contactCache,
                pagination,
                loaded,
                searchText,
                setSelectedContact,
                setContactSearchResult,
                setSearchText,
                setPagination,
                updateContactCache,
                setContactCard,
                setLoaded
            }}
        >
            {children}
        </ContactsContext.Provider>
    );
}
