import { FC, useCallback, useEffect, useState } from 'react';
import { useSlideout } from '../../../../hooks/use-slideout';
import { PatientProfileWidgetProvider } from './hooks/use-patient-profile-widget';
import { EventInboxCommunicationsWidget, EventPatientProfile, EventPatientProfileWithPhoneNumber } from '../../../../types/event-bus';
import { PatientProfileWidgetComponent } from './patient-profile-widget';
import { useEventBus } from '../../../../hooks/use-event-bus';

const PatientProfileWidgetWithProvider: FC = () => {
  const { listen } = useEventBus();
  const { currentEvent } = useSlideout();
  const [patientIdEvent, setPatientIdEvent] = useState<EventPatientProfile>();
  const [patientPhoneEvent, setPatientPhoneEvent] = useState<EventPatientProfileWithPhoneNumber>();
  const [patientInboxEvent, setPatientInboxEvent] = useState<EventInboxCommunicationsWidget>();

  useEffect(() => {
    (async () => {
      switch (currentEvent?.messageType) {
        case 'patientProfile': {
          resetWidget();
          setTimeout(() => {
            const event = currentEvent as EventPatientProfile;
            if (event.data.patientId) setPatientIdEvent(event as EventPatientProfile);
            else setPatientPhoneEvent(currentEvent as EventPatientProfileWithPhoneNumber);
          }, 0)
          break;
        }
        case 'inboxCommunications':
          resetWidget();
          setTimeout(() => {
            setPatientInboxEvent(currentEvent as EventInboxCommunicationsWidget);
          }, 0)
          break;
      }
    })();
  }, [currentEvent]);

  useEffect(() => {
    const listener = listen('closeWidget', event => {
      if (event.messageType === 'closeWidget') resetWidget();
    });

    return () => listener();
  }, [listen]);

  const resetWidget = () => {
    setPatientIdEvent(undefined);
    setPatientPhoneEvent(undefined);
    setPatientInboxEvent(undefined);
  }

  return (patientIdEvent || patientPhoneEvent || patientInboxEvent ?
    <PatientProfileWidgetProvider
      patientIdEvent={patientIdEvent}
      patientPhoneEvent={patientPhoneEvent}
      patientInboxEvent={patientInboxEvent}
    >
      <PatientProfileWidgetComponent />
    </PatientProfileWidgetProvider>
    : null
  )
}

export const PatientProfileWidget = PatientProfileWidgetWithProvider;
