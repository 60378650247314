import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";

export const getCustomizedAppointmentCount = (patientPreferences: PatientPreferencesResponse) => {
  let count = 0;
  patientPreferences.firstReminderScheduleStatus !== 'Global' ? count++ : null;
  patientPreferences.secondReminderScheduleStatus !== 'Global' ? count++ : null;
  patientPreferences.saveDateReminderScheduleStatus !== 'Global' ? count++ : null;
  patientPreferences.postCardReminderScheduleStatus !== 'Global' ? count++ : null;
  patientPreferences.sameDayReminderScheduleStatus !== 'Global' ? count++ : null;
  return count;
};