import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 'none'
    },
    hiddenFrame: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '0',
        height: '0',
        border: 'none',
        display: 'none'
    }
}))