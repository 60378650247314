import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CakeIcon from '@mui/icons-material/Cake';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const getStatusIcon = (status?: string) => {
  if (!status)
    return { icon: ReportProblemOutlinedIcon, color: 'orange' }

  if (status.toLocaleLowerCase().includes('birthday'))
    return { icon: CakeIcon, color: 'rgb(25, 118, 210)' }

  if (status.toLocaleLowerCase().includes('balance'))
    return { icon: AttachMoneyIcon, color: '#008A06' }

  if (status.toLocaleLowerCase().includes('first appointment'))
    return { icon: StarBorderIcon, color: 'rgb(25, 118, 210)' }

  if (status.toLocaleLowerCase().includes('missing'))
    return { icon: HelpOutlineIcon, color: 'orange' }

  return { icon: ReportProblemOutlinedIcon, color: 'orange' }
}