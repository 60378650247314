import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  subSectionContainer: {
    padding: '8px 24px 8px'
  },
  subSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  allComSubSection: {
    marginLeft: '16px'
  },
  displayText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000'
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#008A06'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2DE13F'
    }
  }
});
