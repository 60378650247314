import { useContext } from 'react'
import { WrapperFramesContext } from './provider'

export default function useWrapperFrames() {
    const ctx = useContext(WrapperFramesContext)

    if (!ctx) throw new Error('useWrapperFrames must be used within FramesProvider')

    return ctx
}
