import { useEffect } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useConfig, useOidc } from '@revenuewell/front-end-bundle';
import { usePubnub } from './use-pubnub/use-pubnub';
import Claims from '@revenuewell/logic-oidc/types/claims';

const IntercomInit = (): null => {
    const { oidcService } = useOidc();
    const { boot } = useIntercom();
    const { mailboxes } = usePubnub();

    useEffect(() => {
        if (!mailboxes.length) return;

        const init = async () => {
            const user: Claims = await oidcService.getClaims();
            boot({
                hideDefaultLauncher: true,
                name: `${user.firstName} ${user.lastName}`,
                companies: mailboxes.map(m => ({
                    companyId: m.locationId.toString(),
                    name: m.location.name
                }))
            });
        };

        init();
    }, [boot, oidcService, mailboxes]);

    return null;
};

const useIntercomApi = () => {
    const { trackEvent } = useIntercom();

    const submitEvent = async (eventName: string, eventData?: any) => {
        if (trackEvent) {
            trackEvent(eventName, eventData);
        }
    };

    return {
        submitEvent
    };
};

const Intercom: React.FC<React.PropsWithChildren<any>> = props => {
    const { config } = useConfig();

    if (config?.intercom?.appId) {
        return (
            <IntercomProvider appId={config.intercom.appId}>
                <IntercomInit />
                {props.children}
            </IntercomProvider>
        );
    }

    return props.children;
};

export { Intercom, useIntercomApi };
