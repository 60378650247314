import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  familyMember: {
    display: 'flex',
    flexDirection: 'row',
    margin: '3px 0px'
  },
  name: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    marginLeft: '4px',
    textDecoration: 'none'
  },
  rp: {
    marginLeft: '4px',
    color: '#565F6F'
  },
  age: {
    color: '#565F6F'
  }
});