import React, { useCallback, useEffect, useRef } from 'react';

type Listener = (event: MessageEvent<any>) => void;

const useChildrenListener = () => {
    const listenerRef = useRef<Listener[]>([]);

    const onLogout = useCallback((action: Function, ...args) => {
        const handleLogout: Listener = event => {
            if (event.data?.message_type === 'not_authorized') {
                action(...args);
            }
        };
        listenerRef.current = [...(listenerRef.current || []), handleLogout];
        window.addEventListener('message', handleLogout);
    }, []);

    useEffect(() => {
        // clean up listeners
        return () => {
            listenerRef.current.forEach(listener => {
                window.removeEventListener('message', listener);
            });
        };
    }, []);

    return { onLogout };
};

export default useChildrenListener;
