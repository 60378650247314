import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from '@mui/styles';
import { Chip } from '../../../../../../../shared/chip';
import { CommunicationStatus } from '../../types/communication-status';



export interface CampaignCommunicationStatusProps {
  status: CommunicationStatus;
  label: string;
}

export interface ColorClass {
  primary: string;
  secondary: string;
}

const CampaignCommunicationStatus = ({ status, label }: CampaignCommunicationStatusProps) => {
  const getColorClass = (status: CommunicationStatus) => {
    switch (status) {
      case CommunicationStatus.none:
        return { primary: '#621B16', secondary: '#F7CECD' };
      case CommunicationStatus.some:
        return { primary: '#6B4904', secondary: '#FFF0CA' };
      case CommunicationStatus.all:
        return { primary: '#005E05', secondary: '#D8F8D9' };
    }
  };

  const getStatusIcon = (status: CommunicationStatus) => {
    switch (status) {
      case CommunicationStatus.none:
        return <CloseIcon />;
      case CommunicationStatus.some:
        return <RemoveIcon />;
      case CommunicationStatus.all:
        return <CheckIcon />;
    }
  };

  const colorClass = getColorClass(status);
  const statusIcon = getStatusIcon(status);

  const useStyles = makeStyles({
    comSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      padding: '6px 0px 6px 0',
      height: '20px',
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      '& label': {
        marginLeft: '8px',
        color: colorClass.primary
      }
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.comSection}>
      <Chip icon={statusIcon} color={colorClass} />
      <label>{label}</label>
    </div>
  );
};

export default CampaignCommunicationStatus;
