import { makeStyles } from '@mui/styles';
import { Avatar } from '@revenuewell/ui-icons';

const useClasses = makeStyles({
    container: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px'
    },
    text: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '28px',
        color: '#565F6F'
    }
});

interface NoPatientCardProps {
    searching: boolean;
}

export default function NoPatientCard({ searching }: NoPatientCardProps) {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <Avatar size='l' />
            <label className={classes.text}>{searching ? 'No Patients Found' : 'Enter a Name or Phone'}</label>
        </div>
    );
}
