import { ReactNode } from 'react';
import { Box, IconButton, styled, Toolbar as ToolbarMui } from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import { ReactComponent as FullscreenIcon } from '../../../assets/union.svg';
const Toolbar = styled(ToolbarMui)({
    paddingLeft: '12px !important',
    paddingRight: '12px !important'
});

const ButtonsRight = styled(Box)({
    marginLeft: 'auto'
});

interface SlideOutCardProps {
    onBackClick?: () => void;
    onClose?: () => void;
    onFullscreen?: () => void;
    isVisible: boolean;
    children: ReactNode;
}

export default function SlideOutCard({ isVisible, children, onBackClick, onClose, onFullscreen }: SlideOutCardProps) {
    if (!isVisible) return <></>;

    return (
        <>
            <Toolbar
                sx={{
                    justifyContent: 'space-between',
                    color: '#8D97A4',
                    backgroundColor: '#fff',
                    minHeight: '56px !important',
                    paddingTop: '10px',
                    paddingBottom: '0'
                }}
            >
                {onBackClick && (
                    <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ ml: 0.2 }}
                        onClick={onBackClick}
                    >
                        <ArrowBack />
                    </IconButton>
                )}
                <ButtonsRight>
                    {onFullscreen && (
                        <IconButton
                            size='large'
                            edge='start'
                            color='inherit'
                            aria-label='menu'
                            sx={{ mr: '20px' }}
                            onClick={onFullscreen}
                        >
                            <FullscreenIcon />
                        </IconButton>
                    )}
                    {onClose && (
                        <IconButton
                            size='large'
                            edge='start'
                            color='inherit'
                            aria-label='menu'
                            sx={{ mr: 0.2 }}
                            onClick={onClose}
                        >
                            <Close />
                        </IconButton>
                    )}
                </ButtonsRight>
            </Toolbar>
            {children}
        </>
    );
}
