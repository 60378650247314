import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabHeaderContainer: {
    minHeight: '54px',
    borderBottom: '2px solid #E1E4E7'
  },
  tabHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    color: '#000000'
  },
  '& label': {
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '30px',
    letterSpacing: '0.15px',
    color: ' #2C3542'
  },
  balanceSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '8px 0px 16px 0',
    height: '20px',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    '& label': {
      marginLeft: '8px'
    }
  }
});
