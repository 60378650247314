import { useCallback, useEffect, useMemo, useState } from 'react';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useNotifications } from '@revenuewell/uc-notifications-client';
import { IMailboxWithLocation } from '../../../../services/mailbox-service/mailbox-service';
import InboxService from '../../../../services/inbox-service/inbox-service';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { useCommsFilter } from '../../../../hooks/use-communications';
import { useEventBus } from '../../../../hooks/use-event-bus';
import { FilterList as FilterIcon, MoreVert as MoreIcon, Close as CloseIcon } from '@mui/icons-material';
import { FilterChipsContainer, FilterChip, FilterTooltip } from '../filter-widget/styles/inbox-filter-styles';
import { InboxContainer, InboxIconButton } from '../inbox-widget/styles/inbox-styles';
import { CommsHeader, CommsPreviewFooter } from './styles/communcations-styles';
import CommunicationsPanel from './communications-panel';
import CommsChatBox from './communications-chat-box';
import CommsActionsMenuList from './communications-actions';
import { ChannelUpdateEvent } from '@revenuewell/uc-notifications-client/dist/types/notifications';
import { ChannelSearch } from '../../../../services/inbox-service/types';
import { TextTemplateProvider } from '../../../../hooks/use-text-templates/use-text-templates';
import { usePatientProfileWidget } from '../patient-profile-widget/hooks/use-patient-profile-widget';
import { ArchivedIndicator, StarredIndicator, UnreadIndicator } from '../inbox-widget/styles/inbox-preview-card-styles';
import { Box } from '@mui/material';
import { WidgetRoutePath } from '../patient-profile-widget/navigation/route-config';

export interface Status {
  isUnread?: boolean;
  isArchived?: boolean;
  isSaved?: boolean;
}

interface ChannelStatus extends Status {
  channelId: string;
}

interface CommuncationsWidgetProps {
  patientPhoneNumbers: string[];
  initialPhoneNumber: string;
  patientId?: number;
  locationId: number;
  timestamp?: number;
}

export default function CommuncationsWidget({
  patientPhoneNumbers,
  initialPhoneNumber,
  patientId,
  locationId,
  timestamp
}: CommuncationsWidgetProps) {
  const { commsFilter, updateCommsFilter } = useCommsFilter();
  const { oidcService } = useOidcWithAdmin();
  const { config } = useConfig();
  const { listen } = useNotifications();
  const { mailboxes } = usePubnub();
  const { setBreadcrumbExtensions, goTo } = usePatientProfileWidget();
  const [channelsStatuses, setChannelsStatuses] = useState<ChannelStatus[]>([]);
  const [finalStatus, setFinalStatus] = useState<Status>({});
  const [patientMailboxIds, setPatientMailboxIds] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inboxService = useMemo(() => InboxService.getInstance(config, oidcService), [config, oidcService]);

  useEffect(() => {
    setBreadcrumbExtensions({
      placeholers: {
        left: { component: CommSatusComponent },
        right: { component: FilterComponent() }
      }
    });
  }, [finalStatus, commsFilter, anchorEl, patientMailboxIds, patientPhoneNumbers]);

  const setUnread = (channels: ChannelSearch[]) => {
    channels.forEach(element => {
      const phoneNumber = element.channelId.split('.')[1];
      inboxService.applyChannelAction(phoneNumber, element.mailboxId, { isUnread: false });
    });
  };

  const fetchStatuses = useCallback(
    async (phoneNumbers: string[]) => {
      if (!mailboxes.length && !inboxService) return;

      const currMailboxes = commsFilter.mailboxes?.length ? commsFilter.mailboxes : mailboxes;
      const searchRequest = {
        mailboxIds: currMailboxes.map(m => m.id),
        phoneNumbers: phoneNumbers
      };

      const channels = await inboxService.channelSearch(searchRequest);
      if (channels.length) {
        setPatientMailboxIds(channels.map(channel => channel.mailboxId));
        const newStatuses: ChannelStatus[] = channels.map(channel => ({
          channelId: channel.channelId,
          isUnread: channel.isUnread,
          isArchived: channel.isArchived,
          isSaved: channel.isSaved
        }));
        setUnread(channels);
        setChannelsStatuses(newStatuses);
      }
    },
    [inboxService, mailboxes, commsFilter.mailboxes]
  );

  useEffect(() => {
    fetchStatuses(patientPhoneNumbers);
  }, [patientPhoneNumbers, fetchStatuses]);

  const updateStatuses = useCallback(
    (obj: any) => {
      const newStatus: ChannelStatus = {
        channelId: obj.channelId,
        isUnread: obj.isUnread === 'True',
        isArchived: obj.isArchived === 'True',
        isSaved: obj.isSaved === 'True'
      };

      const index = channelsStatuses.findIndex(item => item.channelId === newStatus.channelId);

      if (index !== -1) {
        const updatedChannelStatuses = [...channelsStatuses];
        updatedChannelStatuses[index] = newStatus;
        setChannelsStatuses(updatedChannelStatuses);
      } else setChannelsStatuses([...channelsStatuses, newStatus]);
    },
    [channelsStatuses]
  );

  useEffect(() => {
    return listen('channel-update', event => {
      const { data } = event as ChannelUpdateEvent;

      if (patientPhoneNumbers.indexOf(data.phoneNo) < 0) return;

      updateStatuses(data);
    });
  }, [listen, patientPhoneNumbers, updateStatuses]);

  const handleFinalStatusUpdate = (status: Status) => {
    setFinalStatus(status);
  };

  useEffect(() => {
    handleFinalStatusUpdate({
      isUnread: channelsStatuses.some(item => item.isUnread),
      isSaved: channelsStatuses.some(item => item.isSaved),
      isArchived: channelsStatuses.some(item => item.isArchived)
    });
  }, [channelsStatuses]);

  const onClickFilter = () => {
    goTo(WidgetRoutePath.INBOX_FILTERS);
  };

  const handleDeleteCommType = () => {
    updateCommsFilter({ commType: undefined });
  };

  const handleDeleteLocation = (itemToDelete: IMailboxWithLocation) => () => {
    const newMailboxes = commsFilter.mailboxes?.filter(mailbox => mailbox !== itemToDelete);
    updateCommsFilter({ mailboxes: newMailboxes });
  };

  const onClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const CommSatusComponent = <Box sx={{ display: 'flex', alignItems: "center" }}>
    {finalStatus.isUnread && <UnreadIndicator sx={{ marginRight: '4px' }} />}
    {finalStatus.isSaved && <StarredIndicator sx={{ marginRight: '4px' }} />}
    {finalStatus.isArchived && <ArchivedIndicator sx={{ marginRight: '4px' }} />}
  </Box>

  const FilterComponent = () => {
    return <>
      <CommsHeader>
        <InboxIconButton onClick={onClickFilter}>
          <FilterIcon fontSize='small' />
        </InboxIconButton>
        <InboxIconButton onClick={onClickMore} toggled={!!anchorEl}>
          <MoreIcon fontSize='small' />
        </InboxIconButton>
        <CommsActionsMenuList
          patientPhoneNumbers={patientPhoneNumbers}
          patientMailboxIds={patientMailboxIds}
          channelStatus={finalStatus}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </CommsHeader>
      {
        commsFilter.commType || commsFilter.mailboxes?.length ? (
          <FilterChipsContainer>
            {commsFilter.commType && (
              <FilterChip
                label={commsFilter.commType}
                variant='outlined'
                deleteIcon={<CloseIcon />}
                onDelete={handleDeleteCommType}
              />
            )}
            {commsFilter.mailboxes?.map((item, index) => (
              <FilterTooltip key={index} title={item.location.name} placement='top'>
                <FilterChip
                  label={item.location.name}
                  variant='outlined'
                  deleteIcon={<CloseIcon />}
                  onDelete={handleDeleteLocation(item)}
                />
              </FilterTooltip>
            ))}
          </FilterChipsContainer>
        ) : null
      }
    </>
  }


  return (
    <InboxContainer>
      <CommunicationsPanel timestamp={timestamp} />
      <CommsPreviewFooter>
        <TextTemplateProvider>
          <CommsChatBox
            phoneNumbers={patientPhoneNumbers}
            locationId={locationId}
            patientId={patientId}
            initialPhoneNumber={initialPhoneNumber}
          />
        </TextTemplateProvider>
      </CommsPreviewFooter>
    </InboxContainer>
  );
}
