import { Backdrop, Fade, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Window, Header, Content, Title, Description, Toolbar, SnoozeButton, FixNowButton, GetHelpButton } from './styles';
import { SmsRegistrationService } from '../../../services/sms-registration/sms-registration-service';
import { useConfig, useOidcWithAdmin } from '@revenuewell/front-end-bundle';
import { useEventBus } from '../../../hooks/use-event-bus';
import { useLocations } from '../../../hooks/use-locations';

const snoozeInterval = 5 * 60 * 1000;
const snoozeTickInterval = 5000;

export const SmsRegistartion: React.FC = () => {
  const { config } = useConfig();
  const { oidcService } = useOidcWithAdmin();
  const { publish } = useEventBus();
  const [open, setOpen] = useState(false);
  const [snoozeTick, setSnoozeTick] = useState(snoozeInterval);
  const [snoozeless, setSnoozeless] = useState(false);
  const service = new SmsRegistrationService(config, oidcService);
  const {locations} = useLocations();

  useEffect(() => {
    (async () => {
      const claims = await oidcService.getClaims();
      const locationId = claims.locationId;
      const location = locations.find(loc => loc.id = locationId);
      const locName = location?.name.toLocaleLowerCase();
      if(locName?.startsWith('demo ') || locName?.startsWith('qa ') || locName?.endsWith("(qa)"))
        return;

      const brandInfo = await service.fetchBrandInfo();

      if(brandInfo.status === 404) {
        setSnoozeless(true);
        setOpen(true);
      } else if(brandInfo.brand?.status === 'TaxValidationError') {
        setSnoozeless(false);
        setOpen(true);
        const interval = setInterval(() => setSnoozeTick((prev) => prev - snoozeTickInterval), snoozeTickInterval);
        return () => clearInterval(interval);
      }
    })();
  }, []);

  useEffect(() => {
    if (snoozeTick < snoozeTickInterval && !open) {
      (async () => {
        const brandInfo = await service.fetchBrandInfo();
        brandInfo.status !== 404 && brandInfo.brand?.status === 'TaxValidationError'
          ? setOpen(true) 
          : setSnoozeTick(snoozeInterval);
      })();
    }
  }, [snoozeTick]);

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleGetHelp = () => {
    window.open(config.support.a2pUrl, '_blank');
  };

  const handleSnoozeClick = () => {
    setOpen(false);
    setSnoozeTick(snoozeInterval);
  };

  const handleFixNowClick = () => {
    setOpen(false);
    publish({
      messageType: 'navigate',
      data: {
          pathname: `/settings/sms-registration`,
          crossFrame: true,
          replaceState: false
      }
    })
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        disableEscapeKeyDown
        disableAutoFocus={true}
        disableEnforceFocus={true}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Window sx={{ transform: 'translate(-50%, -50%)' }}>
            <Header>Urgent Action Required</Header>
            <Content>
              <Title>Your patients are not receiving your text messages.</Title>
              <Description>
                Register your practice to solve this issue.
              </Description>
              <Toolbar>
                {!snoozeless && (
                  <SnoozeButton variant="contained" color="error" onClick={handleSnoozeClick}>
                    Snooze for 5 minutes
                  </SnoozeButton>
                )}
                <GetHelpButton color='secondary' variant="contained" onClick={handleGetHelp}>
                  Get Help
                </GetHelpButton>
                <FixNowButton variant="contained" onClick={handleFixNowClick}>
                  Fix Now
                </FixNowButton>
              </Toolbar>
            </Content>
          </Window>
        </Fade>
      </Modal>
    </div>
  );
};
