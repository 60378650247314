import * as React from 'react';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    details: {
        backgroundColor: 'white',
        padding: '0px 16px'
    }
});

const AccordionDetails: React.FC = props => {
    const classes = useStyles();

    return <MuiAccordionDetails className={classes.details}>{props.children}</MuiAccordionDetails>;
};

export default AccordionDetails;
