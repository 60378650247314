import { PatientPreferencesResponse } from "../../../../../../../services/api-client/contact-api";
import { CommunicationStatus } from "../types/communication-status";
import { getCustomizedTextCount } from "./get-customized-text-count";


export const getTextStatus = (patientPreferences: PatientPreferencesResponse) => {
    const count = getCustomizedTextCount(patientPreferences);

    if (count === 0) return CommunicationStatus.none;
    if (count === 6) return CommunicationStatus.all;
    return CommunicationStatus.some;
};