import { Typography, Box, ToggleButton } from '@mui/material';
import CheckedIcon from '@mui/icons-material/Check';
import { InboxSectionSeparator, OverlineText } from '../inbox-widget/styles/inbox-styles';
import { FilterContainer, FilterToggleButtonGroup } from '../filter-widget/styles/inbox-filter-styles';
import { LocationsSelector } from './../filter-widget/filter-location-selector';
import { commTypes, CommType, ICommsFilter } from '../../../../hooks/use-communications/use-communications-filter';
import { IMailboxWithLocation } from '../../../../services/mailbox-service/mailbox-service';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';

interface CommsFilteringPanelProps {
    filterPre: ICommsFilter;
    onFilterPreselected: (filterPre: Partial<ICommsFilter>) => void;
}

export default function CommsFilteringPanel({ filterPre, onFilterPreselected }: CommsFilteringPanelProps) {
    const { mailboxes } = usePubnub();

    const onCommTypeChange = (_event: React.MouseEvent<HTMLElement>, newCommType: CommType) => {
        onFilterPreselected({ commType: newCommType });
    };

    const onMailboxesChange = (newMailboxes: IMailboxWithLocation[]) => {
        onFilterPreselected({ mailboxes: newMailboxes });
    }

    return (
        <FilterContainer>
            <InboxSectionSeparator>
                <OverlineText variant='overline'>Channel</OverlineText>
            </InboxSectionSeparator>
            <Box>
                <FilterToggleButtonGroup
                    value={filterPre.commType}
                    exclusive
                    onChange={onCommTypeChange}
                    aria-label="communication type"
                >
                    {commTypes.map((commType, index) =>
                        <ToggleButton
                            key={index}
                            value={commType}>
                            {filterPre.commType === commType &&
                                <CheckedIcon sx={{ fontSize: 'small', mr: 1 }} />
                            }
                            <Typography variant='body2' textTransform='none' color='#0259AB'>
                                {commType}
                            </Typography>
                        </ToggleButton>
                    )}
                </FilterToggleButtonGroup>
            </Box>
            {mailboxes.length > 1 && (
                <>
                    <InboxSectionSeparator>
                        <OverlineText variant='overline'>Practice Location</OverlineText>
                    </InboxSectionSeparator>
                    <LocationsSelector
                        value={filterPre.mailboxes || []}
                        onChange={(value) => onMailboxesChange(value)}
                    />
                </>
            )}
        </FilterContainer>
    );
}