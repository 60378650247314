import { OidcService } from '@revenuewell/logic-oidc';
import { AuthApiService } from '@revenuewell/logic-http';
import { NavigationService } from '@revenuewell/logic-navigation';

export interface ChannelsUnreadCountsParams {
    mailboxIds: number[];
}

export interface ChannelsUnreadCounts {
    total: number;
    newPatient: number;
    saved: number;
}

export default class PublisherService extends AuthApiService {
    private config: any;
    private mailboxId: number;
    private publisherUrl: string;

    constructor(config: any, oidcService: OidcService, mailboxId: number) {
        super(oidcService, new NavigationService());
        this.config = config;
        this.mailboxId = mailboxId;
        this.publisherUrl = this.config.publisher.apiUrl;
        return this;
    }

    public async sendMessage(from: string, to: string, body: string, messageType = 'direct'): Promise<void> {
        const url = `${this.publisherUrl}/mailboxes/${this.mailboxId}/channels/${to}/messages`;

        const data = {
            from,
            to,
            body,
            date: new Date().toISOString(),
            messageType,
            isResponseAwaiting: true
        };

        await this.authFetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        });
    }

    public async applyChannelAction(
        phoneNumber: string,
        action: { isArchived?: boolean; isSaved?: boolean; isUnread?: boolean }
    ): Promise<void> {
        const url = `${this.publisherUrl}/mailboxes/${this.mailboxId}/channels/${phoneNumber}`;

        await this.authFetch(url, {
            method: 'PATCH',
            body: JSON.stringify(action),
            headers: { 'Content-Type': 'application/json' }
        });
    }

    public async channelsUnreadCounts(params: ChannelsUnreadCountsParams): Promise<ChannelsUnreadCounts> {
        const url = `${this.publisherUrl}/mailboxes/channels/unreadcounts/messenger`;

        const response = await this.authFetch(url, {
            method: 'POST',
            body: JSON.stringify({
                ...params,
            }),
            headers: { 'Content-Type': 'application/json' }
        });

        const data: ChannelsUnreadCounts = await response.json();

        return data;
    }

    public async addMessageAction(phoneNumber: string, messageTimetoken: string, action: { type: string, value: string }) {
        const url = `${this.publisherUrl}/message-actions/${this.mailboxId}/channels/${phoneNumber}/messages/${messageTimetoken.replace('.', '')}`;
        await this.authFetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ action })
        })
    }
    
    public async removeMessageAction(phoneNumber: string, messageTimetoken: string, actionTimetoken: string) {
        const url = `${this.publisherUrl}/message-actions/${this.mailboxId}/channels/${phoneNumber}/messages/${messageTimetoken.replace('.', '')}?actionTimetoken=${actionTimetoken.replace('.', '')}`;
        return await this.authFetch(url, { method: 'DELETE' });
    }
}
