import React from 'react';
import { PubnubProvider } from '../../../hooks/use-pubnub/use-pubnub';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../shared/material-theme';
import { MessengerNotificationProvider } from '../../../hooks/use-messenger-notification/use-messenger-notification';
import { EventBusProvider } from '../../../hooks/use-event-bus';
import { AlertsProvider } from '../../../hooks/use-alerts';
import { NotificationsProvider } from '@revenuewell/uc-notifications-client';
import { Intercom } from '../../../hooks/use-intercom';
import { SearchProvider } from '../../../hooks/use-search/use-search';
import ViewModeProvider from '../../../hooks/use-view-mode/provider';
import { SlideOutCardProvider } from '../../shared/slide-out-card/provider';
import HistoryInterceptProvider from '../../../hooks/use-history-intercept';
import { AdminInterceptProvider } from '@revenuewell/front-end-bundle';
import { LocationsProvider } from '../../../hooks/use-locations';
import { ContactsProvider } from '../../../hooks/use-contacts';
import { PhoneWidgetProviders } from '@revenuewell/widget-phone';
import { SettingsProvider } from '../../../hooks/use-settings';
import { InboxPreviewProvider, InboxFilterProvider } from '../../../hooks/use-inbox';
import { CommunicationsProvider, CommsFilterProvider, CommsChatProvider } from '../../../hooks/use-communications';
import { KazooProvider } from '../../../hooks/use-kazoo/use-kazoo';
import { VoicemailProvider } from '../../../hooks/use-voicemail/use-voicemail';
import { LwpWidgetProviders } from '@revenuewell/widget-legacy-phone';
import { FaxProvider } from '../../../hooks/use-fax/use-fax';
import { FeatureFlagProvider } from '../../../hooks/feature-flags/use-feature-flags';
import { InsuranceAlertsProvider } from '../../../hooks/use-insurance-alerts/use-insurance-alerts';

const useGlobalStyles = makeStyles({
  '@global': {
    body: {
      color: 'rgba(0,0,0,0.87)',
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      margin: 0,
      backgroundColor: '#ffffff',

      '& #root': {
        width: '100vw',
        height: '100vh'
      },

      '& a': {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'inherit'
      }
    }
  }
});

export default function AppProviders(props: React.PropsWithChildren<{}>) {
  useGlobalStyles();
  return (
    <AdminInterceptProvider>
      <HistoryInterceptProvider>
        <SlideOutCardProvider>
          <EventBusProvider>
            <ViewModeProvider>
              <SettingsProvider>
                <FeatureFlagProvider>
                  <LocationsProvider>
                    <ContactsProvider>
                      <KazooProvider>
                        <PhoneWidgetProviders>
                          <LwpWidgetProviders>
                            <VoicemailProvider>
                              <FaxProvider>
                                <PubnubProvider>
                                  <Intercom>
                                    <MessengerNotificationProvider>
                                      <NotificationsProvider>
                                        <InsuranceAlertsProvider>
                                          <AlertsProvider>
                                              <InboxPreviewProvider>
                                                <CommsFilterProvider>
                                                  <InboxFilterProvider>
                                                    <CommunicationsProvider>
                                                      <CommsChatProvider>
                                                        <SearchProvider>
                                                          <ThemeProvider theme={theme}>
                                                            {props.children}
                                                          </ThemeProvider>
                                                        </SearchProvider>
                                                      </CommsChatProvider>
                                                    </CommunicationsProvider>
                                                  </InboxFilterProvider>
                                                </CommsFilterProvider>
                                              </InboxPreviewProvider>
                                          </AlertsProvider>
                                        </InsuranceAlertsProvider>
                                      </NotificationsProvider>
                                    </MessengerNotificationProvider>
                                  </Intercom>
                                </PubnubProvider>
                              </FaxProvider>
                            </VoicemailProvider>
                          </LwpWidgetProviders>
                        </PhoneWidgetProviders>
                      </KazooProvider>
                    </ContactsProvider>
                  </LocationsProvider>
                </FeatureFlagProvider>
              </SettingsProvider>
            </ViewModeProvider>
          </EventBusProvider>
        </SlideOutCardProvider>
      </HistoryInterceptProvider>
    </AdminInterceptProvider>
  );
}
