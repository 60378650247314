import { useEventBus } from "../../../../../../../hooks/use-event-bus";
import { Link } from '@mui/material';
import { PhoneType } from "../types";
import { useStyles } from "./phone-component.styles";

interface PhoneComponentProps {
  phoneNumber?: string;
  phoneType: PhoneType;
};

export function PhoneComponent({ phoneNumber, phoneType }: PhoneComponentProps) {
  const classes = useStyles();
  const { publish } = useEventBus();

  const formattedPhone = phoneNumber && phoneNumber.length > 0
    ? `(${phoneNumber.substring(0, 3)})${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`
    : '-';

  if (formattedPhone !== '-') {
    return (
      <div className={classes.phoneNumber}>
        <Link style={{ textDecoration: 'none' }} onClick={() => {
          publish({
            messageType: 'startCall',
            data: {
              phoneNumber: formattedPhone
            }
          });
        }}
        >
          {formattedPhone}
        </Link>
        <label>{`${phoneType}`}</label>
      </div>
    );
  } else {
    return (
      <div className={classes.phoneNumber}>
        <label>{formattedPhone}</label>
        <label>{`${phoneType}`}</label>
      </div>
    );
  }
}