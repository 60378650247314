import React from 'react'
import { styled, Box, Typography } from '@mui/material'

export const PatientProfileWidgetWrapper = styled('div')({
  fontFamily: 'Roboto',
  fontSize: '12px',
  letterSpacing: '0.15px',
  color: 'rgba(28, 61, 100, 1)',
  backgroundColor: 'white',
  margin: 0,
  padding: 0,
  cursor: 'default',
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column'
})
