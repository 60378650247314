import { format } from 'date-fns';
import { AlertContainer, AlertContent, IconBox } from './alert';
import AlertHeader from './alert-header';
import AlertLocation from './alert-location';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { styled } from '@mui/material';
import { useHistory } from '../../../hooks/use-history-intercept';

const AlertIconBox = styled(IconBox)({
    backgroundColor: '#B5D1F9'
});

const AlertIcon = styled(DescriptionOutlinedIcon)({
    color: '#2C5A9C',
    width: 16
});

interface FormAlertProps {
    date: Date;
    location?: string;
    name?: string | null;
    patientId?: string | null;
    onRemove?: () => void;
}

export default function CampaignsUnsubscribedAlert({ date, name, patientId, location, onRemove }: FormAlertProps) {
    const history = useHistory();

    return (
        <AlertContainer onClick={() => history.push(`/contacts/contacts/${patientId}`)}>
            <AlertHeader
                icon={
                    <AlertIconBox>
                        <AlertIcon />
                    </AlertIconBox>
                }
                title='Campaigns'
                date={format(date, 'hh:mm a')}
                onClose={onRemove}
            />
            <AlertContent>{name} has unsubscribed from your online communications.</AlertContent>
            {!!location && <AlertLocation>{location}</AlertLocation>}
        </AlertContainer>
    );
}
