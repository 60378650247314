import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses
} from '@mui/material';
import { ContactSearchByNumber } from '@revenuewell/front-end-bundle';
import { useEffect, useMemo, useState } from 'react';
import { useContactState } from '../../../../hooks/use-contacts';
import { usePubnub } from '../../../../hooks/use-pubnub/use-pubnub';
import { IMailboxWithLocation } from '../../../../services/mailbox-service/mailbox-service';
import { importantContacts } from '../../../../utils/contact';

interface CommLocationSelectProps {
    currentMailbox: IMailboxWithLocation;
    phoneNumbers: string[];
    currentPhoneNumber: string;
    patientId?: number;
    onSelectPhoneNumber: (phone: string) => void;
}

interface PhoneNumberEntry {
    phoneNumber: string;
    displayName: string;
    type: string;
}

const PhoneNumberSelect = ({
    currentMailbox,
    currentPhoneNumber,
    phoneNumbers,
    patientId,
    onSelectPhoneNumber
}: CommLocationSelectProps) => {
    const { mailboxes } = usePubnub();
    const [searchText, setSearchText] = useState('');
    const { contactCache } = useContactState();
    const [initialized, setInitialized] = useState(false);

    const DisplayName = styled(Typography)({
        color: '#0259AB',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    });

    const PhoneNumberTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11
        }
    }));

    const style = {
        '.MuiOutlinedInput-input': {
            paddingTop: '8px',
            paddingBottom: '8px'
        },
        '.MuiOutlinedInput-notchedOutline ': {
            border: '1px solid rgba(25, 118, 210, 0.5)'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(25, 118, 210, 0.5)'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(25, 118, 210, 0.5)'
        }
    };

    const contacts = useMemo(() => {
        const tmpContacts = phoneNumbers.flatMap(phoneNumber =>
            contactCache[phoneNumber] ? contactCache[phoneNumber].data : []
        );
        return tmpContacts;
    }, [phoneNumbers, patientId]);

    const finalContacts = useMemo(() => {
        if (!currentMailbox || !contacts?.length) {
            return undefined;
        }
        return importantContacts(contacts, patientId, currentMailbox.location.idNetwork);
    }, [currentMailbox, contacts]);

    const generateName = (contact: ContactSearchByNumber | undefined) => {
        if(contact){
            return contact.firstName + ' ' + contact.lastName + (contact.isResponsibleParty ? " RP" : "");
        }
        return "";
    }

    const displayedOptions = useMemo(() => {
        const phoneNumberOptions: PhoneNumberEntry[] = [];
        const phoneNumberSeen = new Set();
        finalContacts?.map(finalContact => {
            if (finalContact?.cellPhone && !phoneNumberSeen.has(finalContact?.cellPhone)) {
                phoneNumberOptions.push({
                    phoneNumber: finalContact.cellPhone,
                    displayName:
                        finalContacts.length == 1
                            ? 'Cell'
                            : generateName(finalContact) + ' (Cell) ',
                    type: 'cell'
                });
                phoneNumberSeen.add(finalContact?.cellPhone);
            }

            if (finalContact?.homePhone && !phoneNumberSeen.has(finalContact?.homePhone)) {
                phoneNumberOptions.push({
                    phoneNumber: finalContact.homePhone,
                    displayName:
                        finalContacts.length == 1
                            ? 'Home'
                            : generateName(finalContact) + ' (Home) ',
                    type: 'home'
                });
                phoneNumberSeen.add(finalContact?.homePhone);
            }

            if (finalContact?.workPhone && !phoneNumberSeen.has(finalContact?.workPhone)) {
                phoneNumberOptions.push({
                    phoneNumber: finalContact.workPhone,
                    displayName:
                        finalContacts.length == 1
                            ? 'Work'
                            : generateName(finalContact) + ' (Work) ',
                    type: 'work'
                });
                phoneNumberSeen.add(finalContact?.workPhone);
            }
        });

        return phoneNumberOptions;
    }, [searchText, finalContacts, mailboxes]);

    useEffect(() => {
        const isCell = displayedOptions.filter(options => 
            options.phoneNumber == currentPhoneNumber && options.type == 'cell').length > 0;

        if (!isCell) {
            const phoneNumber = finalContacts 
                && finalContacts[0].cellPhone 
                && finalContacts.filter(x => x.cellPhone === currentPhoneNumber).length === 1 
                    ? finalContacts[0].cellPhone 
                    : currentPhoneNumber;

            if (phoneNumber != currentPhoneNumber) onSelectPhoneNumber(phoneNumber);
        }
        setInitialized(true);
    }, [currentPhoneNumber, displayedOptions, finalContacts]);

    if (!displayedOptions?.length || !initialized) return <></>;

    return (
        <FormControl fullWidth variant='outlined'>
            <Select
                id='search-select'
                labelId='search-select-label'
                sx={{ ...style, color: '#0259AB' }}
                MenuProps={{
                    autoFocus: false,
                    MenuListProps: { sx: { backgroundColor: '#FFFFFF', width: 290 } },
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    transformOrigin: { vertical: 'bottom', horizontal: 'left' }
                }}
                value={currentPhoneNumber}
                onChange={e => onSelectPhoneNumber(e.target.value)}
                onClose={() => setSearchText('')}
                renderValue={() => {
                    const name = displayedOptions.find(phone => phone.phoneNumber === currentPhoneNumber)?.displayName;
                    return (
                        <PhoneNumberTooltip title={name}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <AccountCircleRoundedIcon sx={{ color: '#0675DD' }} />
                                <DisplayName> {name} </DisplayName>
                            </Box>
                        </PhoneNumberTooltip>
                    );
                }}
            >
                {displayedOptions.map((option, i) => (
                    <MenuItem key={i} value={option.phoneNumber}>
                        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{option.displayName}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PhoneNumberSelect;
