import React, { useCallback } from 'react';
import moment from 'moment';
import { formatPhoneNumber } from '../../utils/format-phone-number';
import { Box } from '@mui/material';
import { PatientInfo, PatientName, VerticalSeparator } from './contact-info.styles';
import { usePatientProfileWidget } from '../../hooks/use-patient-profile-widget';

export const ContactInfo = () => {
  const { contactDetails, contactsByPhone } = usePatientProfileWidget();

  const getPhoneNumber = (contactDetails: any) => {
    if (contactDetails.cellPhone)
      return formatPhoneNumber(contactDetails.cellPhone);
    else if (contactDetails.homePhone)
      return formatPhoneNumber(contactDetails.homePhone);
    else if (contactDetails.workPhone)
      return formatPhoneNumber(contactDetails.workPhone);
    return '';
  }

  const getContactName = useCallback(() => {
    let name = `${contactDetails.firstName} ${contactDetails.lastName}`;

    if(contactsByPhone?.length > 1) return name += ' + Others';

    if(contactDetails.isResponsibleParty) name += ` (RP)`;

    return name;
  }, [contactDetails, contactsByPhone]);

  return <>
    {contactDetails &&
      <PatientInfo>
        <PatientName>
          {`${getContactName()}${contactDetails.dateOfBirth ? `, ${moment().diff(contactDetails.dateOfBirth, 'years', false)}` : ''}`}
        </PatientName>
        <Box>
          {`${contactDetails.dateOfBirth ? moment(contactDetails.dateOfBirth).format('MM/DD/YYYY') : ''}`}
          {contactDetails.dateOfBirth ? <VerticalSeparator /> : null}
          {`${getPhoneNumber(contactDetails)}`}
        </Box>
      </PatientInfo>
    }
  </>
}