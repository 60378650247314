import InboxFilteringPanel from './inbox-filter-panel';
import { useInboxFilter } from '../../../../hooks/use-inbox';
import { IInboxFilter } from '../../../../hooks/use-inbox/use-inbox-filter';
import { useEffect, useState } from 'react';
import {
    InboxContainer,
    InboxHeader,
    InboxTitle,
    InboxPreviewFooter,
    InboxFooterButton,
    InboxFooterButtonLight
} from '../inbox-widget/styles/inbox-styles';

interface InboxFilterWidgetProps {
    onFilterApplied: () => void;
}

export default function InboxFilterWidget({ onFilterApplied }: InboxFilterWidgetProps) {
    const { previewFilter, updatePreviewFilter } = useInboxFilter();
    const [filterPre, setFilterPre] = useState(previewFilter);

    useEffect(() => {
        setFilterPre(previewFilter);
    }, [previewFilter]);

    const onFilterPreselected = (newFilterPre: Partial<IInboxFilter>) => {
        setFilterPre((prevFilterPre) => ({
            ...prevFilterPre,
            ...newFilterPre
        }));
    };

    const onClickResetFilter = () => {
        setFilterPre({
            commType: undefined,
            commStatuses: undefined,
            mailboxes: undefined
        })
    };

    const onClickApplyFilter = () => {
        updatePreviewFilter(filterPre);
        onFilterApplied();
    };

    const isFilterOptionsEmpty = (filterPre: IInboxFilter) => {
        return (
            !filterPre.commStatuses?.length &&
            !filterPre.mailboxes?.length &&
            !filterPre.commType
        );
    };

    return (
        <InboxContainer>
            <InboxHeader>
                <InboxTitle variant='h3'> Filtering - Inbox </InboxTitle>
            </InboxHeader>
            <InboxFilteringPanel
                filterPre={filterPre}
                onFilterPreselected={onFilterPreselected}
            />
            <InboxPreviewFooter>
                <InboxFooterButtonLight
                    disabled={isFilterOptionsEmpty(filterPre)}
                    variant='contained'
                    size='medium'
                    onClick={onClickResetFilter}
                    disableElevation
                >
                    Clear All
                </InboxFooterButtonLight>
                <InboxFooterButton
                    variant='contained'
                    size='medium'
                    onClick={onClickApplyFilter}
                    disableElevation
                >
                    Apply
                </InboxFooterButton>
            </InboxPreviewFooter>
        </InboxContainer>
    );
}
